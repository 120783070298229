import axios from '../../axios/axiosInstance';
import Actions from './actionTypes';
import {alertActions} from './alertActions';
import store from 'store';
import {urlBase64ToUint8Array} from '../../helpers/pushNotificationVapidConverter';
import {browserDetector} from '../../utils/browserDetector';
import {fecthProfile} from "./userActions";

export const changePassword = (data, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .put('/users/password', data)
        .then((response) => {
            dispatch(alertActions.success(response.data.message));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 401) {
                    history.push('/login');
                }
            }
        });
};

export const updateUserProfile = (data, history) => {
    return async (dispatch) => {
        const userData = store.get('kmt-user');
        dispatch(alertActions.clear());
        const url = '/users';
        await axios
            .put(url, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                store.set('kmt-user', {
                    accessToken: userData.accessToken,
                    areaOfInterest: userData.areaOfInterest,
                    country: userData.country,
                    roles: userData.roles,
                    imageUrl: response.data.user.imageUrl,
                    dob: response.data.user.dob,
                    fullName: response.data.user.fullName,
                    gender: response.data.user.gender,
                    talentCategories: response.data.user.talentCategories,
                });
                dispatch(alertActions.success(response.data.message));
                dispatch(fecthProfile(response.data.user));
            })
            .catch((error) => {
                if (error.response) {
                    dispatch(
                        alertActions.error(
                            error.response.data.message || error.response.message
                        )
                    );
                }
            });
    };
};

export const fetchPrivacySettings = (history) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .get('/privacySettings')
        .then((response) => {
            dispatch({
                type: Actions.FETCH_PRIVACY_SETTINGS,
                payload: response.data,
            });
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 401) {
                    history.push('/login');
                }
            }
        });
};

export const showOrHideReadReceipt = (history, data) => async (dispatch) => {
    dispatch(alertActions.clear());
    dispatch(toggleReadReceipt(data));

    await axios
        .put('/users/readReceipt')
        .then((response) => {
            dispatch(toggleReadReceipt(data));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(toggleReadReceipt(!data));
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 401) {
                    history.push('/login');
                }
            }
        });
};

export const toggleMakeAccountPrivate = (history, data) => async (dispatch) => {
    dispatch(alertActions.clear());
    dispatch(makeAccountPrivate(data));

    await axios
        .put('/users/private')
        .then((response) => {
            dispatch(makeAccountPrivate(data));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(makeAccountPrivate(!data));
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 401) {
                    history.push('/login');
                }
            }
        });
};

export const showOrHideLocation = (history, data) => async (dispatch) => {
    dispatch(alertActions.clear());
    dispatch(showLocation(data));

    await axios
        .put('/users/showLocation')
        .then((response) => {
            dispatch(showLocation(data));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(showLocation(!data));
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 401) {
                    history.push('/login');
                }
            }
        });
};

export const fetchNotificationAndAlertSettings = (history) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .get('/notificationSettings')
        .then((response) => {
            dispatch({
                type: Actions.FETCH_NOTIFICATIONS_AND_ALERT_SETTINGS,
                payload: response.data,
            });
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 401) {
                    history.push('/login');
                }
            }
        });
};

export const subscribeToNewsLetter = (history) => async (dispatch) => {
    dispatch(alertActions.clear());
    dispatch(activateSubscribeToNewsLetter(true));

    await axios
        .post('/newsLetterSub')
        .then((response) => {
            dispatch(activateSubscribeToNewsLetter(true));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(activateSubscribeToNewsLetter(false));
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 401) {
                    history.push('/login');
                }
            }
        });
};

export const unsubscribeToNewsLetter = (data, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    dispatch(activateSubscribeToNewsLetter(false));
    await axios
        .delete('/newsLetterSub')
        .then((response) => {
            dispatch(activateSubscribeToNewsLetter(false));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(activateSubscribeToNewsLetter(true));
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 401) {
                    history.push('/login');
                }
            }
        });
};

// toggle email notification setting activation
export const emailNotifcationActivation = (activate, deactivate, history) => async (
    dispatch
) => {
    dispatch(alertActions.clear());
    dispatch(allowEmailNotification(activate));

    await axios
        .put('/users/emailNotification')
        .then((response) => {
            dispatch(allowEmailNotification(activate));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(allowEmailNotification(deactivate));
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 401) {
                    history.push('/login');
                }
            }
        });
};

// dispatch functions
const toggleReadReceipt = (data) => ({
    type: Actions.TOGGLE_READ_RECEIPT,
    payload: data,
});

const makeAccountPrivate = (data) => ({
    type: Actions.MAKE_ACCOUNT_PRIVATE,
    payload: data,
});

const showLocation = (data) => ({
    type: Actions.SHOW_LOCATION,
    payload: data,
});

const activateSubscribeToNewsLetter = (data) => ({
    type: Actions.SUBSCRIBE_TO_NEWSLETTER,
    payload: data,
});

const allowEmailNotification = (bool) => ({
    type: Actions.ALLOW_EMAIL_NOTIFICATION,
    payload: bool,
});

//--------------------------------------- //
// ------- PUSH NOTIFICATION ------------ //
//--------------------------------------- //

// Vapig key for push notification
const vapidPublicKey =
    'BGQ8hn0yDjlMZrRFauK6GWXGjAVNfZFq8l13olcax7spTeposj7QirtCo3bC7jJ9CkLnpszfFwjFabJCmZx8uzQ';

// convert vapid key
const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

export const triggerPushNotification = (history) => async (dispatch) => {
    dispatch(alertActions.clear());
    addPushNotification(history, dispatch);
};

export const addPushNotification = async (history, dispatch) => {
    if ('serviceWorker' in navigator) {
        const register = await navigator.serviceWorker.register('/sw.js', {
            scope: '/',
        });
        try {
            const subscription = await register.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: convertedVapidKey,
            });

            Notification.requestPermission();

            axios
                .post('/subscriptions', {
                    subscription,
                    browser: browserDetector(),
                })
                .then((response) => {})
                .catch((error) => {
                    if (error.response) {
                        dispatch(alertActions.error(error.response.data.message));
                        if (error.response.status === 401) {
                            history.push('/login');
                        }
                    }
                });
        } catch (err) {
            // console.error(err);
        }
    } else {
        // console.error('Service workers are not supported in this browser');
    }
};

export const removePushNotification = (history) => async (dispatch) => {
    dispatch(alertActions.clear());
    if ('serviceWorker' in navigator) {
        await axios
            .delete('/subscriptions')
            .then((response) => {})
            .catch((error) => {
                if (error.response) {
                    dispatch(alertActions.error(error.response.data.message));
                    if (error.response.status === 401) {
                        history.push('/login');
                    }
                }
            });

        // unsubscribe
        // const reg = await navigator.serviceWorker.ready;
        // const subscription = await reg.pushManager.getSubscription();
        // const successful = await subscription.unsubscribe();
    } else {
        console.error('Service workers are not supported in this browser');
    }
};
