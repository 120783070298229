import React, {useState, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import RegistrationDefault from '../../components/layout/RegistrationDefault';
import $ from 'jquery';
import {connect} from 'react-redux';
import * as actions from '../../redux/actions';
import {genderData} from './selectDatas';
import Select from 'react-select';
import {validateAll} from 'indicative/validator';
import {scrollTop} from '../../utils/scrollTop';
import {displayLoader} from '../../utils/displayLoader';
import LeftViewOnAuthPages from '../../components/Blocks/LeftViewOnAuthPages';
import useAlert from '../../components/CustomHooks/useAlert';
import {withRouter} from 'react-router-dom';
import InputPhoneNumber from '../../components/Blocks/InputPhoneNumber';
import {capitalizeFirstLetter} from '../../utils/capitalizeFirstLetter';
import {countries_states} from '../../utils/countries_states';
import store from 'store';
import Input from '../../components/Form/Input';

const initial = {
    profilePicture: '',
    dob: '',
    gender: '',
    country: '',
    phone: '',
    sponsorCode: '',
};
const CompleteSignup = (props) => {
    const user = store.get('kmt-user');
    const isVoter = user && user.areaOfInterest === 'vote';

    const [formData, setFormData] = useState(initial);
    const [category, setCategory] = useState('');
    const [errors, setErrors] = useState('');
    const [fileSizeError, setFileSizeError] = useState(null);
    const [countriesList, setCountriesList] = useState([]);
    const [callingCode, setCallingCode] = useState('+000 ');

    // fetch list of countries
    useEffect(() => {
        fetchCountries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchCountries = async () => {
        await props.getAllTalentCategories();

        const countries = countries_states.map((country) => ({
            label: country.name,
            value: country.name,
            callingCodes: country.phone_code.replace('+', ''),
        }));

        setCountriesList(countries);
    };

    const handleProfilePictureUpload = (e) => {
        $('#avatar_file').click();
        $('#avatar_file').change(function (e) {
            e.preventDefault();
            profilePhoto(this);
        });
    };

    function profilePhoto(input) {
        if (input.files && input.files[0]) {
            if (input.files[0].size <= 2097152) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    $('.avatar').attr(
                        'style',
                        "background-image: url('" + e.target.result + "')"
                    );
                };
                reader.readAsDataURL(input.files[0]);
                handleInputChange('profilePicture', input.files[0]);
                setFileSizeError(null);
            } else {
                setFileSizeError('File too big! (Max of 2MB)');
            }
        }
    }

    const handleInputChange = (name, value) => {
        if (name === 'country') {
            setCallingCode(value ? `+${value.callingCodes} ` : '');
        }
        setFormData({
            ...formData,
            [name]: value,
            phone: name === 'country' ? '' : name === 'phone' ? value : '',
        });
    };

    const handleErrorRemoveOnFocus = (name) => {
        setErrors({
            ...errors,
            [name]: '',
        });
    };

    const handleSelectedCategories = (e) => {
        setCategory(e);
    };

    // handle complete form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            category,
        };
        const rules = isVoter
            ? {}
            : {
                  category: 'required',
              };

        const messages = isVoter
            ? {}
            : {
                  'category.required': 'Pick a category that meet your talent',
              };

        validateAll(data, rules, messages)
            .then(() => {
                scrollTop();
                completeSignUp();
            })
            .catch((errors) => {
                const formattedErrors = {};
                errors.forEach((error) => (formattedErrors[error.field] = error.message));
                setErrors(formattedErrors);
            });

        const completeSignUp = async () => {
            const {profilePicture, dob, gender, country, sponsorCode, phone} = formData;
            // let value = Array.from(category, (option) => option.value);

            const file = new FormData();
            file.append('file', profilePicture);
            file.set('dob', dob && new Date(dob).toISOString());
            file.set('gender', gender.value);
            file.set('sponsorCode', sponsorCode);
            file.set('country', country.value);
            file.set('phone', `${callingCode}${phone}`);
            file.set('talentCategories', [category.value]);

            // Send Request
            await props.completeNormalUserSignup(file, props.history);
        };
    };

    const renderSelectCategory = () => {
        if (isVoter) {
            return <React.Fragment />;
        }

        const {talentCategories} = props;
        const options = talentCategories.map((c) => ({
            label: capitalizeFirstLetter(c.name),
            value: c.name,
        }));

        return (
            <div className="input bordered categories-select">
                <Select
                    isClearable
                    name="category"
                    placeholder="Select Categories"
                    className="select                            "
                    onChange={handleSelectedCategories}
                    options={options}
                    onFocus={() => handleErrorRemoveOnFocus('category')}
                />
                <small className="text-danger">{errors.category}</small>
            </div>
        );
    };

    useAlert(props.alert);

    return (
        <>
            <RegistrationDefault
                pageTitle={'Complete Signup!'}
                linkName={'Login'}
                linkUrl={'/login'}
                linkStyle={'adj'}
                pageNum="02"
                pageTag="Personal Information"
            />
            <main className="account">
                <LeftViewOnAuthPages step={2} />
                <section className="right-pad">
                    {props.completeSignupStart && displayLoader('Configuring Account')}
                    <div className="container">
                        <form onSubmit={handleSubmit}>
                            <section
                                className="step-2 animated fadeIn"
                                style={{display: 'block'}}
                            >
                                <div className="title">
                                    <h3>Almost Done !!</h3>
                                    <p>Please update your personal Information </p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div
                                            className="profile-placeholder"
                                            onClick={handleProfilePictureUpload}
                                        >
                                            <input
                                                type="file"
                                                hidden
                                                id="avatar_file"
                                                accept="image/*"
                                            />
                                            <div className="avatar"></div>
                                            <p>
                                                Profile Image{' '}
                                                <small>Upload your display image</small>
                                                {fileSizeError && (
                                                    <small className="text-danger">
                                                        {fileSizeError}
                                                    </small>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="input bordered date custom-date-select">
                                            <DatePicker
                                                name="dob"
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={50}
                                                showMonthDropdown
                                                selected={formData.dob}
                                                onChange={(date) =>
                                                    handleInputChange('dob', date)
                                                }
                                                maxDate={new Date()}
                                                placeholderText="Date of Birth"
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                        <div className="input bordered categories-select">
                                            <Select
                                                isClearable
                                                name="gender"
                                                placeholder="Select Gender"
                                                className="select"
                                                onChange={(e) =>
                                                    handleInputChange('gender', e)
                                                }
                                                options={genderData}
                                            />
                                        </div>
                                        <div className="input bordered categories-select">
                                            <Select
                                                isClearable
                                                name="country"
                                                placeholder="Select Country"
                                                className="select"
                                                onChange={(e) =>
                                                    handleInputChange('country', e)
                                                }
                                                options={countriesList}
                                            />
                                        </div>

                                        <InputPhoneNumber
                                            callingCode={callingCode}
                                            phone={formData.phone}
                                            handleInputChange={(e) =>
                                                handleInputChange('phone', e.target.value)
                                            }
                                        />

                                        {renderSelectCategory()}

                                        <Input
                                            type="text"
                                            name="sponsorCode"
                                            value={formData.sponsorCode}
                                            placeholder="Referral code"
                                            label="Referral code"
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'sponsorCode',
                                                    e.target.value
                                                )
                                            }
                                            errorMessage={errors.sponsorCode}
                                        />

                                        <div className="action">
                                            <button
                                                type="submit"
                                                className="btn btn-primary btn-block"
                                                disabled={
                                                    props.completeSignupStart
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {' '}
                                                Complete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </form>
                    </div>
                </section>
            </main>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        completeSignupStart: state.auth.completeSignupStart,
        alert: state.alert,
        talentCategories: state.talentCategories.talentCategories,
    };
};

export default withRouter(connect(mapStateToProps, actions)(CompleteSignup));
