import React, {useState} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';

const CreateComment = (props) => {
    const token = localStorage.getItem('token');
    const [commentText, setCommentText] = useState('');

    const handleCommentSubmit = (e) => {
        e.preventDefault();
        const data = {
            postId: props.postId,
            commentBody: commentText,
        };
        props.makeComment(data, token);
        setCommentText('');
    };
    return (
        <form onSubmit={handleCommentSubmit}>
            <section className="input comment-input">
                <input
                    type="text"
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                    placeholder="Write a comment"
                />
                <button
                    type="submit"
                    className="btn flat"
                    disabled={props.isCommentStart ? true : false}
                >
                    Post
                </button>
            </section>
        </form>
    );
};

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, actions)(CreateComment);
