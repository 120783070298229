import React, {useState, useEffect} from 'react';
import {withRouter, Link} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import {connect} from 'react-redux';
import * as actions from '../../redux/actions';
import store from 'store';

import CreatePost from './CreatePost';
import Post from './Post';

import {TimeSettings} from '../../utils/TimeSettings';
import SharePostWithAddedTextModal from './PostBits/SharePostWithAddedTextModal';
import EditPostModal from './PostBits/EditPostModal';
import $ from 'jquery';
import DeletePostModal from './PostBits/DeletePostModal';
import FilterTalentModal from '../Talents/FilterTalentModal';
import {DateSuperscript} from '../../utils/DateSuperscript';
import {stringFirstUpperCase} from '../../utils/stringFirstsUppercase';
import {capitalizeFirstLetter} from '../../utils/capitalizeFirstLetter';
import Audition from "./Audition";

const ShowLoaderWhenFetchingPost = () => {
    return (
        <section className="content-post">
            <div className="panel">
                <div className="head">
                    <div className="media-panel">
                        <figure>
                            <div></div>
                        </figure>
                        <section>
                            <div></div>
                        </section>
                    </div>
                </div>
                <section className="content">
                    <div className="media fetching" style={{background: '#f2f2f2'}}></div>
                    <div className="media-panel">
                        <section className="control"></section>
                        <div className="comment-placeholder">
                            <figure>
                                <div></div>
                                <div></div>
                            </figure>
                            <div></div>
                            <div></div>
                            <div></div>
                            <hr />
                            <form>
                                <section className="input"></section>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
};

const CenterBar = (props) => {
    const match = props.match.path;
    const userData = store.get('kmt-user');
    const catId =
        userData &&
        userData.talentCategories &&
        userData.talentCategories.length &&
        userData.talentCategories[0].id;

    const [feeds, setFeeds] = useState([]);

    const [selectedFeed, setSelectedFeed] = useState({});

    useEffect(() => {
        setFeeds(props.feeds.slice(0, 15));
    }, [props.feeds]);

    const refresh = () => {
        if (match === '/feeds') {
            props.fetchFeeds(true, 'home', catId, props.history);
        } else {
            props.fetchFeeds(true, 'discover', catId, props.history);
        }
    };

    const fetchMoreData = () => {
        setFeeds(props.feeds.slice(0, feeds.length + 15));
    };

    const renderDisplayIfRecordIsEmpty = () => {
        return (
            <div className="jumbotron">
                {match === '/explore-talents' ? (
                    <>
                        <p className="">
                            Welcome, there are so many awesome talents to be discovered
                            here.
                        </p>
                        <Link
                            className="btn btn-primary btn-lg"
                            to="/feeds"
                            role="button"
                        >
                            Click here
                        </Link>
                    </>
                ) : (
                    <>
                        <p className="">
                            Welcome, there are so many awesome talents to be discovered
                            here.
                        </p>
                        <Link
                            className="btn btn-primary btn-lg"
                            to="/explore-talents"
                            role="button"
                        >
                            Explore Talents
                        </Link>
                    </>
                )}
            </div>
        );
    };

    // Handle on click edit post on action dropdown
    const handleEditPostClick = (feed) => {
        $('#editPostModal').modal('show');
        setSelectedFeed(feed);
    };

    const handleDeletePostClick = (feed) => {
        $('#deletePostModal').modal('show');
        setSelectedFeed(feed);
    };

    return (
        <div className="col-lg-8">
            <aside>
                {match === '/explore-talents' ? '' : <CreatePost />}

                <div>
                    <button
                        className="btn btn-secondary px-6 mb-4 d-sm-block d-lg-none"
                        data-toggle="modal"
                        data-target="#filterModal"
                    >
                        Filter
                    </button>
                </div>

                {props.feedLoading ? (
                    <>
                        <ShowLoaderWhenFetchingPost />
                        <ShowLoaderWhenFetchingPost />
                    </>
                ) : feeds.length === 0 ? (
                    renderDisplayIfRecordIsEmpty()
                ) : (
                    <section className="content-post">
                        <InfiniteScroll
                            dataLength={feeds.length - 5}
                            next={fetchMoreData}
                            hasMore={true}
                            // loader={
                            //     <div className="loader text-center">
                            //         <p>Loading more ...</p>
                            //     </div>
                            // }
                            endMessage={
                                <div className="text-center">
                                    <p>Yay! You have seen it all</p>
                                </div>
                            }
                            // below props only if you need pull down functionality
                            refreshFunction={refresh}
                            pullDownToRefresh
                            pullDownToRefreshContent={
                                <div className="refresher">
                                    <h5>&#8595; Pull down to refresh</h5>
                                </div>
                            }
                            releaseToRefreshContent={
                                <div className="refresher">
                                    <h5>&#8593; Release to refresh</h5>
                                </div>
                            }
                        >
                            {feeds.map((feed, index) =>
                                feed.auditionType ? (
                                   <Audition feed={feed} />
                                ) : (
                                    <Post
                                        key={feed.postId}
                                        postId={feed.postId}
                                        profilePicture={feed.creatorImage}
                                        statusTag={feed.creatorAreaOfInterest}
                                        userId={feed.creatorId}
                                        fullName={feed.creatorName}
                                        timePosted={TimeSettings(feed.createdAt)}
                                        postImg={feed.mediaUrl}
                                        body={feed.body}
                                        isLiked={feed.isLiked}
                                        totalLikes={feed.likersCount}
                                        isCommentStart={feed.isCommentStart}
                                        totalComments={feed.commentorsCount}
                                        hideComment={feed.hideComment}
                                        commentLists={feed.comments}
                                        talentCategories={feed.talentCategories}
                                        isFollowing={feed.isFollowing}
                                        shareStatus={feed.shareStatus || null}
                                        sharedPost={feed.sharedPost}
                                        isPostOwner={feed.isPostOwner}
                                        isPrivate={feed.private}
                                        requestId={feed.requestId}
                                        country={feed.country}
                                        region={feed.region}
                                        handleEditPostClick={() =>
                                            handleEditPostClick(feed)
                                        }
                                        handleDeletePostClick={() =>
                                            handleDeletePostClick(feed)
                                        }
                                    />
                                )
                            )}
                        </InfiniteScroll>
                    </section>
                )}

                {/*Onclick of share post, Modal to add post before sharing a post */}
                <SharePostWithAddedTextModal />

                {/* Show edit modal */}
                <EditPostModal selectedFeed={selectedFeed} />

                {/* Show delete modal */}
                <DeletePostModal selectedFeed={selectedFeed} />

                <FilterTalentModal
                    categories={props.categories}
                    fetchFeeds={props.fetchFeeds}
                    match={props.match}
                    feedLoading={props.feedLoading}
                    talentCategories={props.talentCategories}
                    getAllTalentCategories={props.getAllTalentCategories}
                />
            </aside>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        feedLoading: state.feeds.feedLoading,
        feeds: state.feeds.feeds,
        keptShareData: state.feeds.keptShareData,
        talentCategories: state.talentCategories.talentCategories,
    };
};

export default withRouter(connect(mapStateToProps, actions)(CenterBar));
