import React, {useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from '../../../redux/actions';
import IconLike from '../../../assets/img/svg/icon-like.svg';
import PrivateIcon from '../../../assets/img/svg/lock.svg';
import Pagination from '../../Pagination/Pagination';
import {capitalizeFirstLetter} from '../../../utils/capitalizeFirstLetter';
import ProfilePostModal from './ProfilePostModal';
import store from 'store';
import {getMediaType} from '../../../utils/global';
import $ from 'jquery';

const PostLoader = () => {
    const renderLoader = () => {
        return (
            <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="list-item userPost-loader">
                    <div className="card">
                        <div></div>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <>
            {renderLoader()}
            {renderLoader()}
            {renderLoader()}
            {renderLoader()}
            {renderLoader()}
            {renderLoader()}
            {renderLoader()}
            {renderLoader()}
        </>
    );
};

const RenderPostItem = ({mediaUrl, children}) => {
    const mediaType = mediaUrl ? getMediaType(mediaUrl) : null;

    switch (mediaType) {
        case 'mp4':
        case 'webm':
        case 'ogg':
            return (
                <div
                    className="card post-item preview-wrapper"
                    style={{
                        backgroundColor: '#003049',
                    }}
                >
                    <video muted>
                        <source src={mediaUrl} type={`video/${mediaType}`} />
                    </video>
                    {children}
                </div>
            );
        default:
            return (
                <div
                    className="card"
                    style={{
                        backgroundColor: '#003049',
                        backgroundImage: `url(${mediaUrl})`,
                    }}
                >
                    {children}
                </div>
            );
    }
};
const PostItem = (props) => {
    const {likersCount, comments, isLiked} = props;
    const mediaUrl = props.post.mediaUrl || props.post.sharedPost?.mediaUrl || '';

    return (
        <div
            className="col-lg-3 col-md-6 col-sm-12"
            onClick={props.handlePostDetailModalShow}
        >
            <div className="list-item">
                <RenderPostItem mediaUrl={mediaUrl}>
                    <div className="likes-comments">
                        <p>
                            {isLiked ? (
                                <i>
                                    <img src={IconLike} alt="" />
                                </i>
                            ) : (
                                <i>
                                    <svg
                                        width="25"
                                        height="21"
                                        viewBox="0 0 25 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M12.8465 20.7165C12.6015 20.9007 12.3078 20.9997 12.0065 20.9997C11.7052 20.9997 11.4115 20.9007 11.1665 20.7165C8.8485 18.9672 2.4315 13.965 0.911501 11.0807C0.0120168 9.38681 -0.233115 7.39478 0.226796 5.51651C0.686707 3.63823 1.81648 2.01737 3.3825 0.989101C4.39554 0.343597 5.55752 0.0015388 6.7425 0C7.76239 0.00524581 8.7675 0.256519 9.68021 0.734421C10.5929 1.21232 11.3888 1.90407 12.0065 2.75625C12.6241 1.90427 13.4199 1.21265 14.3324 0.73476C15.2449 0.256871 16.2498 0.00548556 17.2695 0C18.4548 0.0017746 19.617 0.343808 20.6305 0.989101C22.1965 2.01737 23.3263 3.63823 23.7862 5.51651C24.2461 7.39478 24.001 9.38681 23.1015 11.0807C21.5815 13.9629 15.1645 18.9672 12.8465 20.7165Z"
                                            fill="#fff"
                                        ></path>
                                    </svg>
                                </i>
                            )}

                            <label className="pl-2">
                                {/* Format Like count */}
                                {likersCount.length === '4'
                                    ? `${likersCount[0]}k`
                                    : likersCount.length === '6'
                                    ? `${likersCount[0]}M`
                                    : likersCount}
                            </label>
                        </p>
                        <p>
                            <i>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8 0H12C14.1217 0 16.1566 0.842855 17.6569 2.34315C19.1571 3.84344 20 5.87827 20 8C20 10.1217 19.1571 12.1566 17.6569 13.6569C16.1566 15.1571 14.1217 16 12 16V19.5C7 17.5 0 14.5 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0Z"
                                        fill="#fff"
                                    ></path>
                                </svg>
                            </i>
                            <label className="pl-2">{comments}</label>
                        </p>
                    </div>
                </RenderPostItem>
            </div>
        </div>
    );
};

const PostsListItems = ({userPosts, handlePostDetailModalShow}) => {
    return (
        <>
            {userPosts &&
                userPosts.map((post) => (
                    <React.Fragment key={post.postId}>
                        <PostItem
                            postId={post.postId}
                            mediaUrl={post.mediaUrl}
                            post={post}
                            likersCount={post.likersCount}
                            isLiked={post.isLiked}
                            comments={post.commentorsCount}
                            handlePostDetailModalShow={() =>
                                handlePostDetailModalShow(post)
                            }
                        />
                    </React.Fragment>
                ))}
        </>
    );
};

// Main Posts Wrapper
const ProfilePosts = (props) => {
    const userData = store.get('kmt-user');
    const [postOnModal, setPostOnModal] = useState(null);
    const [visible, setVisible] = useState(false);
    const handlePostDetailModalShow = (post) => {
        setPostOnModal(post);
        setVisible(true);
    };

    // Show only Post with Media files
    // const userPosts = props.userPosts.filter(post => post.mediaUrl)

    $('#viewPost')
        .on('hidden.bs.modal', function () {
            setVisible(false);
        });

    //display Posts with media
    const postWithMedia = props.userPosts.filter(
        (post) => post.mediaUrl || post.sharedPost?.mediaUrl
    );

    return (
        <>
            <section
                id="user_post"
                className="all-post animated FadeIn"
                style={{display: 'block'}}
            >
                {props.private &&
                !props.isFollowing &&
                userData.fullName !== props.fullName ? (
                    <div className="text-center">
                        <img src={PrivateIcon} width="100rem" alt="" />
                        <p className="mt-3">Account is private.</p>
                    </div>
                ) : (
                    <>
                        <p>
                            {/* display if in postDetails page or in profile page */}
                            {props.isPostDetailsPage
                                ? `More post from ${capitalizeFirstLetter(
                                      props.userName
                                  )}`
                                : `${capitalizeFirstLetter(props.userName)}'s post`}
                        </p>
                        <div className="row">
                            {props.postLoading ? (
                                <PostLoader />
                            ) : (
                                <PostsListItems
                                    userPosts={postWithMedia}
                                    postLoading={props.postLoading}
                                    handlePostDetailModalShow={handlePostDetailModalShow}
                                />
                            )}
                        </div>

                        {/* display pagination if in postDetails page or in profile page */}
                        {props.isPostDetailsPage ? (
                            ''
                        ) : (
                            <div className="d-flex flex-row justify-content-center">
                                <Pagination
                                    handlePaginate={props.handlePaginate}
                                    pageCount={props.pageCount}
                                />
                            </div>
                        )}
                    </>
                )}
            </section>
            {visible && (
                <ProfilePostModal
                    postOnModal={postOnModal}
                    userName={props.userName}
                    hideModal={() => setVisible(false)}
                />
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        userPosts: state.user.userPosts.posts || [],
    };
};
export default withRouter(connect(mapStateToProps, actions)(ProfilePosts));
