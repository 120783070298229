import React from 'react';
import Img1 from '../../../assets/img/png/photo-avatar.png';
import PostBody from '../PostBits/PostBody';
import {TimeSettings} from '../../../utils/TimeSettings';
import CreateComment from '../PostBits/CreateComment';
import PostClicks from '../PostBits/PostClicks';

//Large to Bigger screens only
// This view is only available for bigger screens
// For smaller screens, reference ../PostBits/PostComments
const PostDetailsCommentLists = (props) => {
    const {
        fullName,
        body,
        sharedPost,
        isCommentStart,
        commentLists,
        postId,
        isLiked,
        totalLikes,
        totalComments,
        mediaUrl,
        sharePostId,
        talentCategories,
        isShareStart,
        shareStatus,
        isPostOwner
    } = props;
    return (
        <div className="panel">
            <div className="head">
                <label htmlFor="">Comments</label>
            </div>
            <div className="d-comments">
                <aside>
                    <div>
                        <section className="post">
                            <i>
                                <img src={mediaUrl || Img1} alt="" />
                            </i>{' '}
                            <PostBody
                                fullName={fullName}
                                body={body}
                                sharedPost={sharedPost}
                            />
                        </section>
                    </div>
                    {/* Preloader when a comment is posted */}
                    {isCommentStart ? (
                        <>
                            <div className="comment-placeholder">
                                <figure>loading...</figure>
                            </div>
                        </>
                    ) : (
                        ''
                    )}
                    {/* Comments list */}
                    {commentLists.length === 0
                        ? ''
                        : commentLists.map((commentList) => (
                              <div key={commentList.commentId}>
                                  <section className="post">
                                      <i>
                                          <img
                                              src={commentList.commentorImage || Img1}
                                              alt=""
                                          />
                                      </i>{' '}
                                      <p>
                                          <span>{commentList.commentorName}</span>
                                          {commentList.comment}
                                      </p>
                                  </section>
                                  <section className="time-stamp">
                                      <small>{TimeSettings(commentList.createdAt)}</small>
                                  </section>
                              </div>
                          ))}
                </aside>
                <PostClicks
                    postId={postId}
                    isLiked={isLiked}
                    totalLikes={totalLikes}
                    totalComments={totalComments}
                    mediaUrl={mediaUrl}
                    sharePostId={sharePostId}
                    body={body}
                    talentCategories={talentCategories}
                    isShareStart={isShareStart}
                    shareStatus={shareStatus}
                    isPostOwner={isPostOwner}
                />
                <hr />
                <CreateComment postId={postId} />
            </div>
        </div>
    );
};

export default PostDetailsCommentLists;
