import axios from '../../axios/axiosInstance';
import Actions from './actionTypes';
import {alertActions} from './alertActions';

export const fetchAllConversations = (history) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .get('/conversations')
        .then((response) => {
            dispatch(fecthConversations(response.data.conversations));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

export const fetchPreviousConversations = (userId, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .get('/messages', {
            params: {
                user1: userId,
            },
        })
        .then((response) => {
            dispatch(fetchPrevMessages(response.data.messages));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

export const deleteChatHistory = (userId) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .delete(`/conversations/${userId}`)
        .then((response) => {
            dispatch({
                type: Actions.CLEAR_CHAT_HISTORY,
                payload: userId,
            });
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
            }
        });
};

export const keepUserIdfromProfileToFetchMessage = (data, history) => async (
    dispatch
) => {
    dispatch({
        type: Actions.KEEP_USERID_TO_MESSAGE,
        payload: data,
    });

    history.push('/messages');
};

// Dispatch functions
const fecthConversations = (data) => {
    return {
        type: Actions.FETCH_CONVERSATION,
        payload: data,
    };
};

const fetchPrevMessages = (data) => {
    return {
        type: Actions.FETCH_PREVIOUS_MESSAGE,
        payload: data,
    };
};
