import React from 'react';
import {DateSuperscript} from '../../utils/DateSuperscript';
import {stringFirstUpperCase} from '../../utils/stringFirstsUppercase';
import {capitalizeFirstLetter} from '../../utils/capitalizeFirstLetter';
import {Link} from 'react-router-dom';

const Audition = ({feed}) => {
    return (
        <div className="panel" key={feed.uniqueUrlId}>
            <section className="content">
                <div
                    className="media"
                    style={{
                        background: `#000000d9 url(${feed.mediaUrl}) no-repeat center`,
                    }}
                >
                    <div className="media-overlay-wrapper"></div>
                </div>

                <div className="media-panel p-4">
                    <div className="row">
                        <div className="col">
                            <label>
                                {new Date(feed.startDate).toLocaleDateString('default', {
                                    month: 'long',
                                })}{' '}
                                <strong>
                                    {new Date(feed.startDate).getUTCDate()}{' '}
                                    <small>
                                        {DateSuperscript(
                                            new Date(feed.startDate).getUTCDate()
                                        )}
                                    </small>
                                </strong>
                            </label>
                        </div>

                        <div className="col">
                            <div className="audition-short">
                                <h3 data-toggle="tooltip" title={feed.title}>
                                    {feed.title.length > 13
                                        ? stringFirstUpperCase(feed.title.slice(0, 12)) +
                                          '...'
                                        : stringFirstUpperCase(feed.title)}
                                </h3>
                                <p>
                                    {capitalizeFirstLetter(feed.description.slice(0, 30))}
                                    ...
                                </p>

                                <Link to={`/talent-auditions/${feed.uniqueUrlId}`}>
                                    <button className="btn btn-sm btn-block btn-secondary mt-3">
                                        View Audition
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Audition;
