import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import * as actions from '../../redux/actions';

import PhotoIcon from '../../assets/img/svg/picture-icon.svg';
import VideoIcon from '../../assets/img/svg/video-icon.svg';
import CreatePostWrapper from './CreatePostBits/CreatePostWrapper';

//--------------MAIN CREATE POST WRAPPER-------------------//
// Main Post on Feeds Page that renders the modal window
const CreatePost = () => {
    return (
        <CreatePostWrapper>
            <form>
                <div className="input">
                    <input
                        type="text"
                        maxLength="0"
                        length="0"
                        placeholder="Make a post"
                        data-toggle="modal"
                        data-target="#createPost"
                    />
                </div>
                <hr />
                <div className="control">
                    <div>
                        <i>
                            <img
                                src={PhotoIcon}
                                alt=""
                                data-toggle="modal"
                                data-target="#createPost"
                            />
                        </i>
                        <i>
                            <img
                                src={VideoIcon}
                                alt=""
                                data-toggle="modal"
                                data-target="#createPost"
                            />
                        </i>
                    </div>
                    <div className="action">
                        <button
                            type="button"
                            className="btn flat"
                            data-toggle="modal"
                            data-target="#createPost"
                        >
                            Post
                        </button>
                    </div>
                </div>
            </form>
        </CreatePostWrapper>
    );
};

const mapStateToProps = (state) => {
    return {
        talentCategories: state.feeds.talentCategories,
    };
};

export default withRouter(connect(mapStateToProps, actions)(CreatePost));
