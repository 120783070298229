import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../redux/actions';
import {withRouter} from 'react-router-dom';
const Signout = (props) => {
    localStorage.clear();
    useEffect(() => {
        props.history.push('/login');
    }, [props.history]);
    return <React.Fragment />;
};

export default withRouter(connect(null, actions)(Signout));
