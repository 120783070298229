import React from 'react';
import CategoryFilter from './CategoryFilter';
const FilterTalentModal = (props) => {
    return (
        <div
            className="modal fade"
            id="filterModal"
            tabIndex="-1"
            aria-labelledby="filterModal"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <CategoryFilter
                            categories={props.categories}
                            fetchFeeds={props.fetchFeeds}
                            match={props.match}
                            feedLoading={props.feedLoading}
                            getAllTalentCategories={props.getAllTalentCategories}
                            talentCategories={props.talentCategories}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilterTalentModal;
