import Actions from '../actions/actionTypes';

const INITIAL_STATE = {
    loginStart: false,
    signupStart: false,
    socialStart: false,

    activateStart: false,

    completeSignupStart: false,

    socialError: '',

    authenticated: null,

    requestForgotPasswordStart: false,

    authenticateForgotPasswordStart: false,
    authenticateForgotPasswordMessage: '',

    message: '',
};

const AuthReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        // Login
        case Actions.LOGIN_START:
            return {...state, loginStart: action.payload};

        // Normal signup
        case Actions.SIGNUP_START:
            return {...state, signupStart: action.payload};

        //Complete Login/Signup Via Google and Facebook
        case Actions.SOCIAL_START:
            return {...state, socialStart: action.payload};

        // Activate account
        case Actions.ACTIVATE_START:
            return {...state, activateStart: action.payload};

        // Complete user account
        case Actions.COMPLETE_SIGNUP_START:
            return {...state, completeSignupStart: action.payload};

        //Request Forgot Password
        case Actions.REQUEST_FORGOT_PASSWORD_START:
            return {...state, requestForgotPasswordStart: action.payload};

        case Actions.AUTHENTICATE_FORGOT_PASSWORD_START:
            return {...state, authenticateForgotPasswordStart: true};
        case Actions.AUTHENTICATE_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                authenticateForgotPasswordStart: false,
                authenticateForgotPasswordMessage: action.payload,
            };
        case Actions.AUTHENTICATE_FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                authenticateForgotPasswordStart: false,
                authenticateForgotPasswordMessage: action.payload,
            };

        case Actions.UNAUTH_USER:
            return {...state, authenticated: null};
        default:
            return state;
    }
};

export default AuthReducer;
