import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../redux/actions';
import PanelHead from './PostBits/PanelHead';
import PanelImage from './PostBits/PanelImage';
import PostClicks from './PostBits/PostClicks';
import PostComments from './PostBits/PostComments';
import CreateComment from './PostBits/CreateComment';
import PostBodyWithCommentLink from './PostBits/PostBodyWithCommentLink';
import {withRouter} from 'react-router-dom';

const Post = (props) => {
    const {
        userId,
        loading,
        postId,
        profilePicture,
        statusTag,
        fullName,
        timePosted,
        isFollowing,
        postImg,
        body,
        isLiked,
        totalLikes,
        isCommentStart,
        totalComments,
        hideComment,
        shareStatus,
        commentLists,
        talentCategories,
        sharedPost,
        isPostOwner,
        isPrivate,
        requestId,
        country,
        region,
        handleEditPostClick,
        handleDeletePostClick,
    } = props;

    return (
        <div className="panel">
            {/* Post Header with Avartar, user name and action */}
            <PanelHead
                userId={userId}
                profilePicture={profilePicture}
                statusTag={statusTag}
                fullName={fullName}
                timePosted={timePosted}
                loading={loading}
                isFollowing={isFollowing}
                isPostOwner={isPostOwner}
                isPrivate={isPrivate}
                requestId={requestId}
                postId={postId}
                handleEditPostClick={handleEditPostClick}
                handleDeletePostClick={handleDeletePostClick}
            />
            <section className="content">
                {/* Display Uploaded post image/video */}
                <PanelImage
                    postId={postId}
                    sharePostId={postId}
                    postImg={postImg}
                    sharedPost={sharedPost}
                />

                <div className="media-panel">
                    {/* Display/Handle Likes, Comment Count & Share actions */}
                    <PostClicks
                        postId={postId}
                        isLiked={isLiked}
                        totalLikes={totalLikes}
                        totalComments={totalComments}
                        mediaUrl={postImg}
                        sharePostId={postId}
                        body={body}
                        talentCategories={talentCategories}
                        shareStatus={shareStatus}
                    />
                    <div className="comment-placeholder">
                        <PostBodyWithCommentLink
                            userId={userId}
                            postId={postId}
                            totalComments={totalComments}
                            fullName={fullName}
                            body={body}
                            sharedPost={sharedPost}
                            country={country}
                            region={region}
                        />
                        <PostComments
                            isCommentStart={isCommentStart}
                            commentLists={commentLists}
                            hideComment={hideComment}
                        />
                        {/* if hidecomment is true */}
                        {hideComment ? (
                            ''
                        ) : (
                            <>
                                {props.location.pathname === '/talents' ||
                                props.location.pathname === '/scouts' ? (
                                    ''
                                ) : (
                                    <>
                                        <hr />
                                        <CreateComment
                                            postId={postId}
                                            isCommentStart={isCommentStart}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.feeds.loading,
    };
};

export default withRouter(connect(mapStateToProps, actions)(Post));
