import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';
import ShareImg from '../../../assets/img/svg/share-ico.svg';
import DeleteImg from '../../../assets/img/svg/trash-black-icon.svg';
import IconLike from '../../../assets/img/svg/icon-like.svg';
import IconUnLike from '../../../assets/img/svg/icon-like_.svg';
import useAlert from '../../CustomHooks/useAlert';
import {withRouter} from 'react-router-dom';
import DeletePostModal from './DeletePostModal';
import $ from 'jquery';

const ShareOption = (props) => {
    return (
        <div className="action dropdown custom-sharePost">
            <div
                className="sharePost"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                <img src={ShareImg} alt="" />
                <label>Share</label>
            </div>
            <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="profile-menu"
                x-placement="bottom-end"
                style={{
                    position: 'absolute',
                    willChange: 'transform',
                    top: ' 0px',
                    left: '0px',
                    transform: 'translate3d(151px, 44px, 0px)',
                }}
            >
                <div
                    className="dropdown-item custom-dropdown"
                    //trigger modal window (window component on CenterBar.js because of
                    //ui view) and keep post data
                    data-toggle="modal"
                    data-target="#sharePostWithText"
                    onClick={props.keepData}
                >
                    <span>Write Post</span>
                </div>
                <div className="dropdown-item custom-dropdown">
                    <span onClick={props.directlySharePost}>
                        {' '}
                        <span>{props.shareStatus ? 'Post Shared' : 'Share Post'}</span>
                    </span>
                </div>
            </div>
        </div>
    );
};

const PostClicks = (props) => {
    let {
        postId,
        isLiked,
        totalLikes,
        totalComments,
        mediaUrl,
        sharePostId,
        body,
        talentCategories,
        shareStatus,
        isPostOwner,
    } = props;

    const selectedFeed = {postId};

    const handleLikeClick = () => {
        const data = {
            postId,
        };
        props.unlikePost(data);
    };
    const handleUnlikeClick = () => {
        const data = {
            postId,
        };
        props.likePost(data);
    };

    const handleDirectlySharePost = () => {
        const data = {
            sharePostId: sharePostId,
            body: body,
            talentCategories: talentCategories,
        };

        props.sharePost(data);
    };

    const keepData = () => {
        const data = {
            sharePostId: sharePostId,
            mediaUrl: mediaUrl,
            body: body,
            talentCategories: talentCategories,
        };
        props.justKeepDataToShowOnModalBeforeShare(data);
    };

    const handleDeletePostClick = () => {
        $('#deletePostModal').modal('show');
    };

    // Render Alert when post is shared directly
    useAlert(props.alert);

    return (
        <>
            <section className="control">
                <div>
                    <p>
                        {props.location.pathname === '/talents' ||
                        props.location.pathname === '/scouts' ? (
                            <i>
                                <img src={IconUnLike} alt="" />
                            </i>
                        ) : (
                            <>
                                {/* if user like post, display like icon else dispay unlike icon */}
                                {isLiked ? (
                                    <i onClick={handleLikeClick}>
                                        <img src={IconLike} alt="" />
                                    </i>
                                ) : (
                                    <i onClick={handleUnlikeClick}>
                                        <img src={IconUnLike} alt="" />
                                    </i>
                                )}
                            </>
                        )}
                        <label>
                            {/* Format Like count */}
                            {totalLikes.length === '4'
                                ? `${totalLikes[0]}k`
                                : totalLikes.length === '6'
                                ? `${totalLikes[0]}M`
                                : totalLikes}
                        </label>
                    </p>
                    <p>
                        <i>
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M8 0H12C14.1217 0 16.1566 0.842855 17.6569 2.34315C19.1571 3.84344 20 5.87827 20 8C20 10.1217 19.1571 12.1566 17.6569 13.6569C16.1566 15.1571 14.1217 16 12 16V19.5C7 17.5 0 14.5 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0Z"
                                    fill="#03293D"
                                ></path>
                            </svg>
                        </i>
                        <label>{totalComments}</label>
                    </p>
                </div>
                {/* if on the unsignedin page, dont show the shareoption */}
                {props.location.pathname === '/talents' ||
                props.location.pathname === '/scouts' ? (
                    ''
                ) : (
                    <div style={{display: 'flex'}}>
                        {isPostOwner && (
                            <>
                                <img src={DeleteImg} alt="delete" />
                                <span
                                    className="delete-label"
                                    onClick={handleDeletePostClick}
                                >
                                    Delete
                                </span>
                            </>
                        )}

                        <ShareOption
                            shareStatus={shareStatus}
                            directlySharePost={handleDirectlySharePost}
                            keepData={keepData}
                        />
                    </div>
                )}

                {/* Show delete modal */}
                <DeletePostModal selectedFeed={selectedFeed} />
            </section>
        </>
    );
};

const mapStateToProps = (state) => {
    return {};
};

export default withRouter(connect(mapStateToProps, actions)(PostClicks));
