import React from 'react';
import {Link} from 'react-router-dom';
import VerticalIcon from '../../../assets/img/svg/elipsis-vertical.svg';
import {capitalizeFirstLetter} from '../../../utils/capitalizeFirstLetter';
import {TimeSettings} from '../../../utils/TimeSettings';

const ChatHeader = (props) => {
    const {fullName, id, imageUrl, areaOfInterest, lastSeen} = props.userInfo;
    return (
        <>
            {props.userInfo ? (
                <div className="cardheader">
                    <div className="d-flex">
                        <section>
                            <div
                                className="img_cont"
                                style={{background: `url(${imageUrl})`}}
                            >
                                <small className="status_tag_">
                                    {areaOfInterest === 'talent'
                                        ? 'T'
                                        : areaOfInterest === 'scout'
                                        ? 'S'
                                        : ''}
                                </small>
                            </div>
                            <div className="user_info">
                                <p>
                                    {capitalizeFirstLetter(fullName)}
                                    <span>
                                        {lastSeen
                                            ? `last online ${TimeSettings(lastSeen)}`
                                            : 'Online'}
                                    </span>
                                </p>
                            </div>
                        </section>
                        {/* Just a checker to render if the userInfo is updated */}
                        {fullName ? (
                            <div className="action dropdown">
                                <a
                                    className="dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    href="/"
                                >
                                    <img src={VerticalIcon} alt="" />
                                </a>
                                <div
                                    className="dropdown-menu dropdown-menu-right"
                                    aria-labelledby="profile-menu"
                                    x-placement="bottom-end"
                                    style={{
                                        position: 'absolute',
                                        willChange: 'transform',
                                        top: '0px',
                                        left: '0px',
                                        transform: 'translate3d(151px, 44px, 0px)',
                                    }}
                                >
                                    <Link to={`/profile/${id}`} className="dropdown-item">
                                        <span>View Profile</span>
                                    </Link>
                                    <a
                                        className="dropdown-item"
                                        data-toggle="modal"
                                        data-target="#reportUser"
                                        href="/"
                                    >
                                        <span>Report Profile</span>
                                    </a>
                                    <a
                                        className="dropdown-item red"
                                        data-toggle="modal"
                                        data-target="#deleteChat"
                                        href="/"
                                    >
                                        <span>Delete Chat</span>
                                    </a>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default ChatHeader;
