import Actions from '../actions/actionTypes';

const initial = {
    talentCategories: [],
};

const TalentCategoriesnReducer = (state = initial, action) => {
    switch (action.type) {
        case Actions.FETCH_TALENT_CATEGORIES:
            return {
                ...state,
                talentCategories: action.payload,
            };

        default:
            return state;
    }
};

export default TalentCategoriesnReducer;
