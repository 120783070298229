import React, {useEffect} from 'react';
import XIcon from '../../assets/img/svg/x.svg';
import $ from 'jquery';

const Modal = ({
    modalIdentifier,
    title,
    wrapperStyles,
    modalDismissedHandler,
    children,
    isVisible,
}) => {
    const triggerModal = (status) => {
        $(`#${modalIdentifier}`)
            .modal(status ? 'show' : 'hide')
            .on('hidden.bs.modal', function () {
                if (typeof modalDismissedHandler === 'function') {
                    modalDismissedHandler();
                }
            });
    };

    useEffect(() => {
        triggerModal(isVisible);
    }, [isVisible]);

    const handleModalDismiss = () => {
        if (modalDismissedHandler) modalDismissedHandler();
    };
    return (
        <div className="modal fade" id={modalIdentifier} role="dialog">
            <div className="modal-dialog modal-wrapper" style={wrapperStyles || {}}>
                <div className=" modal-content-wrap">
                    <div className="header-wrap">
                        <div className="close_" onClick={handleModalDismiss}>
                            <label data-dismiss="modal">
                                <img src={XIcon} alt="" />
                                <span>Close</span>
                            </label>
                        </div>
                        <div>
                            <p>{title}</p>
                        </div>
                    </div>
                    <div className="modal-body">{children}</div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
