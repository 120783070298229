import React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../components/layout/MainLayout/Header';
import Footer from '../components/layout/MainLayout/Footer';
import {scrollTop} from '../utils/scrollTop';

const PrivacyPolicy = () => {
    scrollTop();

    return (
        <>
            <Helmet>
                <title>Privay Policy</title>
            </Helmet>
            <Header />
            <main className="main">
                <section className="t_and_c privacy">
                    <div className="container">
                        <div className="title">
                            <h1>Privacy Policies</h1>
                        </div>
                        <div className="content">
                            <p>
                                These terms of use are entered into by and between you and
                                In Bloom Holding, Inc. ("Couple", "we" or "us"). The
                                following terms and conditions, together with any
                                documents they expressly incorporate by reference
                                (collectively, these "Terms of Use"), govern your access
                                to and use of www.couple.co, including any content,
                                functionality, applications and services offered on or
                                through Couple.co (the "Website"), whether as a guest or a
                                registered user.
                            </p>
                            <h3>Changes to the Terms of Use</h3>
                            <p>
                                OWe process personal data in compliance with the relevant
                                data protection regulations of the GDPR, the DPA (Data
                                Protection Act) and the PECR (Privacy and Electronic
                                Communications Regulations). We will only process data
                                where we are legally permitted to do so. When you use this
                                website, we will process personal data only with your
                                consent, for the performance of a contract to which you
                                are a party, or in order to take steps at your request
                                prior to entering into a contract, for compliance with a
                                legal obligation or if the processing is necessary for the
                                purposes of our legitimate interests or the legitimate
                                interests of a third party, except where such interests
                                are overridden by your interests or fundamental rights and
                                freedoms which require the protection of personal data.
                            </p>
                            <h3>Accessing the Website and Account Security</h3>
                            <p>
                                We process personal data in compliance with the relevant
                                data protection regulations of the GDPR, the DPA (Data
                                Protection Act) and the PECR (Privacy and Electronic
                                Communications Regulations). We will only process data
                                where we are legally permitted to do so. When you use this
                                website, we will process personal data only with your
                                consent, for the performance of a contract to which you
                                are a party, or in order to take steps at your request
                                prior to entering into a contract, for compliance with a
                                legal obligation or if the processing is necessary for the
                                purposes of our legitimate interests or the legitimate
                                interests of a third party, except where such interests
                                are overridden by your interests or fundamental rights and
                                freedoms which require the protection of personal data.
                            </p>
                            <h3>Accessing the Website and Account Security</h3>
                            <p>
                                We reserve the right to withdraw or amend this Website,
                                and any service or material we provide on the Website, in
                                our sole discretion without notice. We will not be liable
                                if for any reason all or any part of the Website is
                                unavailable at any time or for any period. From time to
                                time, we may restrict access to some parts of the Website,
                                or the entire Website, to users, including registered
                                users.
                            </p>
                            <h3>you are responsible for:</h3>
                            <ul className="unordered">
                                <li>
                                    Making all arrangements necessary for you to have
                                    access to the Website.
                                </li>
                                <li>
                                    Ensuring that all persons who access the Website
                                    through your internet connection are aware of these
                                    Terms of Use and comply with them.
                                </li>
                            </ul>
                            <p>
                                If you create an account on the Website or sign up for
                                special offers, you will be asked to provide your e-mail
                                address and certain other information. It is a condition
                                of your use of the Website that all the information you
                                provide on the Website be correct, current and complete.
                                You agree that all information you provide to create an
                                account on this Website or otherwise, including but not
                                limited to through the use of any interactive features on
                                the Website, is governed by our Privacy Policy, and you
                                consent to all actions we take with respect to your
                                information consistent with our Privacy Policy.
                            </p>
                            <h3>Intellectual Property Rights</h3>
                            <p>
                                The Website and its entire contents, features and
                                functionality (including but not limited to all
                                information, software, text, displays, images, video and
                                audio, and the design, selection and arrangement thereof),
                                are owned by Couple, its licensors or other providers of
                                such material and are protected by United States and
                                international copyright, trademark, patent, trade secret
                                and other intellectual property or proprietary rights
                                laws.{' '}
                            </p>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default PrivacyPolicy;
