/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import HorizontalIcon from '../../../assets/img/svg/horizontal-ellipsis.svg';
import * as actions from '../../../redux/actions';
import Img1 from '../../../assets/img/svg/user.svg';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {showDayBasedOnUTCDate} from '../../../utils/showDayBasedOnUTCDate';
import {TimeSettings} from '../../../utils/TimeSettings';
import InfiniteScroll from 'react-infinite-scroll-component';

const NotificationItems = ({notifications, deleteNotifications}) => {
    const [allNotifications, setAllNotifications] = useState([]);

    // Update on mount component =? componentDidUpdate with notifications
    useEffect(() => {
        if (notifications) {
            // Restructure the messages to show chat based on date chatted
            // STEP 1
            const groupedByTime = notifications.reduce((acc, item) => {
                const found = acc.find(
                    (i) =>
                        new Date(i.createdAt).getUTCDate() ===
                        new Date(item.createdAt).getUTCDate()
                );
                const notification = {
                    notificationId: item.notificationId,
                    type: item.type,
                    read: item.read,
                    from: item.from,
                    post: item.type === 'view' ? 'viewer' : item.post,
                };

                if (found) {
                    found.content.push(notification);
                } else {
                    acc.push({
                        createdAt: item.createdAt,
                        content: [notification],
                    });
                }

                return acc;
            }, []);

            // STEP 2
            const groupedByPostIdOrView = groupedByTime.map((g) => ({
                createdAt: g.createdAt,
                content: g.content.reduce((acc, item) => {
                    const found = acc.find((i) => i.post === item.post);
                    const notification = {
                        notificationId: item.notificationId,
                        type: item.type,
                        read: item.read,
                        from: item.from,
                        post: item.type === 'view' ? 'viewer' : item.post,
                    };

                    if (found) {
                        found.content.push(notification);
                    } else {
                        acc.push({
                            post: item.post,
                            content: [notification],
                        });
                    }

                    return acc;
                }, []),
            }));

            // STEP 3
            const finalDeepGroupingByType = groupedByPostIdOrView.map((g) => ({
                createdAt: g.createdAt,
                content: g.content.map((i) => ({
                    post: i.post,
                    content: i.content.reduce((acc, item) => {
                        const found = acc.find((i) => i.type === item.type);
                        const notification = {
                            notificationId: item.notificationId,
                            type: item.type,
                            read: item.read,
                            from: item.from,
                            post: item.type === 'view' ? 'viewer' : item.post,
                        };

                        if (found) {
                            found.content.push(notification);
                        } else {
                            acc.push({
                                type: item.type,
                                content: [notification],
                            });
                        }

                        return acc;
                    }, []),
                })),
            }));

            // update state with the restructured notification list
            setAllNotifications(finalDeepGroupingByType);
        }
    }, [notifications]);

    //handle delete notification
    const handleDeleteNotification = async (notificationsId = []) => {
        const notificationIds = notificationsId.map((n) => n.notificationId);
        await deleteNotifications(notificationIds);
    };

    const renderOnNotificationType = (notificationsList, timeStamp) => {
        return (
            <>
                {notificationsList.map((list, index) => (
                    <React.Fragment key={index}>
                        {list.content.map((i, index) => (
                            <div className="list-tile" key={index}>
                                <div className="d-flex user_info">
                                    <div className="stacked">
                                        <ul className="notification-img-stack">
                                            {/* display profile picture stack based on notification list length */}
                                            {i.content.length <= 3 ? (
                                                <>
                                                    {i.content.map((item) => (
                                                        <li key={item.notificationId}>
                                                            <img
                                                                src={
                                                                    item.from.imageUrl ||
                                                                    Img1
                                                                }
                                                                alt=""
                                                            />
                                                        </li>
                                                    ))}
                                                </>
                                            ) : (
                                                <>
                                                    {i.content
                                                        .splice(0, 3)
                                                        .map((item) => (
                                                            <li key={item.notificationId}>
                                                                <img
                                                                    src={
                                                                        item.from
                                                                            .imageUrl ||
                                                                        Img1
                                                                    }
                                                                    alt=""
                                                                />
                                                            </li>
                                                        ))}
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                    <p>
                                        {/* Name and others or just name based on list length and also
                                        view a post/profile based on notification type */}
                                        {i.content[0] && i.content[0].from.fullName}
                                        {i.content.length > 1
                                            ? ' and ' +
                                              (i.content.length - 1) +
                                              ' others '
                                            : ' '}
                                        {i.type} your{' '}
                                        {i.type === 'view' ? 'profile' : 'post'}
                                        <small>{TimeSettings(timeStamp)}</small>
                                    </p>
                                </div>
                                <div className="d-flex action_btn alt_">
                                    <div className="action dropdown">
                                        <a
                                            className="dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <img src={HorizontalIcon} alt="" />
                                        </a>
                                        <div
                                            className="dropdown-menu dropdown-menu-right"
                                            aria-labelledby="profile-menu"
                                            x-placement="bottom-end"
                                            style={{
                                                position: 'absolute',
                                                willChange: 'transform',
                                                top: '0px',
                                                left: '0px',
                                                transform: 'translate3d(4px, 19px, 0px)',
                                            }}
                                        >
                                            <button
                                                className="btn btn-sm dropdown-item red delete-notification-dropdown-btn"
                                                onClick={() =>
                                                    handleDeleteNotification(i.content)
                                                }
                                            >
                                                <span>Delete Notification</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </React.Fragment>
                ))}
            </>
        );
    };

    return (
        <>
            {allNotifications &&
                allNotifications.map((notification) => (
                    <React.Fragment key={notification.createdAt}>
                        <div className="tracker notification">
                            <span>{showDayBasedOnUTCDate(notification.createdAt)}</span>
                        </div>
                        {renderOnNotificationType(
                            notification.content,
                            notification.createdAt
                        )}
                    </React.Fragment>
                ))}
        </>
    );
};

const Loader = () => {
    return (
        <div className="list-tile">
            <div className="col">
                <div className="chat-loader">
                    <div className="media-panel d-flex">
                        <figure>
                            <div></div>
                        </figure>
                        <section>
                            <div></div>
                            <div></div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
};

const NotificationList = (props) => {
    const [mainNotificationList, setMainNotificationList] = useState([]);
    const [hasMore] = useState(true);

    const fetchData = () => {
        props.fetchAllNotifications(1, mainNotificationList.length + 15);
    };

    useEffect(() => {
        setMainNotificationList(props.notifications);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.notifications]);

    const renderLoader = () => {
        return (
            <>
                <Loader />
                <Loader />
                <Loader />
            </>
        );
    };
    return (
        <>
            {props.loading ? (
                renderLoader()
            ) : (
                <InfiniteScroll
                    dataLength={mainNotificationList.length} //This is important field to render the next data
                    next={fetchData}
                    hasMore={hasMore}
                    // loader={
                    //     props.loading || (
                    //         <div className="loader">
                    //             <p>Loading more ...</p>
                    //         </div>
                    //     )
                    // }
                    endMessage={
                        <div className="loader">
                            <p>Yay! You have seen it all</p>
                        </div>
                    }
                >
                    <NotificationItems
                        notifications={mainNotificationList}
                        deleteNotifications={props.deleteNotifications}
                    />
                </InfiniteScroll>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        notifications: state.notifications.notifications.notifications,
    };
};
export default withRouter(connect(mapStateToProps, actions)(NotificationList));
