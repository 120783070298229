import React from 'react';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import Header from '../components/layout/MainLayout/Header';
import Footer from '../components/layout/MainLayout/Footer';

// Images
import GooglePlay from '../assets/img/png/google-play.png';
import IosStore from '../assets/img/png/ios.png';
import Group from '../assets/img/png/image-grouped.png';
import Apple from '../assets/img/png/apple.png';
import Android from '../assets/img/png/android.png';

import Vlog1 from '../assets/img/png/Vlog 1.png';
import Vlog2 from '../assets/img/png/Vlog 2.png';

import Screenshot from '../assets/img/png/screenshot1.png';
import {scrollTop} from '../utils/scrollTop';

import Partner1Img from '../assets/img/png/partners/singaitalia.webp';
import Partner2Img from '../assets/img/png/partners/darius.jpg';
import Partner3Img from '../assets/img/png/partners/ba.jpg';
import Partner4Img from '../assets/img/png/partners/vfa.jpg';
import Partner5Img from '../assets/img/png/partners/opm.png';
import Partner6Img from '../assets/img/png/miss-eko.png';
import TalentCategoriesSlider from '../components/Blocks/TalentCategoriesSlider';

const Intro = () => {
    return (
        <section className="intro">
            <div className="tier_one">
                <div className="container">
                    <div className="h3ro">
                        <h1>Discover the world's best Talents conveniently</h1>
                        <p>
                            At MySpotlyt, we connect promising new talent to trustworthy
                            scouts and the right talent managers. Talent Discovery is as
                            easy and seamless as ordering a cab. So whether you’re a scout
                            or a superstar in the making, get ready to discover or be
                            discovered like never before!
                            <br />
                            <Link to="/signup" className="mt-3 btn btn-primary">
                                Get Started &gt;&gt;
                            </Link>
                        </p>

                        <div className="d-flex justify-contenet-evenly">
                            <Link to="/download" className="mt-2">
                                <img src={GooglePlay} alt="google" />
                            </Link>
                            <Link to="/download" className="mt-2">
                                <img src={IosStore} alt="ios" />
                            </Link>
                        </div>
                    </div>
                    <div className="deco">
                        <img src={Group} alt="group" />
                    </div>
                </div>
            </div>
        </section>
    );
};

const TalentMedia = () => {
    return (
        <>
            <section className="talents-media">
                <div className="container">
                    <h2>
                        Singers, Footballers, Fashion Models, <br />
                        Entertainers, designers & more!
                    </h2>
                    <Link to="/talents" className="btn btn-primary">
                        See all talent categories
                    </Link>
                </div>

                <TalentCategoriesSlider />
            </section>
        </>
    );
};

const Methods = () => {
    return (
        <section className="methods">
            <div className="container">
                <div className="tier_one">
                    <h2>Two ways to showcase your talent</h2>
                    <p>You can be discovered by the world in just 2 minutes.</p>
                </div>
                <section className="tier_two">
                    <div>
                        <img src={Vlog1} alt="" />
                        <h3>Make a video of your talent</h3>
                        <p>
                            This is the 1st step to being discovered. Don’t hoard your
                            amazing talent.
                        </p>
                    </div>
                    <div>
                        <img src={Vlog2} alt="" />
                        <h3>Upload your video here</h3>
                        <p>Let the world know what you were born to do.</p>
                    </div>
                </section>
            </div>
        </section>
    );
};

const Platform = () => {
    return (
        <section className="platform">
            <div className="tier_one">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="deco">
                                <img src={Screenshot} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="item">
                                <h3>Our Mobile Version</h3>
                                <p>
                                    Be discovered on the go, your stage is in your hands,
                                    download our mobile app for fast and convenient Talent
                                    Discovery. Available on Google Play and App Store.
                                </p>
                                <div className="download">
                                    <div style={{marginRight: '4em'}}>
                                        <div className="icon">
                                            <img src={Android} alt="" />
                                        </div>
                                        <Link to="/download">
                                            <label>
                                                <i>
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M3 19H21V21H3V19ZM13 13.172L19.071 7.1L20.485 8.514L12 17L3.515 8.515L4.929 7.1L11 13.17V2H13V13.172Z"
                                                            fill="#03293D"
                                                        ></path>
                                                    </svg>{' '}
                                                </i>
                                                Download on <br />
                                                Play Store
                                            </label>
                                        </Link>
                                    </div>
                                    <div>
                                        <div className="icon">
                                            <img src={Apple} alt="apple" />
                                        </div>
                                        <Link to="/download">
                                            <label>
                                                <i>
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M3 19H21V21H3V19ZM13 13.172L19.071 7.1L20.485 8.514L12 17L3.515 8.515L4.929 7.1L11 13.17V2H13V13.172Z"
                                                            fill="#03293D"
                                                        ></path>
                                                    </svg>{' '}
                                                </i>
                                                Download on <br />
                                                App Store
                                            </label>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tier_two">
                <h3>Want to show the world what you were born to do?</h3>
                <p>
                    Then sign up now and get free access to online auditions for different
                    categories of Talents
                </p>
                <Link to="/signup" className="btn btn-secondary">
                    Get started
                </Link>
            </div>
        </section>
    );
};

const Partner = () => {
    const partners = [
        {
            id: 1,
            img: Partner1Img,
        },
        {
            id: 2,
            img: Partner2Img,
        },
        {
            id: 3,
            img: Partner3Img,
        },
        {
            id: 4,
            img: Partner4Img,
        },
        {
            id: 5,
            img: Partner5Img,
        },
        {
            id: 6,
            img: Partner6Img,
        },
    ];
    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-md-8 offset-md-2 text-center">
                    <div className="tier_one">
                        <h3 className="font-weight-bold partner-title">Our Partners</h3>
                    </div>
                    <div className="partner-wrapper">
                        <div className="partner-list">
                            {partners.map((a, key) => (
                                <div className="partner-item" key={key}>
                                    <img src={a.img} alt="" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Home = () => {
    scrollTop();

    return (
        <>
            <Helmet>
                <title>Welcome | Explore your talent</title>
            </Helmet>
            <Header />
            <main className="main">
                <Intro />
                <TalentMedia />
                <Methods />
                <Platform />

                <Partner />
            </main>
            <Footer />
        </>
    );
};

export default Home;
