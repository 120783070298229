import React from 'react';
import {Helmet} from 'react-helmet';
import LeftSideBar from '../../../components/Feeds/LeftSideBar';
import Header from '../../../components/Feeds/Header';
import Footer from '../../../components/Feeds/Footer';
import AppliedAuditionList from '../../../components/Feeds/Audition/AppliedAuditionList';

const AppliedAudition = () => {
    return (
        <>
            <Helmet>
                <title>Talents | Applied Auditions</title>
            </Helmet>
            <main className="main feeds">
                <LeftSideBar />
                <section id="wrapper">
                    <Header />
                    <div className="container content_">
                        <AppliedAuditionList />
                        <Footer />
                    </div>
                </section>
            </main>
        </>
    );
};

export default AppliedAudition;
