/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const UnsignedInOptionsDropdown = (props) => {
    return (
        <div className="head_">
            <h3>Upcoming Auditions </h3>
            <section>
                <div className="btn-flat dropdown aud-type">
                    <a
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <span>Audition Type</span>
                        <i className="icon">
                            <svg
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    opacity="0.4"
                                    d="M1.78119 0.218262L5.22069 3.65778L8.6602 0.218262L9.7207 1.27876L5.22069 5.77878L0.72069 1.27876L1.78119 0.218262Z"
                                    fill="#bdbdbd"
                                ></path>
                            </svg>
                        </i>
                    </a>
                    <div
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="profile-menu"
                        x-placement="bottom-end"
                        style={{
                            position: 'absolute',
                            willChange: 'transform',
                            top: '0px',
                            left: '0px',
                            transform: 'translate3d(-10px, 44px, 0px)',
                        }}
                    >
                        <button
                            className="dropdown-item py-2"
                            onClick={() => props.handleFilterByType(null)}
                        >
                            <span>All</span>
                        </button>
                        <button
                            className="dropdown-item py-2"
                            onClick={() => props.handleFilterByType('voting')}
                        >
                            <span>Voting</span>
                        </button>
                        <button
                            className="dropdown-item py-2"
                            onClick={() => props.handleFilterByType('non-voting')}
                        >
                            <span>Non-Voting</span>
                        </button>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default UnsignedInOptionsDropdown;
