import React from 'react';

const Footer = () => {
    return (
        <footer>
            <div className="row">
                <div className="col-sm-12 col-lg-12">
                    <hr />
                    <p className="copyright">2021 - All Right Reserved - Sokanae Srl</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
