import React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../components/layout/MainLayout/Header';
import Footer from '../components/layout/MainLayout/Footer';
import TalentFeedsWrapper from '../components/Talents/TalentFeedsWrapper';

import Shutter from '../assets/img/png/Shutter 3.png';
import DotPattern from '../assets/img/svg/dot-pattern.svg';

const Intro = () => {
    return (
        <section className="intro">
            <div className="tier_one">
                <div className="container">
                    <div className="h3ro">
                        <h1>Put The Spotlight On You</h1>
                        <p>
                            Whoever you are, wherever you are and whatever special gift
                            you have, there’s a place for you in the world and a talent
                            spotter waiting to take you there. All you need is your own
                            world stage; a platform that shines your skills to the right
                            people. It’s time to seize the moment.
                        </p>
                    </div>
                    <div className="deco">
                        <img src={Shutter} alt="shutter" />
                        <img className="pattern" src={DotPattern} alt="pattern" />
                    </div>
                </div>
            </div>
        </section>
    );
};

const Talents = () => {
    const defaultFilter = {areaOfInterest: 'talent'};
    return (
        <>
            <Helmet>
                <title>Talents</title>
            </Helmet>
            <Header />
            <main className="plublic">
                <Intro />
                <TalentFeedsWrapper defaultFilter={defaultFilter} />
            </main>
            <Footer />
        </>
    );
};

export default Talents;
