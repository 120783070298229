import axios from '../../axios/axiosInstance';
import Actions from './actionTypes';
import {alertActions} from './alertActions';
import store from 'store';
import {addPushNotification} from './settingsActions';
import {browserDetector} from '../../utils/browserDetector';
import {fecthProfile} from './userActions';

// ---NORMAL USER LOGIN
export const normalLoginUser = (email, password, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    dispatch(loginStart(true));

    const url = '/auth/signin';
    await axios
        .post(url, {email, password})
        .then((response) => {
            if (response.status !== 200 || response.status === 401) {
                dispatch(alertActions.error('Invalid Username or Password'));
                dispatch(loginStart(false));
            }

            if (
                response.data.user.allowPushNotification &&
                response.data.user.browser === browserDetector()
            ) {
                redirectBasedOnAction(response, history);
                dispatch(fecthProfile(response.data.user));
            } else {
                addPushNotification(history, dispatch);
                redirectBasedOnAction(response, history);
                dispatch(fecthProfile(response.data.user));
            }
            // clear the url from storage
            store.remove('performOperationFrom');

            dispatch(loginStart(false));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error('Invalid Username or Password'));
                dispatch(loginStart(false));
            }
        });
};

// ----NORMAL USER SIGNUP
export const normalSignupUser = (data, history) => {
    return async (dispatch) => {
        dispatch(signupStart(true));
        dispatch(alertActions.clear());
        const url = '/auth/signup';
        await axios
            .post(url, data)
            .then((response) => {
                dispatch(alertActions.success(response.data.message));
                dispatch(signupStart(false));
                history.push('/check-your-email');
            })
            .catch((error) => {
                if (error.response) {
                    dispatch(alertActions.error(error.response.data.message));
                    dispatch(signupStart(false));
                }
            });
    };
};

// --ACTIVATE USER SIGNUP VIA EMAIL
export const activateNormalUser = (data, history) => {
    return async (dispatch) => {
        dispatch(activateStart(true));
        dispatch(alertActions.clear());
        const url = '/auth/email-activate';
        await axios
            .post(url, data)
            .then((response) => {
                dispatch(alertActions.success(response.data.message));
                store.set('kmt-user', response.data.user);
                dispatch(activateStart(false));
                history.push('/complete-signup');
                dispatch(fecthProfile(response.data.user));
            })
            .catch((error) => {
                if (error.response) {
                    dispatch(alertActions.error(error.response.data.message));
                    dispatch(activateStart(false));
                }
            });
    };
};

// // --COMPLETE USER SIGNUP VIA EMAIL
export const completeNormalUserSignup = (data, history) => {
    return async (dispatch) => {
        dispatch(completeSignupStart(true));
        dispatch(alertActions.clear());
        const url = '/users/complete';
        const token = store.get('kmt-user') && store.get('kmt-user').accessToken;
        await axios
            .put(url, data, {
                headers: {
                    'x-access-token': token,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                store.set('kmt-user', {accessToken: token, ...response.data.user});
                dispatch(alertActions.success(response.data.message));
                dispatch(completeSignupStart(false));

                if (store.get('performOperationFrom')) {
                    history.push(store.get('performOperationFrom'));
                }
                history.push('/feeds');

                dispatch(fecthProfile(response.data.user));

                // clear the url from storage
                store.remove('performOperationFrom');
            })
            .catch((error) => {
                if (error.response) {
                    dispatch(
                        alertActions.error(
                            error.response.data.message || error.response.message
                        )
                    );
                    dispatch(completeSignupStart(false));
                }
            });
    };
};

// ---GET DATA ON CLICK OF GOOGLE SIGNUP/SIGNIN
export const getGoogleSignupUser = (data, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    const url = '/oauth/google';
    await axios
        .post(url, {oauthId: data.profileObj.googleId})
        .then((response) => {
            if (response.status === 200) {
                if (
                    response.data.user.allowPushNotification &&
                    response.data.user.browser === browserDetector()
                ) {
                    redirectBasedOnAction(response, history);
                } else {
                    addPushNotification(history, dispatch);
                    redirectBasedOnAction(response, history);
                }

                dispatch(fecthProfile(response.data.user));
                // clear the url from storage
                store.remove('performOperationFrom');
            }
        })
        .catch((error) => {
            if (error.response) {
                store.set('signUpWith', 'google');
                store.set('signUpwithSocialData', {
                    oauthId: data.profileObj.googleId,
                    fullName: data.profileObj.name,
                    email: data.profileObj.email,
                    profilePicture: data.profileObj.imageUrl,
                });
                history.push('/ga-signup');
            }
        });
};

// ---GET DATA ON CLICK OF FACEBOOK SIGNUP/SIGNIN
export const getFacebookSignupUser = (data, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    const url = '/oauth/facebook';
    await axios
        .post(url, {oauthId: data.id})
        .then((response) => {
            if (response.status === 200) {
                if (
                    response.data.user.allowPushNotification &&
                    response.data.user.browser === browserDetector()
                ) {
                    redirectBasedOnAction(response, history);
                } else {
                    addPushNotification(history, dispatch);
                    redirectBasedOnAction(response, history);
                }
                dispatch(fecthProfile(response.data.user));
                // clear the url from storage
                store.remove('performOperationFrom');
            }
        })
        .catch((error) => {
            if (error.response) {
                store.set('signUpWith', 'facebook');
                store.set('signUpwithSocialData', {
                    oauthId: data.id,
                    fullName: data.name,
                    email: data.email,
                    profilePicture: data.picture.data.url,
                });

                history.push('/ga-signup');
            }
        });
};

// ---COMPLETE REGISTRATION BY PASSING THE DATA SENT VIA GOOGLE/FACEBOOK TO DB
export const completeSignupUserViaSocial = (path, data, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    dispatch(socialStart(true));
    const url = `/oauth/${path}?method=register`;
    await axios
        .post(url, data)
        .then((response) => {
            if (response.status === 201) {
                store.set('kmt-user', response.data.user);
                if (store.get('performOperationFrom')) {
                    history.push(store.get('performOperationFrom'));
                } else {
                    history.push('/feeds');
                }

                dispatch(fecthProfile(response.data.user));

                // clear the url from storage
                store.remove('performOperationFrom');

                dispatch(socialStart(false));
            }
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                dispatch(socialStart(false));
            }
        });
};

// // REQUEST FOR PASSWORD RESET
export const requestForgetPassword = (data, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    dispatch(requestForogtPasswordStart(true));
    const url = '/auth/forgot-password';
    await axios
        .put(url, data)
        .then((response) => {
            dispatch(alertActions.success(response.data.message));
            dispatch(requestForogtPasswordStart(false));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                dispatch(requestForogtPasswordStart(false));
            }
        });
};

// // GET DATA FOR NEW PASSWORD AND SEND TO DB TO UPDATE DATA
export const authenticateForgotPassword = (data, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    dispatch(authenticateForgotPasswordStart(true));
    const url = '/auth/reset-password';
    await axios
        .put(url, data)
        .then((response) => {
            dispatch(alertActions.success(response.data.message));
            dispatch(authenticateForgotPasswordStart(false));
            history.push('/login');
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                dispatch(authenticateForgotPasswordStart(false));
            }
        });
};

// Helper function to redirect user amd store userInfo
const redirectBasedOnAction = (response = {}, history) => {
    store.set('kmt-user', response.data.user);
    if (store.get('performOperationFrom')) {
        history.push(store.get('performOperationFrom'));
    } else {
        history.push('/feeds');
    }
};

// // Dispatch functions
const socialStart = (value) => ({
    type: Actions.SOCIAL_START,
    payload: value,
});

const loginStart = (value) => ({
    type: Actions.LOGIN_START,
    payload: value,
});

const signupStart = (value) => ({
    type: Actions.SIGNUP_START,
    payload: value,
});

const activateStart = (value) => ({
    type: Actions.ACTIVATE_START,
    payload: value,
});

const completeSignupStart = (value) => ({
    type: Actions.COMPLETE_SIGNUP_START,
    payload: value,
});

const requestForogtPasswordStart = (value) => ({
    type: Actions.REQUEST_FORGOT_PASSWORD_START,
    payload: value,
});

const authenticateForgotPasswordStart = (value) => ({
    type: Actions.AUTHENTICATE_FORGOT_PASSWORD_START,
    payload: value,
});
