import React from 'react';
import {Route, Redirect} from 'react-router';
import store from 'store';

const ProtectedRoute = ({component: Component, isgeneral, ...rest}) => (
    <Route
        {...rest}
        render={(props) => {
            const currentUser =
                store.get('kmt-user') && store.get('kmt-user').accessToken;
            if (typeof currentUser === 'undefined' || currentUser === null) {
                store.set('performOperationFrom', props.history.location.pathname);
                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: {from: props.location},
                        }}
                    />
                );
            }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

export default ProtectedRoute;
