const Actions = {
    ALERT_SUCCESS: 'ALERT_SUCCESS',
    ALERT_ERROR: 'ALERT_ERROR',
    ALERT_CLEAR: 'ALERT_CLEAR',
    ALERT_INFO: 'ALERT_INFO',
    ALERT_WARNING: 'ALERT_WARNING',

    IS_ERROR: 'is_error',

    // Main Authenticate user
    AUTH_USER: 'auth_user',
    UNAUTH_USER: 'auth_user',

    //Complete Social Login/Signup
    SOCIAL_START: 'social_start',

    LOADING: 'loading',

    // Login
    LOGIN_START: 'login_start',

    // Normal Signup
    SIGNUP_START: 'signup_start',

    // Activate Normal User signup
    ACTIVATE_START: 'activate_start',

    // Complete Signup
    COMPLETE_SIGNUP_START: 'complete_signup_start',

    // Forgot Password
    REQUEST_FORGOT_PASSWORD_START: 'request_forgot_password_start',

    // Authenticate Forgot Password after email sent
    AUTHENTICATE_FORGOT_PASSWORD_START: 'aunthenticate_forgot_password_start',

    // FETCH talent categories
    FETCH_TALENT_CATEGORIES: 'fetch_talent_categories',

    // fetch feeds
    FETCH_FEEDS_START: 'fetch_feeds_start',
    FETCH_FEEDS: 'fetch_feeds',
    FETCH_RECENT_MEDIA_FEEDS: 'fetch_recent_media_feeds',

    // fetch single post
    FETCH_SINGLE_POST: 'fetch_single_post',

    // follow
    FOLLOW_STATUS: 'follow_status',

    // FOLLOW_REQUEST
    FOLLOW_REQUEST: 'follow_request',

    // Cancel follow request
    CANCEL_FOLLOW_REQUEST: 'cancel_follow_request',

    // fetch followings
    FETCH_FOLLOWINGS: 'fetch_followings',

    // fetch followings
    FETCH_FOLLOWERS: 'fetch_followers',

    // fetch follow request
    FETCH_FOLLOW_REQUEST: 'fetch_follow_request',

    // ACCEPT_FOLLOW_REQUEST
    ACCEPT_FOLLOW_REQUEST: 'accept_follow_request',

    // ACCEPT_FOLLOW_REQUEST
    DECLINE_FOLLOW_REQUEST: 'decline_follow_request',

    // like post
    LIKE_POST_STATUS: 'like_post_status',
    UNLIKE_POST_STATUS: 'unlike_post_status',

    //comment
    COMMENT_START: 'comment_start',
    COMMENT_SUCCESSFUL: 'comment_successful',

    // share post
    SHARE_STATUS: 'share_status',

    // Update post
    UPDATE_POST: 'update_post',

    // Delete post
    DELETE_POST: 'delete_post',

    // Keep data to show on modal before sharing
    KEEP_DATA_TO_SHARE: 'keep_data_to_share',

    // Auditions
    // Fetch Audition
    FETCH_AUDITION: 'fetch_audition',

    // Vote Audition Entry
    VOTE_AUDITION_ENTRY: 'vote_audition_entry',

    VOTE_AUDITION_START: 'vote_audition_start',

    // Fetch Single Audition
    FETCH_SINGLE_AUDITION: 'fetch_single_audition',
    FETCH_AUDITION_ENTRY: 'fetch_audition_entry',

    // Fetching audition applicant
    FETCH_AUDITION_APPLICANTS: 'fetch_audition_applicants',

    // Fetching applied audition
    FETCH_APPLIED_AUDITION: 'fetch_applied_audition',

    // Fetch all chat converstion
    FETCH_CONVERSATION: 'fetch_conversation',

    // Clear chat converstion
    CLEAR_CHAT_HISTORY: 'clear_chat_history',

    // Fetch all previous chat converstion for a user
    FETCH_PREVIOUS_MESSAGE: 'fetch_previous_message',

    // Fetch all notifications
    FETCH_NOTIFICATIONS: 'fetch_notifications',

    // delete notification
    DELETE_NOTIFICATIONS: 'delete_notifications',

    // Set user stat
    SET_USER_STAT: 'set_user_stat',
    UPDATE_USER_STAT: 'update_user_stat',

    // Fetch single user
    FETCH_USER: 'fetch_user',

    // Fetch user posts
    FETCH_USER_POST: 'fetch_user_post',

    // store the userid
    KEEP_USERID_TO_MESSAGE: 'keep_userid_to_message',

    // fetch notifications and alert seettings
    FETCH_NOTIFICATIONS_AND_ALERT_SETTINGS: 'fetch_notifications_and_alert_settings',

    // fetch privacy settings
    FETCH_PRIVACY_SETTINGS: 'fetch_privacy_settings',

    // activate / deactivate read receipt
    TOGGLE_READ_RECEIPT: 'toggle_read_receipt',

    // make account private
    MAKE_ACCOUNT_PRIVATE: 'make_account_private',

    // Enable or Disable location
    SHOW_LOCATION: 'show_location',

    // subscribe to newsletter
    SUBSCRIBE_TO_NEWSLETTER: 'subscribe_to_newsletter',

    // allow email notification
    ALLOW_EMAIL_NOTIFICATION: 'allow_email_notification',

    // fetch users
    FETCH_USERS: 'fetch_users',

    // Update Bio
    UPDATE_BIO: 'update_bio',
};

export default Actions;
