export const getMediaType = (url) => {
    if (!url) return null;

    const extension = url.split('.').pop();

    return extension.split('?')[0];
};

export const isVideo = (url) => {
    if (!url) return false;

    return url.includes('.webm') || url.includes('.mp4') || url.includes('.ogg');
};

export const auditionEligibility = (user, country, region, audition) => {
    const age = user.dob && new Date().getFullYear() - new Date(user.dob).getFullYear();

    const categoryAllowed =
        (audition.talentCategories && audition.talentCategories.length === 0) ||
        (audition.talentCategories &&
            audition.talentCategories[0].id === user.talentCategories[0].id);
    const countryAllowed =
        !audition.countries ||
        (audition.countries && audition.countries.length === 0) ||
        (audition.countries && !audition.countries[0]) ||
        (audition.countries &&
            country &&
            audition.countries[0].toLowerCase() === country.toLocaleLowerCase());
    const regionAllowed =
        !audition.regions ||
        (audition.regions && audition.regions.length === 0) ||
        (audition.regions && !audition.regions[0]) ||
        (audition.regions &&
            region &&
            audition.regions[0].toLowerCase() === region.toLocaleLowerCase());

    const ageIsAllowed =
        typeof audition.startAge == 'undefined' ||
        typeof audition.endAge == 'undefined' ||
        (audition.startAge <= age && audition.endAge >= age);

    return categoryAllowed && countryAllowed && ageIsAllowed && regionAllowed;
};

export const unsignedAuditionEligibility = (country, region, audition) => {
    const countryAllowed =
        !audition.countries ||
        (audition.countries && audition.countries.length === 0) ||
        (audition.countries && !audition.countries[0]) ||
        (audition.countries &&
            country &&
            audition.countries[0].toLowerCase() === country.toLowerCase());

    const regionAllowed =
        !audition.regions ||
        (audition.regions && audition.regions.length === 0) ||
        (audition.regions && !audition.regions[0]) ||
        (audition.regions &&
            region &&
            audition.regions[0].toLowerCase() === region.toLowerCase());

    return countryAllowed && regionAllowed;
};

export const paymentOptions = [
    {label: 'Pay with Paystack', value: 'paystak'},
    {label: 'Pay with Flutterwave', value: 'flutter'},
    {label: 'Pay with Stripe', value: 'stripe'},
];
