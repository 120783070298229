import React, {useState} from 'react';
import moment from 'moment';
import ShareIcon from '../../assets/img/svg/share-icon_.svg';
import {Link} from 'react-router-dom';
import {DateSuperscript} from '../../utils/DateSuperscript';
import {stringFirstUpperCase} from '../../utils/stringFirstsUppercase';
import {capitalizeFirstLetter} from '../../utils/capitalizeFirstLetter';
import FacebookIcon from '../../assets/img/svg/facebook.svg';
import TwitterIcon from '../../assets/img/svg/twitter.svg';

const UnsignedInAuditionItem = ({audition}) => {
    const [show, setShow] = useState(false);
    const [id, setId] = useState(null);

    const handleSharedropdown = (e, id) => {
        e.stopPropagation();
        e.preventDefault();
        setShow((prev) => !prev);
        setId(id);
    };

    return (
        <div className="col-lg-4 audition-item" key={audition.auditionId}>
            <div className="panel">
                <Link to={`/auditions/${audition.uniqueUrlId}`}>
                    <section className="content">
                        <div
                            className="media"
                            style={{
                                background: `#000000d9 url(${audition.mediaUrl}) no-repeat center`,
                            }}
                        >
                            <div className="share">
                                <a
                                    href="/"
                                    onClick={(e) =>
                                        handleSharedropdown(e, audition.auditionId)
                                    }
                                >
                                    <img src={ShareIcon} alt="" />
                                    <label>Share</label>
                                </a>

                                {show && audition.auditionId === id ? (
                                    <div className="more-options">
                                        <div className="input-group-append d-flex">
                                            <span className="share-audtion-icon">
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://www.facebook.com/sharer.php?u=http%3A%2F%2Fmyspotlyt.com/%2F"
                                                >
                                                    <img src={FacebookIcon} alt="" />
                                                </a>
                                            </span>
                                        </div>
                                        <div className="input-group-append d-flex">
                                            <span className="share-audtion-icon">
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://twitter.com/intent/tweet
?url=http%3A%2F%2Fmyspotlyt.com%2F
&text=Audition+from+MySpotlyt.
&hashtags=audition,talents"
                                                >
                                                    <img src={TwitterIcon} alt="" />
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className="media-panel">
                            <label>
                                {moment(audition.startDate).format("MMMM")}
                                <strong>
                                    {moment(audition.startDate).format("D")}
                                    <small>
                                        {DateSuperscript(parseInt(moment(audition.startDate).format("D")))}
                                    </small>
                                </strong>
                            </label>
                            <div className="audition-short">
                                <h3 data-toggle="tooltip" title={audition.title}>
                                    {audition.title.length > 13
                                        ? stringFirstUpperCase(
                                              audition.title.slice(0, 30)
                                          ) + '...'
                                        : stringFirstUpperCase(audition.title)}
                                </h3>
                                <p>
                                    {capitalizeFirstLetter(
                                        audition.description.slice(0, 50)
                                    )}
                                    ...
                                </p>
                            </div>
                        </div>
                    </section>
                </Link>
            </div>
        </div>
    );
};

export default UnsignedInAuditionItem;
