import React from 'react';
import MainImg from '../../assets/img/png/grouped-img.png';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
    }

    handleClick = () => {
        // window.history.go(-1);
        window.location.href = "/";
        this.props.onReset()
    };

    render() {
        if (this.state.hasError) {
            return (
                <div
                    style={{
                        height: '100vh',
                        width: '100%',
                        backgroundImage: `url(${MainImg})`,
                        backgroundRepeat: 'repeat-x',
                    }}
                    className="d-flex justify-content-center align-items-center"
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100vh',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        }}
                    />
                    <div className="text-center" style={{zIndex: 999, opacity: 0.8}}>
                        <h1
                            className="text-white font-weight-bold"
                            style={{fontSize: '4rem'}}
                        >
                            Something went wrong. Please try again
                        </h1>
                        <button
                            className="btn btn-primary mt-3"
                            onClick={this.handleClick}
                        >
                            &lt;&lt; Click to Go Back
                        </button>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
