import React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../components/layout/MainLayout/Header';
import Footer from '../components/layout/MainLayout/Footer';
import {scrollTop} from '../utils/scrollTop';

const Terms = () => {
    scrollTop();

    return (
        <>
            <Helmet>
                <title>Terms</title>
            </Helmet>
            <Header />
            <main className="main">
                <section className="t_and_c privacy">
                    <div className="container">
                        <h1 className="text-center">Our Terms of use for MySpotlyt</h1>
                        <div className="content">
                            <p className="mb-2 text-justify">
                                Please note that this platform is not in any way
                                affiliated to any individual, organisation or company
                                therein mentioned. All content here is solely for
                                showcasing and general informational purposes.
                            </p>

                            <p className="mb-2 text-justify">
                                Unless otherwise stated, by signing up to and using this
                                platform, you have granted MySpotlyt express permission to
                                use all intellectual property in their various formats on
                                any MySpotlyt platform (including but not limited to our
                                website and our apps) and they are controlled and licensed
                                by us.
                            </p>

                            <p className="mb-2 text-justify">
                                Any unauthorised use of said materials contained herein,
                                is at the user's risk. You should not rely upon the
                                materials or information on the website as a basis for
                                making business, legal or any other decisions.
                            </p>

                            <p className="mb-2 text-justify">
                                All engagements and business between talents and scouts on
                                all MySpotlyt platforms are purely at the risk of the
                                engaging parties. MySpotlyt is not liable or responsible
                                for any outcomes that may arise from these contacts and
                                connections.
                            </p>

                            <p className="mb-2 text-justify">
                                All of the content contained on MySpotlyt is edited,
                                checked and verified for accuracy as much as it is
                                possible to do so. However, we cannot assure either its
                                accuracy or the safety of any external links it might
                                contain.
                            </p>

                            <p className="mb-2 text-justify">
                                MySpotlyt as well as its owners, affiliates and
                                contributing authors can therefore not be held responsible
                                for any problems or damage that may occur as a result of
                                making use of materials contained on our site.
                            </p>

                            <p className="mb-2 text-justify">
                                MySpotlyt may at any time and at its sole discretion
                                change or replace the information available on this
                                website. We will not be liable for any direct, incidental,
                                consequential, indirect or punitive damages arising out of
                                access to or use of any content available on this website,
                                regardless of the accuracy or totality.
                            </p>

                            <p className="mb-2 text-justify">
                                While MySpotlyt cannot offer legal defence to anyone, we
                                remain vigilant about takedown notices impacting all
                                creators. All third party intellectual property referenced
                                by MySpotlyt remain the property of their respective
                                owners. Unless specifically identified as such,
                                MySpotlyt's use of third party trademarks does not
                                indicate any relationship, sponsorship or endorsement
                                between MySpotlyt, owners of these trademarks and scouts.
                            </p>

                            <p className="mb-2 text-justify">
                                The owners of this platform cannot be held accountable for
                                the copyrighted content. MySpotlyt is a messenger and
                                sharer of information and strives to verify but cannot
                                warrant the accuracy of copyrights or completeness of the
                                information on this website. You may not, except with our
                                express written permission, distribute or commercially
                                exploit the content. Nor may you transmit it or store it
                                in any other website or other form of electronic retrieval
                                system.
                            </p>

                            <p className="mb-2 text-justify">
                                Talents are hereby advised to apply discretionary measures
                                and due diligence in partnering with scouts for any and
                                further engagements. MySpotlyt will not be answerable to
                                any deal or partnership that may result in a change or
                                reversal of course or direction.
                            </p>

                            <h3>General information</h3>
                            <p className="mb-2 text-justify">
                                The use of the products and services we offer may result
                                in the processing of personal data. The term “personal
                                data” under data protection law refers to all information
                                relating to a specific or identifiable person. An IP
                                address can also be considered personal data. An IP
                                address is assigned to each device connected to the
                                internet by the internet service provider, so that it can
                                send and receive data. When you use the website, we
                                collect data that you provide yourself. In addition, when
                                you use the website, we automatically collect certain
                                information about your use of it
                            </p>
                            <p className="mb-2 text-justify">
                                We process personal data in compliance with the relevant
                                data protection regulations of the GDPR, the DPA (Data
                                Protection Act) and the PECR (Privacy and Electronic
                                Communications Regulations). We will only process data
                                where we are legally permitted to do so. When you use this
                                website, we will process personal data only with your
                                consent, for the performance of a contract to which you
                                are a party, or in order to take steps at your request
                                prior to entering into a contract, for compliance with a
                                legal obligation or if the processing is necessary for the
                                purposes of our legitimate interests or the legitimate
                                interests of a third party, except where such interests
                                are overridden by your interests or fundamental rights and
                                freedoms which require the protection of personal data.
                            </p>

                            <h3>Transmission of data</h3>
                            <p className="mb-2 text-justify">
                                Unless otherwise stated in the following sections, data
                                will be processed on the servers of technical service
                                providers commissioned by us for this purpose. These
                                service providers will only process the data after having
                                received express instructions and they are contractually
                                obliged to guarantee adequate technical and organisational
                                measures for data protection.
                            </p>
                            <p className="mb-2 text-justify">
                                Unless otherwise stated in the following sections, data
                                will be processed on the servers of technical service
                                providers commissioned by us for this purpose. These
                                service providers will only process the data after having
                                received express instructions and they are contractually
                                obliged to guarantee adequate technical and organisational
                                measures for data protection.
                            </p>
                            <h3>Visitor tracking</h3>
                            <p>
                                We use Google Analytics to collect standard internet log
                                information and details of visitor behaviour on our
                                website. Google Analytics collects information from users
                                such as age, gender, interests, demographics, how often
                                they visit our website, what pages they visit, and what
                                other websites they have used before coming to our
                                website. We use the information we get from Google
                                Analytics to analyse traffic, re-market our services to
                                users, improve our marketing, advertising, and generally
                                improve our website experience. We have enabled Google
                                Analytics advertising features such as re-marketing with
                                Google Analytics, Google Display Network Impression
                                Reporting, and Google Analytics Demographics and Interest
                                Reporting. Google Analytics collects only the IP address
                                assigned{' '}
                            </p>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default Terms;
