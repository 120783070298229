import React, {useState, useEffect} from 'react';
import RegistrationDefault from '../../components/layout/RegistrationDefault';
import {connect} from 'react-redux';
import * as actions from '../../redux/actions';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleIcon from '../../assets/img/svg/google-icon.svg';
import FacebookIcon from '../../assets/img/svg/fb-icon.svg';
import Input from '../../components/Form/Input';
import {validateAll, extend} from 'indicative/validator';
import {getValue} from 'indicative-utils';
// import {useToasts} from 'react-toast-notifications';
import {scrollTop} from '../../utils/scrollTop';
// import {googleAuthResponseMessage} from '../../utils/googleAuthReponseMessage';
import {displayLoader} from '../../utils/displayLoader';
import LeftViewOnAuthPages from '../../components/Blocks/LeftViewOnAuthPages';
import useAlert from '../../components/CustomHooks/useAlert';
import {Link} from 'react-router-dom';

extend('validemail', {
    async: true,

    compile(args) {
        return args;
    },

    async validate(data, field, args, config) {
        const fieldValue = getValue(data, field);
        const re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(fieldValue);
    },
});

const initial = {
    fullName: '',
    email: '',
    password: '',
    areaOfInterest: '',
    agreement: true,
};

const Signup = (props) => {
    // const {addToast} = useToasts();
    const [form, setForm] = useState(initial);
    const [retypePassword, setRetypePassword] = useState('');
    const [errors, setErrors] = useState('');
    const [loadingOnSocial, setLoadingOnSocial] = useState(false);

    useEffect(() => {
        scrollTop();
    }, []);

    const responseSuccessGoogle = async (response) => {
        scrollTop();
        setLoadingOnSocial(true);
        await props.getGoogleSignupUser(response, props.history);
    };

    const responseFailureGoogle = ({error, details}) => {
        // if (error) addToast(googleAuthResponseMessage(error), {appearance: 'error'});
        setLoadingOnSocial(false);
    };

    const responseFacebook = (response) => {
        scrollTop();
        setLoadingOnSocial(true);
        props.getFacebookSignupUser(response, props.history);
    };
    const responseFailureFacebook = () => {
        setLoadingOnSocial(false);
    };

    const handleInputChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleErrorRemoveOnFocus = (e) => {
        setErrors({
            ...errors,
            [e.target.name]: '',
        });
    };

    // handle complete form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        const rules = {
            fullName: 'required',
            email: 'required|email|validemail',
            password: 'required|min:8',
            areaOfInterest: 'required',
        };

        const messages = {
            'fullName.required': 'Your fullname is required',
            'email.email': 'Enter valid email address',
            'email.required': 'Enter email address',
            'email.validemail': 'Enter valid email address',
            'password.required': 'Password is required',
            'password.min': 'Password too short (minimum of 8 characters)',
            'areaOfInterest.required': 'Select your area of talent',
        };

        validateAll(form, rules, messages)
            .then(() => {
                signUp();
            })
            .catch((errors) => {
                const formattedErrors = {};
                errors.forEach((error) => (formattedErrors[error.field] = error.message));
                setErrors(formattedErrors);
            });

        const signUp = async () => {
            if (form.password !== retypePassword) {
                setErrors({...errors, retypePassword: 'Password does not match'});
            } else {
                scrollTop();
                await props.normalSignupUser(form, props.history);

                setForm(initial);
                setRetypePassword('');
                setErrors('');
            }
        };
    };

    const renderOnSocialSignup = () => {
        return displayLoader('Checking Account');
    };

    const renderOnNormalSignup = () => {
        return displayLoader('Creating Account');
    };

    useAlert(props.alert);

    return (
        <>
            <RegistrationDefault
                pageTitle={'Signup Here!'}
                linkName={'Login'}
                linkUrl={'/login'}
                linkStyle={'adj'}
            />
            <main className="account">
                <LeftViewOnAuthPages step={1} />
                <section className="right-pad">
                    {loadingOnSocial
                        ? renderOnSocialSignup()
                        : props.signupStart
                        ? renderOnNormalSignup()
                        : ''}
                    <div className="container main-form">
                        <form onSubmit={handleSubmit}>
                            <section className="step-1">
                                <div className="title">
                                    <h3>Get started absolutely free</h3>
                                    <p>Create your Free Account</p>
                                </div>
                                <Input
                                    type="text"
                                    name="fullName"
                                    value={form.fullName}
                                    onChange={handleInputChange}
                                    placeholder="Full Name"
                                    label="Full Name"
                                    errorMessage={errors.fullName}
                                    onFocus={handleErrorRemoveOnFocus}
                                />
                                <Input
                                    type="email"
                                    errorMessage={errors.email}
                                    name="email"
                                    value={form.email}
                                    onChange={handleInputChange}
                                    onFocus={handleErrorRemoveOnFocus}
                                    placeholder="Email Address"
                                    label="Email Address"
                                />
                                <Input
                                    type="password"
                                    name="password"
                                    value={form.password}
                                    errorMessage={errors.password}
                                    onChange={handleInputChange}
                                    onFocus={handleErrorRemoveOnFocus}
                                    placeholder="Password"
                                    label="Password"
                                />

                                <Input
                                    type="password"
                                    name="retypePassword"
                                    value={retypePassword}
                                    onChange={(e) => setRetypePassword(e.target.value)}
                                    placeholder="Confirm Password"
                                    label="Confirm Password"
                                    errorMessage={errors.retypePassword}
                                    onFocus={handleErrorRemoveOnFocus}
                                />

                                <div className="tag">
                                    <label>Select User Type</label>
                                </div>
                                <small className="text-danger">
                                    {errors.areaOfInterest}
                                </small>
                                <div className="radio_option mode">
                                    <div className="custom-control custom-radio left">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            id="talent"
                                            name="areaOfInterest"
                                            value="talent"
                                            checked={form.areaOfInterest === 'talent'}
                                            onChange={handleInputChange}
                                            onClick={handleErrorRemoveOnFocus}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="talent"
                                        >
                                            <div>
                                                Talents{' '}
                                                <small>
                                                    I want to showcase my talents here
                                                </small>
                                            </div>

                                        </label>
                                    </div>
                                    <div className="custom-control custom-radio left">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            id="scout"
                                            name="areaOfInterest"
                                            value="scout"
                                            checked={
                                                form.areaOfInterest === 'scout'
                                                    ? true
                                                    : false
                                            }
                                            onChange={handleInputChange}
                                            onClick={handleErrorRemoveOnFocus}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="scout"
                                        >
                                            <div>
                                                Scouts{' '}
                                                <small>
                                                    I want to see those who are showcasing
                                                    talents
                                                </small>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="custom-control custom-radio right">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            id="vote"
                                            name="areaOfInterest"
                                            value="vote"
                                            checked={
                                                form.areaOfInterest === 'vote'
                                                    ? true
                                                    : false
                                            }
                                            onChange={handleInputChange}
                                            onClick={handleErrorRemoveOnFocus}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="vote"
                                        >
                                            None{' '}
                                            <small>
                                                I want to be able to vote for talents.
                                            </small>
                                        </label>
                                    </div>
                                </div>

                                <div className="user-agreement px-2">
                                    <p className="text-center">
                                        By clicking Create my account, you agree to Know
                                        My Talent's{' '}
                                        <Link to="user-agreement">User Agreement</Link>,{' '}
                                        <Link to="privacy-policy">Privacy Policy</Link>,
                                        and <Link to="cookie-policy">Cookie Policy</Link>
                                    </p>
                                </div>

                                <div className="action">
                                    <button
                                        type="submit"
                                        disabled={props.signupStart}
                                        className="btn btn-primary apply_"
                                    >
                                        Create my account
                                    </button>
                                    <p className="small m-0 p-0 d-lg-none mt-3">or signup with</p>
                                    <div className="alt">
                                        <small>or</small>
                                        <div className="d-flex">
                                            <GoogleLogin
                                                clientId={
                                                    process.env.REACT_APP_GOOGLE_CLIENT_ID
                                                }
                                                render={(renderProps) => (
                                                    <img
                                                        onClick={renderProps.onClick}
                                                        disabled={renderProps.disabled}
                                                        src={GoogleIcon}
                                                        alt="Signup with google"
                                                    />
                                                )}
                                                onSuccess={responseSuccessGoogle}
                                                onFailure={responseFailureGoogle}
                                                responseType="id_token"
                                            />
                                            <FacebookLogin
                                                appId={
                                                    process.env
                                                        .REACT_APP_FACEBOOK_CLIENT_ID
                                                }
                                                fields="name,email,picture"
                                                callback={responseFacebook}
                                                onFailure={responseFailureFacebook}
                                                render={(renderProps) => (
                                                    <img
                                                        onClick={renderProps.onClick}
                                                        width="60%"
                                                        src={FacebookIcon}
                                                        alt="Signup with facebook"
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </form>
                    </div>
                </section>
            </main>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        signupStart: state.auth.signupStart,
        alert: state.alert,
    };
};

export default connect(mapStateToProps, actions)(Signup);
