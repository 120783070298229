import Actions from '../actions/actionTypes';

const initial = {
    user: {},
    userPosts: {
        totalCount: 0,
    },
    followOnProfile: false,
    followings: {},
    followers: {},
    userStats: {},
    followRequests: {},
    users: [],
};

const UserReducer = (state = initial, action) => {
    switch (action.type) {
        case Actions.FETCH_USER:
            return {
                ...state,
                user: action.payload,
            };

        case Actions.FOLLOW_STATUS:
            return {
                ...state,
                followOnProfile: action.payload.isFollowOrUnfollow,
            };

        case Actions.FETCH_USER_POST:
            return {
                ...state,
                userPosts: action.payload,
            };

        case Actions.FETCH_FOLLOWINGS:
            return {
                ...state,
                followings: action.payload,
            };

        case Actions.FETCH_FOLLOWERS:
            return {
                ...state,
                followers: action.payload,
            };

        case Actions.FETCH_FOLLOW_REQUEST:
            return {
                ...state,
                followRequests: action.payload,
            };

        case Actions.ACCEPT_FOLLOW_REQUEST:
            return {
                ...state,
                followRequests: action.payload.isAccept
                    ? state.followRequests.filter(
                          (f) => f.followRequestId !== action.payload.requestId
                      )
                    : state.followRequests,
            };

        case Actions.DECLINE_FOLLOW_REQUEST:
            return {
                ...state,
                followRequests: action.payload.isReject
                    ? state.followRequests.map(
                          (f) => f.followRequestId !== action.payload.requestId
                      )
                    : state.followRequests,
            };
        case Actions.FETCH_USERS:
            return {
                ...state,
                users: action.payload,
            };

        case Actions.UPDATE_BIO:
            return {
                ...state,
                user: {
                    ...state.user,
                    bio: action.payload,
                },
            };
        case Actions.SET_USER_STAT:
            return {
                ...state,
                userStats: {
                    ...state.userStats,
                   ...action.payload,
                },
            };
        case Actions.UPDATE_USER_STAT:
            const curr = state.userStats[action.payload.key] || 0;
            const newCount = curr + action.payload.value
            return {
                ...state,
                userStats: {
                    ...state.userStats,
                   [action.payload.key]: newCount > 0 ? newCount : 0,
                },
            };

        default:
            return state;
    }
};

export default UserReducer;
