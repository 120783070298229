import React from 'react';
import MainImg from '../assets/img/png/grouped-img.png';

const Error404 = () => {
    const handleClick = () => {
        window.history.go(-1);
    };
    return (
        <>
            <div
                style={{
                    height: '100vh',
                    width: '100%',
                    backgroundImage: `url(${MainImg})`,
                    backgroundRepeat: 'repeat-x',
                }}
                className="d-flex justify-content-center align-items-center"
            >
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100vh',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    }}
                ></div>
                <div className="text-center" style={{zIndex: 999, opacity: 0.8}}>
                    <h1
                        className="text-white font-weight-bold"
                        style={{fontSize: '4rem'}}
                    >
                        Page Not Found.
                    </h1>
                    <button className="btn btn-primary mt-3" onClick={handleClick}>
                        &lt;&lt; Click to Go Back
                    </button>
                </div>
            </div>
        </>
    );
};

export default Error404;
