import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import store from 'store';

const AuditionHeader = (props) => {
    const userAreaOfInterest =
        store.get('kmt-user') && store.get('kmt-user').areaOfInterest;

    const route = props.match.path;
    return (
        <section>
            <button
                type="button"
                className="btn btn-flat"
                data-toggle="modal"
                data-target="#filterAuditionModal"
                style={{fontSize: '1rem'}}
                disabled={props.auditions.length === 0 ? true : false}
            >
                <i className="icon">
                    <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.5 7.36125V17.25C16.5 17.664 16.164 18 15.75 18C15.336 18 15 17.664 15 17.25V7.36125C14.1285 7.05075 13.5 6.2265 13.5 5.25C13.5 4.2735 14.1285 3.44925 15 3.138V0.75C15 0.336 15.336 0 15.75 0C16.164 0 16.5 0.336 16.5 0.75V3.138C17.3708 3.44925 18 4.2735 18 5.25C18 6.2265 17.3708 7.05075 16.5 7.36125ZM15.75 4.5C15.3368 4.5 15 4.836 15 5.25C15 5.66325 15.3368 6 15.75 6C16.1632 6 16.5 5.66325 16.5 5.25C16.5 4.836 16.1632 4.5 15.75 4.5ZM9.75 14.862V17.25C9.75 17.664 9.414 18 9 18C8.586 18 8.25 17.664 8.25 17.25V14.862C7.37925 14.5507 6.75 13.7265 6.75 12.75C6.75 11.7735 7.37925 10.9493 8.25 10.638V0.75C8.25 0.336 8.586 0 9 0C9.414 0 9.75 0.336 9.75 0.75V10.638C10.6215 10.9493 11.25 11.7735 11.25 12.75C11.25 13.7265 10.6215 14.5507 9.75 14.862ZM9 12C8.58675 12 8.25 12.336 8.25 12.75C8.25 13.164 8.58675 13.5 9 13.5C9.41325 13.5 9.75 13.164 9.75 12.75C9.75 12.336 9.41325 12 9 12ZM3 11.112V17.25C3 17.664 2.664 18 2.25 18C1.836 18 1.5 17.664 1.5 17.25V11.112C0.6285 10.8015 0 9.9765 0 9C0 8.0235 0.6285 7.1985 1.5 6.888V0.75C1.5 0.336 1.836 0 2.25 0C2.664 0 3 0.336 3 0.75V6.888C3.87075 7.1985 4.5 8.0235 4.5 9C4.5 9.9765 3.87075 10.8015 3 11.112ZM2.25 8.25C1.83675 8.25 1.5 8.58675 1.5 9C1.5 9.41325 1.83675 9.75 2.25 9.75C2.66325 9.75 3 9.41325 3 9C3 8.58675 2.66325 8.25 2.25 8.25Z"
                            fill="#7158E2"
                        />
                    </svg>
                </i>
                Filter
            </button>
            <div className="btn-flat dropdown sort">
                <Link
                    to="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <span>Categories</span>
                    <i className="icon">
                        <svg
                            width="10"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                opacity="0.4"
                                d="M1.78119 0.218262L5.22069 3.65778L8.6602 0.218262L9.7207 1.27876L5.22069 5.77878L0.72069 1.27876L1.78119 0.218262Z"
                                fill="#7158E2"
                            ></path>
                        </svg>
                    </i>
                </Link>
                <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="profile-menu"
                    x-placement="bottom-end"
                    style={{
                        position: 'absolute',
                        willChange: 'transform',
                        top: '0px',
                        left: '0px',
                        transform: 'translate3d(-10px, 44px, 0px)',
                        fontFamily: 'Work Sans',
                        fontSize: '.8rem',
                    }}
                >
                    <button
                        className="dropdown-item py-2"
                        onClick={() => props.handleFilterByCategory(undefined)}
                    >
                        <span>All</span>
                    </button>
                    {props.categories.map((category, key) => (
                        <button
                            key={key}
                            onClick={() => props.handleFilterByCategory(category.id)}
                            className="dropdown-item py-2"
                        >
                            {category.name}
                        </button>
                    ))}
                </div>
            </div>

            <div className="btn-flat dropdown sort">
                <Link
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <span>Audition Type</span>
                    <i className="icon">
                        <svg
                            width="10"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                opacity="0.4"
                                d="M1.78119 0.218262L5.22069 3.65778L8.6602 0.218262L9.7207 1.27876L5.22069 5.77878L0.72069 1.27876L1.78119 0.218262Z"
                                fill="#7158E2"
                            ></path>
                        </svg>
                    </i>
                </Link>
                <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="profile-menu"
                    x-placement="bottom-end"
                    style={{
                        position: 'absolute',
                        willChange: 'transform',
                        top: '0px',
                        left: '0px',
                        transform: 'translate3d(-10px, 44px, 0px)',
                        fontFamily: 'Work Sans',
                        fontSize: '.8rem',
                    }}
                >
                    <button
                        className="dropdown-item py-2"
                        onClick={() => props.handleFilterByType(null)}
                    >
                        <span>All</span>
                    </button>
                    <button
                        className="dropdown-item py-2"
                        onClick={() => props.handleFilterByType('voting')}
                    >
                        <span>Voting</span>
                    </button>
                    <button
                        className="dropdown-item py-2"
                        onClick={() => props.handleFilterByType('non-voting')}
                    >
                        <span>Non-Voting</span>
                    </button>
                </div>
            </div>
            <div className="btn-flat dropdown sort">
                <Link
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <span>Sort By</span>
                    <i className="icon">
                        <svg
                            width="10"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                opacity="0.4"
                                d="M1.78119 0.218262L5.22069 3.65778L8.6602 0.218262L9.7207 1.27876L5.22069 5.77878L0.72069 1.27876L1.78119 0.218262Z"
                                fill="#7158E2"
                            ></path>
                        </svg>
                    </i>
                </Link>
                <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="profile-menu"
                    x-placement="bottom-end"
                    style={{
                        position: 'absolute',
                        willChange: 'transform',
                        top: '0px',
                        left: '0px',
                        transform: 'translate3d(-10px, 44px, 0px)',
                        fontFamily: 'Work Sans',
                        fontSize: '.8rem',
                    }}
                >
                    <button
                        className="dropdown-item py-2"
                        onClick={() => props.handleSortPageBy('newest')}
                    >
                        <span>Newest</span>
                    </button>
                    <button
                        className="dropdown-item py-2"
                        onClick={() => props.handleSortPageBy('oldest')}
                    >
                        <span>Oldest</span>
                    </button>
                </div>
            </div>

            {/* If user is  a talent user, 'View audition you applied for
                else if the route path is my-auditions(seen by scouts user only)
                then Create New Audition, else view my audition(scout user only)' */}
            {userAreaOfInterest === 'talent' ? (
                <Link
                    to="/talent-auditions/applied-auditions"
                    className="btn btn-primary"
                >
                    View Applied Auditions
                </Link>
            ) : route === '/talent-auditions/my-auditions' ? (
                <Link to="/talent-auditions/create-audition" className="btn btn-primary">
                    Create New Audition
                </Link>
            ) : (
                <Link
                    to="/talent-auditions/my-auditions"
                    className="btn btn-primary ml-3"
                >
                    My Auditions
                </Link>
            )}
        </section>
    );
};

export default withRouter(AuditionHeader);
