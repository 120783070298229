import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';
import LeftSideBar from '../../../components/Feeds/LeftSideBar';
import Header from '../../../components/Feeds/Header';
import store from 'store';

import {stringFirstUpperCase} from '../../../utils/stringFirstsUppercase';
import {capitalizeFirstLetter} from '../../../utils/capitalizeFirstLetter';
import useAlert from '../../../components/CustomHooks/useAlert';
import {scrollTop} from '../../../utils/scrollTop';
import {ApplicantVideoModal} from '../../unsignedInAudition/UnsignedInAuditionHiglights';
import {getMediaType, isVideo, paymentOptions} from '../../../utils/global';
import PlayIcon from '../../../assets/img/svg/play-icon.svg';
import $ from 'jquery';
import MainLayoutHeader from '../../../components/layout/MainLayout/Header';
import PhotoIcon from '../../../assets/img/svg/photo-file-icon.svg';
import Modal from '../../../components/Modal';
import Select from 'react-select';

const AuditionDetailsBanner = (props) => {
    const {mediaUrl, title, description} = props;
    return (
        <section id="audition-header">
            <div
                className="tier_one sub alt"
                style={{
                    background: `#000000d9 url(${mediaUrl}) no-repeat center`,
                }}
            >
                <div className="container">
                    <div className="h3ro alt alt_">
                        <h1>{stringFirstUpperCase(title)}</h1>
                        <p>
                            {description && description.length >= 100
                                ? capitalizeFirstLetter(description.slice(0, 100)) + '...'
                                : capitalizeFirstLetter(description)}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

const AuditionDetails = (props) => {
    const userData = store.get('kmt-user') || null;
    const {auditionEntry} = props;
    const auditionUniqueUrl = props.match.params.auditionId;
    const {userInfo, auditionInfo, auditionEntry: applicantEntry} = auditionEntry;
    const entryId = applicantEntry ? applicantEntry.auditionEntryId : '';
    const {hasVoted, votedAuditionEntryId} = userInfo || {};
    const [voted, setVoted] = useState(hasVoted);
    const audition = auditionInfo || {};
    const hasFreeVote = audition.voteCategory === 'free';
    const [voteVerificationModal, setVoteVerificationModal] = useState(false);
    const [votingModalIsVisible, setVotingModalIsVisible] = useState(false);
    const [voteValidationModal, setVoteValidationModal] = useState(false);
    const [voteCount, setVoteCount] = useState(null);
    const [payBy, setPayBy] = useState(paymentOptions[0]);
    const [verificationCode, setVerificationCode] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [totalVoteCost, setTotalVoteCost] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    //modal window with video & applicant name
    const [displayVideoModal, setDisplayVideoModal] = useState({
        applicantName: '',
        applicantVideo: '',
    });

    useEffect(() => {
        const voteAmount = audition.voteAmount || 0;
        setTotalVoteCost(voteAmount * voteCount);
    }, [voteCount, audition.voteAmount]);

    useEffect(() => {
        const voteStatus = hasVoted && votedAuditionEntryId === entryId;
        setVoted(voteStatus);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo]);

    const [loading, setLoading] = useState(false);

    const handleVoting = () => {
        // handle voting if user is accessToken is available i.e user is logged in
        if (userData && userData.accessToken) {
            // handle voting or unvoting of audition entry
            if (hasFreeVote && voted === true) {
                props.unvotedAuditionEntry(talentEntry.auditionEntryId, props.history);
                setVoted(false);
            } else {
                if (hasFreeVote) {
                    props.votedAuditionEntry(talentEntry.auditionEntryId, props.history);
                    setVoted(true);
                } else {
                    // Open dialog for number of votes
                    setVotingModalIsVisible(true);
                }
            }
        } else {
            if (hasFreeVote) {
                store.set('performOperationFrom', props.history.location.pathname);
                props.history.push('/login');
            } else {
                setVoteVerificationModal(true);
            }
        }
    };

    const talentEntry = auditionEntry.auditionEntry || null;

    useEffect(() => {
        scrollTop();
        fetchEntry();
        props.fetchSingleAuditionByUrl(auditionUniqueUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchEntry = async () => {
        setLoading(true);
        await props.fetchAuditionEntry(props.match.params.slug, auditionUniqueUrl);
        setLoading(false);
    };

    const renderButton = () => {
        if (hasFreeVote && hasVoted && votedAuditionEntryId !== entryId) {
            return <React.Fragment />;
        }
        return audition.auditionType === 'voting' ? (
            <button
                className={`btn ${voted ? 'btn-primary' : 'btn-secondary'} `}
                type="button"
                disabled={props.voteLoading}
                onClick={handleVoting}
            >
                {hasFreeVote && voted ? 'Unvote' : 'Vote'}
            </button>
        ) : (
            <React.Fragment />
        );
    };

    const renderLoader = () => {
        return (
            <section className="waiting">
                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </section>
        );
    };

    const displayApplicantVideo = (applicantName, applicantVideo) => {
        setDisplayVideoModal({
            applicantName,
            applicantVideo,
        });
        // Display the modal
        $('#applicantVideo')
            .modal('show')
            .on('hidden.bs.modal', function () {
                setDisplayVideoModal({
                    applicantName: '',
                    applicantVideo: '',
                });
            });
    };

    const handlePaidVoting = async () => {
        setIsLoading(true);
        await props.votedAuditionEntry(talentEntry.auditionEntryId, props.history, {
            count: voteCount,
        }, payBy.value);
        setIsLoading(false);
    };

    const handleExternalVoteVerification = async () => {
        setIsLoading(true);
        let payload = {email};
        let paymentMethod = undefined;
        if (!hasFreeVote) {
            payload.count = voteCount;
            paymentMethod = payBy.value;
        }

        props
            .verifyUnauthenticatedVote(
                talentEntry.auditionEntryId,
                props.history,
                payload,
                false,
                paymentMethod
            )
            .then((res) => {
                if (res.data.url) {
                    window.location.href = res.data.url;
                } else {
                    setVoteValidationModal(true);
                    setVoteVerificationModal(false);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                const errorMessage = error.response
                    ? error.response.data.message
                    : 'Unable to process your vote';
                setError(errorMessage);
                setIsLoading(false);
            });
    };

    const handleVoteValidation = async () => {
        setIsLoading(true);
        let payload = {email, verificationCode};
        let paymentMethod = undefined;
        if (!hasFreeVote) {
            payload.count = voteCount;
            paymentMethod = payBy.value;
        }

        props
            .verifyUnauthenticatedVote(
                talentEntry.auditionEntryId,
                props.history,
                payload,
                true,
                paymentMethod
            )
            .then((res) => {
                if (res.data.url) {
                    window.location.href = res.data.url;
                }
                setIsLoading(false);
            })
            .catch((error) => {
                const errorMessage = error.response
                    ? error.response.data.message
                    : 'Unable to process your vote';
                setError(errorMessage);
                setIsLoading(false);
            });
    };

    useAlert(props.alert);

    return (
        <>
            <Helmet>
                <title>Talent Audition</title>
            </Helmet>
            <main className={`${userData ? 'main feeds' : 'main feeds public'}`}>
                {userData && <LeftSideBar />}
                <section id="wrapper">
                    {userData ? <Header /> : <MainLayoutHeader />}
                    <div className="container content_ plublic">
                        {loading && renderLoader()}
                        <section className="scouts-audition">
                            <AuditionDetailsBanner
                                mediaUrl={audition.auditionUrl}
                                title={audition.auditionTitle}
                                description={audition.auditionDescription}
                                audition={audition}
                            />

                            {talentEntry && (
                                <section className="content audition-entry mt-4">
                                    <div
                                        className="audition-video-container media"
                                        onClick={() => {
                                            displayApplicantVideo(
                                                talentEntry.applicant.fullName,
                                                talentEntry.mediaUrl
                                            );
                                        }}
                                    >
                                        {isVideo(talentEntry.mediaUrl) ? (
                                            <React.Fragment>
                                                <video muted>
                                                    <source
                                                        src={talentEntry.mediaUrl}
                                                        type={`video/${getMediaType(
                                                            talentEntry.mediaUrl
                                                        )}`}
                                                    />
                                                </video>
                                                <div className="player_">
                                                    <img src={PlayIcon} alt="" />
                                                </div>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <img
                                                    src={talentEntry.mediaUrl}
                                                    alt="talent"
                                                    className="img-preview"
                                                />
                                                <div className="player_">
                                                    <img src={PhotoIcon} alt="" />
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                    <div className="media-panel pl-3 d-flex pt-4">
                                        <label
                                            style={{
                                                background: `#000000d9 url(${
                                                    talentEntry.applicant &&
                                                    talentEntry.applicant.imageUrl
                                                }) no-repeat center`,
                                            }}
                                        />
                                        <div className="ml-2">
                                            <Link
                                                to={`/profile/${talentEntry.applicant.id}`}
                                                className="no-style"
                                            >
                                                <h3 className="m-0 p-0 text-capitalize">
                                                    {talentEntry.applicant &&
                                                        talentEntry.applicant.fullName}
                                                </h3>
                                            </Link>

                                            {/*<p>*/}
                                            {/*    {capitalizeFirstLetter(*/}
                                            {/*        talentEntry.applicant*/}
                                            {/*            .talentCategories &&*/}
                                            {/*            talentEntry.applicant*/}
                                            {/*                .talentCategories[0].name*/}
                                            {/*    )}*/}
                                            {/*</p>*/}
                                            <h2 className="votes-counter mt-2 mb-2">
                                                {talentEntry.voteCount === 1
                                                    ? `1 vote`
                                                    : `${talentEntry.voteCount} votes`}
                                            </h2>
                                            {renderButton()}
                                        </div>
                                    </div>
                                    <ApplicantVideoModal
                                        displayVideoModal={displayVideoModal}
                                    />
                                </section>
                            )}
                        </section>
                    </div>

                    <Modal
                        title="Vote Talent"
                        modalDismissedHandler={() => setVotingModalIsVisible(false)}
                        isVisible={votingModalIsVisible}
                        wrapperStyles={{maxWidth: '400px'}}
                        modalIdentifier="voteCount"
                    >
                        <>
                            <h4 className="text-capitalize mb-4">
                                {talentEntry && talentEntry.applicant.fullName}
                            </h4>
                            <div className="input bordered">
                                <input
                                    type="number"
                                    name="voteCount"
                                    min="1"
                                    value={voteCount}
                                    onChange={(e) => setVoteCount(e.target.value)}
                                    onFocus={() => {}}
                                    placeholder="Vote count"
                                />
                                <label>Vote count</label>
                            </div>
                            <div className="input bordered categories-select">
                                <Select
                                    name="payBy"
                                    placeholder={'Pay by'}
                                    className="select"
                                    onChange={(option) => setPayBy(option)}
                                    value={payBy}
                                    options={paymentOptions}
                                />
                            </div>
                            <button
                                type="submit"
                                disabled={voteCount < 1 || isLoading}
                                className="btn btn-block btn-primary"
                                onClick={handlePaidVoting}
                            >
                                Proceed (NGN {totalVoteCost})
                            </button>{' '}
                        </>
                    </Modal>

                    <Modal
                        title="Vote Talent"
                        modalDismissedHandler={() => setVoteVerificationModal(false)}
                        isVisible={voteVerificationModal}
                        wrapperStyles={{maxWidth: '400px'}}
                        modalIdentifier="externalVoter"
                    >
                        <>
                            <h4 className="text-capitalize mb-4">
                                {talentEntry && talentEntry.applicant.fullName}
                            </h4>
                            {error && (
                                <p className="text-center mb-2 text-danger">{error}</p>
                            )}

                            <div className="input bordered">
                                <input
                                    type="text"
                                    required
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    onFocus={() => {}}
                                    placeholder="Email address"
                                />
                                <label>Email Address</label>
                            </div>
                            {hasFreeVote ? (
                                <React.Fragment>
                                    <button
                                        type="submit"
                                        disabled={isLoading}
                                        className="btn btn-block btn-primary"
                                        onClick={handleExternalVoteVerification}
                                    >
                                        Proceed
                                    </button>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className="input bordered">
                                        <input
                                            type="number"
                                            name="voteCount"
                                            min="1"
                                            value={voteCount}
                                            onChange={(e) => setVoteCount(e.target.value)}
                                            onFocus={() => {}}
                                            placeholder="Vote count"
                                        />
                                        <label>Vote count</label>
                                    </div>
                                    <div className="input bordered categories-select">
                                        <Select
                                            name="payBy"
                                            placeholder={'Pay by'}
                                            className="select"
                                            onChange={(option) => setPayBy(option)}
                                            value={payBy}
                                            options={paymentOptions}
                                        />
                                    </div>
                                    <button
                                        type="submit"
                                        disabled={voteCount < 1 || isLoading}
                                        className="btn btn-block btn-primary"
                                        onClick={handleExternalVoteVerification}
                                    >
                                        Proceed (NGN {totalVoteCost})
                                    </button>
                                </React.Fragment>
                            )}
                            <p className="text-center small mt-3">
                                Do you have an account ? Sign in to your{' '}
                                <Link to="/login"> account here </Link>
                            </p>
                            <p className="text-center small">
                                Or <Link to="/signup"> create account </Link> now
                            </p>
                        </>
                    </Modal>

                    <Modal
                        title="Vote Talent"
                        modalDismissedHandler={() => setVoteValidationModal(false)}
                        isVisible={voteValidationModal}
                        wrapperStyles={{maxWidth: '400px'}}
                        modalIdentifier="externalVoteValidation"
                    >
                        <>
                            <h4 className="text-capitalize mb-4">
                                {talentEntry && talentEntry.applicant.fullName}
                            </h4>
                            <p className="text-center mb-2 pl-2 pr-2">
                                Please enter the verification code sent to your email
                            </p>
                            {error && (
                                <p className="text-center text-danger mb -2">{error}</p>
                            )}

                            <div className="input bordered">
                                <input
                                    type="text"
                                    name="verificationCode"
                                    value={verificationCode}
                                    onChange={(e) => setVerificationCode(e.target.value)}
                                    onFocus={() => {}}
                                    placeholder="Enter code here"
                                />
                                <label>Verification code</label>
                            </div>
                            <button
                                type="submit"
                                disabled={isLoading}
                                className="btn btn-block btn-primary"
                                onClick={handleVoteValidation}
                            >
                                Proceed
                            </button>
                        </>
                    </Modal>
                </section>
            </main>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        voteLoading: state.audition.loading,
        auditionEntry: state.audition.auditionEntry,
        alert: state.alert,
    };
};

export default withRouter(connect(mapStateToProps, actions)(AuditionDetails));
