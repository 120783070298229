import React, {useEffect, useState} from 'react';
import FollowsItem from './FollowsItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as actions from '../../../redux/actions';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import useAlert from '../../CustomHooks/useAlert';
import {statCounter} from '../../../utils/statCounter';

const FollowsList = (props) => {
    // following/follower
    const actionType = props.match.params.slug;
    const otherUsersId = props.location.pathname.slice(9, 33);

    const [loading, setLoading] = useState(false);
    const [followingOrFollowersList, setFollowingOrFollowersList] = useState([]);

    // fetch data on mount
    useEffect(() => {
        fetchFollowsBasedOnAction({pageNumber: 1, pageSize: 15});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionType]);

    // fetch data and also check if fetching for other users via userId or current login user
    const fetchFollowsBasedOnAction = async (pagination) => {
        setLoading(true);
        if (actionType === 'following') {
            await props.getfollowings(
                otherUsersId.includes('follow') ? 0 : otherUsersId,
                pagination,
                props.history
            );
        } else {
            await props.getfollowers(
                otherUsersId.includes('follow') ? 0 : otherUsersId,
                pagination,
                props.history
            );
        }
        setLoading(false);
    };

    // componentDidUpdate with follower/following data
    useEffect(() => {
        if (actionType === 'following') {
            setFollowingOrFollowersList(props.followings);
        } else {
            setFollowingOrFollowersList(props.followers);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.followers, props.followings]);

    // fetch more data on scroll
    const fetchMoreData = () => {
        // update pagination list
        const pagination = {
            pageNumber: 1,
            pageSize: followingOrFollowersList && followingOrFollowersList.length + 15,
        };

        // fetch data based on the pagination
        if (actionType === 'following') {
            props.getfollowings(
                otherUsersId.includes('follow') ? 0 : otherUsersId,
                pagination,
                props.history
            );
        } else {
            props.getfollowers(
                otherUsersId.includes('follow') ? 0 : otherUsersId,
                pagination,
                props.history
            );
        }
    };

    // render loader
    const renderLoader = () => {
        return (
            <div className="list-tile">
                <div className="col">
                    <div className="chat-loader">
                        <div className="media-panel d-flex">
                            <figure>
                                <div></div>
                            </figure>
                            <section>
                                <div></div>
                                <div></div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    // Alert message if error occur on fetch
    useAlert(props.alert);

    return (
        <section className="friends">
            <div>
                <h3>
                    {actionType === 'following' ? 'People Following' : 'My Followers'}{' '}
                    <span>
                        {actionType === 'following'
                            ? statCounter(props.followingsCount)
                            : statCounter(props.folowersCount)}
                    </span>
                </h3>
            </div>
            <div className="row">
                <div className="col-lg-8">
                    {loading ? (
                        <>
                            {renderLoader()}
                            {renderLoader()}
                            {renderLoader()}
                        </>
                    ) : followingOrFollowersList &&
                      followingOrFollowersList.length === 0 ? (
                        <div className="col list-tile">
                            <p>
                                {actionType === 'following'
                                    ? 'You are not following anyone yet.'
                                    : "You don't have followers yet."}
                            </p>
                        </div>
                    ) : (
                        <InfiniteScroll
                            dataLength={
                                (followingOrFollowersList &&
                                    followingOrFollowersList.length) ||
                                0
                            }
                            next={fetchMoreData}
                            hasMore={true}
                            loader={
                                <div className="loader">
                                    <p>Loading more ...</p>
                                </div>
                            }
                            endMessage={
                                <div className="loader">
                                    <p>Yay! You have seen it all</p>
                                </div>
                            }
                        >
                            {followingOrFollowersList &&
                                followingOrFollowersList.map(
                                    (followersOrFollowings, index) => (
                                        <React.Fragment
                                            key={followersOrFollowings.userId}
                                        >
                                            <FollowsItem
                                                userId={followersOrFollowings.userId}
                                                profilePicture={
                                                    followersOrFollowings.imageUrl
                                                }
                                                email={followersOrFollowings.email}
                                                fullName={followersOrFollowings.fullName}
                                                areaOfInterest={
                                                    followersOrFollowings.areaOfInterest
                                                }
                                                user={followersOrFollowings}
                                            />
                                        </React.Fragment>
                                    )
                                )}
                        </InfiniteScroll>
                    )}
                </div>
                <div class="col-lg-4">
                    <section class="recents">
                        <div class="notif_tab">
                            <div class="main active">
                                <label>
                                    {actionType === 'following'
                                        ? 'Following'
                                        : 'Followers'}
                                </label>
                            </div>
                        </div>
                        <div class="content_display">
                            <section class="notification animated shake">
                                <div class="title">
                                    <h5>
                                        {actionType === 'following'
                                            ? 'Total Following'
                                            : 'Total Followers'}
                                    </h5>
                                </div>
                                <p className="text-center">
                                    {actionType === 'following'
                                        ? props.followingsCount
                                        : props.folowersCount}
                                </p>
                            </section>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        followers: state.user.followers.followers,
        folowersCount: state.user.followers.totalCount,

        followings: state.user.followings.followings,
        followingsCount: state.user.followings.totalCount,

        alert: state.alert,
    };
};

export default withRouter(connect(mapStateToProps, actions)(FollowsList));
