import {combineReducers} from 'redux';
import AuthReducer from './AuthReducer';
import FeedReducer from './FeedReducer';
import AuditionReducer from './AuditionReducer';
import AlertReducer from './AlertReducer';
import MessageReducer from './MessageReducer';
import NotificationReducer from './NotificationReducer';
import UserReducer from './UserReducer';
import SettingsReducer from './SettingsReducer';
import TalentCategoriesnReducer from './TalentCategoriesReducer';

export default combineReducers({
    alert: AlertReducer,
    auth: AuthReducer,
    feeds: FeedReducer,
    audition: AuditionReducer,
    message: MessageReducer,
    notifications: NotificationReducer,
    user: UserReducer,
    settings: SettingsReducer,
    talentCategories: TalentCategoriesnReducer,
});
