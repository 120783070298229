import React from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import LogoWhite from '../../../assets/img/svg/logo-white.svg';
import MainLogo from '../../../assets/img/svg/logo.svg';
import BackArrow from '../../../assets/img/svg/back-arrow.svg';

const RegistrationDefault = ({
    pageTitle,
    pageNum,
    pageTag,
    linkStyle,
    linkUrl,
    linkName,
}) => {
    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <body className="auth" />
            </Helmet>
            <header>
                <div className="container">
                    <div className="nav">
                        <Link to="/">
                            <img alt="MySpotlyt Logo" className="large" src={LogoWhite} />
                        </Link>
                        <Link to="/">
                            <i>
                                <img alt="back" src={BackArrow} />
                            </i>
                            <label>Back</label>
                        </Link>
                    </div>
                    <div className="action">
                        <Link to="/" className="logo">
                            <img className="large" src={MainLogo} alt="MySpotlyt Logo" />
                        </Link>
                        <label>
                            <small className="applyLevel">{pageNum || '01'}</small>{' '}
                            {pageTag || 'Create Account'}
                        </label>
                        <Link className={`btn btn-secondary ${linkStyle}`} to={linkUrl}>
                            {linkName}
                        </Link>
                    </div>
                </div>
            </header>
        </>
    );
};

export default RegistrationDefault;
