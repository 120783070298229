import axios from 'axios';
import configureStore from '../store';
import {alertActions} from '../redux/actions/alertActions';
import {history} from '../helpers/History';
import store from 'store';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
    (config) => {
        const token = store.get('kmt-user');
        if (token) config.headers['x-access-token'] = `${token && token.accessToken}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const successHandler = (response) => {
    return response;
};

const errorHandler = (error) => {
    const storage = configureStore();
    const status = error.response?.status;
    if (status === 401) {
        store.clearAll();
        history.push('/login');
        storage.dispatch(alertActions.error('Error'));
        return {};
    }
    return Promise.reject(error);
};

instance.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandler(error)
);

export default instance;
