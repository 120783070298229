import axios from '../../axios/axiosInstance';
import Actions from './actionTypes';
import {alertActions} from './alertActions';


export const showAlertSuccess = (message) => (dispatch) => {
    dispatch(alertActions.success(message))
}
export const getAllTalentCategories = (history) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .get(`/talentCategory`)
        .then((response) => {
            dispatch(fetchingCategories(response.data.talentCategories));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

// // Dispatch functions
const fetchingCategories = (value) => ({
    type: Actions.FETCH_TALENT_CATEGORIES,
    payload: value,
});
