import Home from '../pages/Home';
import Talents from '../pages/Talents';
import Signin from '../pages/auth/Signin';
import Signup from '../pages/auth/Signup';
import FacebookGoogleSignup from '../pages/auth/FacebookGoogleSignup';
import ConfirmEmail from '../pages/auth/ConfirmEmail';
import CompleteSignup from '../pages/auth/CompleteSignup';
import RequestForgetPassword from '../pages/auth/RequestForgetPassword';
import CreateNewPassword from '../pages/auth/CreateNewPassword';
import Feeds from '../pages/feeds/Feeds';
import Terms from '../pages/Terms';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Faq from '../pages/Faq';
import Error404 from '../pages/Error404';
import Signout from '../pages/auth/Signout';
import Auditions from '../pages/feeds/audition/Auditions';
import SingleFeedDetails from '../pages/feeds/SingleFeedDetails';
import AppliedAudition from '../pages/feeds/audition/AppliedAudition';
import AuditionDetails from '../pages/feeds/audition/AuditionDetails';
import AuditionEntryDetails from '../pages/feeds/audition/AuditionEntryDetails';
import ApplyAudition from '../pages/feeds/audition/ApplyAudition';
import CreateAudition from '../pages/feeds/audition/CreateAudition';
import EditAudition from '../pages/feeds/audition/EditAudition';
import ViewApplicants from '../pages/feeds/audition/ViewApplicants';
import Messages from '../pages/feeds/Messages';
import Notifications from '../pages/feeds/Notifications';
import Profile from '../pages/feeds/Profile';
import Settings from '../pages/feeds/Settings';
import Follows from '../pages/feeds/Follows';
import UnsignedInAudition from '../pages/unsignedInAudition/UnsignedInAudition';
import UnsignedInAuditionDetails from '../pages/unsignedInAudition/UnsignedInAuditionDetails';
import UnsignedInAuditionApply from '../pages/unsignedInAudition/UnsignedInAuditionApply';
import ComingSoon from '../pages/ComingSoom';
import Scouts from '../pages/Scouts';
import FollowRequests from '../pages/feeds/FollowRequests';
import CheckEmailSuccessMessage from '../pages/auth/CheckEmailSuccessMessage';
import Disclaimer from '../pages/Disclaimer';
import SearchResult from '../pages/feeds/SearchResult';

const Routes = [
    {
        path: '/',
        exact: true,
        auth: false,
        component: Home,
    },
    {
        path: '/talents',
        exact: true,
        auth: false,
        component: Talents,
    },
    {
        path: '/scouts',
        exact: true,
        auth: false,
        component: Scouts,
    },
    {
        path: '/auditions',
        exact: true,
        auth: false,
        component: UnsignedInAudition,
    },

    {
        path: '/auditions/:slug',
        exact: true,
        auth: false,
        component: UnsignedInAuditionDetails,
    },

    {
        path: '/auditions/apply-audition/:slug',
        exact: true,
        auth: false,
        component: UnsignedInAuditionApply,
    },

    {
        path: '/signout',
        exact: true,
        auth: false,
        component: Signout,
    },
    {
        path: '/login',
        exact: true,
        auth: false,
        component: Signin,
    },
    {
        path: '/signup',
        exact: true,
        auth: false,
        component: Signup,
    },
    {
        path: '/check-your-email',
        exact: true,
        auth: false,
        component: CheckEmailSuccessMessage,
    },
    {
        path: '/ga-signup',
        exact: true,
        auth: false,
        component: FacebookGoogleSignup,
    },
    {
        path: '/authentication/activate',
        exact: true,
        auth: false,
        component: ConfirmEmail,
    },
    {
        path: '/complete-signup',
        exact: true,
        auth: false,
        component: CompleteSignup,
    },
    {
        path: '/forgetpassword',
        exact: true,
        auth: false,
        component: RequestForgetPassword,
    },
    {
        path: '/authentication/reset',
        exact: true,
        auth: false,
        component: CreateNewPassword,
    },
    {
        path: '/terms',
        exact: true,
        auth: false,
        component: Terms,
    },
    {
        path: '/privacy-policy',
        exact: true,
        auth: false,
        component: PrivacyPolicy,
    },
    {
        path: '/faq',
        exact: true,
        auth: false,
        component: Faq,
    },

    {
        path: '/download',
        exact: true,
        auth: false,
        component: ComingSoon,
    },
    {
        path: '/disclaimer',
        exact: true,
        auth: false,
        component: Disclaimer,
    },

    // Require Authentication
    {
        path: '/feeds',
        exact: true,
        auth: true,
        component: Feeds,
    },

    {
        path: '/explore-talents',
        exact: true,
        auth: true,
        component: Feeds,
    },

    {
        path: '/talent-auditions',
        exact: true,
        auth: true,
        component: Auditions,
    },

    {
        path: '/posts/:slug',
        exact: true,
        auth: true,
        component: SingleFeedDetails,
    },

    {
        path: '/talent-auditions/applied-auditions',
        exact: true,
        auth: true,
        component: AppliedAudition,
    },
    {
        path: '/talent-auditions/my-auditions',
        exact: true,
        auth: true,
        component: Auditions,
    },
    {
        path: '/talent-auditions/create-audition',
        exact: true,
        auth: true,
        component: CreateAudition,
    },
    {
        path: '/talent-auditions/:slug',
        exact: true,
        auth: false,
        component: AuditionDetails,
    },

    {
        path: '/talent-auditions/apply-audition/:slug',
        exact: true,
        auth: true,
        component: ApplyAudition,
    },
    {
        path: '/talent-auditions/edit-audition/:slug',
        exact: true,
        auth: true,
        component: EditAudition,
    },
    {
        path: '/talent-auditions/applicants/:slug',
        exact: true,
        auth: true,
        component: ViewApplicants,
    },

    {
        path: '/talent-auditions/:auditionId/:slug',
        exact: true,
        auth: false,
        component: AuditionEntryDetails,
    },

    {
        path: '/messages',
        exact: true,
        auth: true,
        component: Messages,
    },

    {
        path: '/messages/:slug',
        exact: true,
        auth: true,
        component: Messages,
    },

    {
        path: '/notifications',
        exact: true,
        auth: true,
        component: Notifications,
    },

    {
        path: '/profile',
        exact: true,
        auth: true,
        component: Profile,
    },

    {
        path: '/profile/:slug',
        exact: true,
        auth: true,
        component: Profile,
    },

    {
        path: '/profile/:slug/:slug',
        exact: true,
        auth: true,
        component: Follows,
    },

    {
        path: '/follow-request',
        exact: true,
        auth: true,
        component: FollowRequests,
    },

    {
        path: '/settings',
        exact: true,
        auth: true,
        component: Settings,
    },

    {
        path: '/settings/:slug',
        exact: true,
        auth: true,
        component: Settings,
    },

    {
        path: '/search/results',
        exact: true,
        auth: true,
        component: SearchResult,
    },

    {
        auth: false,
        component: Error404,
    },
];

export default Routes;
