import React, {useState, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import * as actions from '../../../redux/actions';
import PictureIcon from '../../../assets/img/svg/picture-icon.svg';
import ProfilePicture from '../../../assets/img/png/photo-avatar.png';
import Input from '../../Form/Input';
import useAlert from '../../CustomHooks/useAlert';

import {genderData} from '../../../pages/auth/selectDatas';
import Select from 'react-select';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import $ from 'jquery';
import InputPhoneNumber from '../../Blocks/InputPhoneNumber';
import {countries_states} from '../../../utils/countries_states';
import {capitalizeFirstLetter} from '../../../utils/capitalizeFirstLetter';

const fetchCountries = () => {
    return countries_states.map((country) => ({
        label: country.name,
        value: country.name,
        callingCodes: country.phone_code.replace('+', ''),
    }));
};

// Profile Settings
const ProfileSettings = (props) => {
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [callingCode, setCallingCode] = useState('+000 ');
    const [countriesList] = useState(fetchCountries());
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchUserDetails();
        if (props.talentCategories.length === 0) {
            props.getAllTalentCategories();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchUserDetails = async () => {
        setLoading(true);
        await props.fetchUserProfile(0);
        setLoading(false);
    };

    // update user once user data is fetched
    useEffect(() => {
        setUser({
            ...props.user,
            dob: user.dob ? new Date(props.user.dob) : undefined,
            country: {
                label: props.user.country,
                value: props.user.country,
            },
            tel: props.user.phone,
            gender: {
                label: props.user.gender,
                value: props.user.gender,
            },
            categories: {
                label:
                    props.user.talentCategories &&
                    props.user.talentCategories[0] &&
                    props.user.talentCategories[0].name,
                value:
                    props.user.talentCategories &&
                    props.user.talentCategories[0] &&
                    props.user.talentCategories[0].name,
            },
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user]);

    // update country and tel number
    useEffect(() => {
        let extractedPhone = props.user.phone || '';
        if (props.user.phone) {
            const getCallCode = countriesList.find((c) => c.value === props.user.country);
            // include  + to callingCode e.g +234
            const addPlusToCallCode = getCallCode ? `+${getCallCode.callingCodes}` : '';

            // extract the rest of the number, e.g 9053xxx
            extractedPhone = props.user.phone
                ? props.user.phone.slice(addPlusToCallCode.length)
                : '';

            setCallingCode(addPlusToCallCode);
        } else if (countriesList.length && props.user.country) {
            const country = countriesList.find((c) => c.label === props.user.country);
            if (country) {
                setCallingCode(`+${country.callingCodes}`);
            }
        }

        setUser({
            ...props.user,
            dob: props.user.dob ? new Date(props.user.dob) : undefined,
            country: {
                label: props.user.country,
                value: props.user.country,
            },
            tel: extractedPhone,
            gender: {
                label: props.user.gender,
                value: props.user.gender,
            },
            categories: {
                label:
                    props.user.talentCategories &&
                    props.user.talentCategories[0] &&
                    props.user.talentCategories[0].name,
                value:
                    props.user.talentCategories &&
                    props.user.talentCategories[0] &&
                    props.user.talentCategories[0].name,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user]);

    const handleInputChange = (name, value) => {
        if (name === 'country') {
            setCallingCode(value ? `+${value.callingCodes} ` : '');
        }

        setUser({
            ...user,
            [name]: value,
            phone: name === 'country' ? '' : name === 'phone' ? value : '',
        });
    };

    const handleProfilePictureUpload = (e) => {
        $('#avatar_file').click();
        $('#avatar_file').change(function (e) {
            e.preventDefault();
            profilePhoto(this);
        });
    };

    function profilePhoto(input) {
        if (input.files && input.files[0]) {
            if (input.files[0].size <= 2097152) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    $('.avatar').attr(
                        'style',
                        "background-image: url('" + e.target.result + "')"
                    );
                };
                reader.readAsDataURL(input.files[0]);
                handleInputChange('profilePicture', input.files[0]);
                setError(null);
            } else {
                setError('File too big! (Max of 2MB)');
            }
        }
    }

    const renderLoader = () => {
        return (
            <div className="loading">
                <div className="lds-ripple">
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    };

    const handleUpdateProfile = async (e) => {
        e.preventDefault();
        setFormSubmitting(true);

        const file = new FormData();
        file.set('fullName', user.fullName);
        file.set('dob', user.dob && new Date(user.dob).toISOString());
        file.set('gender', user.gender.value);
        file.set('country', user.country.value);
        // if profile changed, add to payload
        if (user.profilePicture) {
            file.append('file', user.profilePicture);
        }

        // if tel changes
        const fullPhone = `${callingCode}${user.tel}`
        if (props.user.phone !== fullPhone) {
            file.set('phone', fullPhone);
        }

        if (!user.categories.value) {
            file.set('talentCategories', '');
        } else {
            file.set('talentCategories', user.categories.value);
        }

        // send request
        await props.updateUserProfile(file, props.history);
        setFormSubmitting(false);
    };

    useAlert(props.alert);

    const talentCategoriesOptions = props.talentCategories.map((c) => ({
        label: capitalizeFirstLetter(c.name),
        value: c.name,
    }));

    return (
        <>
            {loading ? (
                renderLoader()
            ) : (
                <div className="setting-sect active" id="setting-profile">
                    <h5>
                        Profile Updates{' '}
                        <small>A short description to help understand title...</small>
                    </h5>
                    <form onSubmit={handleUpdateProfile} className="pt-4">
                        <div className="avater" onClick={handleProfilePictureUpload}>
                            <input type="file" hidden id="avatar_file" accept="image/*" />
                            <div
                                className="image avatar"
                                style={{
                                    backgroundImage: `url(${
                                        user.imageUrl || ProfilePicture
                                    })`,
                                }}
                            />
                            <div>
                                <p>Your Avatar</p>
                                <p
                                    className="btn-link change"
                                    style={{textDecoration: 'none'}}
                                >
                                    <img src={PictureIcon} alt="" className="icon" />{' '}
                                    Change Picture
                                </p>
                                {error && <small className="text-danger">{error}</small>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <Input
                                    type="text"
                                    name="fullName"
                                    value={user.fullName}
                                    onChange={(e) =>
                                        handleInputChange('fullName', e.target.value)
                                    }
                                    placeholder="Full Name"
                                    label="Full Name"
                                />
                            </div>
                        </div>
                        <Input
                            type="email"
                            value={user.email}
                            aria-disabled={true}
                            disabled
                            className="disabled"
                        />

                        <div className="input bordered categories-select">
                            <Select
                                isClearable
                                name="country"
                                placeholder="Select Country"
                                className="select"
                                onChange={(e) => handleInputChange('country', e)}
                                options={countriesList}
                                defaultValue={user.country}
                            />
                        </div>

                        <InputPhoneNumber
                            callingCode={callingCode}
                            phone={user.tel}
                            name="tel"
                            handleInputChange={(e) =>
                                handleInputChange('tel', e.target.value.trim())
                            }
                            placeholder="Phone Number"
                            label="Phone Number"
                        />

                        <div className="input bordered  categories-select">
                            <Select
                                // isMulti
                                isClearable
                                name="category"
                                placeholder="Select Categories"
                                // className="select basic-multi-select"
                                className="select"
                                onChange={(e) => handleInputChange('categories', e)}
                                options={talentCategoriesOptions}
                                value={user.categories}
                            />
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="input bordered date custom-date-select">
                                <DatePicker
                                    showYearDropdown
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={50}
                                    showMonthDropdown
                                    selected={user.dob}
                                    onChange={(date) => handleInputChange('dob', date)}
                                    maxDate={new Date()}
                                    placeholderText="Date of Birth"
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                            <div className="input bordered  categories-select">
                                <Select
                                    isClearable
                                    name="gender"
                                    placeholder="Select Gender"
                                    className="select"
                                    onChange={(e) => handleInputChange('gender', e)}
                                    options={genderData}
                                    value={user.gender}
                                />
                            </div>
                        </div>

                        <button
                            type="submit"
                            disabled={formSubmitting}
                            className="btn btn-primary dark"
                        >
                            {formSubmitting ? 'Updating changes...' : 'Update changes'}
                        </button>
                    </form>
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        alert: state.alert,
        talentCategories: state.talentCategories.talentCategories,
    };
};

export default withRouter(connect(mapStateToProps, actions)(ProfileSettings));
