export const DateSuperscript = (date) => {
    switch (date) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        case 4:
            return 'th';
        case 5:
            return 'th';
        case 6:
            return 'th';
        case 7:
            return 'th';
        case 8:
            return 'th';
        case 9:
            return 'th';
        case 10:
            return 'th';
        case 11:
            return 'th';
        case 12:
            return 'th';
        case 13:
            return 'th';
        case 14:
            return 'th';
        case 15:
            return 'th';
        case 16:
            return 'th';
        case 17:
            return 'th';
        case 18:
            return 'th';
        case 19:
            return 'th';
        case 20:
            return 'th';
        case 21:
            return 'st';
        case 22:
            return 'nd';
        case 23:
            return 'rd';
        case 24:
            return 'th';
        case 25:
            return 'th';
        case 26:
            return 'th';
        case 27:
            return 'th';
        case 28:
            return 'th';
        case 29:
            return 'th';
        case 30:
            return 'th';
        case 31:
            return 'st';
        default:
            return;
    }
};
