import React from 'react';

import InstagramIcon from '../../../assets/img/svg/instagram.svg';
import FacebookIcon from '../../../assets/img/svg/facebook.svg';
import LinkedInIcon from '../../../assets/img/svg/linkedin.svg';
import TwitterIcon from '../../../assets/img/svg/twitter.svg';
import EmailIcon from '../../../assets/img/svg/email.svg';
import ChatIcon from '../../../assets/img/svg/chat.svg';

const SupportSettings = () => {
    return (
        <div className="setting-sect active" id="setting-support">
            <h5>
                Support <small>A short description to help understand title...</small>
            </h5>
            <section className="social-handle">
                <div className="d-card support-card ">
                    <a
                        href="https://www.instagram.com/myspotlyt/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className="icon">
                            <img src={InstagramIcon} alt="" />
                        </div>
                        <p>
                            Follow us on <strong>Instagram</strong>
                        </p>
                    </a>
                </div>
                <div className="d-card support-card ">
                    <a
                        href="https://www.facebook.com/MySpotlyt"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className="icon">
                            <img src={FacebookIcon} alt="" />
                        </div>
                        <p>
                            Follow us on <strong>Facebook</strong>
                        </p>
                    </a>
                </div>
                <div className="d-card support-card ">
                    <a
                        href="https://www.linkedin.com/company/11414712/admin/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className="icon">
                            <img src={LinkedInIcon} alt="" />
                        </div>
                        <p>
                            Follow us on <strong>Linkedin</strong>
                        </p>
                    </a>
                </div>
            </section>
            <section className="social-handle">
                <div className="d-card support-card alt">
                    <a
                        href="https://twitter.com/Myspotlyt1"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className="icon">
                            <img src={TwitterIcon} alt="" />
                        </div>
                        <p>
                            Follow us on <strong>Twitter</strong>
                        </p>
                    </a>
                </div>
                <div className="d-card support-card alt ">
                    <a
                        href="mailto:info@myspotlyt.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className="icon">
                            <img src={EmailIcon} alt="" />
                        </div>
                        <p>
                            Email us<strong>info@myspotlyt.com</strong>
                        </p>
                    </a>
                </div>
                <div className="d-card support-card alt ">
                    <a
                        href="https://wa.me/+393510922531"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className="icon">
                            <img src={ChatIcon} alt="" />
                        </div>
                        <p>
                            Chat with us <strong>+39 351 092 2531</strong>
                        </p>
                    </a>
                </div>
            </section>
        </div>
    );
};

export default SupportSettings;
