import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from '../../redux/actions';
import UnsignedInAuditionItem from './UnsignedInAuditionItem';

const FetchAuditionLoader = () => {
    return (
        <div className="col-lg-4">
            <div className="panel">
                <section className="content">
                    <div
                        className="media audition custom-fetching"
                        style={{
                            background: `#f2f2f2`,
                        }}
                    ></div>
                    <div className="media-panel">
                        <div className="audition-short"></div>
                    </div>
                </section>
            </div>
        </div>
    );
};

const UnsignedAuditionList = (props) => {
    const renderLoader = () => {
        return (
            <div class="row">
                <FetchAuditionLoader />
                <FetchAuditionLoader />
                <FetchAuditionLoader />
                <FetchAuditionLoader />
                <FetchAuditionLoader />
                <FetchAuditionLoader />
            </div>
        );
    };

    return (
        <>
            {props.loading ? (
                renderLoader()
            ) : (
                <InfiniteScroll
                    dataLength={props.auditions.length} //This is important field to render the next data
                    next={props.fetchData}
                    hasMore={true}
                    style={{
                        overflow: 'visible',
                    }}
                    loader={
                        props.loading ? (
                            ''
                        ) : (
                            <div className="row">
                                <div className="loader">
                                    <p>Loading more ...</p>
                                </div>
                            </div>
                        )
                    }
                    endMessage={
                        <div className="row">
                            <div className="loader">
                                <p>Yay! You have seen it all</p>
                            </div>
                        </div>
                    }
                >
                    <div className="row">
                        {props.auditions.map((audition) => (
                            <React.Fragment key={audition.auditionId}>
                                <UnsignedInAuditionItem audition={audition} />
                            </React.Fragment>
                        ))}
                    </div>
                </InfiniteScroll>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {};
};

export default withRouter(connect(mapStateToProps, actions)(UnsignedAuditionList));
