import React from 'react';
export const AuthProgressIndicatorStep1 = () => {
    return (
        <ul className="path">
            <li>
                <span className="active"></span>
            </li>
            <li>
                <span></span>
            </li>
        </ul>
    );
};

export const AuthProgressIndicatorStep2 = () => {
    return (
        <ul className="path">
            <li>
                <span className="done"></span>
            </li>
            <li>
                <span className="active"></span>
            </li>
        </ul>
    );
};
