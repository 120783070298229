import React from 'react';

const InputPhoneNumber = ({callingCode, phone, handleInputChange}) => {
    return (
        <div className="input bordered">
            <div className="input-group">
                <div
                    className="input-group-prepend"
                    style={{
                        position: 'absolute',
                        height: '65px',
                    }}
                >
                    <span
                        className="input-group-text"
                        style={{
                            borderTopLeftRadius: '10px',
                            borderBottomLeftRadius: '10px',
                        }}
                    >
                        {callingCode}
                    </span>
                </div>

                <input
                    type="tel"
                    name="phone"
                    value={phone}
                    onChange={handleInputChange}
                    placeholder="Phone Number"
                    label="Phone Number"
                    pattern="^[0-9-+\s()]*$"
                    style={{
                        paddingLeft: '4.2rem',
                    }}
                />
                <label style={{left: '70px'}}>Phone Number</label>
            </div>
        </div>
    );
};

export default InputPhoneNumber;
