import React, {useState} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../redux/actions';
import RegistrationDefault from '../../components/layout/RegistrationDefault';
import {useLocation} from 'react-router-dom';
import Input from '../../components/Form/Input';
import LeftViewOnAuthPages from '../../components/Blocks/LeftViewOnAuthPages';
import {displayLoader} from '../../utils/displayLoader';
import {validateAll} from 'indicative/validator';
import {scrollTop} from '../../utils/scrollTop';
import useAlert from '../../components/CustomHooks/useAlert';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const CreateNewPassword = (props) => {
    let query = useQuery();

    const [newPassword, setNewPassword] = useState('');
    const [retypePassword, setRetypePassword] = useState('');
    // Get errors
    const [errors, setErrors] = useState('');

    const handleErrorRemoveOnFocus = (e) => {
        setErrors({
            ...errors,
            [e.target.name]: '',
        });
    };

    // Handle reset password
    const handleResetPassword = (e) => {
        e.preventDefault();

        const data = {
            newPassword,
            retypePassword,
        };

        const rules = {
            newPassword: 'required|min:8',
            retypePassword: 'required|min:8',
        };

        const messages = {
            'newPassword.required': 'Enter a new password',
            'newPassword.min': 'Password too short (minimum of 8 characters)',
            'retypePassword.required': 'Make sure the password matches',
            'retypePassword.min': 'Make sure the password matches',
        };

        validateAll(data, rules, messages)
            .then(() => {
                scrollTop();
                completeReset();
            })
            .catch((errors) => {
                const formattedErrors = {};
                errors.forEach((error) => (formattedErrors[error.field] = error.message));
                setErrors(formattedErrors);
            });

        const completeReset = async () => {
            // process data to Backend
            if (newPassword !== retypePassword) {
                setErrors({...errors, retypePassword: 'Password does not match'});
            } else {
                const data = {
                    resetLink: query.get('key'),
                    newPassword,
                };
                await props.authenticateForgotPassword(data, props.history);

                setNewPassword('');
                setRetypePassword('');
            }
        };
    };

    const renderOnResetCreation = () => {
        return displayLoader('Configuring New Password');
    };

    useAlert(props.alert);

    return (
        <>
            <RegistrationDefault
                pageTitle={'Reset Password!'}
                linkName={'Login'}
                linkUrl={'/login'}
                linkStyle={'adj'}
                pageNum="02"
                pageTag="Reset Password"
            />
            <main className="account">
                <LeftViewOnAuthPages />
                <section className="right-pad">
                    {props.authenticateForgotPasswordStart && renderOnResetCreation()}
                    <div className="container">
                        <form
                            onSubmit={handleResetPassword}
                            className="animated bounceIn"
                        >
                            <section className="step-1">
                                <div className="title">
                                    <h3>Complete Password Reset</h3>
                                    <p>Fill the form below to set a new password.</p>
                                </div>

                                <Input
                                    type="password"
                                    name="newPassword"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    placeholder="New Password"
                                    label="New Password"
                                    errorMessage={errors.newPassword}
                                    onFocus={handleErrorRemoveOnFocus}
                                />
                                <Input
                                    type="password"
                                    name="retypePassword"
                                    value={retypePassword}
                                    onChange={(e) => setRetypePassword(e.target.value)}
                                    placeholder="Re-Type New Password"
                                    label="Re-Type New Password"
                                    errorMessage={errors.retypePassword}
                                    onFocus={handleErrorRemoveOnFocus}
                                />

                                <div className="action">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-lg"
                                        disabled={
                                            props.authenticateForgotPasswordStart
                                                ? true
                                                : false
                                        }
                                    >
                                        Complete
                                    </button>
                                </div>
                            </section>
                        </form>
                    </div>
                </section>
            </main>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        authenticateForgotPasswordStart: state.auth.authenticateForgotPasswordStart,
        alert: state.alert,
    };
};

export default connect(mapStateToProps, actions)(CreateNewPassword);
