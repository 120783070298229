import React, {useEffect, useState} from 'react';
import * as actions from '../../../redux/actions';
import {withRouter} from 'react-router-dom';
import XIcon from '../../../assets/img/svg/x.svg';
import PostDetailsCommentLists from '../PostDetailsBits/PostDetailsCommentLists';
import {connect} from 'react-redux';
import $ from 'jquery';
import {getMediaType, isVideo} from '../../../utils/global';

const ProfilePostModal = (props) => {
    const {singlePost} = props;
    const mediaUrl = singlePost.mediaUrl || singlePost.sharedPost?.mediaUrl || null;
    const mediaType = getMediaType(mediaUrl);
    const isVideoMediaType = isVideo(mediaUrl);

    const modalStyle =
        mediaUrl && isVideoMediaType ? {} : {background: `url(${mediaUrl})`};

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.postOnModal) {
            getSinglePost();
            $('#viewPost').modal('show');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.postOnModal]);

    const getSinglePost = async () => {
        setLoading(true);
        await props.fetchSinglePost(props.postOnModal.postId, props.history);
        setLoading(false);
    };

    const refactorTalentCategories = (talentCategories = []) => {
        let talent = [];
        for (var cat of talentCategories) {
            talent.push(cat.name);
        }
        return talent;
    };

    const renderLoader = () => {
        return (
            <div
                className="loading py-5"
                style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    margin: 'auto',
                    textAlign: 'center',
                }}
            >
                <div className="lds-ripple">
                    <div/>
                    <div/>
                </div>
            </div>
        );
    };

    return (
        <div className="modal fade" id="viewPost" role="dialog">
            <div
                className="modal-dialog modal-wrapper"
                style={{
                    maxWidth: `${mediaUrl ? '973px' : '530px'}`,
                    marginTop: '4%',
                }}
            >
                <div className=" modal-content-wrap">
                    <div className="header-wrap">
                        <div className="close_">
                            <label data-dismiss="modal">
                                <img src={XIcon} alt="" />
                                <span>Close</span>
                            </label>
                        </div>
                        <div>
                            <p>{props.userName}'s Post</p>
                        </div>
                    </div>
                    <div className="modal-body" style={{padding: '0em 0em'}}>
                        <section className="post-view userPostView">
                            {loading ? (
                                renderLoader()
                            ) : (
                                <>
                                    {mediaUrl ? (
                                        <div className="media" style={modalStyle}>
                                            {/* Display the video player if the post is a video */}
                                            {isVideo(mediaUrl) && (
                                                <video
                                                    className="post-video-player"
                                                    controls
                                                >
                                                    <source
                                                        src={mediaUrl}
                                                        type={`video/${mediaType}`}
                                                    />
                                                    Your browser does not support the
                                                    video tag.
                                                </video>
                                            )}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    <div className="post-comment-aside">
                                        <PostDetailsCommentLists
                                            fullName={singlePost.creatorName}
                                            body={singlePost.body}
                                            sharedPost={singlePost.sharedPost || null}
                                            isCommentStart={
                                                singlePost.isCommentStart || null
                                            }
                                            commentLists={singlePost.comments || []}
                                            postId={singlePost.postId}
                                            isLiked={singlePost.isLiked}
                                            totalLikes={singlePost.likersCount || 0}
                                            totalComments={singlePost.commentorsCount}
                                            mediaUrl={singlePost.creatorImage || null}
                                            sharePostId={singlePost.postId}
                                            talentCategories={refactorTalentCategories(
                                                singlePost.talentCategories
                                            )}
                                            shareStatus={singlePost.shareStatus || null}
                                            isPostOwner={singlePost.isPostOwner}
                                        />
                                    </div>
                                </>
                            )}
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        totalCount: state.user.userPosts.totalCount,
        alert: state.alert,

        singlePost: state.feeds.singlePost,
        keptShareData: state.feeds.keptShareData,
    };
};
export default withRouter(connect(mapStateToProps, actions)(ProfilePostModal));
