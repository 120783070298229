import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import SearchIcon from '../../assets/img/svg/search-icon.svg';
import PlayIcon from '../../assets/img/svg/play-icon.svg';
import PhotoIcon from '../../assets/img/svg/photo-file-icon.svg';
import * as actions from '../../redux/actions';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import $ from 'jquery';
import store from 'store';
import XIcon from '../../assets/img/svg/x.svg';
import Pagination from '../../components/Pagination/Pagination';
import {getMediaType, isVideo, paymentOptions} from '../../utils/global';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import debounce from 'lodash/debounce';
import Modal from '../../components/Modal';
import Select from 'react-select';

const AuditionHighlightsSearch = ({handleEntriesSearch}) => {
    const [searchText, setSearchText] = useState('');
    const handleChange = (e) => {
        setSearchText(e.target.value);
        handleEntriesSearch(e.target.value);
    };
    return (
        <form>
            <div className="input search">
                <i className="tag">
                    <img src={SearchIcon} alt="search" />
                </i>
                <input
                    type="text"
                    value={searchText}
                    placeholder="Search for videos"
                    onChange={handleChange}
                />
            </div>
            <small>e.g Dance, Pop, Culture, and more...</small>
        </form>
    );
};

const AuditionHighlightsVideos = ({
    loading,
    auditionUniqueUrl,
    voteLoading,
    applicants,
    history,
    votedAuditionEntry,
    unvotedAuditionEntry,
    displayApplicantVideo,
    handleCopySuccess,
    audition,
    verifyVote,
}) => {
    const [voteVerificationModal, setVoteVerificationModal] = useState(false);
    const [voteValidationModal, setVoteValidationModal] = useState(false);
    const [votingModalIsVisible, setVotingModalIsVisible] = useState(false);
    const [applicant, setApplicant] = useState({});
    const [voteCount, setVoteCount] = useState(null);
    const [verificationCode, setVerificationCode] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [totalVoteCost, setTotalVoteCost] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [payBy, setPayBy] = useState(paymentOptions[0]);

    const hasFreeVote = audition.voteCategory === 'free';

    useEffect(() => {
        const voteAmount = audition.voteAmount || 0;
        setTotalVoteCost(voteAmount * voteCount);
    }, [voteCount, audition.voteAmount]);

    const renderLoader = () => {
        return (
            <div className="col-lg-3 col-md-6">
                <div className="panel">
                    <section className="content">
                        <div
                            className="media"
                            style={{background: 'rgba(229, 229, 229, 0.3)'}}
                        />
                        <div className="media-panel">
                            <figure>
                                <div />
                            </figure>
                            <section>
                                <div />
                                <div />
                                <div />
                            </section>
                        </div>
                    </section>
                </div>
            </div>
        );
    };

    const {userInfo} = applicants;
    const {hasVoted, votedAuditionEntryId} = userInfo || {};
    const [voted, setVoted] = useState(hasVoted);
    const [votedAuditionId, setVotedAuditionId] = useState(votedAuditionEntryId);

    useEffect(() => {
        setVoted(hasVoted);
        setVotedAuditionId(votedAuditionEntryId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo]);

    const handleVoting = (applicant) => {
        setApplicant(applicant);
        const {auditionEntryId, hasVoted} = applicant;
        // handle voting if user is accessToken is available i.e user is logged in
        if (store.get('kmt-user') && store.get('kmt-user').accessToken) {
            // handle voting or unvoting of audition entry
            if(hasFreeVote) {
                if (
                    (applicants.userInfo && applicants.userInfo.hasVoted === true) ||
                    hasVoted === true
                ) {
                    unvotedAuditionEntry(auditionEntryId, history);
                } else {
                    votedAuditionEntry(auditionEntryId, history, {});
                }
            } else {
                // Open dialog for number of votes
                setVotingModalIsVisible(true);
            }
        } else {
            if (hasFreeVote) {
                store.set('performOperationFrom', history.location.pathname);
                history.push('/login');
            } else {
                setVoteVerificationModal(true);
            }
        }
    };

    const showCopyAlert = () => {
        handleCopySuccess('Link copied to clipboard');
    };

    const renderButton = (applicant) => {
        if (hasFreeVote && voted && votedAuditionId !== applicant.auditionEntryId) {
            return (
                <CopyToClipboard
                    text={`${window.location.origin}/talent-auditions/${auditionUniqueUrl}/${applicant.auditionSerialNumber}`}
                    onCopy={showCopyAlert}
                >
                    <button className="btn btn-outline-secondary">Copy link</button>
                </CopyToClipboard>
            );
        }

        return (
            <React.Fragment>
                {audition.auditionType === 'voting' && (
                    <button
                        className={`btn ${
                            (applicants.userInfo && applicants.userInfo.hasVoted) ||
                            applicant.hasVoted
                                ? 'btn-primary'
                                : 'btn-secondary'
                        } `}
                        type="button"
                        disabled={voteLoading}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleVoting(applicant);
                        }}
                    >
                        {hasFreeVote
                            ? (applicants.userInfo && applicants.userInfo.hasVoted) ||
                              applicant.hasVoted
                                ? 'Unvote'
                                : 'Vote'
                            : 'Vote'}
                    </button>
                )}

                <CopyToClipboard
                    text={`${window.location.origin}/talent-auditions/${auditionUniqueUrl}/${applicant.auditionSerialNumber}`}
                    onCopy={showCopyAlert}
                >
                    <button className="btn btn-outline-secondary">Copy link</button>
                </CopyToClipboard>
            </React.Fragment>
        );
    };

    const handlePaidVoting = async () => {
        setIsLoading(true);
        await votedAuditionEntry(
            applicant.auditionEntryId,
            history,
            {count: voteCount},
            payBy.value
        );
        setIsLoading(false);
    };

    const handleExternalVoteVerification = async () => {
        setIsLoading(true);
        let payload = {email};
        let paymentMethod = undefined;
        if (!hasFreeVote) {
            payload.count = voteCount;
            paymentMethod = payBy.value;
        }

        verifyVote(applicant.auditionEntryId, history, payload, false, paymentMethod)
            .then((res) => {
                if (res.data.url) {
                    window.location.href = res.data.url;
                } else {
                    setVoteValidationModal(true);
                    setVoteVerificationModal(false);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                const errorMessage = error.response
                    ? error.response.data.message
                    : 'Unable to process your vote';
                setError(errorMessage);
                setIsLoading(false);
            });
    };

    const handleVoteValidation = async () => {
        setIsLoading(true);
        let payload = {email, verificationCode};
        let paymentMethod = undefined;
        if (!hasFreeVote) {
            payload.count = voteCount;
            paymentMethod = payBy.value;
        }

        verifyVote(applicant.auditionEntryId, history, payload, true, paymentMethod)
            .then((res) => {
                if (res.data.url) {
                    window.location.href = res.data.url;
                }
                setIsLoading(false);
            })
            .catch((error) => {
                const errorMessage = error.response
                    ? error.response.data.message
                    : 'Unable to process your vote';
                setError(errorMessage);
                setIsLoading(false);
            });
    };

    // check if phone verification is needed
    const checkPhoneVerified = true; //user.phoneVerified

    return (
        <div className="row">
            <Modal
                title="Vote Talent"
                modalDismissedHandler={() => setVotingModalIsVisible(false)}
                isVisible={votingModalIsVisible}
                wrapperStyles={{maxWidth: '400px'}}
                modalIdentifier="voteCount"
            >
                {/* */}
                {checkPhoneVerified ? (
                    <>
                        <h4 className="text-capitalize mb-4">
                            {applicant.applicant && applicant.applicant.fullName}
                        </h4>
                        <div className="input bordered">
                            <input
                                type="number"
                                name="voteCount"
                                min="1"
                                value={voteCount}
                                onChange={(e) => setVoteCount(e.target.value)}
                                onFocus={() => {}}
                                placeholder="Vote count"
                            />
                            <label>Vote count</label>
                        </div>
                        <div className="input bordered categories-select">
                            <Select
                                name="payBy"
                                placeholder={'Pay by'}
                                className="select"
                                onChange={(option) => setPayBy(option)}
                                value={payBy}
                                options={paymentOptions}
                            />
                        </div>
                        <button
                            type="submit"
                            disabled={voteCount < 1 || isLoading}
                            className="btn btn-block btn-primary"
                            onClick={handlePaidVoting}
                        >
                            Proceed (NGN {totalVoteCost})
                        </button>{' '}
                    </>
                ) : (
                    <p className="text-center text-danger">
                        Oops! You need to verify your phone before you can vote.
                    </p>
                )}
            </Modal>

            <Modal
                title="Vote Talent"
                modalDismissedHandler={() => setVoteVerificationModal(false)}
                isVisible={voteVerificationModal}
                wrapperStyles={{maxWidth: '400px'}}
                modalIdentifier="externalVoter"
            >
                <>
                    <h4 className="text-capitalize mb-4">
                        {applicant.applicant && applicant.applicant.fullName}
                    </h4>
                    {error && <p className="text-center mb-2 text-danger">{error}</p>}

                    <div className="input bordered">
                        <input
                            type="text"
                            required
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onFocus={() => {}}
                            placeholder="Email address"
                        />
                        <label>Email Address</label>
                    </div>
                    {hasFreeVote ? (
                        <React.Fragment>
                            <button
                                type="submit"
                                disabled={isLoading}
                                className="btn btn-block btn-primary"
                                onClick={handleExternalVoteVerification}
                            >
                                Proceed
                            </button>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="input bordered">
                                <input
                                    type="number"
                                    name="voteCount"
                                    min="1"
                                    value={voteCount}
                                    onChange={(e) => setVoteCount(e.target.value)}
                                    onFocus={() => {}}
                                    placeholder="Vote count"
                                />
                                <label>Vote count</label>
                            </div>
                            <div className="input bordered categories-select">
                                <Select
                                    name="payBy"
                                    placeholder={'Pay by'}
                                    className="select"
                                    onChange={(option) => setPayBy(option)}
                                    value={payBy}
                                    options={paymentOptions}
                                />
                            </div>
                            <button
                                type="submit"
                                disabled={voteCount < 1 || isLoading}
                                className="btn btn-block btn-primary"
                                onClick={handleExternalVoteVerification}
                            >
                                Proceed (NGN {totalVoteCost})
                            </button>
                        </React.Fragment>
                    )}
                    <p className="text-center small mt-3">
                        Do you have an account ? Sign in to your{' '}
                        <Link to="/login"> account here </Link>
                    </p>
                    <p className="text-center small">
                        Or <Link to="/signup"> create account </Link> now
                    </p>
                </>
            </Modal>

            <Modal
                title="Vote Talent"
                modalDismissedHandler={() => setVoteValidationModal(false)}
                isVisible={voteValidationModal}
                wrapperStyles={{maxWidth: '400px'}}
                modalIdentifier="externalVoteValidation"
            >
                <>
                    <h4 className="text-capitalize mb-4">
                        {applicant.applicant && applicant.applicant.fullName}
                    </h4>
                    <p className="text-center mb-2 pl-2 pr-2">
                        Please enter the verification code sent to your email
                    </p>
                    {error && <p className="text-center text-danger mb -2">{error}</p>}

                    <div className="input bordered">
                        <input
                            type="text"
                            name="verificationCode"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                            onFocus={() => {}}
                            placeholder="Enter code here"
                        />
                        <label>Verification code</label>
                    </div>
                    <button
                        type="submit"
                        disabled={isLoading}
                        className="btn btn-block btn-primary"
                        onClick={handleVoteValidation}
                    >
                        Proceed
                    </button>
                </>
            </Modal>
            {loading ? (
                <>
                    {renderLoader()}
                    {renderLoader()}
                    {renderLoader()}
                    {renderLoader()}
                    {renderLoader()}
                    {renderLoader()}
                    {renderLoader()}
                    {renderLoader()}
                </>
            ) : (
                applicants &&
                applicants.auditionEntries &&
                applicants.auditionEntries.map((applicant) => (
                    <div className="col-lg-3 col-md-6" key={applicant.auditionEntryId}>
                        <div className="panel">
                            <section className="content">
                                <div
                                    className="audition-video-container media"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        displayApplicantVideo(
                                            applicant.applicant.fullName,
                                            applicant.mediaUrl
                                        );
                                    }}
                                >
                                    {isVideo(applicant.mediaUrl) ? (
                                        <React.Fragment>
                                            <video muted>
                                                <source
                                                    src={applicant.mediaUrl}
                                                    type={`video/${getMediaType(
                                                        applicant.mediaUrl
                                                    )}`}
                                                />
                                            </video>
                                            <div className="player_">
                                                <img src={PlayIcon} alt="" />
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <img
                                                src={applicant.mediaUrl}
                                                alt="talent"
                                                className="img-preview"
                                            />
                                            <div className="player_">
                                                <img src={PhotoIcon} alt="" />
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                                <div className="media-panel">
                                    <label
                                        style={{
                                            background: `#000000d9 url(${
                                                applicant.applicant &&
                                                applicant.applicant.imageUrl
                                            }) no-repeat center`,
                                        }}
                                    />
                                    <div>
                                        <Link
                                            to={`/profile/${applicant.applicant.id}`}
                                            className="no-style"
                                        >
                                            <h3 className="text-capitalize">
                                                {applicant.applicant &&
                                                    applicant.applicant.fullName}
                                            </h3>
                                        </Link>

                                        {/*<p>*/}
                                        {/*    {capitalizeFirstLetter(*/}
                                        {/*        applicant.applicant.talentCategories &&*/}
                                        {/*            applicant.applicant.talentCategories[0].name*/}
                                        {/*    )}*/}
                                        {/*</p>*/}
                                        <h2 className="votes-counter">
                                            {applicant.voteCount === 1
                                                ? `1 vote`
                                                : `${applicant.voteCount} votes`}
                                        </h2>
                                        {renderButton(applicant)}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};

export const ApplicantVideoModal = ({displayVideoModal}) => {
    const isVideoType = isVideo(displayVideoModal.applicantVideo);
    return (
        <div className="modal fade" id="applicantVideo" role="dialog">
            <div className="modal-dialog modal-wrapper">
                <div className=" modal-content-wrap">
                    <div className="header-wrap">
                        <div className="close_">
                            <label data-dismiss="modal">
                                <img src={XIcon} alt="" />
                                <span>Close</span>
                            </label>
                        </div>
                        <div>
                            <p className="text-capitalize">
                                {displayVideoModal.applicantName}'s Video
                            </p>
                        </div>
                    </div>
                    <div className="modal-body media-player">
                        {displayVideoModal.applicantVideo && isVideoType ? (
                            <video width="470" height="260" controls>
                                <source
                                    src={displayVideoModal.applicantVideo}
                                    type="video/mp4"
                                />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <img
                                src={displayVideoModal.applicantVideo}
                                alt=""
                                className="img-fluid"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const UnsignedInAuditionHiglights = (props) => {
    // const auditionId = props.match.params.slug;
    const auditionId = props.audition ? props.audition.auditionId : null;
    const auditionUniqueUrl = props.audition ? props.audition.uniqueUrlId : null;

    const [applicants, setApplicants] = useState([]);
    const [loading, setLoading] = useState(false);

    //modal window with video & applicant name
    const [displayVideoModal, setDisplayVideoModal] = useState({
        applicantName: '',
        applicantVideo: '',
    });

    const [pagination, setPagination] = useState({
        pageNumber: 1,
        pageSize: 12,
        searchText: '',
    });

    // Page count
    const pageCount =
        props.totalCount && Math.ceil(props.totalCount / pagination.pageSize);

    useEffect(() => {
        setApplicants(props.applicants);
    }, [props.applicants]);

    const fetchAuditionEntries = async () => {
        setLoading(true);
        await props.fetchAuditionApplicants(auditionId, pagination, props.history);
        setLoading(false);
    };

    useEffect(() => {
        if (auditionId) {
            fetchAuditionEntries();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination, auditionId]);

    const handlePaginate = async (data) => {
        let selected = parseInt(data.selected) + 1;
        let pageNumber = Math.ceil(selected);
        // Update pagination state
        setPagination({...pagination, pageNumber});
    };

    const displayApplicantVideo = (applicantName, applicantVideo) => {
        setDisplayVideoModal({
            applicantName,
            applicantVideo,
        });
        // Display the modal
        $('#applicantVideo')
            .modal('show')
            .on('hidden.bs.modal', function () {
                setDisplayVideoModal({
                    applicantName: '',
                    applicantVideo: '',
                });
            });
    };

    const debounceSearch = debounce((searchText) => {
        setPagination({
            ...pagination,
            search: searchText,
        });
    }, 500);

    const handleEntriesSearch = (text) => {
        debounceSearch(text);
    };

    return (
        <section className="highlights">
            <div className="container alternate">
                <h3>Audition Videos</h3>
                <AuditionHighlightsSearch handleEntriesSearch={handleEntriesSearch} />
                <AuditionHighlightsVideos
                    applicants={applicants}
                    auditionUniqueUrl={auditionUniqueUrl}
                    audition={props.audition}
                    loading={loading}
                    voteLoading={props.voteLoading}
                    history={props.history}
                    votedAuditionEntry={props.votedAuditionEntry}
                    unvotedAuditionEntry={props.unvotedAuditionEntry}
                    verifyVote={props.verifyUnauthenticatedVote}
                    displayApplicantVideo={displayApplicantVideo}
                    handleFetchAuditions={fetchAuditionEntries}
                    handleCopySuccess={props.showAlertSuccess}
                />
                {/* Pagination */}
                <div className="d-flex flex-row justify-content-center">
                    <Pagination handlePaginate={handlePaginate} pageCount={pageCount} />
                </div>
            </div>
            <ApplicantVideoModal displayVideoModal={displayVideoModal} />
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        applicants: state.audition.applicants,
        alert: state.alert,
        voteLoading: state.audition.loading,
        totalCount: state.audition.totalCount,
        user: state.user,
    };
};

export default withRouter(connect(mapStateToProps, actions)(UnsignedInAuditionHiglights));
