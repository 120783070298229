import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';
import NotificationList from './NotificationList';
import NotifcationStat from './NotifcationStat';
import useAlert from '../../CustomHooks/useAlert';
import {scrollTop} from '../../../utils/scrollTop';

const NotificationWrapper = (props) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        scrollTop();

        handleFetchingNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFetchingNotifications = async () => {
        setLoading(true);
        await props.fetchAllNotifications(1, 15);
        setLoading(false);
    };

    // Alert message if error occur on fetch
    useAlert(props.alert);

    return (
        <section className="notifications">
            <div>
                <h3>Notifications</h3>
            </div>
            <div className="row">
                <div className="col-lg-8">
                    <NotificationList loading={loading} />
                </div>
                <div className="col-lg-4">
                    <NotifcationStat loading={loading} />
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        notifications: state.notifications.notifications,
        alert: state.alert,
    };
};
export default withRouter(connect(mapStateToProps, actions)(NotificationWrapper));
