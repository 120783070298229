import axios from '../../axios/axiosInstance';
import Actions from './actionTypes';
import {alertActions} from './alertActions';

export const fetchAllNotifications = (pageNumber, pageSize, history) => async (
    dispatch
) => {
    dispatch(alertActions.clear());
    await axios
        .get('/notification', {
            params: {
                pageNumber: pageNumber,
                pageSize: pageSize,
            },
        })
        .then((response) => {
            dispatch(fecthNotifications(response.data));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

export const deleteNotifications = (data, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .delete('/notification', {
            data: {
                notificationIds: data,
            },
        })
        .then((response) => {
            dispatch(delNotification(data));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

// Dispatch functions
const fecthNotifications = (data) => {
    return {
        type: Actions.FETCH_NOTIFICATIONS,
        payload: data,
    };
};

const delNotification = (data) => {
    return {
        type: Actions.DELETE_NOTIFICATIONS,
        payload: data,
    };
};
