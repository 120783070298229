import React, {useState, useEffect, useRef} from 'react';
import UnsignedInOptionsDropdown from './UnsignedInOptionsDropdown';
import UnsignedAuditionList from '../../components/UnSignedInAudition/UnsignedAuditionList';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from '../../redux/actions';
import SearchIcon from '../../assets/img/svg/search-icon.svg';
import debounce from 'lodash/debounce';

const UnsignedInUpcomingAudition = (props) => {
    const [loading, setLoading] = useState(false);
    const [auditions, setAuditions] = useState([]);
    const [type, setType] = useState('');
    const [search, setSearch] = useState('');

    const [pagination] = useState({
        pageNumber: 1,
        pageSize: 13,
    });

    useEffect(() => {
        fetchAuditions(pagination);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setAuditions(props.auditions);
    }, [props.auditions]);

    const fetchAuditions = async (pagination, type) => {
        setLoading(true);
        await props.fetchAuditions('/auditions', pagination, null, type, props.history);

        setLoading(false);
    };

    const handleFilterByType = (type) => {
        setType(type);

        fetchAuditions(pagination, type);
    };

    const fetchData = () => {
        props.fetchAuditions(
            '/auditions',
            {
                ...pagination,
                pageSize: auditions.length + 6,
            },
            null,
            type,
            props.history,
            {
                search,
            }
        );
    };

    const delayedQuery = useRef(
        debounce((searchQuery) => {
            props.fetchAuditions(
                '/auditions',
                {
                    ...pagination,
                    pageSize: auditions.length + 6,
                },
                null,
                type,
                props.history,
                {
                    search: searchQuery,
                }
            );
        }, 500)
    ).current;

    const onTextSearchChange = (e) => {
        const textSearch = e.target.value;
        setSearch(textSearch);
        delayedQuery(textSearch.trim());
    };

    return (
        <section className="highlights">
            <div className="container alternate">
                <UnsignedInOptionsDropdown handleFilterByType={handleFilterByType} />

                <div className="input input-search">
                    <i className="input-search-icon">
                        <img src={SearchIcon} alt="search" />
                    </i>
                    <input
                        type="text"
                        placeholder="Search audition"
                        name="talentCategories"
                        value={search}
                        onChange={onTextSearchChange}
                    />
                </div>

                <UnsignedAuditionList
                    auditions={auditions}
                    loading={loading}
                    fetchData={fetchData}
                />
            </div>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        alert: state.alert,
        auditions: state.audition.auditions,
        totalCount: state.audition.totalCount,
    };
};

export default withRouter(connect(mapStateToProps, actions)(UnsignedInUpcomingAudition));
