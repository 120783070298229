import React from 'react';
import CookieConsent from 'react-cookie-consent';

const CookiesConsent = () => {
    return (
        <CookieConsent
            location="bottom"
            buttonText="Accept & Continue"
            buttonClasses="btn btn-lg btn-primary"
            buttonStyle={{
                marginRight: '4rem',
                marginLeft: '3rem',
                marginTop: '1rem',
                color: '#fff',
                background: '##7158e2',
                borderRadius: '4px',
            }}
            cookieName="CookieConsent"
            style={{
                background: '#03293d',
                color: '#fff',
                alignItems: 'center',
                paddingTop: '2rem',
            }}
            expires={150}
        >
            <div className="container">
                <div className="col">
                    <h3>We care about your privacy</h3>
                    <p style={{fontFamily: "'Work Sans', sans-serif"}}>
                        We use cookies to improve your experience on our site and to show
                        you relevant advertising.
                        <br />
                        To find out more, read our updated{' '}
                        <a href="/privacy-policy">privacy policy</a>.
                    </p>
                </div>
            </div>
        </CookieConsent>
    );
};
export default CookiesConsent;
