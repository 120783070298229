import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as action from '../../../redux/actions';

import store from 'store';
import useAlert from '../../CustomHooks/useAlert';
import {capitalizeFirstLetter} from '../../../utils/capitalizeFirstLetter';
import Pagination from '../../Pagination/Pagination';
import {ApplicantVideoModal} from '../../../pages/unsignedInAudition/UnsignedInAuditionHiglights';
import $ from 'jquery';

const TableHeader = () => {
    return (
        <thead>
            <tr>
                <th>Audition Name</th>
                <th>Date Applied</th>
                <th>Action</th>
                <th>Status</th>
            </tr>
        </thead>
    );
};

const TableBody = (props) => {
    const setStatusBasedOnDateDifference = (auditionEndDate) => {
        const date1 = new Date();
        const date2 = new Date(auditionEndDate);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays >= 1) {
            return true;
        } else {
            return false;
        }
    };

    const handleDisplayEntry = (entry) => {
        props.displayApplicantVideo(
            props.user,
            entry
        );
    };

    return (
        <>
            <tbody>
                {props.appliedAuditionLists &&
                    props.appliedAuditionLists.map((appliedAuditionList) => (
                        <tr key={appliedAuditionList.audition.title}>
                            <td>
                                <label
                                    data-toggle="tooltip"
                                    title={appliedAuditionList.audition.title}
                                >
                                    {appliedAuditionList.audition.title.length > 20
                                        ? capitalizeFirstLetter(
                                              appliedAuditionList.audition.title
                                          ) + '...'
                                        : capitalizeFirstLetter(
                                              appliedAuditionList.audition.title
                                          )}
                                </label>
                            </td>
                            <td>
                                <label>
                                    {new Date(
                                        appliedAuditionList.auditionEntry.createdAt
                                    ).toDateString()}
                                </label>
                            </td>
                            {/*<td*/}
                            {/*    className={`${*/}
                            {/*        appliedAuditionList.isActive ? '' : 'inactive'*/}
                            {/*    }`}*/}
                            {/*>*/}
                            {/*    <Link to="#">*/}
                            {/*        <label>*/}
                            {/*            <img src={ShareIcon} alt="" /> share Audition*/}
                            {/*            Video*/}
                            {/*        </label>*/}
                            {/*    </Link>*/}
                            {/*</td>*/}
                            <td>
                                <Link to="#">
                                    <label
                                        onClick={() =>
                                            handleDisplayEntry(
                                                appliedAuditionList.auditionEntry.mediaUrl
                                            )
                                        }
                                    >
                                        View entry
                                    </label>
                                </Link>
                            </td>
                            <td>
                                <label
                                    className={`btn-flat ${
                                        setStatusBasedOnDateDifference(
                                            appliedAuditionList.audition.updatedAt
                                        )
                                            ? 'active'
                                            : 'inactive'
                                    }`}
                                >
                                    {setStatusBasedOnDateDifference(
                                        appliedAuditionList.audition.updatedAt
                                    )
                                        ? 'Active'
                                        : 'Inactive'}
                                </label>
                            </td>
                        </tr>
                    ))}
            </tbody>
        </>
    );
};

const AppliedAuditionList = (props) => {
    const [loading, setLoading] = useState(false);
    const [displayVideoModal, setDisplayVideoModal] = useState({
        applicantName: '',
        applicantVideo: '',
    });
    const user = store.get('kmt-user');
    const interest = user && user.areaOfInterest;

    const [pagination, setPagination] = useState({
        pageNumber: 1,
        pageSize: 9,
    });

    // Page count
    const pageCount =
        props.totalCount && Math.ceil(props.totalCount / pagination.pageSize);

    const {appliedAuditions} = props;

    useEffect(() => {
        fetchAppliedAuditions(pagination, props.history);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchAppliedAuditions = async (pagination) => {
        setLoading(true);
        // if you a talent user, else go back to auditions
        if (interest === 'talent') {
            await props.fetchAppliedAuditions(pagination, props.history);
        } else {
            props.history.push('/talent-auditions');
        }
        setLoading(false);
    };

    const handlePaginate = async (data) => {
        // Get page number on click of pagination numbers
        // Note: initial pagination number start from 0, so we add +1
        let selected = parseInt(data.selected) + 1;
        let pageNumber = Math.ceil(selected);

        // Update pagination state
        setPagination({...pagination, pageNumber});

        // fetch audition based on new pagination value
        fetchAppliedAuditions({...pagination, pageNumber}, props.history);
    };

    const renderLoader = () => {
        return (
            <div className="loading text-center mt-5">
                <div className="lds-ripple">
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    };

    const renderDisplayIfRecordIsEmpty = () => {
        return (
            <div className="jumbotron">
                <p className="">
                    Welcome, there are so many awesome talents to be discovered here
                </p>
                <button
                    className="btn btn-primary btn-lg"
                    onClick={() => props.history.goBack()}
                >
                    Go Back to Auditions
                </button>
            </div>
        );
    };

    const displayApplicantVideo = (applicantName, applicantVideo) => {
        setDisplayVideoModal({
            applicantName,
            applicantVideo,
        });
        // Display the modal
        $('#applicantVideo')
            .modal('show')
            .on('hidden.bs.modal', function () {
                setDisplayVideoModal({
                    applicantName: '',
                    applicantVideo: '',
                });
            });
    };

    // Display message if error occurs
    useAlert(props.alert);

    return (
        <section className="applied-audition">
            <div className="head">
                <h3>Applied Auditions</h3>
            </div>
            {loading ? (
                renderLoader()
            ) : appliedAuditions.length === 0 ? (
                renderDisplayIfRecordIsEmpty()
            ) : (
                <section className="applicants-list talents">
                    <div className="table-responsive">
                        <table className="table">
                            <TableHeader />
                            <TableBody
                                appliedAuditionLists={appliedAuditions}
                                displayApplicantVideo={displayApplicantVideo}
                                user={user ? user.fullName : ''}
                            />
                        </table>
                        <div className="d-flex flex-row justify-content-center">
                            <Pagination
                                handlePaginate={handlePaginate}
                                pageCount={pageCount}
                            />
                        </div>
                        <ApplicantVideoModal displayVideoModal={displayVideoModal} />
                    </div>
                </section>
            )}
        </section>
    );
};

const mapStateToProps = (state) => ({
    appliedAuditions: state.audition.appliedAuditions,
    totalCount: state.audition.totalCount,
    alert: state.alert,
});

export default withRouter(connect(mapStateToProps, action)(AppliedAuditionList));
