import React, {useState} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../redux/actions';
import RegistrationDefault from '../../components/layout/RegistrationDefault';
import Input from '../../components/Form/Input';
import LeftViewOnAuthPages from '../../components/Blocks/LeftViewOnAuthPages';
import {validateAll, extend} from 'indicative/validator';
import {getValue} from 'indicative-utils';
import {scrollTop} from '../../utils/scrollTop';
import {displayLoader} from '../../utils/displayLoader';
import useAlert from '../../components/CustomHooks/useAlert';

extend('validemail', {
    async: true,

    compile(args) {
        return args;
    },

    async validate(data, field, args, config) {
        const fieldValue = getValue(data, field);
        const re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(fieldValue);
    },
});

const RequestForgetPassword = (props) => {
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState('');

    const handleResetPassword = (e) => {
        e.preventDefault();

        const data = {
            email,
        };

        const rules = {
            email: 'required|email|validemail',
        };

        const messages = {
            'email.email': 'Enter valid email address',
            'email.required': 'Enter email address',
            'email.validemail': 'Enter valid email address',
        };

        validateAll(data, rules, messages)
            .then(() => {
                scrollTop();
                reset();
                setEmail('');
            })
            .catch((errors) => {
                const formattedErrors = {};
                errors.forEach((error) => (formattedErrors[error.field] = error.message));
                setErrors(formattedErrors);
            });

        const reset = async () => {
            const payload = {
                email,
            };
            await props.requestForgetPassword(payload, props.history);
        };
    };

    const renderOnRequestReset = () => {
        return displayLoader(
            'Password Reset Initiated',
            `Kindly check your email for 
        password reset link to generate a new one`
        );
    };

    useAlert(props.alert);

    return (
        <>
            <RegistrationDefault
                pageTitle={'Reset Password!'}
                linkName={'Login'}
                linkUrl={'/login'}
                linkStyle={'adj'}
                pageTag="Reset Password"
            />
            <main className="account">
                <LeftViewOnAuthPages />
                <section className="right-pad">
                    {props.requestForgotPasswordStart && renderOnRequestReset()}
                    <div className="container">
                        <form
                            onSubmit={handleResetPassword}
                            className="animated bounceIn"
                        >
                            <section className="step-1">
                                <div className="title">
                                    <h3>Did you forget your password?</h3>
                                    <p>
                                        No worries. Just enter your email in the field
                                        below.
                                    </p>
                                </div>

                                <Input
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Email Address"
                                    label="Email Address"
                                    errorMessage={errors.email}
                                    onFocus={() => setErrors('')}
                                />

                                <div className="action">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={
                                            props.requestForgotPasswordStart
                                                ? true
                                                : false
                                        }
                                    >
                                        Create New Password
                                    </button>
                                </div>
                            </section>
                        </form>
                    </div>
                </section>
            </main>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        requestForgotPasswordStart: state.auth.requestForgotPasswordStart,
        alert: state.alert,
    };
};

export default connect(mapStateToProps, actions)(RequestForgetPassword);
