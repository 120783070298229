import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import * as actions from '../../../redux/actions';
import $ from 'jquery';
import XIcon from '../../../assets/img/svg/x.svg';
import {renderUserProfile} from '../../../utils/renderUserProfile';
import useAlert from '../../CustomHooks/useAlert';

// for sharing post
const SharePostWithAddedTextModal = (props) => {
    const {keptShareData} = props;

    const [loading, setLoading] = useState(false);
    const [sharePost, setSharePost] = useState('');

    useEffect(() => {
        // if sharing is successful, hide modal window
        if (props.shareStatus) $('#sharePostWithText').modal('hide');
    }, [props.shareStatus]);

    const handleSharePost = async (e) => {
        setLoading(true);
        e.preventDefault();
        const data = {
            sharePostId: keptShareData.sharePostId,
            body: sharePost,
            talentCategories: keptShareData.talentCategories,
        };

        await props.sharePost(data);
        setLoading(false);
        setSharePost('');
    };

    const renderPostToShareDetail = () => {
        return (
            <div className="custom-share-postData container">
                <div className="row">
                    {keptShareData.mediaUrl ? (
                        <>
                            <div
                                className="col-md-4"
                                style={{
                                    backgroundImage: `url(${keptShareData.mediaUrl})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center center',
                                    backgroundRepeat: 'no-repeat',
                                }}
                            ></div>
                            <div className="col-md-8">{keptShareData.body}</div>
                        </>
                    ) : (
                        <div className="col-md-12">{keptShareData.body}</div>
                    )}
                </div>
            </div>
        );
    };

    useAlert(props.alert);

    return (
        <div
            className="modal fade"
            id="sharePostWithText"
            data-backdrop="static"
            data-keyboard="false"
        >
            <div
                className="modal-dialog modal-wrapper"
                style={{maxWidth: '573px', marginTop: '4%'}}
            >
                <div className=" modal-content-wrap">
                    <div className="header-wrap">
                        <div className="close_">
                            <label data-dismiss="modal">
                                <img src={XIcon} alt="" />
                                <span>Close</span>
                            </label>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="container wrapr">
                            <form className="create-post-form" onSubmit={handleSharePost}>
                                <div>
                                    <p>Share Post</p>
                                </div>
                                <hr />

                                {/* user profile */}
                                {renderUserProfile()}

                                <section className="list-wrap">
                                    <textarea
                                        className="form-control create-post-textarea"
                                        placeholder="What do you want to talk about?"
                                        name="sharePost"
                                        value={sharePost}
                                        onChange={(e) => setSharePost(e.target.value)}
                                    />

                                    {renderPostToShareDetail()}
                                </section>
                                <section className="pt-3">
                                    <div className="container">
                                        <div className="row d-flex justify-content-between align-items-center">
                                            <div className="col-md-8 create-post-icons"></div>
                                            <div className="col-md-4">
                                                <div className="action">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-block text-center"
                                                    >
                                                        {loading
                                                            ? 'Sharing...'
                                                            : 'Share Post'}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        keptShareData: state.feeds.keptShareData,
        shareStatus: state.feeds.shareStatus,
    };
};

export default withRouter(connect(mapStateToProps, actions)(SharePostWithAddedTextModal));
