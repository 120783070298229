import React, {useState} from 'react';
import {BrowserRouter, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import Routes from './Routes';
import PublicRoute from './PublicRoute';
import ProtectedRoute from './ProtectedRoute';
import {alertActions} from '../redux/actions/alertActions';
import {history} from '../helpers/History';
import ErrorBoundary from '../components/Blocks/ErrorBoundry';

const MainRoutes = (props) => {
    const [errorBoundaryKey, setErrorBoundaryKey] = useState(0);

    const handleResetButtonClick = () => {
        setErrorBoundaryKey(errorBoundaryKey + 1);
    }

    history.listen(() => {
        props.clear();
    });

    return (
        <ErrorBoundary key={errorBoundaryKey} onReset={handleResetButtonClick}>
            <BrowserRouter basename="/">
                <Switch>
                    {Routes.map((route, i) => {
                        if (route.auth) {
                            return (
                                <ProtectedRoute
                                    isgeneral={route.general}
                                    key={i}
                                    {...route}
                                />
                            );
                        } else {
                            return <PublicRoute key={i} {...route} />;
                        }
                    })}
                </Switch>
            </BrowserRouter>
        </ErrorBoundary>
    );
};

function mapStateToProps(state) {
    const {alert} = state;
    return {
        alert,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        clear: () => dispatch(alertActions.clear()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainRoutes);
