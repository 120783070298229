import React from 'react';
import {withRouter} from 'react-router-dom';
import {getMediaType, isVideo} from '../../../utils/global';

const PanelImage = (props) => {
    const {postImg, sharedPost, location, history, postId, sharePostId} = props;

    // Render Post Image/Video if post is a shared post or normal post
    return (
        <>
            {postImg ? (
                <div
                    className="media"
                    onClick={() =>
                        location.pathname === '/talents' ||
                        location.pathname === '/scouts' ||
                        location.pathname.length > 25
                            ? ''
                            : history.push(`/posts/${postId}`)
                    }
                >
                    {isVideo(postImg) ? (
                        <video className="post-video-player" controls>
                            <source src={postImg} type={`video/${getMediaType(postImg)}`} />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <img src={postImg} alt="" />
                    )}
                </div>
            ) : sharedPost && sharedPost.mediaUrl ? (
                <div
                    className="media"
                    onClick={() =>
                        location.pathname === '/talents' ||
                        location.pathname === '/scouts' ||
                        location.pathname.length > 25
                            ? ''
                            : history.push(`/posts/${sharePostId}`)
                    }
                    style={{
                        background: `url(${sharedPost.mediaUrl}) no-repeat center`,
                    }}
                >
                    {/* Display the video player if the post is a video */}
                    {(sharedPost?.mediaUrl.includes('.mp4') ||
                        sharedPost.mediaUrl.includes('.webm') ||
                        sharedPost.mediaUrl.includes('.ogg')) && (
                        <video className="post-video-player" controls>
                            <source src={sharedPost.mediaUrl} type="video/mp4" />
                            <source src={sharedPost.mediaUrl} type="video/ogg" />
                            Your browser does not support the video tag.
                        </video>
                    )}
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default withRouter(PanelImage);
