import React from 'react';
import VideoIcon from '../../../assets/img/svg/movie-ico.svg';
import PhotoIcon from '../../../assets/img/svg/photo-file-icon.svg';
import PlusIcon from '../../../assets/img/svg/plus-icon-white.svg';
import SendIcon from '../../../assets/img/svg/send-arrow-icon.svg';
import FileIconWhite from '../../../assets/img/svg/file-icon-white.svg';
import $ from 'jquery';

const TypeMessage = ({
    text,
    handleKeyUp,
    handleInputChange,
    handleSendMessage,
    handleMediaUploader,
    uploadedFile,
}) => {
    const handleFileUploadOption = () => {
        $('.card-footer .more-options').toggleClass('show');
    };

    return (
        <div className="card-footer">
            {uploadedFile ? (
                <div className="uploadMain">
                    <div className="uploadAttachment">
                        <img src={FileIconWhite} alt="" />
                        <small>{uploadedFile.name}</small>
                    </div>
                </div>
            ) : (
                ''
            )}
            <div className="input-group">
                <div className="more-options">
                    <div className="input-group-append d-flex chat-upload chat-video-upload">
                        <span
                            className="action-btn-icon"
                            onClick={() => handleMediaUploader('video')}
                        >
                            <img src={VideoIcon} alt="" />
                            <input
                                id="video_uploader"
                                type="file"
                                hidden
                                accept="video/*"
                            />
                        </span>
                    </div>
                    <div className="input-group-append d-flex chat-upload chat-photo-upload">
                        <span
                            className="action-btn-icon"
                            onClick={() => handleMediaUploader('image')}
                        >
                            <img src={PhotoIcon} alt="" />
                            <input
                                id="image_uploader"
                                type="file"
                                hidden
                                accept="image/*"
                            />
                        </span>
                    </div>
                    <div className="input-group-append d-flex chat-upload chat-file-upload">
                        <span
                            className="action-btn-icon"
                            onClick={() => handleMediaUploader('doc')}
                        >
                            <img src={FileIconWhite} alt="" />
                            <input
                                id="doc_uploader"
                                type="file"
                                hidden
                                accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                            />
                        </span>
                    </div>
                </div>
                <div className="input-group-append start d-flex">
                    <span
                        className="action-btn-icon"
                        onClick={() => handleFileUploadOption()}
                    >
                        <img src={PlusIcon} alt="" />
                    </span>
                </div>
                <input
                    name="text"
                    className="form-control chat-textarea"
                    placeholder="Type your message..."
                    onKeyUp={(e) => handleKeyUp(e)}
                    onChange={(e) => handleInputChange(e)}
                    value={text}
                />
                <div className="input-group-append end d-flex">
                    <span
                        className="action-btn-icon"
                        onClick={(e) => handleSendMessage(e)}
                    >
                        <img src={SendIcon} alt="" />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default TypeMessage;
