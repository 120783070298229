import axios from '../../axios/axiosInstance';
import Actions from './actionTypes';
import {alertActions} from './alertActions';

export const requestPhoneVerification = async () => {
    return await axios.put(`/auth/verify/phone`);
};

export const validatePhone = async (payload) => {
    return await axios.put(`/auth/verify/validate-phone`, payload);
};

export const fetchUserProfile = (userId, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .get(`/users/${userId}`)
        .then((response) => {
            dispatch(fecthProfile(response.data.user));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

export const reportUser = (data) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .post('/reports', data)
        .then((response) => {
            dispatch(alertActions.success(response.data.message));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
            }
        });
};

// follow user on profile page
export const followUserOnProfile = (data) => async (dispatch) => {
    await axios
        .post(`/users/${data.userId}/follows`, data)
        .then((res) => {
            dispatch(updateUserStat({key: 'followingsCount', value: 1}));
            dispatch(followStatus(data.userId, true));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(followStatus(data.userId, false));
            }
        });
};

// unfollow user on profile page
export const unfollowUserOnProfile = (data) => async (dispatch) => {
    await axios
        .delete(`/users/${data.userId}/follows`)
        .then(() => {
            dispatch(updateUserStat({key: 'followingsCount', value: -1}));
            dispatch(followStatus(data.userId, false));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(followStatus(data.userId, true));
            }
        });
};

// get user posts
export const fetchUserPosts = (params, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .get('/posts', {
            params,
        })
        .then((response) => {
            dispatch(fecthPosts(response.data));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

// get user followings
export const getfollowings = (userId, params, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .get(`/users/${userId}/followings`, {
            params,
        })
        .then((response) => {
            dispatch(fecthFollowings(response.data));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

// get user followers
export const getfollowers = (userId, params, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .get(`/users/${userId}/followers`, {
            params,
        })
        .then((response) => {
            dispatch(fecthFollowers(response.data));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

// get user follow request
export const getfollowRequests = (history) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .get(`/followRequest`)
        .then((response) => {
            dispatch(fecthFollowRequest(response.data.requests));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

// accept follow request
export const acceptFollowRequest = (requestId) => async (dispatch) => {
    await axios
        .post(`/acceptRequest/${requestId}`)
        .then((response) => {
            dispatch(
                acceptOrDeclineRequest(
                    Actions.ACCEPT_FOLLOW_REQUEST,
                    requestId,
                    true,
                    false
                )
            );
            dispatch(alertActions.success(response.data.message));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(
                    acceptOrDeclineRequest(
                        Actions.ACCEPT_FOLLOW_REQUEST,
                        requestId,
                        false,
                        false
                    )
                );
                dispatch(alertActions.error(error.response.data.message));
            }
        });
};

// decline follow request
export const declineFollowRequest = (requestId) => async (dispatch) => {
    await axios
        .delete(`/declineRequest/${requestId}`)
        .then((response) => {
            dispatch(
                acceptOrDeclineRequest(
                    Actions.DECLINE_FOLLOW_REQUEST,
                    requestId,
                    false,
                    true
                )
            );
            dispatch(alertActions.success(response.data.message));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(
                    acceptOrDeclineRequest(
                        Actions.DECLINE_FOLLOW_REQUEST,
                        requestId,
                        false,
                        false
                    )
                );
                dispatch(alertActions.error(error.response.data.message));
            }
        });
};

export const fetchUsersOnSearch = (
    search,
    pageNumber = 1,
    pageSize = 10,
    history
) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .get(`/users/?search=${search}&pageSize=${pageSize}&pageNumber=${pageNumber}`)
        .then((response) => {
            dispatch(fecthUsers(response.data.users));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

// accept follow request
export const updateUserBio = (payload) => async (dispatch) => {
    await axios
        .put('/users/bio', payload)
        .then((response) => {
            dispatch(bioUpdate(response.data.bio));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
            }
        });
};

// Dispatch functions
export const fecthProfile = (data) => {
    return {
        type: Actions.FETCH_USER,
        payload: data,
    };
};

const followStatus = (userId, isFollowOrUnfollow) => ({
    type: Actions.FOLLOW_STATUS,
    payload: {
        userId,
        isFollowOrUnfollow,
    },
});

const fecthPosts = (data) => {
    return {
        type: Actions.FETCH_USER_POST,
        payload: data,
    };
};

const fecthFollowings = (data) => {
    return {
        type: Actions.FETCH_FOLLOWINGS,
        payload: data,
    };
};

const fecthFollowers = (data) => {
    return {
        type: Actions.FETCH_FOLLOWERS,
        payload: data,
    };
};

const fecthFollowRequest = (data) => {
    return {
        type: Actions.FETCH_FOLLOW_REQUEST,
        payload: data,
    };
};

const acceptOrDeclineRequest = (actionType, requestId, isAccept, isReject) => {
    return {
        type: actionType,
        payload: {
            requestId,
            isAccept,
            isReject,
        },
    };
};

const fecthUsers = (data) => {
    return {
        type: Actions.FETCH_USERS,
        payload: data,
    };
};

const bioUpdate = (data) => {
    return {
        type: Actions.UPDATE_BIO,
        payload: data,
    };
};

export const dispatchUserStats = () => async (dispatch) => {
    await axios.get(`/users/0`, {}).then((response) => {
        if (response.data) {
            const {followersCount, followingsCount, postsCount} = response.data.user;
            dispatch(setUserStat({followersCount, followingsCount, postsCount}));
        }
    });
};

export const setUserStat = (data) => ({
    type: Actions.SET_USER_STAT,
    payload: data,
});

export const updateUserStat = (data) => ({
    type: Actions.UPDATE_USER_STAT,
    payload: data,
});
