import React from 'react';
import MapIcon from '../../../assets/img/svg/map-icon.svg';
import {Link, withRouter} from 'react-router-dom';
import PostBody from './PostBody';

const PostBodyWithCommentLink = (props) => {
    return (
        <section className="post">
            <PostBody
                userId={props.userId}
                postId={props.postId}
                fullName={props.fullName}
                body={props.body}
                sharedPost={props.sharedPost}
            />
            {props.region && props.country && (
                <p>
                    <img src={MapIcon} width="7rem" alt="" /> {props.region},{' '}
                    {props.country}
                </p>
            )}
            {props.location.pathname === '/talents' ||
            props.location.pathname === '/scouts' ? (
                <a href="/">View all {props.totalComments} comments</a>
            ) : (
                <Link to={`/posts/${props.postId}`}>
                    View all {props.totalComments} comments
                </Link>
            )}
        </section>
    );
};

export default withRouter(PostBodyWithCommentLink);
