/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import * as actions from '../../../redux/actions';
import HorizontalIcon from '../../../assets/img/svg/horizontal-ellipsis.svg';
import {stringFirstUpperCase} from '../../../utils/stringFirstsUppercase';
import {capitalizeFirstLetter} from '../../../utils/capitalizeFirstLetter';
import Avatar from '../../../assets/img/svg/user.svg';
import {connect} from 'react-redux';

const FollowsItem = (props) => {
    const handleMessage = (user) => {
        props.keepUserIdfromProfileToFetchMessage(user, props.history);
    };

    return (
        <>
            <div className="list-tile">
                <div className="d-flex user_info">
                    <img
                        style={{borderRadius: '50%'}}
                        src={
                            props.profilePicture && props.profilePicture.length > 15
                                ? props.profilePicture
                                : Avatar
                        }
                        alt="profile"
                    />
                    <p>
                        {stringFirstUpperCase(props.fullName)}
                        <small>{capitalizeFirstLetter(props.areaOfInterest)}</small>
                    </p>
                </div>
                <div className="d-flex action_btn">
                    <button
                        className="btn btn-primary"
                        onClick={() => handleMessage(props.user)}
                    >
                        Message
                    </button>
                    <div className="action dropdown">
                        <a
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <img src={HorizontalIcon} alt="more icon" />
                        </a>
                        <div
                            className="dropdown-menu dropdown-menu-right"
                            aria-labelledby="profile-menu"
                            x-placement="bottom-end"
                            style={{
                                position: 'absolute',
                                willChange: 'transform',
                                top: '0px',
                                left: '0px',
                                transform: 'translate3d(4px, 19px, 0px)',
                            }}
                        >
                            <Link
                                to={`/profile/${props.userId}`}
                                className="dropdown-item"
                            >
                                <span>View Profile</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {};
};

export default withRouter(connect(mapStateToProps, actions)(FollowsItem));
