import React, {useEffect, useState} from 'react';
import XIcon from '../../../assets/img/svg/x.svg';
import {renderUserProfile} from '../../../utils/renderUserProfile';
import useAlert from '../../CustomHooks/useAlert';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';

const EditPostModal = ({selectedFeed, ...props}) => {
    const [post, setPost] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setPost(selectedFeed.body);
    }, [selectedFeed]);

    const handleUpdatePost = async (e) => {
        setLoading(true);
        e.preventDefault();
        // Send Request
        const payload = {
            ...selectedFeed,
            body: post,
        };
        await props.updatePost(selectedFeed.postId, payload, props.history);
        setLoading(false);
    };

    const handleCloseModal = () => {
        setLoading(false);
        setPost('');
    };

    useAlert(props.alert);
    return (
        <div
            className="modal fade"
            id="editPostModal"
            data-backdrop="static"
            data-keyboard="false"
        >
            <div
                className="modal-dialog modal-wrapper"
                style={{maxWidth: '573px', marginTop: '4%'}}
            >
                <div className=" modal-content-wrap">
                    <div className="header-wrap">
                        <div className="close_">
                            <label data-dismiss="modal" onClick={handleCloseModal}>
                                <img src={XIcon} alt="" />
                                <span>Close</span>
                            </label>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="container wrapr">
                            <form
                                className="create-post-form"
                                onSubmit={handleUpdatePost}
                            >
                                <div>
                                    <p>Edit Post</p>
                                </div>
                                <hr />

                                {/* user profile */}
                                {renderUserProfile()}

                                <section className="list-wrap">
                                    <textarea
                                        className="form-control create-post-textarea"
                                        placeholder="What do you want to talk about?"
                                        name="post"
                                        value={post}
                                        onChange={(e) => setPost(e.target.value)}
                                    />
                                </section>
                                <section className="pt-3">
                                    <div className="container">
                                        <div className="row d-flex justify-content-between align-items-center">
                                            <div className="col-md-6">
                                                <div className="action">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-block text-center"
                                                        disabled={loading}
                                                    >
                                                        {loading
                                                            ? 'Updating Post...'
                                                            : 'Update Post'}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        alert: state.alert,
    };
};

export default withRouter(connect(mapStateToProps, actions)(EditPostModal));
