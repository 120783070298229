import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {withRouter} from 'react-router-dom';
import LeftSideBar from '../../components/Feeds/LeftSideBar';
import Header from '../../components/Feeds/Header';
import CenterBar from '../../components/Feeds/CenterBar';
import RightSideBar from '../../components/Feeds/RightSideBar';
import Footer from '../../components/Feeds/Footer';
import store from 'store';

import {connect} from 'react-redux';
import * as actions from '../../redux/actions';
import useAlert from '../../components/CustomHooks/useAlert';
// import SearchListBox from '../../components/Blocks/SearchListBox';

const Feeds = (props) => {
    const match = props.match.path;
    const userData = store.get('kmt-user');
    const catId =
        userData &&
        userData.talentCategories &&
        userData.talentCategories.length &&
        userData.talentCategories[0].id;

    useEffect(() => {
        if (match === '/feeds') {
            props.fetchFeeds(true, 'home', catId, props.history);
        } else {
            props.fetchFeeds(true, 'discover', catId, props.history);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match]);

    useAlert(props.alert);

    return (
        <>
            <Helmet>
                <title>Talents Feeds</title>
            </Helmet>
            <main className="main feeds">
                <LeftSideBar />
                <section id="wrapper">
                    <Header />

                    <div className="container content_">
                        <div className="row">
                            <CenterBar />
                            <RightSideBar categories={props.talentCategories} />
                        </div>
                        <Footer />
                    </div>
                </section>
            </main>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        alert: state.alert,
        talentCategories: state.feeds.talentCategories,
    };
};

export default withRouter(connect(mapStateToProps, actions)(Feeds));
