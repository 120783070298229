export const showDayBasedOnUTCDate = (date) => {
    const newDate = date && new Date().getUTCDate() - new Date(date).getUTCDate();
    switch (newDate) {
        case 0:
            return 'Today';
        case 1:
            return 'Yesterday';
        case 2:
            return '2days ago';
        case 3:
            return '3days ago';
        case 4:
            return '4days ago';
        case 5:
            return '5days ago';
        case 6:
            return '6days ago';
        case 7:
            return '7days ago';
        default:
            return new Date(date).toLocaleDateString();
    }
};
