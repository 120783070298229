import Actions from '../actions/actionTypes';

const initial = {
    notifications: {
        notifications: [],
    },
};

const NotificationReducer = (state = initial, action) => {
    switch (action.type) {
        case Actions.FETCH_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload,
            };
        case Actions.DELETE_NOTIFICATIONS:
            return {
                ...state,
                notifications: {
                    notifications: state.notifications.notifications.filter(
                        (i) =>
                            i.notificationId !==
                            action.payload.find((j) => j === i.notificationId)
                    ),
                },
            };

        default:
            return state;
    }
};

export default NotificationReducer;
