import Actions from '../actions/actionTypes';

const initial = {
    isClear: false,
    conversations: [],
    previousMessages: [],
    keptUserData: {
        userInfo: {
            id: 0,
        },
    },
};

const MessageReducer = (state = initial, action) => {
    switch (action.type) {
        case Actions.FETCH_CONVERSATION:
            let conversations;
            if (state.conversations.length > 0) {
                const newConversation = action.payload.filter(
                    (conversation) => conversation.message.conversationId === 0
                );
                conversations = [...newConversation, ...state.conversations];
            } else {
                const newConversation = action.payload.filter(
                    (conversation) => conversation.userInfo !== undefined
                );
                conversations = [...newConversation, ...state.conversations];
            }

            return {
                ...state,
                isClear: false,
                conversations
            };

        case Actions.FETCH_PREVIOUS_MESSAGE:
            return {
                ...state,
                isClear: false,
                previousMessages: action.payload,
            };

        case Actions.CLEAR_CHAT_HISTORY:
            return {
                ...state,
                isClear: setTimeout(() => {
                    return true;
                }, 400),
                previousMessages: [],
            };

        case Actions.KEEP_USERID_TO_MESSAGE:
            const user = {
                message: {
                    body: '',
                    conversationId: 0,
                    createdAt: new Date().toISOString(),
                },
                userInfo: {
                    id: action.payload.userId,
                    fullName: action.payload.fullName,
                    imageUrl: action.payload.imageUrl,
                },
            };

            // return conversation and put to top if the user exist in conversation
            let findUserAndMoveToTop = state.conversations.find(
                (c) => c.userInfo.id === user.userInfo.id
            );

            // filter list so there is no duplicated user conversation
            let filterByExisitingUserId = state.conversations.filter(
                (c) => c.userInfo.id !== user.userInfo.id
            );

            return {
                ...state,
                keptUserData: action.payload,

                // if the user was found in the conversation, add the filters out list to conversations list
                // else if the user hasnt been chatted with before, then prepend to the conversation list
                conversations: findUserAndMoveToTop
                    ? [findUserAndMoveToTop, ...filterByExisitingUserId]
                    : [user, ...state.conversations],
            };

        default:
            return state;
    }
};

export default MessageReducer;
