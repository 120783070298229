import React from 'react';

import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';
import PostDetailsCommentLists from './PostDetailsCommentLists';

const PostDetailsRightBar = (props) => {
    const {singlePost} = props;
    const refactorTalentCategories = (talentCategories = []) => {
        let talent = [];
        for (var cat of talentCategories) {
            talent.push(cat.name);
        }
        return talent;
    };

    return (
        <div
            // className={`${
            //     singlePost.mediaUrl ||
            //     (singlePost.sharedPost && singlePost.sharedPost.mediaUrl)
            //         ? 'col-md-5'
            //         : 'col-md-10'
            // }`}
            className="col-md-5"
        >
            <section className="comments-post-wrap animated fadeIn">
                <PostDetailsCommentLists
                    fullName={singlePost.creatorName}
                    body={singlePost.body}
                    sharedPost={singlePost.sharedPost || null}
                    isCommentStart={singlePost.isCommentStart}
                    commentLists={singlePost.comments || []}
                    postId={singlePost.postId}
                    isLiked={singlePost.isLiked}
                    totalLikes={singlePost.likersCount || 0}
                    totalComments={singlePost.commentorsCount}
                    mediaUrl={singlePost.creatorImage || null}
                    sharePostId={singlePost.postId}
                    talentCategories={refactorTalentCategories(
                        singlePost.talentCategories
                    )}
                    shareStatus={singlePost.shareStatus || null}
                />
            </section>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        singlePost: state.feeds.singlePost,
    };
};

export default connect(mapStateToProps, actions)(PostDetailsRightBar);
