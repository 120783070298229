import React, {useEffect, useState, useRef} from 'react';
import {Helmet} from 'react-helmet';
import {withRouter} from 'react-router-dom';
import debounce from 'lodash/debounce';
import store from 'store';
import LeftSideBar from '../../../components/Feeds/LeftSideBar';
import Header from '../../../components/Feeds/Header';
import Footer from '../../../components/Feeds/Footer';
import AuditionList from '../../../components/Feeds/Audition/AuditionList';
import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';
import AuditionHeader from './AuditionHeader';
import useAlert from '../../../components/CustomHooks/useAlert';
import Pagination from '../../../components/Pagination/Pagination';
import AuditionFilterModal from './AuditionFilterModal';
import SearchIcon from '../../../assets/img/svg/search-icon.svg';

// Renders the Audition Lists
const MainAuditionLists = (props) => {
    return (
        <div className="row">
            <AuditionList link="/talent-auditions" loading={props.loading} />
        </div>
    );
};

// Main Audition Page Wrapper
const Auditions = (props) => {
    // Get user area of interest
    const userAreaOfInterest =
        store.get('kmt-user') && store.get('kmt-user').areaOfInterest;

    const [loading, setLoading] = useState(false);
    const [sort, setSort] = useState(null);
    const [type, setType] = useState('');
    const [search, setSearch] = useState('');
    const [categoryId, setCategoryId] = useState('');

    const [pagination, setPagination] = useState({
        pageNumber: 1,
        pageSize: 9,
    });

    // Page count
    const pageCount =
        props.totalCount && Math.ceil(props.totalCount / pagination.pageSize);

    // fetch auditions on componentDidMount
    useEffect(() => {
        fetchAllAuditions(pagination);
        if (props.categories.length === 0) {
            props.getAllTalentCategories();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchAllAuditions = async (pagination, sort, type, filter) => {
        setLoading(true);
        if (props.match.path === '/talent-auditions/my-auditions') {
            await props.fetchAuditions(
                '/scouts/0/auditions',
                pagination,
                sort,
                type,
                props.history,
                filter
            );
        } else {
            await props.fetchAuditions(
                '/auditions',
                pagination,
                sort,
                type,
                props.history,
                filter
            );
        }

        setLoading(false);
    };

    const handlePaginate = async (data) => {
        // Get page number on click of pagination numbers
        // Note: initial pagination number start from 0, so we add +1
        let selected = parseInt(data.selected) + 1;
        let pageNumber = Math.ceil(selected);

        // Update pagination state
        setPagination({...pagination, pageNumber});

        // fetch audition based on new pagination value
        fetchAllAuditions({...pagination, pageNumber});
    };

    // Handle sort by actions
    const handleSortPageBy = (sortBy) => {
        setSort(sortBy);

        fetchAllAuditions(pagination, sortBy, type);
    };

    const handleFilterByType = (type) => {
        setType(type);

        fetchAllAuditions(pagination, sort, type);
    };
    const handleFilterByCategory = (talentCategory) => {
        setCategoryId(talentCategory);

        fetchAllAuditions(pagination, sort, type, { talentCategory });
    };

    const delayedQuery = useRef(
        debounce((search) => {
            fetchAllAuditions(pagination, sort, type, {search});
        }, 500)
    ).current;

    const onTextSearchChange = e => {
        const textSearch = e.target.value;
        setSearch(textSearch);
        delayedQuery(textSearch.trim());
    };

    // Display message if error occurs
    useAlert(props.alert);

    return (
        <>
            <Helmet>
                <title>
                    {/* Display Title based on user area of interest */}
                    {userAreaOfInterest === 'talent'
                        ? 'Talent Auditions'
                        : 'Scout Auditions'}
                </title>
            </Helmet>
            <main className="main feeds">
                <LeftSideBar />
                <section id="wrapper">
                    <Header />
                    <div className="container content_">
                        <section className="scouts-audition">
                            <div className="head">
                                <h3>Auditions</h3>
                                {/* Audition Page Header */}
                                <AuditionHeader
                                    pageNumber={pagination?.pageNumber}
                                    match={props.match}
                                    handleSortPageBy={handleSortPageBy}
                                    handleFilterByType={handleFilterByType}
                                    handleFilterByCategory={handleFilterByCategory}
                                    auditions={props.auditions}
                                    categories={props.categories}
                                />
                            </div>

                            <div className="input input-search">
                                <i className="input-search-icon">
                                    <img src={SearchIcon} alt="search" />
                                </i>
                                <input
                                    type="text"
                                    placeholder="Search audition"
                                    name="talentCategories"
                                    value={search}
                                    onChange={onTextSearchChange}
                                />
                            </div>

                            {/* Audition List */}
                            <MainAuditionLists loading={loading} />

                            {/* Pagination */}
                            <div className="d-flex flex-row justify-content-center">
                                <Pagination
                                    handlePaginate={handlePaginate}
                                    pageCount={pageCount}
                                />
                            </div>
                        </section>
                        <Footer />
                        <AuditionFilterModal
                            fetchAllAuditions={fetchAllAuditions}
                            sort={sort}
                            pagination={pagination}
                            type={type}
                            categoryId={categoryId}
                        />
                    </div>
                </section>
            </main>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        alert: state.alert,
        auditions: state.audition.auditions,
        totalCount: state.audition.totalCount,
        categories: state.talentCategories.talentCategories,
    };
};

export default withRouter(connect(mapStateToProps, actions)(Auditions));
