import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import useAlert from '../../CustomHooks/useAlert';
import * as actions from '../../../redux/actions';
import Avatar from '../../../assets/img/svg/user.svg';
import {stringFirstUpperCase} from '../../../utils/stringFirstsUppercase';
import {capitalizeFirstLetter} from '../../../utils/capitalizeFirstLetter';

const FollowRequestList = (props) => {
    const [loading, setLoading] = useState(false);
    const [followRequestList, setFollowRequestList] = useState([]);
    const [acceptLoading, setAcceptLoading] = useState(false);
    const [declineLoading, setDeclineLoading] = useState(false);

    // fetch data on mount
    useEffect(() => {
        fetchFollowRequestsAction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // update the follow request list
    useEffect(() => {
        setFollowRequestList(props.followRequests);
    }, [props.followRequests]);

    const fetchFollowRequestsAction = async () => {
        setLoading(true);
        await props.getfollowRequests(props.history);
        setLoading(false);
    };

    // render loader
    const renderLoader = () => {
        return (
            <div className="list-tile">
                <div className="col">
                    <div className="chat-loader">
                        <div className="media-panel d-flex">
                            <figure>
                                <div></div>
                            </figure>
                            <section>
                                <div></div>
                                <div></div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const acceptRequest = async (requestId) => {
        setAcceptLoading(true);
        await props.acceptFollowRequest(requestId);
        setAcceptLoading(false);
    };

    const declineRequest = async (requestId) => {
        setDeclineLoading(true);
        await props.declineFollowRequest(requestId);
        setDeclineLoading(false);
    };

    // Alert message if error occur on fetch
    useAlert(props.alert);

    return (
        <section className="friends">
            <div>
                <h3>Follow Request</h3>
            </div>
            <div className="row">
                <div className="col-lg-8">
                    {loading ? (
                        <>
                            {renderLoader()}
                            {renderLoader()}
                            {renderLoader()}
                        </>
                    ) : followRequestList && followRequestList.length === 0 ? (
                        <div className="col list-tile">
                            <p>You don't have pending request.</p>
                        </div>
                    ) : (
                        <>
                            {followRequestList.map((request) => (
                                <div class="list-tile spread">
                                    <div class="d-flex user_info">
                                        <img
                                            style={{borderRadius: '50%'}}
                                            src={
                                                request &&
                                                request.requester.imageUrl.length > 15
                                                    ? request.requester.imageUrl
                                                    : Avatar
                                            }
                                            alt="profile"
                                        />
                                        <p>
                                            {stringFirstUpperCase(
                                                request.requester.fullName
                                            )}
                                            <small>
                                                {capitalizeFirstLetter(
                                                    request.requester.areaOfInterest
                                                )}
                                            </small>
                                        </p>
                                    </div>
                                    <div class="d-flex action_btn alt">
                                        <button
                                            type="button"
                                            class="btn btn-primary"
                                            onClick={() =>
                                                acceptRequest(request.followRequestId)
                                            }
                                        >
                                            {acceptLoading ? 'Loading...' : 'Accept'}
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-secondary"
                                            onClick={() =>
                                                declineRequest(request.followRequestId)
                                            }
                                        >
                                            {declineLoading ? 'Loading...' : 'Decline'}
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </div>
                <div class="col-lg-4">
                    <section class="recents">
                        <div class="notif_tab">
                            <div class="main active">
                                <label>Follow Request</label>
                            </div>
                        </div>
                        <div class="content_display">
                            <section class="notification animated shake">
                                <div class="title">
                                    <h5>Total Request</h5>
                                </div>
                                <p className="text-center">{followRequestList.length}</p>
                            </section>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        alert: state.alert,
        followRequests: state.user.followRequests,
    };
};

export default withRouter(connect(mapStateToProps, actions)(FollowRequestList));
