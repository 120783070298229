import React, {useEffect, useState} from 'react';
import ProfileOverview from './ProfileOverview';
import ProfilePosts from './ProfilePosts';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from '../../../redux/actions';
import ReportUserModal from './ReportUserModal';
import useAlert from '../../CustomHooks/useAlert';
import {scrollTop} from '../../../utils/scrollTop';

const ProfileWrapper = (props) => {
    const [loading, setLoading] = useState(false);
    const [postLoading, setPostLoading] = useState(false);

    const [pagination, setPagination] = useState({
        pageNumber: 1,
        pageSize: 8,
    });

    // fetch posts on componentDidMount
    useEffect(() => {
        scrollTop();

        handleFetchingUserProfile(pagination);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.slug]);

    // Handle fetching of post and user profile
    const handleFetchingUserProfile = async (pagination) => {
        setLoading(true);
        if (props.match.params.slug) {
            const paginate = {
                pagination,
                userId: props.match.params.slug,
            };
            // fetch user profile
            await props.fetchUserProfile(props.match.params.slug, props.history);

            // fetch user posts
            await props.fetchUserPosts(paginate, props.history);
        } else {
            // fetch user profile
            await props.fetchUserProfile(0, props.history);

            // fetch user posts
            await props.fetchUserPosts(pagination, props.history);
        }
        // fetch user posts
        setLoading(false);
    };

    // Page count
    const pageCount =
        props.totalCount && Math.ceil(props.totalCount / pagination.pageSize);

    // Handle pagination
    const handlePaginate = async (data) => {
        // scroll up to post view container
        document.getElementById('user_post').scrollIntoView({
            behavior: 'smooth',
        });

        setPostLoading(true);
        // Get page number on click of pagination numbers
        // Note: initial pagination number start from 0, so we add +1
        let selected = parseInt(data.selected) + 1;
        let pageNumber = Math.ceil(selected);

        // Update pagination state
        setPagination({...pagination, pageNumber});

        // fetch posts based on new pagination value
        if (props.match.params.slug) {
            const paginate = {
                ...pagination,
                pageNumber,
                userId: props.match.params.slug,
            };
            // fetch user posts
            await props.fetchUserPosts(paginate, props.history);
        } else {
            // fetch user posts
            await props.fetchUserPosts({...pagination, pageNumber}, props.history);
        }

        setPostLoading(false);
    };

    // Display message if error occurs
    useAlert(props.alert);

    const renderLoader = () => {
        return (
            <div className="col-12  d-flex align-items-center justify-content-center pt-5 text-center">
                <section className="waiting">
                    <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </section>
            </div>
        );
    };

    return (
        <>
            {loading ? (
                renderLoader()
            ) : (
                <>
                    {props.user && (
                        <>
                            <ProfileOverview user={props.user} />
                            <ProfilePosts
                                user={props.user}
                                handlePaginate={handlePaginate}
                                pageCount={pageCount}
                                postLoading={postLoading}
                                userName={props.user.fullName}
                                private={props.user.private}
                                isFollowing={props.user.isFollowing}
                                fullName={props.user.fullName}
                            />

                            {/* Report Modal */}
                            <ReportUserModal userId={props.user.id} />
                        </>
                    )}
                </>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        totalCount: state.user.userPosts.totalCount,
        alert: state.alert,
    };
};
export default withRouter(connect(mapStateToProps, actions)(ProfileWrapper));
