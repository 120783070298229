/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../../components/layout/MainLayout/Header';
import Footer from '../../components/layout/MainLayout/Footer';
import UnsignedInAuditionIntro from './UnsignedInAuditionIntro';
import UnsignedInUpcomingAudition from './UnsignedInUpcomingAudition';

const UnsignedInAudition = () => {
    return (
        <>
            <Helmet>
                <title>Auditions | Filter categories</title>
            </Helmet>
            <Header />
            <main className="plublic">
                <UnsignedInAuditionIntro />
                <UnsignedInUpcomingAudition />
            </main>
            <Footer />
        </>
    );
};

export default UnsignedInAudition;
