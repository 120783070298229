import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import XIcon from '../../../assets/img/svg/x.svg';
import {countries_states} from '../../../utils/countries_states';
import $ from 'jquery';
import * as actions from '../../../redux/actions';

const AuditionFilterModal = (props) => {
    const [countriesList, setCountriesList] = useState([]);

    const [filterData, setFilterData] = useState({
        country: '',
        ageRange: '',
        gender: '',
    });

    // fetch list of countries
    useEffect(() => {
        fetchCountries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchCountries = async () => {
        setCountriesList(countries_states);
    };

    const handleCategoryFilter = async (e) => {
        e.preventDefault();

        // get startAge
        const startAge =
            filterData.ageRange &&
            (filterData.ageRange.length < 5
                ? filterData.ageRange.slice(0, 1)
                : filterData.ageRange.slice(0, 2));

        // get endAge
        const endAge =
            filterData.ageRange &&
            (filterData.ageRange.length < 5
                ? filterData.ageRange.slice(2, 4)
                : filterData.ageRange.length > 5
                ? '100'
                : filterData.ageRange.slice(3, 5));

        let payload = {};

        if (startAge) {
            payload = {...payload, startAge};
        }
        if (endAge) {
            payload = {...payload, endAge};
        }
        if (filterData.gender) {
            payload = {...payload, gender: filterData.gender};
        }
        if (filterData.country) {
            payload = {...payload, country: filterData.country.toLocaleLowerCase()};
        }

        if (props.categoryId) {
            payload = {...payload, talentCategory: props.categoryId};
        }

        props.fetchAllAuditions(props.pagination, props.sort, props.type, payload);

        $('#filterAuditionModal').modal('hide');
    };

    const handleInputChange = (name, value) => {
        setFilterData({
            ...filterData,
            [name]: value,
        });
    };

    const handleCountryChange = (e) => {
        setFilterData({
            ...filterData,
            country: e.target.value,
        });
    };

    const renderCountries = () => {
        let options = countriesList.map((country, i) => (
            <option key={`country-${i}`} value={country.name}>
                {country.name}
            </option>
        ));
        const defaultHandle = [
            <option key="country-0" value="">
                --Select--
            </option>,
        ];

        options = [...defaultHandle, options];

        return options;
    };

    const renderAgeRange = () => {
        const ages = ['0-14', '15-24', '25-35', '36-45', '46-55', '55-above'];
        let options = ages.map((a, i) => (
            <option key={`age-${i}`} value={a}>
                {a}
            </option>
        ));
        const defaultHandle = [
            <option key="age-0" value="">
                --Select--
            </option>,
        ];

        options = [...defaultHandle, options];

        return options;
    };

    return (
        <div className="modal fade" id="filterAuditionModal" role="dialog">
            <div className="modal-dialog modal-wrapper">
                <div className=" modal-content-wrap">
                    <div className="header-wrap">
                        <div className="close_">
                            <label data-dismiss="modal">
                                <img src={XIcon} alt="" />
                                <span>Close</span>
                            </label>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="container wrapr">
                            <form onSubmit={handleCategoryFilter}>
                                <label htmlFor="category">Country</label>
                                <section className="input bordered">
                                    <select
                                        className="form-control custom-select"
                                        name="country"
                                        onChange={handleCountryChange}
                                        value={filterData.country}
                                    >
                                        {renderCountries()}
                                    </select>
                                </section>

                                <label htmlFor="">Age Range</label>
                                <section className="input bordered">
                                    <select
                                        className="form-control custom-select"
                                        name="age"
                                        onChange={(e) =>
                                            handleInputChange('ageRange', e.target.value)
                                        }
                                        value={filterData.ageRange}
                                    >
                                        {renderAgeRange()}
                                    </select>
                                </section>

                                <div className="radio_option mode">
                                    <div className="custom-control custom-radio left">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            id="all"
                                            value="all"
                                            name="gender"
                                            onChange={(e) =>
                                                handleInputChange('gender', '')
                                            }
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="all"
                                        >
                                            Both <small>Filter for both</small>
                                        </label>
                                    </div>
                                    <div className="custom-control custom-radio left">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            id="male"
                                            value="male"
                                            name="gender"
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'gender',
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="male"
                                        >
                                            Male <small>Filter for male</small>
                                        </label>
                                    </div>
                                    <div className="custom-control custom-radio right">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            id="female"
                                            value="female"
                                            name="gender"
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'gender',
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="female"
                                        >
                                            Female <small>Filter for female</small>
                                        </label>
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    onClick={handleCategoryFilter}
                                    class="btn btn-primary"
                                >
                                    Filter Page
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {};
};
export default withRouter(connect(mapStateToProps, actions)(AuditionFilterModal));
