import Actions from '../actions/actionTypes';

const INITIAL_STATE = {
    totalCount: 0,
    auditions: [],
    audition: {},
    auditionEntry: {},
    loading: false,
    applicants: {
        auditionEntries: [],
        auditionInfo: {},
        userInfo: {},
    },
    appliedAuditions: [],
    categories: [],
};

const AuditionReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        // Fetching Audition
        case Actions.FETCH_AUDITION:
            return {
                ...state,
                totalCount: action.payload.totalCount,
                auditions: action.payload.auditions,
                categories: action.payload.categoriesTalent
                    ? action.payload.categoriesTalent
                    : state.categories,
            };

        case Actions.FETCH_AUDITION_ENTRY:
            return {
                ...state,
                auditionEntry: action.payload,
            };

        case Actions.VOTE_AUDITION_START:
            return {
                ...state,
                loading: true,
            };

        case Actions.FETCH_SINGLE_AUDITION:
            return {
                ...state,
                audition: action.payload,
            };

        case Actions.FETCH_AUDITION_APPLICANTS:
            return {
                ...state,
                totalCount: action.payload.totalCount,
                applicants: action.payload,
            };

        case Actions.FETCH_APPLIED_AUDITION:
            return {
                ...state,
                totalCount: action.payload.totalCount,
                appliedAuditions: action.payload.appliedAuditions,
            };

        case Actions.VOTE_AUDITION_ENTRY:
            return {
                ...state,
                loading: false,
                auditionEntry: {
                    ...state.auditionEntry,
                    auditionEntry: {
                        ...state.auditionEntry.auditionEntry,
                        voteCount: state.auditionEntry.auditionEntry
                            ? state.auditionEntry.auditionEntry.auditionEntryId !==
                              action.payload.auditionEntryId
                                ? state.auditionEntry.auditionEntry.voteCount
                                : action.payload.hasVoted
                                ? state.auditionEntry.auditionEntry.voteCount + 1
                                : state.auditionEntry.auditionEntry.voteCount - 1
                            : undefined,
                    },
                },
                applicants: {
                    ...state.applicants,
                    userInfo: {
                        ...state.applicants.userInfo,
                        votedAuditionEntryId: action.payload.auditionEntryId,
                        hasVoted: action.payload.hasVoted,
                    },
                    auditionEntries: state.applicants.auditionEntries.map((a) => ({
                        ...a,
                        voteCount:
                            a.auditionEntryId !== action.payload.auditionEntryId
                                ? a.voteCount
                                : action.payload.hasVoted
                                ? a.voteCount + 1
                                : a.voteCount - 1,
                        hasVoted:
                            a.auditionEntryId === action.payload.auditionEntryId
                                ? action.payload.hasVoted
                                : false,
                    })),
                },
            };

        default:
            return state;
    }
};

export default AuditionReducer;
