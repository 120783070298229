import React from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import XIcon from '../../../assets/img/svg/x.svg';

const CropPostImageModal = (props) => {
    return (
        <div
            className="modal fade"
            data-backdrop="static"
            data-keyboard="false"
            id="cropImage"
        >
            <div
                className="modal-dialog modal-wrapper"
                style={{maxWidth: '573px', marginTop: '4%'}}
            >
                <div className=" modal-content-wrap">
                    <div className="header-wrap">
                        <div className="close_">
                            <label data-dismiss="modal">
                                <img src={XIcon} alt="" />
                                <span>Close</span>
                            </label>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="container wrapr">
                            <form className="create-post-form">
                                <div>
                                    <p>Crop Image</p>
                                </div>
                                <hr />
                                <section className="">
                                    <ReactCrop
                                        src={props.toCropImage}
                                        crop={props.crop}
                                        onImageLoaded={props.handleLoad}
                                        onChange={(c) => props.handleCropChange(c)}
                                        onComplete={(c, percentCrop) =>
                                            props.handleCompletedCrop(c, percentCrop)
                                        }
                                        imageStyle={{
                                            width: '30rem',
                                        }}
                                    />
                                </section>
                                <section className="pt-3">
                                    <div className="container">
                                        <div className="row d-flex justify-content-between align-items-center">
                                            <div className="col-md-4">
                                                <div className="action">
                                                    <button
                                                        type="button"
                                                        data-dismiss="modal"
                                                        className="btn btn-primary btn-block text-center"
                                                        disabled={
                                                            !props.completedCrop?.width ||
                                                            !props.completedCrop?.height
                                                        }
                                                        onClick={
                                                            props.handleCroppingSaveChanges
                                                        }
                                                    >
                                                        Save Changes
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CropPostImageModal;
