import axios from '../../axios/axiosInstance';
import Actions from './actionTypes';
import {alertActions} from './alertActions';

export const fetchAuditions = (
    routePath = '/auditions',
    pagination,
    sort,
    type,
    history,
    filter = {}
) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .get(`${routePath}`, {
            params: {
                pageNumber: pagination.pageNumber,
                pageSize: pagination.pageSize,
                sort,
                type,
                ...filter,
            },
        })
        .then((response) => {
            dispatch(fetchAuditionData(response.data));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

export const fetchSingleAuditions = (auditionId, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .get(`/auditions/${auditionId}`)
        .then((response) => {
            dispatch(fetchSingleAudition(response.data.audition));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};
export const fetchSingleAuditionByUrl = (auditionId, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .get(`/${auditionId}`)
        .then((response) => {
            dispatch(fetchSingleAudition(response.data.audition));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

export const fetchAuditionEntry = (entryId, auditionId, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .get(`/${auditionId}/${entryId}`)
        .then((response) => {
            dispatch(auditionEntry(response.data));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

export const createAudition = (data, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .post('/auditions', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((response) => {
            dispatch(alertActions.success(response.data.message));
            history.push('/talent-auditions/my-auditions');
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

export const updateAudition = (auditionId, data, history) => async (dispatch) => {
    dispatch(alertActions.clear());

    await axios
        .put(`/auditions/${auditionId}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((response) => {
            dispatch(alertActions.success(response.data.message));
            history.push('/talent-auditions/my-auditions');
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

export const fetchAuditionApplicants = (auditionId, params) => async (dispatch) => {
    dispatch(alertActions.clear());

    await axios
        .get(`/auditions/${auditionId}/entries`, {
            params,
        })
        .then((response) => {
            dispatch(fetchAuditionApplicantsData(response.data));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    // history.push('/login');
                }
            }
        });
};

// For Talent User to View Auditions they Applied for
export const fetchAppliedAuditions = (pagination, history) => async (dispatch) => {
    dispatch(alertActions.clear());

    await axios
        .get('/talents/0/auditions', {
            params: pagination,
        })
        .then((response) => {
            dispatch(fetchAppliedAuditionsData(response.data));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

// For Talent User apply for audition
export const applyAudition = (auditionId, data, history) => async (dispatch) => {
    dispatch(alertActions.clear());

    await axios
        .post(`/auditions/${auditionId}/entries`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((response) => {
            dispatch(alertActions.success(response.data.message));
            history.goBack();
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

export const createNewAuditionEntryRequest = async (payload, auditionId) => {
    return await axios.post(`/auditions/${auditionId}/enlist/entries`, payload, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const votedAuditionEntry = (auditionEntryId, history, payload, payBy) => async (
    dispatch
) => {
    dispatch(alertActions.clear());
    dispatch(voteAuditionAPIStarted());
    let url  = `/entries/${auditionEntryId}/vote`

    if(payBy){
        url += `?paymentplatform=${payBy}`;
    }

    await axios
        .post(url, payload)
        .then((response) => {
            if (response.data.url) {
                window.location.href = response.data.url;
            } else {
                dispatch(voteAuditionEntry(auditionEntryId, true));
                dispatch(alertActions.success('Your vote was processed successfully'));
            }
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

export const unvotedAuditionEntry = (auditionEntryId, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    dispatch(voteAuditionAPIStarted());
    await axios
        .delete(`/entries/${auditionEntryId}/vote`)
        .then((response) => {
            dispatch(voteAuditionEntry(auditionEntryId, false));
            dispatch(alertActions.success('Your vote was removed successfully'));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

export const verifyUnauthenticatedVote = (
    auditionEntryId,
    history,
    payload,
    validate,
    payBy
) => async (dispatch) => {
    dispatch(alertActions.clear());
    dispatch(voteAuditionAPIStarted());

    let url = validate
        ? `/entries/${auditionEntryId}/vote/validate-user`
        : `/entries/${auditionEntryId}/vote/verify-user`;
    if (payBy) {
        url += `?paymentplatform=${payBy}&shouldVerify=false`;
    }

    return await axios.put(url, payload);
};

// Disptach functions
// Fetch Audition
const fetchAuditionData = (auditions) => ({
    type: Actions.FETCH_AUDITION,
    payload: auditions,
});

// Single fetch Audition
const fetchSingleAudition = (audition) => ({
    type: Actions.FETCH_SINGLE_AUDITION,
    payload: audition,
});

const auditionEntry = (audition) => ({
    type: Actions.FETCH_AUDITION_ENTRY,
    payload: audition,
});

// Fetch Audition Applicants
const fetchAuditionApplicantsData = (data) => ({
    type: Actions.FETCH_AUDITION_APPLICANTS,
    payload: data,
});

// Fetch Applied Audition
const fetchAppliedAuditionsData = (data) => ({
    type: Actions.FETCH_APPLIED_AUDITION,
    payload: data,
});

// Vote/Unvote Audition Entry
const voteAuditionEntry = (auditionEntryId, hasVoted) => ({
    type: Actions.VOTE_AUDITION_ENTRY,
    payload: {
        auditionEntryId,
        hasVoted,
    },
});

const voteAuditionAPIStarted = () => ({
    type: Actions.VOTE_AUDITION_START,
});
