import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../redux/actions';
import {withRouter} from 'react-router-dom';
import {rightSideBar} from './data';
import {countries_states} from '../../utils/countries_states';
import {capitalizeFirstLetter} from '../../utils/capitalizeFirstLetter';

const CategoriesSection = (props) => {
    const [countriesList, setCountriesList] = useState([]);
    const [regionList, setRegionList] = useState([]);

    const [filterData, setFilterData] = useState({
        country: '',
        region: '',
        ageRange: '',
        talentCategory: '',
        gender: '',
    });

    const match = props.match.path;

    // fetch list of countries
    useEffect(() => {
        fetchCountries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.talentCategories[0]) {
            setFilterData({
                ...filterData,
                talentCategory: props.talentCategories[0].talentCategoryId,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.talentCategories]);

    const fetchCountries = async () => {
        setCountriesList(countries_states);
        await props.getAllTalentCategories();
    };

    const handleCategoryFilter = async (e) => {
        e.preventDefault();

        // get startAge
        const startAge =
            filterData.ageRange &&
            (filterData.ageRange.length < 5
                ? filterData.ageRange.slice(0, 1)
                : filterData.ageRange.slice(0, 2));

        // get endAge
        const endAge =
            filterData.ageRange &&
            (filterData.ageRange.length < 5
                ? filterData.ageRange.slice(2, 4)
                : filterData.ageRange.length > 5
                ? '100'
                : filterData.ageRange.slice(3, 5));

        let payload = {};

        if (startAge) {
            payload = {...payload, startAge};
        }
        if (endAge) {
            payload = {...payload, endAge};
        }
        if (filterData.gender) {
            payload = {...payload, gender: filterData.gender};
        }
        if (filterData.country) {
            payload = {...payload, country: filterData.country.toLocaleLowerCase()};
        }
        if (filterData.region) {
            payload = {...payload, region: filterData.region.toLocaleLowerCase()};
        }

        const talentCategoryId = filterData.talentCategory;

        if (match === '/feeds') {
            await props.fetchFeeds(
                true,
                'home',
                talentCategoryId,
                props.history,
                payload
            );
        } else {
            await props.fetchFeeds(
                true,
                'discover',
                talentCategoryId,
                props.history,
                payload
            );
        }
    };

    const handleInputChange = (name, value) => {
        setFilterData({
            ...filterData,
            [name]: value,
        });
    };

    const handleCountryChange = (e) => {
        setFilterData({
            ...filterData,
            country: e.target.value,
        });

        setRegionList(countriesList.filter((i) => i.name === e.target.value)[0].states);
    };

    const renderSelectCategory = () => {
        const {talentCategories} = props;
        return talentCategories.map((c, i) => (
            <option key={`category-${i}`} value={c.talentCategoryId}>
                {capitalizeFirstLetter(c.name)}
            </option>
        ));
    };

    const renderAgeRange = () => {
        const ages = ['0-14', '15-24', '25-35', '36-45', '46-55', '55-above'];
        let options = ages.map((a, i) => (
            <option key={`age-${i}`} value={a}>
                {a}
            </option>
        ));
        const defaultHandle = [
            <option key="age-0" value="">
                --Select--
            </option>,
        ];

        options = [...defaultHandle, options];

        return options;
    };

    const renderCountries = () => {
        let options = countriesList.map((country, i) => (
            <option key={`country-${i}`} value={country.name}>
                {country.name}
            </option>
        ));
        const defaultHandle = [
            <option key="country-0" value="">
                --Select--
            </option>,
        ];

        options = [...defaultHandle, options];

        return options;
    };

    const renderRegions = () => {
        let options = regionList.map((region, i) => (
            <option key={`region-${i}`} value={region.name}>
                {region.name}
            </option>
        ));
        const defaultHandle = [
            <option key="region-0" value="">
                --Select--
            </option>,
        ];

        options = [...defaultHandle, options];

        return options;
    };

    return (
        <section className="cat-block">
            <div>
                <h3 className="blur">Let's search</h3>
                <hr />
                {props.feedLoading ? (
                    <section
                        className="item"
                        style={{fontFamily: '"Work Sans",sans-serif'}}
                    >
                        Loading...
                    </section>
                ) : props.categories && props.categories.length === 0 ? (
                    <section
                        className="item"
                        style={{fontFamily: '"Work Sans",sans-serif'}}
                    >
                        No Categories
                    </section>
                ) : (
                    <>
                        <form onSubmit={handleCategoryFilter}>
                            <section className="item">
                                <label htmlFor="category">Talent Category</label>
                                <select
                                    className="form-control custom-select"
                                    name="category"
                                    onChange={(e) =>
                                        handleInputChange(
                                            'talentCategory',
                                            e.target.value
                                        )
                                    }
                                    value={filterData.talentCategory}
                                >
                                    {renderSelectCategory()}
                                </select>
                            </section>
                            <section className="item">
                                <label htmlFor="">Age Range</label>
                                <select
                                    className="form-control custom-select"
                                    name="age"
                                    onChange={(e) =>
                                        handleInputChange('ageRange', e.target.value)
                                    }
                                    value={filterData.ageRange}
                                >
                                    {renderAgeRange()}
                                </select>
                            </section>
                            <section className="item">
                                <label htmlFor="category">Country</label>
                                <select
                                    className="form-control custom-select"
                                    name="country"
                                    onChange={handleCountryChange}
                                    value={filterData.country}
                                >
                                    {renderCountries()}
                                </select>
                            </section>
                            <section className="item">
                                <label htmlFor="category">State</label>
                                <select
                                    className="form-control custom-select"
                                    name="category"
                                    onChange={(e) =>
                                        handleInputChange('region', e.target.value)
                                    }
                                    value={filterData.region}
                                >
                                    {renderRegions()}
                                </select>
                            </section>
                            <section className="item">
                                <label htmlFor="category">Gender</label>
                                <div className="row">
                                    <div className="col-4">
                                        <div className="input checker">
                                            <input
                                                type="radio"
                                                id="gender"
                                                value="all"
                                                name="gender"
                                                onChange={(e) =>
                                                    handleInputChange('gender', '')
                                                }
                                            />
                                            <label className="ml-1">Both </label>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="input checker">
                                            <input
                                                type="radio"
                                                id="gender"
                                                value="male"
                                                name="gender"
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        'gender',
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <label className="ml-1">Male </label>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="input checker">
                                            <input
                                                type="radio"
                                                id="gender"
                                                value="female"
                                                name="gender"
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        'gender',
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <label className="ml-1">Female </label>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="item">
                                <button
                                    type="submit"
                                    className="btn btn-block btn-primary"
                                >
                                    Make Search
                                </button>
                            </section>
                        </form>
                    </>
                )}
            </div>
        </section>
    );
};

const BannerSection = () => {
    return (
        <section className="ads">
            {rightSideBar.ads.map((ad) => {
                return (
                    <div key={ad.banner}>
                        <img src={ad.banner} alt={ad.alt} />
                    </div>
                );
            })}
        </section>
    );
};

const RightSideBar = (props) => {
    return (
        <div className="col-lg-4">
            <div className="rightbar">
                <CategoriesSection
                    categories={props.categories}
                    fetchFeeds={props.fetchFeeds}
                    match={props.match}
                    feedLoading={props.feedLoading}
                    getAllTalentCategories={props.getAllTalentCategories}
                    talentCategories={props.talentCategories}
                />
                <BannerSection />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        feedLoading: state.feeds.feedLoading,
        talentCategories: state.talentCategories.talentCategories,
    };
};

export default withRouter(connect(mapStateToProps, actions)(RightSideBar));
