/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react';
import XIcon from '../../../assets/img/svg/x.svg';
import * as actions from '../../../redux/actions';
import {connect} from 'react-redux';
import useAlert from '../../CustomHooks/useAlert';
import CheckMark from '../../../assets/img/svg/success-checkmark.svg';

const DeleteChat = (props) => {
    const [loading, setLoading] = useState(false);
    const [isClear, setIsClear] = useState(false);

    const handleClearChat = async (e) => {
        setLoading(true);
        await props.deleteChatHistory(props.userId);
        props.handleDeleteChatSocket();
        setLoading(false);
    };

    useEffect(() => {
        setIsClear(props.isClear);
    }, [props.isClear]);
    // Alert message if error occur on fetch
    useAlert(props.alert);
    return (
        <div className="modal fade" id="deleteChat" role="dialog">
            <div className="modal-dialog modal-wrapper">
                <div className=" modal-content-wrap">
                    <div className="header-wrap">
                        <div className="close_">
                            <label data-dismiss="modal">
                                <img src={XIcon} alt="" />
                                <span>Close</span>
                            </label>
                        </div>
                        <div>
                            <p>Delete Conversation</p>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="container wrapr">
                            <section className="delete-chat action-list">
                                {isClear ? (
                                    <div className="text-center success animated fadeIn">
                                        <div>
                                            <img src={CheckMark} alt="" />
                                        </div>
                                        <p className="text-success mt-3">Chat Cleared!</p>
                                        <a
                                            data-dismiss="modal"
                                            onClick={() => setIsClear(false)}
                                        >
                                            Close
                                        </a>
                                    </div>
                                ) : (
                                    <>
                                        <h3>Delete this conversation ?</h3>
                                        <p>
                                            This conversation will be deleted from your
                                            inbox , you wont be able to see this any more
                                        </p>

                                        <div className="d-flex justify-content-between">
                                            <button
                                                className="btn btn-secondary cancel"
                                                data-dismiss="modal"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="button"
                                                onClick={handleClearChat}
                                                className="btn del"
                                                disabled={loading}
                                            >
                                                <div>
                                                    <svg
                                                        width="13"
                                                        height="16"
                                                        viewBox="0 0 13 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M11.9261 3.82812H11.2419L10.1089 14.1111C10.0349 14.7849 9.45426 15.3125 8.78765 15.3125H3.76614C3.09828 15.3125 2.51892 14.7842 2.44548 14.1098L1.3307 3.82812H0.62769C0.281205 3.82812 0 3.54229 0 3.1901C0 2.83792 0.281205 2.55208 0.62769 2.55208H3.76614V1.91406C3.76614 0.858776 4.61101 0 5.64921 0H6.90459C7.94278 0 8.78765 0.858776 8.78765 1.91406V2.55208H11.9261C12.2726 2.55208 12.5538 2.83792 12.5538 3.1901C12.5538 3.54229 12.2726 3.82812 11.9261 3.82812ZM7.53228 1.91406C7.53228 1.56251 7.25044 1.27604 6.90459 1.27604H5.64921C5.30335 1.27604 5.02152 1.56251 5.02152 1.91406V2.55208H7.53228V1.91406ZM2.59361 3.82812L3.69395 13.9707C3.69646 13.9956 3.74103 14.0365 3.76614 14.0365H8.78765C8.81276 14.0365 8.85858 13.995 8.86172 13.9695L9.97901 3.82812H2.59361Z"
                                                            fill="#fff"
                                                        ></path>
                                                    </svg>
                                                    {loading ? 'Deleting...' : 'Delete'}
                                                </div>
                                            </button>
                                        </div>
                                    </>
                                )}
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isClear: state.message.isClear,
        alert: state.alert,
    };
};

export default connect(mapStateToProps, actions)(DeleteChat);
