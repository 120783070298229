import React, {useCallback, useEffect, useState} from 'react';
import $ from 'jquery';
import Hamburger from '../../assets/img/svg/hamburg_.svg';
import Logo from '../../assets/img/svg/logo.svg';
import SearchIcon from '../../assets/img/svg/search-icon.svg';
import MessageIcon from '../../assets/img/svg/msg-icon.svg';
import NotificationIcon from '../../assets/img/svg/notification-icon.svg';
import {Link, withRouter} from 'react-router-dom';
import SearchListBox from '../Blocks/SearchListBox';
import {connect} from 'react-redux';
import * as actions from '../../redux/actions';
// import VerifyPhone from "../VerifyPhone";
import MainLayoutHeader from '../../components/layout/MainLayout/Header';
import store from 'store';

const Header = (props) => {
    const user = store.get('kmt-user') || null;
    const [searchEnabled, setSearchEnabled] = useState(false);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    // Sidebar toggling on mobile
    const handleMenuTrigger = () => {
        $('.feeds #sidebar').toggleClass('show');
        $('#wrapper').toggleClass('full');
    };

    // Handle Search
    const handleSearch = async (e) => {
        setLoading(true);
        setSearch(e.target.value);
        setSearchEnabled(true);

        await props.fetchUsersOnSearch(e.target.value, 1, 10, props.history);

        setLoading(false);
    };

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            setSearch('');
            setSearchEnabled(false);
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);

        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Redirect user and remove overlay
    const handleRedirectToProfileOnClickUser = (userId) => {
        setSearch('');
        setSearchEnabled(false);
        setLoading(false);

        props.history.push(`/profile/${userId}`);
    };

    // Redirect to show all search result page
    const handleShowAllResult = () => {
        props.history.push(`/search/results/?keyword=${search}`);
    };

    return (
        <>
            {user ? (
                <React.Fragment>
                    <header>
                        <div className="container">
                            <div
                                className="menu_trigger"
                                onClick={() => handleMenuTrigger()}
                            >
                                <img src={Hamburger} alt="hamburger" />
                            </div>
                            <div className="left">
                                <div className="icon">
                                    <Link to="/feeds">
                                        <img src={Logo} alt="logo" />
                                    </Link>
                                </div>
                                <form>
                                    <div className="input search">
                                        <i className="tag">
                                            <img src={SearchIcon} alt="search..." />
                                        </i>
                                        <input
                                            type="text"
                                            name="search"
                                            value={search}
                                            placeholder="Search"
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className="right">
                                <div>
                                    <Link to="/messages">
                                        <img src={MessageIcon} alt="message" />
                                    </Link>
                                </div>
                                <div>
                                    <Link to="/notifications">
                                        <img src={NotificationIcon} alt="notification" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/*<VerifyPhone />*/}
                    </header>
                    {searchEnabled && (
                        <SearchListBox
                            loading={loading}
                            handleRedirectToProfileOnClickUser={
                                handleRedirectToProfileOnClickUser
                            }
                            handleShowAllResult={handleShowAllResult}
                        />
                    )}
                </React.Fragment>
            ) : (
                <MainLayoutHeader />
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {};
};

export default withRouter(connect(mapStateToProps, actions)(Header));
