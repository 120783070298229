import React from 'react';
import LeftViewOnAuthPages from '../../components/Blocks/LeftViewOnAuthPages';
import RegistrationDefault from '../../components/layout/RegistrationDefault';
import {displayLoader} from '../../utils/displayLoader';
import SuccessImg from '../../assets/img/svg/success-checkmark.svg';
const CheckEmailSuccessMessage = () => {
    return (
        <>
            <RegistrationDefault
                pageTitle={'Signup Here!'}
                linkName={'Login'}
                linkUrl={'/login'}
                linkStyle={'adj'}
            />
            <main className="account">
                <LeftViewOnAuthPages step={1} />
                <section className="right-pad">
                    {displayLoader(
                        'Check your email',
                        'An email has been sent to the account used in registering. Kindly check to activate your account. Thanks',
                        `${SuccessImg}`
                    )}
                </section>
            </main>
        </>
    );
};

export default CheckEmailSuccessMessage;
