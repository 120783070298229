import React from 'react';
import Shutter4 from '../../assets/img/png/shutter 4.png';
import DotPattern from '../../assets/img/svg/dot-pattern.svg';

const UnsignedInAuditionIntro = () => {
    return (
        <section className="intro">
            <div className="tier_one">
                <div className="container">
                    <div className="h3ro">
                        <h1>Fast-track your rise to the very top!</h1>
                        <p>
                            With MySpotlyt Aweditions, there are unique opportunities to
                            showcase your talent directly to some of the biggest scouts in
                            your field. Check for upcoming Aweditions here.
                        </p>
                    </div>
                    <div className="deco">
                        <img src={Shutter4} alt="" />
                        <img className="pattern" src={DotPattern} alt="" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default UnsignedInAuditionIntro;
