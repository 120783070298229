import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';
import LinkIcon from '../../../assets/img/svg/link-icon.svg';

//Privacy Settings
const PrivacySettings = (props) => {
    const [loading, setLoading] = useState(false);

    const [privacySettings, setPrivacySettings] = useState({
        showReadReceipt: false,
        makeAccountPrivate: false,
        showLocation: false,
    });

    useEffect(() => {
        fetchPrivacySetting();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //fetch notification & alert settings
    const fetchPrivacySetting = async () => {
        setLoading(true);
        await props.fetchPrivacySettings(props.history);
        setLoading(false);
    };

    // componentDidUpdate
    useEffect(() => {
        if (props.privacySettings) setPrivacySettings(props.privacySettings);
    }, [props.privacySettings]);

    // Handle switch changes
    const handleCheckChange = async (name, value) => {
        setPrivacySettings({
            ...privacySettings,
            [name]: value,
        });

        switch (name) {
            case 'showReadReceipt':
                if (privacySettings.showReadReceipt) {
                    props.showOrHideReadReceipt(props.history, false);
                } else {
                    props.showOrHideReadReceipt(props.history, true);
                }
                break;

            case 'makeAccountPrivate':
                if (privacySettings.makeAccountPrivate) {
                    props.toggleMakeAccountPrivate(props.history, false);
                } else {
                    props.toggleMakeAccountPrivate(props.history, true);
                }
                break;

            case 'showLocation':
                if (privacySettings.showLocation) {
                    props.showOrHideLocation(props.history, false);
                } else {
                    props.showOrHideLocation(props.history, true);
                }
                break;

            default:
                return name;
        }
    };

    const renderLoader = () => {
        return (
            <div className="loading">
                <div className="lds-ripple">
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    };

    return (
        <>
            {loading ? (
                renderLoader()
            ) : (
                <div className="setting-sect active" id="setting-privacy">
                    <h5>
                        Privacy & Safety{' '}
                        <small>A short description to help understand title...</small>
                    </h5>
                    <form className="options">
                        <div className="item">
                            <div>
                                <strong>Make Account Private</strong>
                                <p>
                                    When account is private others cant send you a message
                                </p>
                            </div>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={privacySettings.makeAccountPrivate}
                                    name="makeAccountPrivate"
                                    onChange={() =>
                                        handleCheckChange(
                                            'makeAccountPrivate',
                                            !privacySettings.makeAccountPrivate
                                        )
                                    }
                                />
                                <div className="slider round"></div>
                            </label>
                        </div>
                        <hr />
                        <div className="item">
                            <div>
                                <strong>Show Read Recipent on chat</strong>
                                <p>You can allow or disable comments on your post</p>
                            </div>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={privacySettings.showReadReceipt}
                                    name="showReadReceipt"
                                    onChange={() =>
                                        handleCheckChange(
                                            'showReadReceipt',
                                            !privacySettings.showReadReceipt
                                        )
                                    }
                                />
                                <div className="slider round"></div>
                            </label>
                        </div>
                        <hr />
                        <div className="item">
                            <div>
                                <strong>Show My Location</strong>
                                <p>
                                    You can allow or disable showing your location on your
                                    post
                                </p>
                            </div>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={privacySettings.showLocation}
                                    name="showLocation"
                                    onChange={() =>
                                        handleCheckChange(
                                            'showLocation',
                                            !privacySettings.showLocation
                                        )
                                    }
                                />
                                <div className="slider round"></div>
                            </label>
                        </div>
                        <hr />
                        <div className="item">
                            <div>
                                <strong>Privacy Policy</strong>
                                <p>You can allow or disable comments on your post</p>
                            </div>
                            <img src={LinkIcon} alt="" />
                        </div>
                        <hr />
                        <div className="item">
                            <div>
                                <strong>Terms & Conditionsy</strong>
                                <p>You can allow or disable comments on your post</p>
                            </div>
                            <img src={LinkIcon} alt="" />
                        </div>
                    </form>
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        privacySettings: state.settings.privacySettings,
    };
};
export default connect(mapStateToProps, actions)(PrivacySettings);
