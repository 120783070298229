import React from 'react';
import {withRouter} from 'react-router-dom';

const PostBody = (props) => {
    return (
        <>
            {props.sharedPost ? (
                <div
                    onClick={() =>
                        props.location.pathname === '/talents' ||
                        props.location.pathname === '/scouts' ||
                        props.location.pathname.length > 25
                            ? ''
                            : props.history.push(`/posts/${props.postId}`)
                    }
                >
                    <span
                        onClick={() =>
                            props.location.pathname === '/talents' ||
                            props.location.pathname === '/scouts'
                                ? ''
                                : props.history.push(`/profile/${props.userId}`)
                        }
                    >
                        {props.fullName}
                    </span>
                    {props.body}
                    <p className="custom-feedSharedBody">{props.sharedPost.body}</p>
                </div>
            ) : (
                <p
                    onClick={() =>
                        props.location.pathname === '/talents' ||
                        props.location.pathname === '/scouts' ||
                        props.location.pathname.length > 25
                            ? ''
                            : props.history.push(`/posts/${props.postId}`)
                    }
                >
                    <span
                        onClick={() =>
                            props.location.pathname === '/talents'
                                ? ''
                                : props.history.push(`/profile/${props.userId}`)
                        }
                    >
                        {props.fullName}
                    </span>{' '}
                    {props.body}
                </p>
            )}
        </>
    );
};

export default withRouter(PostBody);
