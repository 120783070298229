import React, {useEffect, useState} from 'react';
import Marquee from 'react-fast-marquee';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../redux/actions';
import PlayIcon from '../../assets/img/svg/play-icon.svg';
import PhotoIcon from '../../assets/img/svg/photo-file-icon.svg';
import XIcon from '../../assets/img/svg/x.svg';
import $ from 'jquery';
import {getMediaType} from '../../utils/global';

const PostMediaModal = ({post}) => {
    return (
        <div className="plublic">
            <div className="modal fade" id="applicantVideo" role="dialog">
                <div className="modal-dialog modal-wrapper">
                    <div className=" modal-content-wrap">
                        <div className="header-wrap">
                            <div className="close_">
                                <label data-dismiss="modal">
                                    <img src={XIcon} alt="" />
                                    <span>Close</span>
                                </label>
                            </div>
                            <div>
                                <p>By {post.name}</p>
                            </div>
                        </div>
                        <div className="modal-body">
                            {post.isVideo  ? (
                                <video width="470" height="260" controls>
                                    <source src={post.mediaUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <div className="modal-image-content">
                                    <img src={post.mediaUrl} alt="" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const TalentCategoriesSlider = (props) => {
    const [playMarque, setPlayMarque] = useState(true);

    const [mediaMetadata, setMediaMetadata] = useState({
        mediaUrl: '',
        name: '',
        isVideo: false,
    });

    useEffect(() => {
        props.fetchRecentMediaFeeds();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleShowMedia = (post) => {
        setMediaMetadata({
            mediaUrl: post.mediaUrl,
            name: post.name,
            isVideo: post.isVideo,
        });
        setPlayMarque(false);

        // Display the modal
        $('#applicantVideo')
            .modal('show')
            .on('hidden.bs.modal', function () {
                setMediaMetadata({
                    mediaUrl: '',
                    name: '',
                    isVideo: false,
                });
                setPlayMarque(true);
            });
    };

    const renderVideoMedia = (post) => {
        return (
            <div
                className="list"
                key={post.postId}
                onClick={() =>
                    handleShowMedia({
                        mediaUrl: post.mediaUrl,
                        name: post.creatorName,
                        isVideo: true,
                    })
                }
            >
                <div className="card video-content">
                    <video muted>
                        <source src={post.mediaUrl} type="video/mp4" />
                    </video>
                    <div className="player_">
                        <img src={PlayIcon} alt="play" />
                    </div>
                    <div className="artist text-capitalize">
                        <span
                            className="placeholder-img"
                            style={{
                                background: `#000000d9 url(${post.creatorImage}) no-repeat center`,
                            }}
                        />
                        <label>By {post.creatorName}</label>
                    </div>
                </div>
            </div>
        );
    };
    const renderImageMedia = (post) => {
        return (
            <div
                className="list"
                key={post.postId}
                onClick={() =>
                    handleShowMedia({
                        mediaUrl: post.mediaUrl,
                        name: post.creatorName,
                        isVideo: false,
                    })
                }
            >
                <div
                    className="card"
                    style={{
                        backgroundImage: `url(${post.mediaUrl})`,
                    }}
                >
                    <div className="player_">
                        <img src={PhotoIcon} alt="view" />
                    </div>
                    <div className="artist text-capitalize">
                        <span
                            className="placeholder-img"
                            style={{
                                background: `#000000d9 url(${post.creatorImage}) no-repeat center`,
                            }}
                        />
                        <label>By {post.creatorName}</label>
                    </div>
                </div>
            </div>
        );
    };

    const renderPostMedia = (post) => {
        const mediaType = getMediaType(post.mediaUrl);

        if (mediaType === 'mp4') {
            return renderVideoMedia(post);
        } else {
            return renderImageMedia(post);
        }
    };

    const marqueeProps = {
        pauseOnHover: true,
        speed: 20,
    };

    return (
        <section className="media-list posts-slide">
            <Marquee {...marqueeProps} play={playMarque}>
                {props.recentMediaFeeds.map((post) => renderPostMedia(post))}
            </Marquee>

            <PostMediaModal post={mediaMetadata} />
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        feedLoading: state.feeds.feedLoading,
        recentMediaFeeds: state.feeds.recentMediaFeeds,
    };
};

export default withRouter(connect(mapStateToProps, actions)(TalentCategoriesSlider));
