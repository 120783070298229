import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useHistory, withRouter} from 'react-router-dom';
import LeftSideBar from '../../../components/Feeds/LeftSideBar';
import Header from '../../../components/Feeds/Header';
import Footer from '../../../components/Feeds/Footer';
import $ from 'jquery';
import {connect} from 'react-redux';
import * as action from '../../../redux/actions';
import ProfileImg from '../../../assets/img/svg/user.svg';
import XIcon from '../../../assets/img/svg/x.svg';
import {stringFirstUpperCase} from '../../../utils/stringFirstsUppercase';
import Pagination from '../../../components/Pagination/Pagination';
import Select from 'react-select';
import {countries_states} from '../../../utils/countries_states';
import Dropzone from 'react-dropzone';
import FileAdd from '../../../assets/img/svg/file-add-icon.svg';
import CrossIcon from '../../../assets/img/svg/cross.svg';
import PlayIcon from '../../../assets/img/svg/play_icon.svg';
import {validateAll} from 'indicative/validator';
import {createNewAuditionEntryRequest} from '../../../redux/actions';
import Modal from '../../../components/Modal';

const initialTalentEntry = {
    fullName: '',
    email: '',
    phone: '',
    gender: '',
    country: '',
    region: '',
    description: '',
    file: null,
    agreement: true,
};
const TalentEntryModal = ({auditionId}) => {
    const [formData, setFormData] = useState(initialTalentEntry);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState('');
    const [apiResponse, setApiResponse] = useState('');
    const [apiResponseError, setApiResponseError] = useState(false);
    const [fileSizeError, setFileSizeError] = useState(null);
    const [regionList, setRegionList] = useState([]);
    const [isVisible, setIsVisible] = useState(false);

    const history = useHistory();

    const rules = {
        fullName: 'required',
        email: 'required|email|validemail',
        file: 'required',
    };

    const messages = {
        'fullName.required': 'Name is required',
        'email.required': 'Enter email address',
        'email.validemail': 'Enter valid email address',
        'file.required': 'Upload a valid video',
    };

    const createNewAuditionEntry = async () => {
        setLoading(true);
        const file = new FormData();
        file.append('file', formData.file);
        file.set('fullName', formData.fullName);
        file.set('email', formData.email);
        file.set('phone', formData.phone);
        file.set('gender', formData.gender);
        file.set('country', formData.country.value);
        file.set('region', formData.region.value);
        file.set('description', formData.description);
        file.set('agreement', formData.agreement);

        await createNewAuditionEntryRequest(file, auditionId)
            .then(() => {
                setLoading(false);
                setApiResponse('Audition entry uploaded successfully');
                setApiResponseError(false);

                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        history.push('/login');
                    }
                }

                setApiResponse(error.response.data.message);
                setApiResponseError(true);
                setLoading(false);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        validateAll(formData, rules, messages)
            .then(() => {
                createNewAuditionEntry();
            })
            .catch((errors) => {
                const formattedErrors = {};
                errors.forEach((error) => (formattedErrors[error.field] = error.message));
                setErrors(formattedErrors);
            });
    };

    const handleInputChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleErrorOnFocus = (name) => {
        setErrors({
            ...errors,
            [name]: '',
        });
    };

    const handleCountryChange = (e) => {
        setFormData({
            ...formData,
            country: e,
        });

        if (e) {
            const country_states = countries_states.find((i) => i.name === e.value);
            setRegionList(country_states ? country_states.states : []);
        } else {
            setRegionList([]);
        }
    };

    const renderGenderOptions = () => {
        const options = [
            {
                label: 'Male',
                value: 'male',
            },
            {
                label: 'Female',
                value: 'female',
            },
        ];

        return (
            <div className="input bordered categories-select">
                <Select
                    name="gender"
                    placeholder={'Select gender'}
                    className="select"
                    onChange={(e) => handleInputChange('gender', e)}
                    onFocus={() => handleErrorOnFocus('gender')}
                    value={formData.gender}
                    options={options}
                    isClearable
                />
                <small className="text-danger">{errors.gender}</small>
            </div>
        );
    };

    const renderCountries = () => {
        const options = countries_states.map((c) => ({
            label: c.name,
            value: c.name,
        }));

        return (
            <div className="input bordered categories-select">
                <Select
                    name="country"
                    placeholder="Select Country"
                    className="select"
                    onChange={handleCountryChange}
                    onFocus={() => handleErrorOnFocus('country')}
                    options={options}
                    isClearable
                    value={formData.country}
                />
                <small className="text-danger">{errors.country}</small>
            </div>
        );
    };
    const renderRegions = () => {
        const options = regionList.map((c) => ({
            label: c.name,
            value: c.name,
        }));

        return (
            <div className="input bordered categories-select">
                <Select
                    name="region"
                    placeholder="Select State/City"
                    className="select"
                    onChange={(e) => handleInputChange('region', e)}
                    onFocus={() => handleErrorOnFocus('region')}
                    options={options}
                    isClearable
                    value={formData.region}
                />
                <small className="text-danger">{errors.region}</small>
            </div>
        );
    };

    const handleDrop = (accepted, rejected) => {
        if (accepted[0]) {
            // limit to 15mb => 15728640
            if (accepted[0].size <= 15728640) {
                handleInputChange('file', accepted[0]);

                $('.aud_vid').html('Upload Successful!!!');
                $('.upload-message').html(accepted[0].name);
                $('figure div.section').addClass('show');
                $('figure div.section .file_type .progress span').css({
                    transition: '1.5s ease-in',
                    width: '100%',
                });
                $('.btn-primary').attr('disabled', false);
            } else {
                setFileSizeError('File size is too big. (Maximum filesize is 15MB');
            }
        } else {
            $('.aud_vid').html('Upload Failed!!! Try again');
        }
    };

    const resetForm = () => {
        setApiResponse('');
        setApiResponseError(false);
        setErrors('');
        setFormData(initialTalentEntry);
        setRegionList([]);
        setIsVisible(false);
    };

    return (
        <React.Fragment>
            <button className="btn btn-primary" onClick={() => setIsVisible(true)}>
                Add Applicant
            </button>
            <Modal
                title="Talent Entry"
                wrapperStyles={{maxWidth: '800px'}}
                modalDismissedHandler={resetForm}
                isVisible={isVisible}
                modalIdentifier="talentEnlistModal"
            >
                <div className="talent-entry-form">
                    <form onSubmit={handleSubmit}>
                        <p className="text-center mb-3">
                            Please provide information about the talent entry below
                        </p>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="input bordered">
                                    <input
                                        type="text"
                                        name="fullName"
                                        value={formData.fullName}
                                        onChange={(e) =>
                                            handleInputChange('fullName', e.target.value)
                                        }
                                        onFocus={() => handleErrorOnFocus('fullName')}
                                        placeholder="Talent full name"
                                    />
                                    <label>Full Name</label>
                                    <small className="text-danger">
                                        {errors.fullName}
                                    </small>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="input bordered">
                                    <input
                                        type="text"
                                        name="email"
                                        value={formData.email}
                                        onChange={(e) =>
                                            handleInputChange('email', e.target.value)
                                        }
                                        onFocus={() => handleErrorOnFocus('email')}
                                        placeholder="Email address"
                                    />
                                    <label>Email address</label>
                                    <small className="text-danger">{errors.email}</small>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="input bordered">
                                    <input
                                        type="text"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={(e) =>
                                            handleInputChange('phone', e.target.value)
                                        }
                                        onFocus={() => handleErrorOnFocus('phone')}
                                        placeholder="Phone number"
                                    />
                                    <label>Phone</label>
                                    <small className="text-danger">{errors.phone}</small>
                                </div>
                            </div>
                            <div className="col-lg-6">{renderGenderOptions()}</div>
                            <div className="col-lg-6">{renderCountries()}</div>
                            <div className="col-lg-6">{renderRegions()}</div>
                            <div className="col-lg-12">
                                <div className="input">
                                    <textarea
                                        id=""
                                        cols="30"
                                        rows="2"
                                        name="description"
                                        value={formData.description}
                                        onChange={(e) =>
                                            handleInputChange(
                                                'description',
                                                e.target.value
                                            )
                                        }
                                        onFocus={() => handleErrorOnFocus('description')}
                                        placeholder="Description"
                                    />
                                    <small className="text-danger">
                                        {errors.description}
                                    </small>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <Dropzone onDrop={handleDrop} accept="video/mp4,image/*">
                                    {({getRootProps, getInputProps}) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <figure>
                                                <p className="aud_vid">
                                                    <button type="button">
                                                        <img src={FileAdd} alt="" />
                                                        Add file
                                                    </button>
                                                    or drop file here
                                                </p>

                                                <div className="section animated bounceIn upload-box">
                                                    <aside className="">
                                                        <img src={CrossIcon} alt="" />
                                                    </aside>
                                                    <div>
                                                        <img src={PlayIcon} alt="" />
                                                    </div>
                                                    <div className="file_type">
                                                        <p className="upload-message"></p>
                                                        <div className="progress">
                                                            <span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </figure>
                                        </div>
                                    )}
                                </Dropzone>
                                <small className="text-danger">
                                    {errors.file || fileSizeError}
                                </small>
                            </div>
                        </div>

                        <div className="action d-inline-flex align-items-center">
                            <button
                                type="submit"
                                className="btn btn-primary mt-3 mr-3"
                                disabled={loading ? true : false}
                            >
                                {loading ? 'Sending ...' : 'Create Entry'}
                            </button>
                            <p
                                className={`pt-2 ${
                                    apiResponseError ? 'text-danger' : 'text-success'
                                }`}
                            >
                                {apiResponse}
                            </p>
                        </div>
                    </form>
                </div>
            </Modal>
        </React.Fragment>
    );
};

const Head = ({auditionTitle, auditionId}) => {
    return (
        <div className="head">
            <h3>{auditionTitle}</h3>

            <TalentEntryModal auditionId={auditionId} />
        </div>
    );
};

const ApplicantList = (props) => {
    const {applicants} = props;
    return (
        <section className="applicants-list">
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Applicant</th>
                            <th>Talent</th>
                            <th>Votes</th>
                            <th>Audition Video</th>
                        </tr>
                    </thead>
                    <tbody>
                        {applicants &&
                            applicants.map((applicant) => (
                                <tr key={applicant.auditionEntryId}>
                                    <td>
                                        <div
                                            className="d-flex align-items-center"
                                            onClick={() =>
                                                props.history.push(
                                                    `/profile/${
                                                        applicant.applicant &&
                                                        applicant.applicant.id
                                                    }`
                                                )
                                            }
                                        >
                                            <label
                                                style={{
                                                    width: '3rem',
                                                    height: '3rem',
                                                    borderRadius: '50%',
                                                    backgroundImage: `url(${
                                                        applicant.applicant.imageUrl ||
                                                        ProfileImg
                                                    })`,
                                                    backgroundSize: 'cover',
                                                    backgroundRepeat: 'no-repeat',
                                                }}
                                            ></label>

                                            <label className="ml-3">
                                                {
                                                    applicant.applicant.fullName ? (
                                                        applicant.applicant.fullName.length > 27
                                                            ? stringFirstUpperCase(
                                                            applicant.applicant.fullName
                                                        ) + '...'
                                                            : stringFirstUpperCase(
                                                                applicant.applicant.fullName
                                                            )
                                                    ) : ""
                                                }
                                            </label>
                                        </div>
                                    </td>
                                    <td>
                                        <label className="mt-1">
                                            {applicant.applicant.talentCategories
                                                .map((t) => t.name)
                                                .toString()
                                                .slice(0, 30)}
                                            ...
                                        </label>
                                    </td>
                                    <td>
                                        <label className="mt-1">
                                            {parseFloat(applicant.votes).toFixed(1)}%
                                            Votes
                                        </label>
                                    </td>
                                    <td>
                                        <label
                                            className="btn-flat"
                                            onClick={() =>
                                                props.displayApplicantVideo(
                                                    applicant.applicant.fullName,
                                                    applicant.mediaUrl
                                                )
                                            }
                                        >
                                            Watch Video
                                        </label>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </section>
    );
};

const ApplicantVideoModal = ({displayVideoModal}) => {
    const [videoData, setVideoData] = useState({});

    useEffect(() => {
        setVideoData(displayVideoModal);
    }, [displayVideoModal]);

    return (
        <div className="modal fade" id="applicantVideo" role="dialog">
            <div className="modal-dialog modal-wrapper">
                <div className=" modal-content-wrap">
                    <div className="header-wrap">
                        <div className="close_" onClick={() => setVideoData({})}>
                            <label data-dismiss="modal">
                                <img src={XIcon} alt="" />
                                <span>Close</span>
                            </label>
                        </div>
                        <div>
                            <p>{videoData.applicantName}'s Video</p>
                        </div>
                    </div>
                    <div className="modal-body media-player">
                        {videoData.applicantVideo && (
                            <video muted width="470" height="260" controls>
                                <source src={videoData.applicantVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

// MAIN COMPONENT
const ViewApplicants = (props) => {
    const route = props.match.params.slug;

    //All applicants
    const {applicants} = props;

    //modal window with video & applicant name
    const [displayVideoModal, setDisplayVideoModal] = useState({
        applicantName: '',
        applicantVideo: '',
    });
    const [loading, setLoading] = useState(false);

    const [pagination, setPagination] = useState({
        pageNumber: 1,
        pageSize: 10,
    });

    // Page count
    const pageCount =
        props.totalCount && Math.ceil(props.totalCount / pagination.pageSize);

    useEffect(() => {
        getApplicants();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination]);

    const getApplicants = async () => {
        setLoading(true);
        await props.fetchAuditionApplicants(route, pagination, props.history);
        setLoading(false);
    };

    const handlePaginate = async (data) => {
        // Get page number on click of pagination numbers
        // Note: initial pagination number start from 0, so we add +1
        let selected = parseInt(data.selected) + 1;
        let pageNumber = Math.ceil(selected);

        // Update pagination state
        setPagination({...pagination, pageNumber});
    };

    const displayApplicantVideo = (applicantName, applicantVideo) => {
        setDisplayVideoModal({
            applicantName,
            applicantVideo,
        });
        // Display the modal
        $('#applicantVideo').modal('show');
    };

    const renderLoader = () => {
        return (
            <section className="waiting">
                <div className="lds-ellipsis">
                    <div></div>
                </div>
            </section>
        );
    };

    return (
        <>
            <Helmet>
                <title>Scout | Applicant</title>
            </Helmet>
            <main className="main feeds">
                <LeftSideBar />
                <section id="wrapper">
                    <Header />
                    <div className="container content_">
                        <section className="scouts-audition">
                            <Head
                                auditionTitle={
                                    applicants.auditionInfo &&
                                    applicants.auditionInfo.auditionTitle
                                }
                                auditionId={route}
                            />
                            <ApplicantList
                                loading={loading}
                                applicants={applicants.auditionEntries}
                                displayApplicantVideo={displayApplicantVideo}
                                history={props.history}
                            />
                            {/* Pagination */}
                            <div className="d-flex flex-row justify-content-center">
                                <Pagination
                                    handlePaginate={handlePaginate}
                                    pageCount={pageCount}
                                />
                            </div>
                            {loading && renderLoader()}
                        </section>
                        <Footer />
                    </div>
                    <ApplicantVideoModal displayVideoModal={displayVideoModal} />
                </section>
            </main>
        </>
    );
};

const mapStateToProps = (state) => ({
    applicants: state.audition.applicants,
    totalCount: state.audition.totalCount,
});

export default withRouter(connect(mapStateToProps, action)(ViewApplicants));
