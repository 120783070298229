import React from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = (props) => {
    return (
        <nav aria-label="Page navigation" className="custom-pagination">
            <ReactPaginate
                previousLabel={
                    <>
                        <span className="prev">
                            <svg
                                width="8"
                                height="13"
                                viewBox="0 0 8 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.160156 6.15148C0.160156 6.01151 0.218561 5.87758 0.322279 5.78292L6.32231 0.291409C6.52623 0.105119 6.84242 0.118713 7.0287 0.322121C7.2155 0.525529 7.2014 0.841215 6.99799 1.0275L1.40024 6.15148L6.99799 11.2749C7.2014 11.4607 7.2155 11.7774 7.0287 11.9803C6.84242 12.1837 6.52623 12.1973 6.32231 12.011L0.322279 6.51952C0.218561 6.42487 0.160156 6.29144 0.160156 6.15148Z"
                                    fill="#919AA3"
                                ></path>
                            </svg>{' '}
                        </span>{' '}
                        Previous
                    </>
                }
                nextLabel={
                    <>
                        Next
                        <span className="next">
                            <svg
                                width="8"
                                height="13"
                                viewBox="0 0 8 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M7.15625 6.15126C7.15625 6.29123 7.09785 6.42515 6.99413 6.51981L0.994091 12.0113C0.79018 12.1976 0.473991 12.184 0.287701 11.9806C0.100908 11.7772 0.115006 11.4615 0.318414 11.2752L5.91616 6.15126L0.318414 1.02779C0.115006 0.842004 0.100908 0.525311 0.287701 0.322408C0.473991 0.118999 0.79018 0.105405 0.994091 0.291695L6.99413 5.78321C7.09785 5.87787 7.15625 6.01129 7.15625 6.15126Z"
                                    fill="#919AA3"
                                ></path>
                            </svg>
                        </span>
                    </>
                }
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageLinkClassName={'page-link'}
                pageClassName={'page-item'}
                previousClassName={'page-item prv'}
                previousLinkClassName={'page-lynk'}
                nextClassName={'page-item nxt'}
                nextLinkClassName={'page-lynk'}
                containerClassName={'pagination pagination-circle pg-blue'}
                pageCount={props.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={4}
                onPageChange={props.handlePaginate}
                activeClassName={'active'}
                disabledClassName={'disabled'}
            />
        </nav>
    );
};

export default Pagination;
