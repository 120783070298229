import React from 'react';
import Chats from './Chats';
import TypeMessage from './TypeMessage';
import ChatHeader from './ChatHeader';

const ChatMessages = (props) => {
    return (
        <div className="col-lg-7">
            <div className="card">
                <ChatHeader userInfo={props.userInfo} />

                <Chats
                    messageLoading={props.messageLoading}
                    previousMessages={props.previousMessages}
                    userInfo={props.userInfo}
                    typing={props.typing}
                />

                <TypeMessage
                    text={props.text}
                    handleInputChange={props.handleInputChange}
                    handleKeyUp={props.handleKeyUp}
                    handleSendMessage={props.handleSendMessage}
                    handleMediaUploader={props.handleMediaUploader}
                    uploadedFile={props.uploadedFile}
                />
            </div>
        </div>
    );
};

export default ChatMessages;
