import React from 'react';

import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';

import PanelHead from '../PostBits/PanelHead';
import {TimeSettings} from '../../../utils/TimeSettings';
import PanelImage from '../PostBits/PanelImage';
import PostBody from '../PostBits/PostBody';
import PostComments from '../PostBits/PostComments';
import PostClicks from '../PostBits/PostClicks';
import CreateComment from '../PostBits/CreateComment';
import $ from 'jquery';

const PostDetailsLeftBar = (props) => {
    const {singlePost} = props;
    const refactorTalentCategories = (talentCategories = []) => {
        let talent = [];
        for (var cat of talentCategories) {
            talent.push(cat.name);
        }
        return talent;
    };

    // Handle on click edit post on action dropdown
    const handleEditPostClick = () => {
        $('#editPostModal').modal('show');
    };
    const handleDeletePostClick = () => {
        $('#deletePostModal').modal('show');
    };

    return (
        <div
            // className={`${
            //     singlePost.mediaUrl ||
            //     (singlePost.sharedPost && singlePost.sharedPost.mediaUrl)
            //         ? 'col-md-7'
            //         : 'col-md-10 comment-hidden-sm'
            // }`}
            className="col-md-7"
        >
            <aside>
                {/* <LoadingDetails /> */}
                <section className="content-post animated fadeIn">
                    <div className="panel">
                        <PanelHead
                            userId={singlePost.creatorId}
                            profilePicture={singlePost.creatorImage}
                            statusTag={singlePost.creatorAreaOfInterest}
                            fullName={singlePost.creatorName}
                            timePosted={TimeSettings(singlePost.createdAt)}
                            isFollowing={singlePost.isFollowing}
                            isPostOwner={singlePost.isPostOwner}
                            isPrivate={singlePost.private}
                            requestId={singlePost.requestId}
                            handleEditPostClick={handleEditPostClick}
                            handleDeletePostClick={handleDeletePostClick}
                        />

                        <section className="content alt">
                            {(singlePost.sharedPost && singlePost.sharedPost.mediaUrl) ||
                            singlePost.mediaUrl ? (
                                <PanelImage
                                    sharedPost={singlePost.sharedPost}
                                    postImg={singlePost.mediaUrl}
                                />
                            ) : (
                                <div
                                    className="media"
                                    style={{
                                        background: '#09070a',
                                    }}
                                ></div>
                            )}

                            <div className="media-panel">
                                {/* SMALL DEVICE VIEW ONLY START */}
                                <PostClicks
                                    postId={singlePost.postId}
                                    isLiked={singlePost.isLiked}
                                    totalLikes={singlePost.likersCount || 0}
                                    totalComments={singlePost.commentorsCount}
                                    mediaUrl={singlePost.mediaUrl || null}
                                    sharePostId={singlePost.postId}
                                    body={singlePost.body}
                                    talentCategories={refactorTalentCategories(
                                        singlePost.talentCategories
                                    )}
                                    shareStatus={singlePost.shareStatus || null}
                                />
                                <div className="comment-placeholder">
                                    <section className="post" style={{height: '20px'}}>
                                        <PostBody
                                            fullName={singlePost.creatorName}
                                            body={singlePost.body}
                                            sharedPost={singlePost.sharedPost || null}
                                        />
                                    </section>
                                    <PostComments
                                        isCommentStart={singlePost.isCommentStart}
                                        commentLists={singlePost.comments || []}
                                    />
                                    <hr />
                                    <CreateComment postId={singlePost.postId} />
                                </div>
                                {/* SMALL DEVICE VIEW ONLY END */}
                            </div>
                        </section>
                    </div>
                </section>
            </aside>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        singlePost: state.feeds.singlePost,
    };
};

export default connect(mapStateToProps, actions)(PostDetailsLeftBar);
