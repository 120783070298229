import React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../components/layout/MainLayout/Header';
import Footer from '../components/layout/MainLayout/Footer';
import Caret from '../assets/img/svg/caret-down.svg';
import {scrollTop} from '../utils/scrollTop';

const datas = [
    {
        id: 'headerDetails1',
        questions: {
            tag: 'General questions',
            contents: [
                {
                    content: `No, We do not manage talents nor do we work with 
                    any talent agency in talent recruitment.
                   `,
                    title: `Is MySpotlyt a Talent Agency?`,
                },
                {
                    content: `You are encouraged to post your talents as often 
                    as you can, consider your profile to your online resume. 
                   `,
                    title: `Is there a best time to post? `,
                },
                {
                    content: `As a social media platform for Talents, it is advisable 
                    to post your talents and skills so Scouts/Talent Agencies can easily discover you.
                   `,
                    title: `What Contents I can post?`,
                },
                {
                    content: `Scout/Talent Agency are entitled to include a criteria in their audition announcement.
                   `,
                    title: `Is the Audition Open to all?`,
                },
            ],
        },
    },
    {
        id: 'headerDetails2',
        questions: {
            tag: 'Product',
            contents: [
                {
                    content: `MySpotlyt is a social media platform for Scouts and talent discovery. 
                   `,
                    title: '',
                },
                {
                    content: `With our different categories of talents, Users create their free profiles either 
                    as a Scout or Talent, then select their category of talent/skills, each category is designed with 
                    its Homepage and Audition Page.
                   `,
                    title: '',
                },
                {
                    content: `The Homepage of the talent category is a page where users uploaded images or videos are 
                    seen by every other user in that category.
                   `,
                    title: '',
                },
                {
                    content: `Scouts can announce for an audition at the Audition Page. This audition is seen by every user 
                    in that category and allows them to apply for free by uploading their videos or images in the audition page, 
                    this feature enables Scouts to conduct a convenient screening process. They can either watch all applications or 
                    filter them by sex, age or location of the applicants, this makes their job easier as they save time and money and 
                    have a better opportunity in discovering great talents.
                   `,
                    title: '',
                },
                {
                    content: `These auditions can either be a voting or non-voting type of audition. For the voting type, other Users can 
                    vote for their favorite contestant, and the number of votes each contestant gets can be seen by all.
                   `,
                    title: '',
                },
                {
                    content: `Users can chat, like and share their videos to other social media platforms like Facebook and Twitter.
                   `,
                    title: '',
                },
            ],
        },
    },
    {
        id: 'headerDetails3',
        questions: {
            tag: 'Pricing',
            contents: [
                {
                    content: `MySpotlyt is a Free Platform, users create their profile 
                    for free and also apply for auditions for free.`,
                    title: '',
                },
            ],
        },
    },
];

const Faq = () => {
    scrollTop();

    return (
        <>
            <Helmet>
                <title>Privay Policy</title>
                <script></script>
            </Helmet>
            <Header />
            <main className="main">
                <section className="section t_and_c faq">
                    <div className="container">
                        <div className="heading">
                            <div className="title">
                                <h1>Frequently Asked Questions </h1>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-lg-10 m-auto">
                                    <div className="quest-section">
                                        {datas.map((data, index) => (
                                            <>
                                                <section key={index}>
                                                    <div
                                                        className="faq-sub-header collapsed"
                                                        data-target={`#${data.id}`}
                                                        data-toggle="collapse"
                                                        aria-expanded="false"
                                                        tabindex="-1"
                                                    >
                                                        <figure className="d-flex justify-content-between">
                                                            <h4>{data.questions.tag}</h4>
                                                            <div className="icon">
                                                                <i className="down">
                                                                    <img
                                                                        src={Caret}
                                                                        alt=""
                                                                    />
                                                                </i>
                                                                <i className="up">
                                                                    <img
                                                                        src={Caret}
                                                                        alt=""
                                                                    />
                                                                </i>
                                                            </div>
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="collapse content"
                                                        id={data.id}
                                                    >
                                                        {data.questions.contents.map(
                                                            (data, index) => (
                                                                <>
                                                                    <h6>{data.title}</h6>
                                                                    <p>{data.content}</p>
                                                                </>
                                                            )
                                                        )}
                                                    </div>
                                                </section>
                                                <hr />
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default Faq;
