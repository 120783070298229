import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import LeftSideBar from '../../components/Feeds/LeftSideBar';
import Header from '../../components/Feeds/Header';
import Footer from '../../components/Feeds/Footer';
import SettingsWrapper from '../../components/Feeds/Settings/SettingsWrapper';
import {withRouter} from 'react-router-dom';

const Settings = (props) => {
    useEffect(() => {
        if (props.match.url === '/settings') {
            props.history.push('/settings/update-profile');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Helmet>
                <title>Talents | Settings</title>
            </Helmet>
            <main className="main feeds">
                <LeftSideBar />
                <section id="wrapper">
                    <Header />
                    <div className="container content_">
                        <SettingsWrapper />
                        <Footer />
                    </div>
                </section>
            </main>
        </>
    );
};

export default withRouter(Settings);
