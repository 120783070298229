export const genderData = [
    {value: 'male', label: 'Male'},
    {value: 'female', label: 'Female'},
];

export const countriesData = [
    {value: 'nigeria', label: 'Nigeria'},
    {value: 'ghana', label: 'Ghana'},
];

export const selectCategoriesData = [
    {value: 'dance', label: 'Dance'},
    {value: 'sing', label: 'Sing'},
    {value: 'soccer', label: 'Soccer'},
];
