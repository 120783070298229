import axios from '../../axios/axiosInstance';
import Actions from './actionTypes';
import {alertActions} from './alertActions';
import store from 'store';
import $ from 'jquery';

export const fetchFeeds = (
    shouldRefresh,
    feedsType,
    talentCategory,
    history,
    filter
) => async (dispatch) => {
    dispatch(alertActions.clear());
    dispatch(fetchingStart(true));

    let params = {
        shouldRefresh,
        feedsType,
        talentCategory: talentCategory || undefined,
        ...filter,
    };

    await axios
        .get(`/feeds`, {
            params,
        })
        .then((response) => {
            if (response.status === 200) {
                store.set('userStats', response.data.userInfo);
                // dispatch(alertActions.success(response.data.message));
                dispatch(fetchedFeeds(response.data));
            }
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                dispatch(fetchingStart(false));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

export const fetchRecentMediaFeeds = () => async (dispatch) => {
    dispatch(alertActions.clear());

    await axios
        .get(`/recentMediaFeeds`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(fetchedRecentMediaFeeds(response.data.feeds));
            }
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
            }
        });
};

export const createNewPost = (data, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .post(`/posts`, data)
        .then((response) => {
            dispatch(alertActions.success(response.data.message));
            if (response.status === 200 || response.status === 201) {
                // history.push('/feeds/');
                window.history.go(0);
            }
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

export const updatePost = (postId, data, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .put(`/posts/${postId}`, {
            body: data.body,
            talentCategories: data.talentCategories,
        })
        .then((response) => {
            dispatch(alertActions.success(response.data.message));
            dispatch(updatePostData(data));
            // close modal after updating post
            $('#editPostModal').modal('hide');
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

export const deletePost = (postId, data, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .delete(`/posts/${postId}`)
        .then((response) => {
            dispatch(deletePostData(data));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

export const fetchSinglePost = (postId, history) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .get(`/posts/${postId}`)
        .then((response) => {
            dispatch(fetchSinglePostData(response.data.post));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(fetchSinglePostData({}));
                dispatch(alertActions.error(error.response.data.message));
                if (error.response.status === 403) {
                    history.push('/login');
                }
            }
        });
};

export const followUserRequest = (data) => async (dispatch) => {
    await axios
        .post(`/users/${data.userId}/followRequest`, data)
        .then((response) => {
            dispatch(followRequest(data.userId, true));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(followRequest(data.userId, false));
            }
        });
};

export const cancelFollowUserRequest = (data, requestId) => async (dispatch) => {
    await axios
        .delete(`/removeRequest/${requestId}`)
        .then((response) => {
            dispatch(cancelFollowRequest(data.userId, false));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(cancelFollowRequest(data.userId, true));
            }
        });
};

export const followUsers = (data) => async (dispatch) => {
    await axios
        .post(`/users/${data.userId}/follows`, data)
        .then((response) => {
            dispatch(followStatus(data.userId, true));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(followStatus(data.userId, false));
            }
        });
};

export const unfollowUsers = (data) => async (dispatch) => {
    await axios
        .delete(`/users/${data.userId}/follows`)
        .then((response) => {
            dispatch(followStatus(data.userId, false));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(followStatus(data.userId, true));
            }
        });
};

export const likePost = (data) => async (dispatch) => {
    dispatch(likePostStatus(data.postId, true));
    await axios
        .post(`/posts/${data.postId}/likes`, data)
        .then((response) => {})
        .catch((error) => {
            if (error.response) {
                dispatch(likePostStatus(data.postId, false));
            }
        });
};

export const unlikePost = (data) => async (dispatch) => {
    dispatch(unLikePostStatus(data.postId, false));
    await axios
        .delete(`/posts/${data.postId}/likes`)
        .then((response) => {})
        .catch((error) => {
            if (error.response) {
                dispatch(unLikePostStatus(data.postId, true));
            }
        });
};

export const sharePost = (data) => async (dispatch) => {
    dispatch(alertActions.clear());
    await axios
        .post(`/posts/${data.sharePostId}/share`, data)
        .then((response) => {
            dispatch(alertActions.success(response.data.message));
            dispatch(shareStatus(data.sharePostId, true));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(shareStatus(data.sharePostId, false));
                dispatch(alertActions.error(error.response.data.message));
            }
        });
};

export const justKeepDataToShowOnModalBeforeShare = (data) => (dispatch) => {
    dispatch({type: Actions.KEEP_DATA_TO_SHARE, payload: data});
};

export const makeComment = (data) => async (dispatch) => {
    dispatch(commentStart(data.postId));
    await axios
        .post(`/posts/${data.postId}/comments`, data)
        .then((response) => {
            dispatch(commentSuccess(data, response.data.message));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(commentFailure(data));
                dispatch(alertActions.error(error.response.data.message));
            }
        });
};

// Adding post dispatch functions
// // Dispatch functions
const fetchingStart = (value) => ({
    type: Actions.FETCH_FEEDS_START,
    payload: value,
});

const fetchedFeeds = (feeds) => ({
    type: Actions.FETCH_FEEDS,
    payload: {
        feeds,
    },
});

const fetchedRecentMediaFeeds = (feeds) => ({
    type: Actions.FETCH_RECENT_MEDIA_FEEDS,
    payload: {
        feeds,
    },
});

const followStatus = (userId, isFollowOrUnfollow) => ({
    type: Actions.FOLLOW_STATUS,
    payload: {
        userId,
        isFollowOrUnfollow,
    },
});

const followRequest = (userId, isRequestSent) => ({
    type: Actions.FOLLOW_REQUEST,
    payload: {
        userId,
        isRequestSent,
    },
});

const cancelFollowRequest = (userId, isRequestSent) => ({
    type: Actions.CANCEL_FOLLOW_REQUEST,
    payload: {
        userId,
        isRequestSent,
    },
});

const likePostStatus = (postId, isLike) => ({
    type: Actions.LIKE_POST_STATUS,
    payload: {
        postId,
        isLike,
    },
});

const unLikePostStatus = (postId, isUnLike) => ({
    type: Actions.UNLIKE_POST_STATUS,
    payload: {
        postId,
        isUnLike,
    },
});

const fetchSinglePostData = (post) => ({
    type: Actions.FETCH_SINGLE_POST,
    payload: post,
});

const commentStart = (postId) => ({
    type: Actions.COMMENT_START,
    payload: postId,
});
const commentSuccess = (comment, commentMessage) => ({
    type: Actions.COMMENT_SUCCESSFUL,
    payload: {
        comment,
        commentMessage,
    },
});

const commentFailure = (comment) => ({
    type: Actions.COMMENT_FAILURE,
    payload: {
        comment,
    },
});

const shareStatus = (sharePostId, isShared) => ({
    type: Actions.SHARE_STATUS,
    payload: {
        sharePostId,
        isShared,
    },
});

const updatePostData = (post) => ({
    type: Actions.UPDATE_POST,
    payload: {
        post,
    },
});
const deletePostData = (post) => ({
    type: Actions.DELETE_POST,
    payload: {
        post,
    },
});
