import React, {useState} from 'react';

import SearchIcon from '../../assets/img/svg/search-icon.svg';
import AdBannerImg from '../../assets/img/png/johnwick.png';
import {withRouter} from 'react-router-dom';
import * as actions from '../../redux/actions';
import {connect} from 'react-redux';
// import {capitalizeFirstLetter} from '../../utils/capitalizeFirstLetter';
// import {countries_states} from '../../utils/countries_states';
// import GoogleAd from '../Blocks/GoogleAd';
import CategoryFilter from './CategoryFilter';

const Search = (props) => {
    const [search, setSearch] = useState('');
    const [error, setError] = useState('');
    const defaultFilter = props.defaultFilter || {}

    const handleSubmit = (e) => {
        e.preventDefault();

        const talentCategory = props.talentCategories.find(
            (a) => a.name === search.toLocaleLowerCase()
        );

        if (talentCategory) {
            props.fetchFeeds(
                true,
                'discover',
                `${talentCategory.talentCategoryId}`,
                props.history,
                ...defaultFilter
            );
        } else {
            setError('Category does not exist.');
        }
    };
    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <div className="input search">
                <i className="tag">
                    <img src={SearchIcon} alt="search" />
                </i>
                <input
                    type="text"
                    placeholder="Search Keywords"
                    name="talentCategories"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onFocus={() => setError('')}
                    onKeyPress={() => setError('')}
                />
            </div>
            {error && (
                <>
                    <small className="text-danger">{error}</small>
                    <br />
                </>
            )}
            <small>e.g </small>
            <small>Footballers</small>
            <br />
            <small>Entertainers</small>
            <br />
            <small>Fashion Models</small>
            <br />
            <small>Designers</small>
        </form>
    );
};

const AdBanner = () => {
    return (
        <>
            <section className="ads_">
                <img src={AdBannerImg} alt="" />
            </section>
        </>
    );
};

// Main
const LeftSidebar = (props) => {
    return (
        <div className="col-lg-3">
            <section className="categories">
                <div className="title">
                    <h4>Categories</h4>
                </div>
                <Search
                    talentCategories={props.talentCategories}
                    fetchFeeds={props.fetchFeeds}
                    defaultFilter={props.defaultFilter}
                />

                <CategoryFilter
                    categories={props.categories}
                    fetchFeeds={props.fetchFeeds}
                    match={props.match}
                    feedLoading={props.feedLoading}
                    getAllTalentCategories={props.getAllTalentCategories}
                    talentCategories={props.talentCategories}
                    defaultFilter={props.defaultFilter}
                />
                <AdBanner />

                {/* Disable Ad*/}
                {/*<GoogleAd slot="394738798" />*/}
                {/*<GoogleAd slot="1234567890" />*/}
            </section>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        feedLoading: state.feeds.feedLoading,
        talentCategories: state.talentCategories.talentCategories,
    };
};

export default withRouter(connect(mapStateToProps, actions)(LeftSidebar));
