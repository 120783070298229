import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from '../../../redux/actions';

const NotifcationStat = (props) => {
    return (
        <section className="recents">
            <div className="notif_tab">
                <div className="main active">
                    <label>Notifications</label>
                </div>
            </div>
            <div className="content_display">
                <section className="notification animated shake">
                    <div className="title">
                        <h5>your dashboard</h5>
                    </div>
                    {props.loading ? (
                        'Loading...'
                    ) : (
                        <>
                            <p>
                                {props.totalViews} <small>views today</small>
                            </p>
                        </>
                    )}
                </section>
            </div>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        totalViews: state.notifications.notifications.totalViews,
        totalCount: state.notifications.notifications.totalCount,
    };
};
export default withRouter(connect(mapStateToProps, actions)(NotifcationStat));
