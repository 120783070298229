export const statCounter = (count = 0) => {
    let value;
    if (count >= 1000) {
        value = (count / 1000).toFixed(1) + 'k';
    } else if (count >= 1000000) {
        value = (count / 1000000).toFixed(1) + 'M';
    } else {
        value = count;
    }
    return value;
};
