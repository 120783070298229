/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';
import VerticalIcon from '../../../assets/img/svg/elipsis-vertical.svg';
import PhotoAvatar from '../../../assets/img/svg/user.svg';
import FacebookIcon from '../../../assets/img/svg/facebook.svg';
import TwitterIcon from '../../../assets/img/svg/twitter.svg';

const ActionDropdown = (props) => {
    const postUrl = `${window.location.origin}/posts/${props.postId}`;
    return (
        <div className="action dropdown">
            <Link
                className="dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                to={"/"}
            >
                <img src={VerticalIcon} alt="" />
            </Link>
            <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="profile-menu"
                x-placement="bottom-end"
                style={{
                    position: 'absolute',
                    willChange: 'transform',
                    top: ' 0px',
                    left: '0px',
                    transform: 'translate3d(151px, 44px, 0px)',
                }}
            >
                {/* if unsignedin user, just show the item */}
                {props.location &&
                (props.location.pathname === '/talents' ||
                    props.location.pathname === '/scouts') ? (
                    <a className="dropdown-item custom-dropdown">
                        <span>View Profile</span>
                    </a>
                ) : (
                    <>
                        <Link
                            to={`/profile/${props.userId}`}
                            className="dropdown-item custom-dropdown"
                        >
                            <span>View Profile</span>
                        </Link>
                        {/* if post owner is true, show edit and delete post in action dropdown */}
                        {props.isPostOwner ? (
                            <>
                                <div
                                    className="dropdown-item custom-dropdown"
                                    onClick={props.handleEditPostClick}
                                >
                                    <span className="">Edit Post</span>
                                </div>
                                <div
                                    className="dropdown-item custom-dropdown"
                                    onClick={props.handleDeletePostClick}
                                >
                                    <span className="text-danger">Delete Post</span>
                                </div>
                            </>
                        ) : (
                            <div
                                className="dropdown-item custom-dropdown"
                                onClick={props.handleFollowClick}
                            >
                                {/* change text if private account to send request & text color is following a user if following is true */}
                                {props.requestId ? (
                                    <span className="text-danger">Cancel Request</span>
                                ) : props.isPrivate && !props.isFollowing ? (
                                    <span>Send Follow Request</span>
                                ) : props.isFollowing ? (
                                    <span style={{color: '#7158e2'}}>Following</span>
                                ) : (
                                    <span>Follow</span>
                                )}
                            </div>
                        )}
                    </>
                )}
                <div className="dropdown-item custom-dropdown">
                    <a
                        className="pl-0"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.facebook.com/sharer.php?u=${postUrl}`}
                    >
                        <img src={FacebookIcon} width="18rem" alt="" /> Share to Facebook
                    </a>
                </div>
                <div className="dropdown-item custom-dropdown">
                    <a
                        className="pl-0"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://twitter.com/intent/tweet?url=${postUrl}%2F&text=Post+from+MySpotlyt.&hashtags=feeds,post,talents`}
                    >
                        <img src={TwitterIcon} width="18rem" alt="" /> Share to Twitter
                    </a>
                </div>
            </div>
        </div>
    );
};

const PanelHead = (props) => {
    const {
        postId,
        userId,
        isFollowing,
        profilePicture,
        statusTag,
        fullName,
        timePosted,
        isPostOwner,
        location,
        history,
        isPrivate,
        requestId,
        handleEditPostClick,
        handleDeletePostClick,
    } = props;

    // Handle follow user click
    const handleFollowClick = async (e, userId) => {
        e.preventDefault();
        const data = {
            userId,
        };

        if (isFollowing) {
            await props.unfollowUsers(data);
        } else if (isPrivate && !isFollowing && requestId) {
            await props.cancelFollowUserRequest(data, requestId);
        } else if (isPrivate && !isFollowing) {
            await props.followUserRequest(data);
        } else {
            await props.followUsers(data);
        }
    };

    return (
        <div className="head">
            <div>
                <i>
                    <img
                        src={profilePicture || PhotoAvatar}
                        alt="user"
                        style={{borderRadius: '50%'}}
                    />
                    {/* render status tag based on user area of interest */}
                    <span
                        className={`status_tag ${statusTag}`}
                    >
                        {statusTag && statusTag[0].toUpperCase()}
                    </span>
                </i>
                <label
                    onClick={() =>
                        location.pathname === '/talents'
                            ? ''
                            : history.push(`/profile/${userId}`)
                    }
                >
                    {fullName}
                </label>
                <small>{timePosted}</small>
            </div>
            <ActionDropdown
                isFollowing={isFollowing}
                isPostOwner={isPostOwner}
                handleFollowClick={(e) => handleFollowClick(e, userId)}
                userId={userId}
                location={location}
                isPrivate={isPrivate}
                requestId={requestId}
                postId={postId}
                handleEditPostClick={handleEditPostClick}
                handleDeletePostClick={handleDeletePostClick}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {};
};

export default withRouter(connect(mapStateToProps, actions)(PanelHead));
