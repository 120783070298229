import React from 'react';

import Banner from '../../assets/img/png/banner.png';
import DonaldBanner from '../../assets/img/png/mac-donald.png';
import PipesBanner from '../../assets/img/png/pipes-banner.png';

const RightSidebar = () => {
    const ads = [
        {
            banner: `${Banner}`,
            alt: 'Banner 1',
        },
        {
            banner: `${DonaldBanner}`,
            alt: 'Donnald',
        },
        {
            banner: `${PipesBanner}`,
            alt: 'Pipes',
        },
    ];

    return (
        <div className="col-lg-3">
            <section className="ads">
                {ads.map((ad) => (
                    <div>
                        <img src={ad.banner} alt={ad.alt} />
                    </div>
                ))}
            </section>
        </div>
    );
};

export default RightSidebar;
