import React from 'react';
import {Helmet} from 'react-helmet';
import Footer from '../components/layout/MainLayout/Footer';
import Header from '../components/layout/MainLayout/Header';
import {scrollTop} from '../utils/scrollTop';

const Disclaimer = () => {
    scrollTop();

    return (
        <>
            <Helmet>
                <title>Disclaimer</title>
            </Helmet>
            <Header />
            <main className="main">
                <section className="t_and_c privacy">
                    <div className="container">
                        <div className="title">
                            <h1>Disclaimer</h1>
                        </div>
                        <div className="content">
                            <p>
                                The use of the products and services we offer may result
                                in the processing of personal data. The term “personal
                                data” under data protection law refers to all information
                                relating to a specific or identifiable person. An IP
                                address can also be considered personal data. An IP
                                address is assigned to each device connected to the
                                internet by the internet service provider, so that it can
                                send and receive data. When you use the website, we
                                collect data that you provide yourself. In addition, when
                                you use the website, we automatically collect certain
                                information about your use of it
                            </p>
                            <h3>Information</h3>
                            <p>
                                The use of the products and services we offer may result
                                in the processing of personal data. The term “personal
                                data” under data protection law refers to all information
                                relating to a specific or identifiable person. An IP
                                address can also be considered personal data. An IP
                                address is assigned to each device connected to the
                                internet by the internet service provider, so that it can
                                send and receive data. When you use the website, we
                                collect data that you provide yourself. In addition, when
                                you use the website, we automatically collect certain
                                information about your use of it
                            </p>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default Disclaimer;
