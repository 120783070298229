import React, {useEffect} from 'react';
import {Link, withRouter} from 'react-router-dom';
import $ from 'jquery';
import store from 'store';

// logo
import Logo from '../../../assets/img/svg/logo.svg';
import CloseLogo from '../../../assets/img/svg/close.svg';

import Hambuger from '../../../assets/img/svg/hamburger.svg';

const Header = (props) => {
    const location = props.location.pathname;
    const user = store.get('kmt-user');

    useEffect(() => {
        $('.menu-trigger a, .sidenav .close_').click(function () {
            $('.sidenav').toggleClass('show');
            $('.sidenav .menu li ').toggleClass('fadeInY');
        });
    }, []);

    return (
        <>
            <header className="header">
                <div className="container">
                    <div className="left">
                        <div className="logo">
                            <Link to="/">
                                <img className="large" src={Logo} alt="kmt" />
                                <img className="small" src={Logo} alt="kmt" />
                            </Link>
                        </div>
                        <nav className="nav">
                            <ul>
                                <li>
                                    <Link
                                        to="/talents"
                                        className={`btn btn-link ${
                                            location === '/talents' ? 'active' : ''
                                        }`}
                                    >
                                        Talents
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/scouts"
                                        className={`btn btn-link ${
                                            location === '/scouts' ? 'active' : ''
                                        }`}
                                    >
                                        Scouts
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/auditions"
                                        className={`btn btn-link ${
                                            location === '/auditions' ? 'active' : ''
                                        }`}
                                    >
                                        Auditions
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="right">
                        <div className="actions">
                            {user ? (
                                <Link className="btn btn-secondary" to="/feeds">
                                    Go to Feeds
                                </Link>
                            ) : (
                                <Link className="btn btn-secondary" to="/login">
                                    Login
                                </Link>
                            )}

                            <Link className="btn btn-primary" to="/signup">
                                {' '}
                                Get Started
                            </Link>
                        </div>
                        <div className="menu-trigger">
                            <Link to="#">
                                <img src={Hambuger} alt="" />
                            </Link>
                        </div>
                    </div>
                </div>
            </header>
            <div className="sidenav">
                <div className="close_">
                    <Link to="/">
                        <i className="icon">
                            <img src={CloseLogo} alt="close" />
                        </i>
                    </Link>
                </div>
                <div className="wrapper">
                    <ul className="menu">
                        <li className="animated delay-2m">
                            <Link to="/talents" className={`btn-link `}>
                                Talents
                            </Link>
                        </li>
                        <li className="animated delay-3m">
                            <Link to="/scouts" className="btn-link">
                                Scouts
                            </Link>
                        </li>
                        <li className="animated delay-4m">
                            <Link to="/auditions" className="btn-link">
                                Auditions
                            </Link>
                        </li>
                        <li className="animated delay-5m">
                            {user ? (
                                <Link to="/feeds" className="btn btn-secondary">
                                    Go to Feeds
                                </Link>
                            ) : (
                                <Link to="/login" className="btn btn-secondary">
                                    Login
                                </Link>
                            )}
                        </li>
                        <li className="animated delay-6m">
                            <Link className="btn btn-primary" to="/signup">
                                <div>Get Started</div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default withRouter(Header);
