import React from 'react';
import {Link} from 'react-router-dom';
import {tabOptions} from './data';

const SettingsOptions = ({slug}) => {
    const renderTabOptions = () => {
        return tabOptions.map((option, index) => {
            if (index === 2) {
                return (
                    <React.Fragment key={option.id}>
                        <Link
                            className={`item no-outline ${
                                slug === option.linkUrl ? 'active' : ''
                            }`}
                            to={`/settings/` + option.linkUrl}
                        >
                            <strong>{option.linkName}</strong>
                            <p className="ellipsis">{option.linkSummary}</p>
                        </Link>
                        <label>Others</label>
                    </React.Fragment>
                );
            }
            return (
                <Link
                    key={option.id}
                    className={`item ${
                        slug === option.linkUrl
                            ? 'active'
                            : index === 4
                            ? 'no-outline'
                            : ''
                    }`}
                    to={`/settings/` + option.linkUrl}
                >
                    <strong>{option.linkName}</strong>
                    <p className="ellipsis">{option.linkSummary}</p>
                </Link>
            );
        });
    };

    return (
        <div className="category-cats">
            <div className="category-nav">
                <label>Profile</label>
                {renderTabOptions()}
            </div>
        </div>
    );
};

export default SettingsOptions;
