// eslint-disable-next-line
import React, {useEffect, useState} from 'react';
import store from 'store';
import BDCReverseGeocode from '../geo';

function useGeoLocation() {
    const user = store.get('kmt-user') || {};
    const [geoLocation, setUserCurrentGeoLocation] = useState({country: '', region: ''});

    useEffect(() => {
        try {
            const reverseGeocoder = new BDCReverseGeocode();
            reverseGeocoder.getClientLocation(function (result) {
                let countryName = user.country;
                let regionName = '';
                if (result) {
                    countryName = result.countryName;
                    regionName = result.principalSubdivision;
                }
                setUserCurrentGeoLocation({
                    country: countryName,
                    region: regionName,
                });
            });
        } catch (e) {
            setUserCurrentGeoLocation({
                country: user.country || '',
                region: '',
            });
        }
    }, [user.country]);

    return geoLocation;
}

export default useGeoLocation;
