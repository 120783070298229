import React, {useState} from 'react';
import * as actions from '../../../redux/actions';
import {connect} from 'react-redux';
import Input from '../../Form/Input';

import useAlert from '../../CustomHooks/useAlert';
import {validateAll} from 'indicative/validator';
import {scrollTop} from '../../../utils/scrollTop';
import {withRouter} from 'react-router-dom';
// Password Settings
const PasswordSettings = (props) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [errors, setErrors] = useState('');
    const [loading, setLoading] = useState(false);

    const handleErrorRemoveOnFocus = (e) => {
        setErrors({
            ...errors,
            [e.target.name]: '',
        });
    };

    // Handle change password
    const handleChangePassword = (e) => {
        e.preventDefault();

        const data = {
            oldPassword,
            newPassword,
            confirmPassword,
        };

        const rules = {
            oldPassword: 'required|min:8',
            newPassword: 'required|min:8',
            confirmPassword: 'required|min:8',
        };

        const messages = {
            'oldPassword.required': 'Enter a old password',
            'oldPassword.min': 'Password too short (minimum of 6 characters)',
            'newPassword.required': 'Enter a new password',
            'newPassword.min': 'Password too short (minimum of 6 characters)',
            'confirmPassword.required': 'Make sure you confirm new password',
            'confirmPassword.min':
                'Make sure the length of password matches new password',
        };

        validateAll(data, rules, messages)
            .then(() => {
                scrollTop();
                completeChange();
            })
            .catch((errors) => {
                const formattedErrors = {};
                errors.forEach((error) => (formattedErrors[error.field] = error.message));
                setErrors(formattedErrors);
            });

        const completeChange = async () => {
            setLoading(true);
            // process data to Backend
            if (newPassword !== confirmPassword) {
                setErrors({...errors, confirmPassword: 'Password does not match'});
                setLoading(false);
            } else {
                const payload = {
                    oldPassword,
                    newPassword,
                };
                await props.changePassword(payload, props.history);
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');

                setLoading(false);
            }
        };
    };

    useAlert(props.alert);

    return (
        <div className="setting-sect active" id="setting-password">
            <h5>
                Change Password{' '}
                <small>A short description to help understand title...</small>
            </h5>
            <form onSubmit={handleChangePassword} style={{marginTop: '3em'}}>
                <Input
                    type="password"
                    name="oldPassword"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    placeholder="Old Password"
                    label="Old Password"
                    errorMessage={errors.oldPassword}
                    onFocus={handleErrorRemoveOnFocus}
                />
                <Input
                    type="password"
                    name="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="New Password"
                    label="New Password"
                    errorMessage={errors.newPassword}
                    onFocus={handleErrorRemoveOnFocus}
                />
                <Input
                    type="password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm Password"
                    label="Confirm Password"
                    errorMessage={errors.confirmPassword}
                    onFocus={handleErrorRemoveOnFocus}
                />

                <button type="submit" disabled={loading} className="btn btn-primary dark">
                    {loading ? 'Updating Password...' : 'Update Password'}
                </button>
            </form>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        alert: state.alert,
    };
};

export default withRouter(connect(mapStateToProps, actions)(PasswordSettings));
