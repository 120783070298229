import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroll-component';
import {connect} from 'react-redux';
import Footer from '../../components/Feeds/Footer';
import Header from '../../components/Feeds/Header';
import LeftSideBar from '../../components/Feeds/LeftSideBar';
import * as actions from '../../redux/actions';
import Avatar from '../../assets/img/svg/user.svg';
import {capitalizeFirstLetter} from '../../utils/capitalizeFirstLetter';
import {stringFirstUpperCase} from '../../utils/stringFirstsUppercase';

const SearchItem = (props) => {
    return (
        <div className="list-tile">
            <div className="d-flex user_info">
                <img
                    style={{borderRadius: '50%'}}
                    src={
                        props.profilePicture && props.profilePicture.length > 15
                            ? props.profilePicture
                            : Avatar
                    }
                    alt="profile"
                />
                <p>
                    {stringFirstUpperCase(props.fullName)}
                    <small>{capitalizeFirstLetter(props.areaOfInterest)}</small>
                </p>
            </div>
            <div className="d-flex action_btn">
                <button
                    className="btn btn-primary"
                    onClick={props.handleRedirectToProfile}
                >
                    View Profile
                </button>
            </div>
        </div>
    );
};
const SearchResult = (props) => {
    const searchKeyword = props.location.search?.replace('?keyword=', '');

    const [loading, setLoading] = useState(false);
    const [usersList, setUsersList] = useState([]);

    useEffect(() => {
        setUsersList(props.users);
    }, [props.users]);

    useEffect(() => {
        fetchUsers(1, 15);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchKeyword]);

    const fetchUsers = async (pageNumber, pageSize) => {
        setLoading(true);
        await props.fetchUsersOnSearch(
            searchKeyword,
            pageNumber,
            pageSize,
            props.history
        );
        setLoading(false);
    };

    const fetchMoreData = () => {
        // update pagination list
        const pageSize = usersList?.length + 15;
        props.fetchUsersOnSearch(searchKeyword, 1, pageSize, props.history);
    };

    const handleRedirectToProfile = (userId) => {
        props.history.push(`/profile/${userId}`);
    };

    // render loader
    const renderLoader = () => {
        return (
            <div className="list-tile">
                <div className="col">
                    <div className="chat-loader">
                        <div className="media-panel d-flex">
                            <figure>
                                <div></div>
                            </figure>
                            <section>
                                <div></div>
                                <div></div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <Helmet>
                <title>Search</title>
            </Helmet>
            <main className="main feeds">
                <LeftSideBar />
                <section id="wrapper">
                    <Header />
                    <div className="container content_">
                        <section className="friends">
                            <div>
                                <h3>Keyword : "{searchKeyword}"</h3>
                            </div>

                            <div className="row">
                                <div className="col-lg-8">
                                    {loading ? (
                                        <>
                                            {renderLoader()}
                                            {renderLoader()}
                                            {renderLoader()}
                                        </>
                                    ) : usersList?.length === 0 ? (
                                        <div className="col list-tile">
                                            <p>
                                                No record with the search keyword return.
                                                Try again later.
                                            </p>
                                        </div>
                                    ) : (
                                        <InfiniteScroll
                                            dataLength={usersList?.length || 0}
                                            next={fetchMoreData}
                                            hasMore={true}
                                            loader={
                                                <div className="loader">
                                                    <p>Loading more ...</p>
                                                </div>
                                            }
                                            endMessage={
                                                <div className="loader">
                                                    <p>Yay! You have seen it all</p>
                                                </div>
                                            }
                                        >
                                            {usersList.map((user, index) => (
                                                <React.Fragment key={user.userId}>
                                                    <SearchItem
                                                        userId={user.userId}
                                                        profilePicture={user.imageUrl}
                                                        email={user.email}
                                                        fullName={user.fullName}
                                                        areaOfInterest={
                                                            user.areaOfInterest
                                                        }
                                                        user={user}
                                                        handleRedirectToProfile={() =>
                                                            handleRedirectToProfile(
                                                                user.userId
                                                            )
                                                        }
                                                    />
                                                </React.Fragment>
                                            ))}
                                        </InfiniteScroll>
                                    )}
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </section>
            </main>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        users: state.user.users,
    };
};
export default connect(mapStateToProps, actions)(SearchResult);
