import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {Helmet} from 'react-helmet';
import {Link, withRouter} from 'react-router-dom';
import * as actions from '../../redux/actions';
import Header from '../../components/layout/MainLayout/Header';
import Footer from '../../components/layout/MainLayout/Footer';
import PatternImg from '../../assets/img/svg/dot-pattern.svg';
import {connect} from 'react-redux';
import {scrollTop} from '../../utils/scrollTop';
import useAlert from '../../components/CustomHooks/useAlert';
import {capitalizeFirstLetter} from '../../utils/capitalizeFirstLetter';
import {stringFirstUpperCase} from '../../utils/stringFirstsUppercase';

import Img1 from '../../assets/img/png/audition-img-2.png';
// import Img2 from '../../assets/img/png/media-1.png';
// import PlayIcon from '../../assets/img/svg/play-icon.svg';
import store from 'store';
import UnsignedInAuditionHiglights from './UnsignedInAuditionHiglights';
import useGeoLocation from '../../utils/useGeoLocation';
import {auditionEligibility, unsignedAuditionEligibility} from '../../utils/global';

const AuditionDetailsBanner = (props) => {
    const user = store.get('kmt-user');
    const {mediaUrl, title, description, audition, auditionId} = props;
    const {country, region} = useGeoLocation();

    const isExpired = new Date(audition.endDate).getTime() >= new Date().getTime();
    const isEligible = unsignedAuditionEligibility(country, region, audition);

    const renderApplyButtonBasedOnConditions = () => {
        if (isEligible) {
            return isExpired ? (
                <Link
                    to={`/talent-auditions/apply-audition/${auditionId}`}
                    className="btn flat-btn"
                    onClick={() => store.set('applyAuditionTitle', title)}
                >
                    Apply for audition
                </Link>
            ) : (
                <button className="btn btn-default cursor-default">
                    Audition Expired
                </button>
            );
        } else {
            return (
                <p className="mt-3">
                    Oops!! This audition is not available for your account because this
                    audition is set for a specific user based location, talent category,
                    gender or age range.
                </p>
            );
        }
    };
    const renderApplyButtonBasedOnLoginUserConditions = () => {
        if (auditionEligibility(user, country, region, audition)) {
            return audition.hasApplied ? (
                <button className="btn btn-success cursor-default">Applied</button>
            ) : isExpired ? (
                <Link
                    to={`/talent-auditions/apply-audition/${auditionId}`}
                    className="btn flat-btn"
                    onClick={() => store.set('applyAuditionTitle', title)}
                >
                    Apply for audition
                </Link>
            ) : (
                <button className="btn btn-default cursor-default">
                    Audition Expired
                </button>
            );
        } else {
            return (
                <p className="mt-3">
                    Oops!! This audition is not available for your account because this
                    audition is set for a specific user based location, talent category,
                    gender or age range.
                </p>
            );
        }
    };

    return (
        <section className="intro audition-banner">
            <div
                className="tier_one sub"
                style={{
                    background: `#000000d9 url(${mediaUrl}) no-repeat center`,
                }}
            >
                <div className="container">
                    <div className="h3ro alt">
                        <h1>{stringFirstUpperCase(title)}</h1>
                        <p>
                            {description && description.length >= 100
                                ? capitalizeFirstLetter(description.slice(0, 100)) + '...'
                                : capitalizeFirstLetter(description)}
                        </p>
                        <div className="d-flex justify-content-between">
                            {user
                                ? renderApplyButtonBasedOnLoginUserConditions()
                                : renderApplyButtonBasedOnConditions()}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const AuditionDescriptionVideos = ({mediaUrl}) => {
    return (
        <>
            {mediaUrl && mediaUrl.includes('.mp4') ? (
                <section>
                    <h4>Additional Video</h4>
                    <aside className="d-flex">
                        <video
                            controls
                            poster={Img1}
                            width="300"
                            height="200"
                            controlsList="nodownload"
                        >
                            <source src={mediaUrl} type="video/mp4" />
                            <p>
                                Your browser doesn't support HTML5 video. Here is a{' '}
                                <a
                                    href={mediaUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    link to the video
                                </a>{' '}
                                instead.
                            </p>
                        </video>
                    </aside>
                </section>
            ) : (
                ''
            )}
        </>
    );
};

const AuditionDescription = (props) => {
    const {description, startDate, endDate, mediaUrl} = props;

    return (
        <>
            <div className="col-lg-8">
                <h3>Description</h3>
                <p>{capitalizeFirstLetter(description)}</p>
            </div>
            <div className="col-lg-4 mt-4 pt-1">
                <h4>Start Date - End Date</h4>
                <h5 className="text-muted">
                    {moment(startDate).utc().format('ddd ll')}
                    &nbsp;-&nbsp;
                    {moment(endDate).utc().format('ddd ll')}
                </h5>

                <AuditionDescriptionVideos mediaUrl={mediaUrl} />
            </div>
        </>
    );
};

const AuditionDescriptionWrapper = (props) => {
    const {
        id,
        title,
        description,
        startDate,
        endDate,
        isAuditionOwner,
        auditionId,
        mediaUrl,
    } = props;
    return (
        <section className="description">
            <img className="pattern" src={PatternImg} alt="" />
            <div className="container">
                <div className="row">
                    <AuditionDescription
                        id={id}
                        title={title}
                        description={description}
                        startDate={startDate}
                        endDate={endDate}
                        isAuditionOwner={isAuditionOwner}
                        auditionId={auditionId}
                        mediaUrl={mediaUrl}
                    />
                </div>
            </div>
        </section>
    );
};

const UnsignedInAuditionDetails = (props) => {
    const {audition} = props;
    const auditionId = props.match.params.slug;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        scrollTop();
        fetchSingleAudition();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchSingleAudition = async () => {
        setLoading(true);
        await props.fetchSingleAuditionByUrl(auditionId);
        setLoading(false);
    };

    const renderLoader = () => {
        return (
            <section className="waiting col mt-5 py-5 text-center">
                <div className="col mt-5 py-5">
                    <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </section>
        );
    };

    useAlert(props.alert);

    return (
        <>
            <Helmet>
                <title>Auditions Details</title>
            </Helmet>
            <Header />
            <main className="plublic">
                {loading ? (
                    renderLoader()
                ) : (
                    <>
                        <AuditionDetailsBanner
                            mediaUrl={audition.mediaUrl}
                            title={audition.title}
                            description={audition.description}
                            auditionId={audition.auditionId}
                            audition={audition}
                        />
                        <AuditionDescriptionWrapper
                            id={audition.auditionId}
                            title={audition.title}
                            description={audition.description}
                            startDate={audition.startDate}
                            endDate={audition.endDate}
                            auditionId={audition.auditionId}
                            mediaUrl={audition.mediaUrl}
                        />
                    </>
                )}
                <UnsignedInAuditionHiglights audition={audition} />
            </main>
            <Footer />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        audition: state.audition.audition,
        applicants: state.audition.applicants,
        alert: state.alert,
    };
};

export default withRouter(connect(mapStateToProps, actions)(UnsignedInAuditionDetails));
