import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Img from '../../assets/img/svg/user.svg';
import {capitalizeFirstLetter} from '../../utils/capitalizeFirstLetter';
// import Loader from '../Loader';
const SearchListBox = (props) => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        setUsers(props.users);
    }, [props.users]);

    const renderLoader = () => {
        return (
            <div className="loading text-center mt-5">
                <div className="lds-ripple">
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    };
    return (
        <div className="list-search-box-main">
            {/*list search box */}
            <div className="list-search-box">
                {props.loading ? (
                    <>{renderLoader()}</>
                ) : (
                    <>
                        {users.length > 0 ? (
                            <>
                                <section className="list-wrap">
                                    {users.map((user) => (
                                        <div
                                            className="list"
                                            onClick={() =>
                                                props.handleRedirectToProfileOnClickUser(
                                                    user.userId
                                                )
                                            }
                                        >
                                            <div className="item">
                                                <img src={user.imageUrl || Img} alt="" />
                                                <p>{user.fullName}</p>
                                                <p
                                                    className={
                                                        user.areaOfInterest === 'scout'
                                                            ? 'scout'
                                                            : 'talent'
                                                    }
                                                >
                                                    {capitalizeFirstLetter(
                                                        user.areaOfInterest
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </section>
                                <div className="list-search-footer">
                                    <button
                                        className="btn"
                                        onClick={props.handleShowAllResult}
                                    >
                                        See all results
                                    </button>
                                </div>
                            </>
                        ) : (
                            <p className="list-search-box-empty">
                                No record with the search keyword return. Try again later.
                            </p>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        users: state.user.users,
    };
};
export default connect(mapStateToProps)(SearchListBox);
