/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import store from 'store';
import MapIcon from '../../../assets/img/svg/map-icon.svg';
import Profile7 from '../../../assets/img/svg/user.svg';
import {capitalizeFirstLetter} from '../../../utils/capitalizeFirstLetter';
import {statCounter} from '../../../utils/statCounter';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';
import CreatePostWrapper from '../CreatePostBits/CreatePostWrapper';
import {getUserAge} from '../../../utils/getUserAge';
import EditIcon from '../../../assets/img/svg/editprofile.svg';
import UpdateBio from './UpdateBio';

const ProfilePicture = ({user}) => {
    return (
        <aside>
            <div
                className="avatar"
                style={{background: `url(${user.imageUrl || Profile7}) no-repeat`}}
            ></div>
        </aside>
    );
};

const ProfileHeader = ({user, userId, history}) => {
    // scroll to post view
    const handleScrollToPost = () => {
        document.getElementById('user_post').scrollIntoView({
            behavior: 'smooth',
        });
    };

    // handle link to following/followers list view
    const handleFollowView = (view) => {
        if (view === 'following') {
            history.push(
                `${userId ? `/profile/${userId}/following` : '/profile/0/following'}`
            );
        } else {
            history.push(
                `${userId ? `/profile/${userId}/followers` : '/profile/0/followers'}`
            );
        }
    };

    return (
        <div className="d-top">
            <p>
                {capitalizeFirstLetter(user.fullName)} <small>{user.email}</small>
            </p>
            <div className="stats">
                <label className="text-center" onClick={handleScrollToPost}>
                    {user.postsCount}
                    <small>Post</small>
                </label>
                <label
                    className="text-center"
                    onClick={() => handleFollowView('following')}
                >
                    {statCounter(user.followingsCount)}
                    <small>Following</small>
                </label>
                <label
                    className="text-center"
                    onClick={() => handleFollowView('followers')}
                >
                    {statCounter(user.followersCount)}
                    <small>Followers</small>
                </label>
            </div>
        </div>
    );
};

const ProfileLocation = ({user}) => {
    const userDob = user?.dob;
    const extractedDob = userDob?.slice(0, userDob.indexOf('T'));
    return (
        <>
            <h5>
                <img src={MapIcon} alt="" />
                {getUserAge(extractedDob)}
                {', '} {capitalizeFirstLetter(user.gender)}
                {', '}
                {user.country === 'undefined'
                    ? 'Not Specified'
                    : user.country
                    ? capitalizeFirstLetter(user.country)
                    : 'Not Specified'}
            </h5>
        </>
    );
};

const ProfileSummary = ({user}) => {
    const userData = store.get('kmt-user')?.fullName;
    return (
        <>
            <p>{user.bio}</p>
            {userData === user.fullName && (
                <p
                    className="text-dark edit-bio-button"
                    data-toggle="modal"
                    data-target="#editBio"
                >
                    <img src={EditIcon} className="mr-2" width="17rem" alt="edit" />
                    <span className="mt-1">Edit About</span>
                </p>
            )}
        </>
    );
};

const ProfileFooter = ({
    user,
    handleUnfollow,
    handleFollow,
    history,
    keepUserIdfromProfileToFetchMessage,
    isPrivate,
    isFollowing,
}) => {
    const userData = store.get('kmt-user')?.fullName;

    const handleMessageFromProfile = () => {
        keepUserIdfromProfileToFetchMessage(user, history);
    };

    return (
        <>
            {userData === user.fullName && isPrivate ? (
                <div className="cta d-flex justify-content-between">
                    <section>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            style={{marginRight: '1em'}}
                            data-toggle="modal"
                            data-target="#createPost"
                        >
                            <div>
                                <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6 6V0H8V6H14V8H8V14H6V8H0V6H6Z"
                                        fill="#7158E2"
                                    />
                                </svg>
                                Create Post
                            </div>
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            style={{marginRight: '1em'}}
                            onClick={() => history.push('/follow-request')}
                        >
                            <div>
                                <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6 6V0H8V6H14V8H8V14H6V8H0V6H6Z"
                                        fill="#7158E2"
                                    />
                                </svg>
                                View Follow Request
                            </div>
                        </button>
                    </section>
                </div>
            ) : userData === user.fullName && !isPrivate ? (
                <>
                    <div className="cta d-flex justify-content-between">
                        <section>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                style={{marginRight: '1em'}}
                                data-toggle="modal"
                                data-target="#createPost"
                            >
                                <div>
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 6V0H8V6H14V8H8V14H6V8H0V6H6Z"
                                            fill="#7158E2"
                                        />
                                    </svg>
                                    Create Post
                                </div>
                            </button>
                        </section>
                    </div>
                </>
            ) : (
                <div className="cta d-flex justify-content-between">
                    <section>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            style={{marginRight: '1em'}}
                            onClick={handleMessageFromProfile}
                        >
                            <div>
                                <svg
                                    width="20"
                                    height="19"
                                    viewBox="0 0 20 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M4.91676 15.4167L0.833008 18.625V1.66667C0.833008 1.42355 0.929585 1.19039 1.10149 1.01849C1.2734 0.846577 1.50656 0.75 1.74967 0.75H18.2497C18.4928 0.75 18.7259 0.846577 18.8979 1.01849C19.0698 1.19039 19.1663 1.42355 19.1663 1.66667V14.5C19.1663 14.7431 19.0698 14.9763 18.8979 15.1482C18.7259 15.3201 18.4928 15.4167 18.2497 15.4167H4.91676ZM4.28242 13.5833H17.333V2.58333H2.66634V14.8529L4.28242 13.5833ZM6.33301 7.16667H13.6663V9H6.33301V7.16667Z"
                                        fill="#7158E2"
                                    />
                                </svg>
                                Message
                            </div>
                        </button>
                        {isFollowing ? (
                            <button
                                className="btn btn-secondary"
                                onClick={handleUnfollow}
                            >
                                {' '}
                                <div>Unfollow</div>
                            </button>
                        ) : (
                            <button className="btn btn-secondary" onClick={handleFollow}>
                                <div>
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 6V0H8V6H14V8H8V14H6V8H0V6H6Z"
                                            fill="#7158E2"
                                        />
                                    </svg>
                                    Follow
                                </div>
                            </button>
                        )}
                    </section>
                    <a data-toggle="modal" data-target="#reportUser">
                        Report this profile
                    </a>
                </div>
            )}
        </>
    );
};

const ProfileAsideWrapper = ({
    user,
    handleFollow,
    handleUnfollow,
    history,
    keepUserIdfromProfileToFetchMessage,
    isPrivate,
    isFollowing,
}) => {
    return (
        <div className="d-bottom">
            <ProfileLocation user={user} />
            <ProfileSummary user={user} />
            <ProfileFooter
                user={user}
                handleFollow={() => handleFollow()}
                handleUnfollow={() => handleUnfollow()}
                keepUserIdfromProfileToFetchMessage={keepUserIdfromProfileToFetchMessage}
                history={history}
                isPrivate={isPrivate}
                isFollowing={isFollowing}
            />
        </div>
    );
};

const ProfileOverview = (props) => {
    const userId = props.match.params.slug;
    const handleFollow = async () => {
        const data = {
            userId,
        };
        await props.followUserOnProfile(data);
    };

    const handleUnfollow = async () => {
        const data = {
            userId,
        };
        await props.unfollowUserOnProfile(data);
    };

    return (
        <>
            <section className="profile-container animated fadeIn">
                <ProfilePicture user={props.user} />
                <section className="right-aside">
                    <ProfileHeader
                        user={props.user}
                        history={props.history}
                        userId={props.match.params.slug}
                    />
                    <ProfileAsideWrapper
                        user={props.user}
                        handleFollow={handleFollow}
                        handleUnfollow={handleUnfollow}
                        history={props.history}
                        keepUserIdfromProfileToFetchMessage={
                            props.keepUserIdfromProfileToFetchMessage
                        }
                        isPrivate={props.user.private}
                        isFollowing={props.followOnProfile || props.follow}
                    />
                </section>
            </section>

            {/* Create post modal */}
            <CreatePostWrapper />

            {/* Update Bio */}
            <UpdateBio bio={props.user.bio} />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        followOnProfile: state.user.followOnProfile,
        follow: state.feeds.follow,
    };
};

export default withRouter(connect(mapStateToProps, actions)(ProfileOverview));
