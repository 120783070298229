import React, {useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import ShareIcon from '../../../assets/img/svg/share-icon_.svg';

import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';
import {DateSuperscript} from '../../../utils/DateSuperscript';
import {stringFirstUpperCase} from '../../../utils/stringFirstsUppercase';
import {capitalizeFirstLetter} from '../../../utils/capitalizeFirstLetter';
// import InstagramIcon from '../../../assets/img/svg/instagram.svg';
import FacebookIcon from '../../../assets/img/svg/facebook.svg';
import TwitterIcon from '../../../assets/img/svg/twitter.svg';

const FetchAuditionLoader = () => {
    return (
        <div className="col-lg-4">
            <div className="panel">
                <section className="content">
                    <div
                        className="media audition custom-fetching"
                        style={{
                            background: `#f2f2f2`,
                        }}
                    />
                    <div className="media-panel">
                        <div className="audition-short" />
                    </div>
                </section>
            </div>
        </div>
    );
};

const renderDisplayIfRecordIsEmpty = (match) => {
    return (
        <div className="jumbotron col">
            <p className="">
                Welcome, there are so many awesome talents to be discovered here
            </p>
            {match.path === '/talent-auditions/my-auditions' ? (
                <Link
                    className="btn btn-primary btn-lg"
                    to="/talent-auditions/create-audition"
                    role="button"
                >
                    Create New Audition
                </Link>
            ) : (
                ''
            )}
        </div>
    );
};

const AuditionList = (props) => {
    const [show, setShow] = useState(false);
    const [id, setId] = useState(null);
    const {loading, auditions} = props;

    const handleSharedropdown = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        setShow((prev) => !prev);
        setId(id);
    };

    const viewAudion = (auditionId) => {
        const url = `/talent-auditions/${auditionId}`;
        props.history.push(url);
    };

    const shareToFacebook = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const url = `https://www.facebook.com/sharer.php?u=${window.location.origin}/talent-auditions/details/${id}`;
        window.open(url, '_blank');
    };

    const shareToTwitter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const url = `https://twitter.com/intent/tweet?url=${window.location.origin}/talent-auditions/details/${id}&text=Audition+from+MySpotlyt.&hashtags=audition,talents`;
        window.open(url, '_blank');
    };

    return (
        <>
            {loading ? (
                <>
                    <FetchAuditionLoader />
                    <FetchAuditionLoader />
                    <FetchAuditionLoader />
                </>
            ) : auditions.length === 0 ? (
                renderDisplayIfRecordIsEmpty(props.match)
            ) : (
                auditions.map((audition) => (
                    <div className="col-lg-4 audition-item" key={audition.auditionId}>
                        <div onClick={() => viewAudion(audition.uniqueUrlId)}>
                            <div className="panel">
                                <section className="content">
                                    <div
                                        className="media"
                                        style={{
                                            background: `#000000d9 url(${audition.mediaUrl}) no-repeat center`,
                                        }}
                                    >
                                        <div className="share">
                                            <a
                                                onClick={(e) =>
                                                    handleSharedropdown(
                                                        e,
                                                        audition.auditionId
                                                    )
                                                }
                                                href="/"
                                            >
                                                <img src={ShareIcon} alt="" />
                                                <label>Share</label>
                                            </a>

                                            {show && audition.auditionId === id ? (
                                                <div className="more-options">
                                                    <div className="input-group-append d-flex">
                                                        <span className="share-audtion-icon">
                                                            <a
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                href="https://www.facebook.com/sharer.php?u=http%3A%2F%2Fmyspotlyt.com/%2F"
                                                                onClick={(e) =>
                                                                    shareToFacebook(e)
                                                                }
                                                            >
                                                                <img
                                                                    src={FacebookIcon}
                                                                    alt=""
                                                                />
                                                            </a>
                                                        </span>
                                                    </div>
                                                    <div className="input-group-append d-flex">
                                                        <span className="share-audtion-icon">
                                                            <a
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                href="https://twitter.com/intent/tweet"
                                                                onClick={(e) =>
                                                                    shareToTwitter(e)
                                                                }
                                                            >
                                                                <img
                                                                    src={TwitterIcon}
                                                                    alt=""
                                                                />
                                                            </a>
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div className="media-overlay-wrapper"></div>
                                    </div>

                                    <div className="media-panel">
                                        <label>
                                            {new Date(
                                                audition.startDate
                                            ).toLocaleDateString('default', {
                                                month: 'long',
                                            })}{' '}
                                            <strong>
                                                {new Date(
                                                    audition.startDate
                                                ).getUTCDate()}{' '}
                                                <small>
                                                    {DateSuperscript(
                                                        new Date(
                                                            audition.startDate
                                                        ).getUTCDate()
                                                    )}
                                                </small>
                                            </strong>
                                        </label>
                                        <div className="audition-short">
                                            <h3
                                                data-toggle="tooltip"
                                                title={audition.title}
                                            >
                                                {audition.title.length > 13
                                                    ? stringFirstUpperCase(
                                                          audition.title.slice(0, 12)
                                                      ) + '...'
                                                    : stringFirstUpperCase(
                                                          audition.title
                                                      )}
                                            </h3>
                                            <p>
                                                {capitalizeFirstLetter(
                                                    audition.description.slice(0, 30)
                                                )}
                                                ...
                                            </p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                ))
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        auditions: state.audition.auditions,
    };
};

export default withRouter(connect(mapStateToProps, actions)(AuditionList));
