import Actions from '../actions/actionTypes';

const initial = {
    notificationSettingsIndicator: {},
    privacySettings: {},
};

const SettingsReducer = (state = initial, action) => {
    switch (action.type) {
        case Actions.FETCH_NOTIFICATIONS_AND_ALERT_SETTINGS:
            return {
                ...state,
                settings: action.payload.notificationSettingsIndicator,
            };

        case Actions.FETCH_PRIVACY_SETTINGS:
            return {
                ...state,
                privacySettings: action.payload.privacySettingsIndicator,
            };

        case Actions.TOGGLE_READ_RECEIPT:
            return {
                ...state,
                privacySettings: {
                    ...state.privacySettings,
                    showReadReceipt: action.payload,
                },
            };

        case Actions.MAKE_ACCOUNT_PRIVATE:
            return {
                ...state,
                privacySettings: {
                    ...state.privacySettings,
                    makeAccountPrivate: action.payload,
                },
            };

        case Actions.SHOW_LOCATION:
            return {
                ...state,
                privacySettings: {
                    ...state.privacySettings,
                    showLocation: action.payload,
                },
            };

        case Actions.SUBSCRIBE_TO_NEWSLETTER:
            return {
                ...state,
                settings: {
                    ...state.notificationSettingsIndicator,
                    subscribeToNewsletter: action.payload,
                },
            };

        case Actions.ALLOW_EMAIL_NOTIFICATION:
            return {
                ...state,
                settings: {
                    ...state.notificationSettingsIndicator,
                    showEmailNotification: action.payload,
                },
            };

        default:
            return state;
    }
};

export default SettingsReducer;
