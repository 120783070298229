/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import XIcon from '../../../assets/img/svg/x.svg';
import CheckMark from '../../../assets/img/svg/success-checkmark.svg';
import $ from 'jquery';
import * as actions from '../../../redux/actions';
import {connect} from 'react-redux';

const ReportUser = (props) => {
    const handleReportUser = async (report) => {
        await props.reportUser({userId: props.userId, body: report});
        $('#reportUser').modal('hide');
    };

    return (
        <div className="modal fade" id="reportUser" role="dialog">
            <div className="modal-dialog modal-wrapper">
                <div className=" modal-content-wrap">
                    <div className="header-wrap">
                        <div className="close_">
                            <label data-dismiss="modal">
                                <img src={XIcon} alt="" />
                                <span>Close</span>
                            </label>
                        </div>
                        <div>
                            <p>Report this user</p>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="container wrapr">
                            <div className="tier_one">
                                <form>
                                    <section className="action-list">
                                        <h3>Why are you reporting this user</h3>
                                        <p
                                            onClick={() =>
                                                handleReportUser("It's a Spam")
                                            }
                                        >
                                            Its a Spam
                                        </p>
                                        <p
                                            onClick={() =>
                                                handleReportUser(
                                                    'Contents are Inappropriate'
                                                )
                                            }
                                        >
                                            Contents are Inappropriate
                                        </p>
                                        <p
                                            onClick={() =>
                                                handleReportUser(
                                                    'Content are voileting Privacy Law'
                                                )
                                            }
                                        >
                                            Content are voileting Privacy Law
                                        </p>
                                    </section>
                                </form>
                            </div>
                            <div className="tier_two">
                                <div className="success animated fadeIn">
                                    <div>
                                        <img src={CheckMark} alt="" />
                                    </div>
                                    <p>
                                        Thanks for your FeedBack
                                        <small>
                                            we will take on your response in a few mins
                                            and get back to you{' '}
                                        </small>
                                    </p>
                                    <a data-dismiss="modal">Close</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps, actions)(ReportUser);
