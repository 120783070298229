import React, {useState, useEffect} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import LeftSideBar from '../../../components/Feeds/LeftSideBar';
import Header from '../../../components/Feeds/Header';
import Footer from '../../../components/Feeds/Footer';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import $ from 'jquery';
import Select from 'react-select';
import {validateAll} from 'indicative/validator';

import Dropzone from 'react-dropzone';

import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';

import LessArrow from '../../../assets/img/svg/less-arrow.svg';
import FileAdd from '../../../assets/img/svg/file-add-icon.svg';
import CrossIcon from '../../../assets/img/svg/cross.svg';
import PlayIcon from '../../../assets/img/svg/play_icon.svg';
import useAlert from '../../../components/CustomHooks/useAlert';
import {capitalizeFirstLetter} from '../../../utils/capitalizeFirstLetter';
import {countries_states} from '../../../utils/countries_states';
import moment from 'moment';

const initial = {
    auditionName: '',
    description: '',
    startDate: null,
    endDate: null,
    auditionType: '',
    mediaUrl: null,
    talentCategories: '',
    ageRange: '',
    gender: 'all',
    countries: '',
    regions: '',
};

const EditForm = (props) => {
    const [formData, setFormData] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState('');
    const [, setFileSizeError] = useState(null);
    const [countriesList, setCountriesList] = useState([]);
    const [regionList, setRegionList] = useState([]);

    // fetch list of countries
    useEffect(() => {
        fetchCountries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchCountries = async () => {
        setCountriesList(countries_states);
        await props.getAllTalentCategories();
    };

    useEffect(() => {
        const {audition} = props;
        const country_states = audition.countries
            ? countries_states.find((i) => i.name === audition.countries[0])
            : null;

        setRegionList(country_states ? country_states.states : []);

        setFormData({
            auditionId: audition.auditionId,
            auditionName: audition.title,
            description: audition.description,
            startDate: new Date(moment(audition.startDate).utc().format('YYYY-MM-DD')),
            endDate: new Date(moment(audition.endDate).utc().format('YYYY-MM-DD')),
            auditionType: audition.auditionType,
            mediaUrl: audition.mediaUrl,
            talentCategories:
                audition.talentCategories.length > 0
                    ? props.talentCategories
                          .filter((d) => d.name === audition.talentCategories[0].name)
                          .map((a) => ({
                              label: capitalizeFirstLetter(a.name),
                              value: a.name,
                          }))
                    : '',
            ageRange: {
                label: `${
                    audition.startAge === 0 && audition.endAge === 100
                        ? 'All'
                        : audition.startAge === 50 && audition.endAge === 100
                        ? `${audition.startAge} year - above`
                        : `${audition.startAge} year - ${audition.endAge} years`
                }`,
                value: `${audition.startAge}-${audition.endAge}`,
                startAge: `${audition.startAge}`,
                endAge: `${audition.endAge}`,
            },
            gender: audition.gender,
            countries: {
                label: audition.countries ? audition.countries[0] : '',
                value: audition.countries ? audition.countries[0] : '',
            },
            regions: {
                label: audition.regions ? audition.regions[0] : '',
                value: audition.regions ? audition.regions[0] : '',
            },
            voteAmount: audition.voteAmount || null,
            auditionAmount: audition.auditionAmount || null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.audition, props.talentCategories]);

    const handleInputChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleErrorOnFocus = (name) => {
        setErrors({
            ...errors,
            [name]: '',
        });
    };

    const handleDrop = (accepted, rejected) => {
        if (accepted[0]) {
            // limit to 15mb => 15728640
            if (accepted[0].size <= 15728640) {
                handleInputChange('editMediaUrl', accepted[0]);

                $('.aud_vid').html('Upload Successful!!!');
                $('.upload-message').html(accepted[0].name);
                $('figure section').addClass('show');
                $('figure section .file_type .progress span').css({
                    transition: '1.5s ease-in',
                    width: '100%',
                });
                $('.btn-primary').attr('disabled', false);
            } else {
                setFileSizeError('File size is too big. (Maximum filesize is 15MB');
            }
        } else {
            $('.aud_vid').html('Upload Failed!!! Try again');
        }
    };

    const handleAuditionSubmit = (e) => {
        e.preventDefault();

        const {
            auditionName,
            description,
            startDate,
            endDate,
            talentCategories,
            auditionType,
            ageRange,
            voteAmount,
            auditionAmount,
        } = formData;

        const data = {
            auditionName,
            description,
            startDate,
            endDate,
            auditionType,
            talentCategories,
            ageRange,
            voteAmount,
            auditionAmount,
        };
        const rules = {
            auditionName: 'required',
            description: 'required',
            startDate: 'required',
            endDate: 'required',
            ageRange: 'required',
            voteAmount: 'number',
            auditionAmount: 'number',
        };

        const messages = {
            'auditionName.required': 'Audition name is required',
            'description.required': 'Desciption about the audition is required',
            'startDate.required': 'Start Date required',
            'endDate.required': 'End Date required',
            'ageRange.required':
                'Select the age range targeted for this audition or select All',
            'voteAmount.number': 'Enter a valid amount',
            'auditionAmount.number': 'Enter a valid amount',
        };

        validateAll(data, rules, messages)
            .then(() => {
                updateAudition();
            })
            .catch((errors) => {
                const formattedErrors = {};
                errors.forEach((error) => (formattedErrors[error.field] = error.message));
                setErrors(formattedErrors);
            });

        const updateAudition = async () => {
            const {
                auditionName,
                description,
                startDate,
                endDate,
                auditionType,
                mediaUrl,
                editMediaUrl,
                talentCategories,
                ageRange,
                countries,
                regions,
                gender,
                voteAmount,
                auditionAmount,
            } = formData;

            const payload = {
                auditionName,
                description,
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD'),
                auditionType,
                mediaUrl,
                gender,
                talentCategories,
                startAge: ageRange.startAge,
                endAge: ageRange.endAge,
                countries: countries ? countries.value : null,
                regions: regions ? regions.value : null,
            };

            setLoading(true);
            const file = new FormData();
            if (editMediaUrl) {
                file.append('file', editMediaUrl);
            }
            file.set('description', payload.description);
            file.set('auditionType', payload.auditionType);
            file.set('gender', payload.gender);
            file.set('startAge', payload.startAge);
            file.set('endAge', payload.endAge);
            file.set('auditionCategory', auditionAmount ? 'pay' : 'free');
            file.set('auditionAmount', auditionAmount || 0);
            file.set('voteCategory', voteAmount ? 'pay' : 'free');
            file.set('voteAmount', voteAmount || 0);

            if (payload.auditionName !== props.audition.title) {
                file.set('title', payload.auditionName);
            }

            if (
                payload.startDate !==
                moment(props.audition.startDate).format('YYYY-MM-DD')
            ) {
                file.set('startDate', payload.startDate + 'T00:00');
            }
            if (payload.endDate !== moment(props.audition.endDate).format('YYYY-MM-DD')) {
                file.set('endDate', payload.endDate + 'T23:59');
            }

            if (payload.countries) {
                file.set('countries', payload.countries);
            }
            if (payload.regions) {
                file.set('regions', payload.regions);
            }
            if (talentCategories) {
                file.set('talentCategories', [talentCategories.value]);
            } else {
                file.set('talentCategories', '-1');
            }

            // return;
            // Send request
            await props.updateAudition(formData.auditionId, file, props.history);
            setLoading(false);
        };
    };

    const renderSelectCategory = () => {
        const {talentCategories} = props;
        const options = talentCategories.map((c) => ({
            label: capitalizeFirstLetter(c.name),
            value: c.name,
        }));

        return (
            <div className="input bordered categories-select">
                <Select
                    name="talentCategories"
                    placeholder="Select Talent Category"
                    className="select"
                    onChange={(e) => handleInputChange('talentCategories', e)}
                    onFocus={() => handleErrorOnFocus('talentCategories')}
                    options={options}
                    isClearable
                    value={formData.talentCategories}
                    isLoading={props.talentCategories.length === 0 ? true : false}
                />
                <small className="text-danger">{errors.talentCategories}</small>
            </div>
        );
    };

    const renderAgeRange = () => {
        const options = [
            {
                label: 'All',
                value: '0-100',
                startAge: '0',
                endAge: '100',
            },
            {
                label: '0 year - 14 years',
                value: '0-14',
                startAge: '0',
                endAge: '14',
            },
            {
                label: '15 years - 24 years',
                value: '15-24',
                startAge: '15',
                endAge: '24',
            },
            {
                label: '25 years - 35 years',
                value: '25-35',
                startAge: '25',
                endAge: '35',
            },
            {
                label: '36 years - 45 years',
                value: '36-45',
                startAge: '36',
                endAge: '45',
            },
            {
                label: '46 years - 55 years',
                value: '46-55',
                startAge: '46',
                endAge: '55',
            },
            {
                label: '55 years - above',
                value: '55-100',
                startAge: '55',
                endAge: '100',
            },
        ];

        return (
            <div className="input bordered categories-select">
                <Select
                    name="ageRange"
                    placeholder="Select Age Range"
                    className="select"
                    onChange={(e) => handleInputChange('ageRange', e)}
                    onFocus={() => handleErrorOnFocus('ageRange')}
                    value={formData.ageRange}
                    options={options}
                    isClearable
                />
                <small className="text-danger">{errors.ageRange}</small>
            </div>
        );
    };

    const handleCountryChange = (e) => {
        setFormData({
            ...formData,
            countries: e,
        });

        if (e) {
            const country_states = countriesList.find((i) => i.name === e.value);
            setRegionList(country_states ? country_states.states : []);
        } else {
            setRegionList([]);
        }
    };

    const renderCountries = () => {
        const options = countriesList.map((c) => ({
            label: c.name,
            value: c.name,
        }));

        return (
            <div className="input bordered categories-select">
                <Select
                    name="countries"
                    placeholder="Select Country"
                    className="select"
                    onChange={handleCountryChange}
                    options={options}
                    isClearable
                    value={formData.countries}
                />
                <small className="form-info">
                    Note: You can omit country to make audition available worldwide
                </small>
            </div>
        );
    };

    const renderRegions = () => {
        const options = regionList.map((c) => ({
            label: c.name,
            value: c.name,
        }));

        return (
            <div className="input bordered categories-select">
                <Select
                    name="regions"
                    placeholder="Select State/City"
                    className="select"
                    onChange={(e) => handleInputChange('regions', e)}
                    options={options}
                    isClearable
                    value={formData.regions}
                />
                <small className="form-info">
                    Note: You can omit state/city to make audition available in the
                    selected country
                </small>
            </div>
        );
    };

    const renderGender = () => {
        return (
            <>
                <div className="tag">
                    <label>Select Gender</label>
                </div>
                <div className="">
                    <div className="radio_option mode">
                        <div className="custom-control custom-radio left">
                            <input
                                type="radio"
                                className="custom-control-input"
                                id="all"
                                name="gender"
                                value="all"
                                onChange={(e) =>
                                    handleInputChange('gender', e.target.value)
                                }
                                checked={formData.gender === 'all' ? true : false}
                            />
                            <label className="custom-control-label" htmlFor="all">
                                Both <small>Audition is available for all genders</small>
                            </label>
                        </div>
                        <div className="custom-control custom-radio left">
                            <input
                                type="radio"
                                className="custom-control-input"
                                id="male"
                                name="gender"
                                value="male"
                                onChange={(e) =>
                                    handleInputChange('gender', e.target.value)
                                }
                                checked={formData.gender === 'male' ? true : false}
                            />
                            <label className="custom-control-label" htmlFor="male">
                                Male <small>Audition is available for males only</small>
                            </label>
                        </div>
                        <div className="custom-control custom-radio right">
                            <input
                                type="radio"
                                className="custom-control-input"
                                id="female"
                                name="gender"
                                value="female"
                                onChange={(e) =>
                                    handleInputChange('gender', e.target.value)
                                }
                                checked={formData.gender === 'female' ? true : false}
                            />
                            <label className="custom-control-label" htmlFor="female">
                                Female
                                <small>Audition is available for females only</small>
                            </label>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <section className="audition-form" style={{margin: '0 5em'}}>
            <div className="head_">
                <Link to="#" onClick={() => props.history.goBack()}>
                    <img src={LessArrow} alt="" /> Back
                </Link>
                <div>
                    <p>
                        Edit Audition{' '}
                        <small>Make changes to this audition already created</small>
                    </p>
                </div>
            </div>
            <form onSubmit={handleAuditionSubmit}>
                <div className="input bordered">
                    <input
                        type="text"
                        name="auditionName"
                        value={formData.auditionName}
                        onChange={(e) =>
                            handleInputChange('auditionName', e.target.value)
                        }
                        onFocus={() => handleErrorOnFocus('auditionName')}
                        placeholder="Audition Name"
                    />
                    <label htmlFor="auditionName">Audition Name</label>
                    <small className="text-danger">{errors.auditionName}</small>
                </div>

                <div className="row">
                    <div className="col-lg-6">{renderSelectCategory()}</div>
                    <div className="col-lg-6">{renderAgeRange()}</div>
                    <div className="col-lg-6">{renderCountries()}</div>
                    <div className="col-lg-6">{renderRegions()}</div>
                </div>

                {renderGender()}

                <div className="input">
                    <textarea
                        id=""
                        cols="30"
                        rows="3"
                        name="description"
                        value={formData.description}
                        onChange={(e) => handleInputChange('description', e.target.value)}
                        onFocus={() => handleErrorOnFocus('description')}
                        placeholder="Short Description"
                    />
                    <small className="text-danger">{errors.description}</small>
                </div>

                <div className="d-flex justify-content-between">
                    <div className="input bordered date custom-date-select">
                        <DatePicker
                            name="startDate"
                            selected={formData.startDate}
                            selectsStart
                            startDate={formData.startDate}
                            endDate={formData.endDate}
                            onChange={(date) => handleInputChange('startDate', date)}
                            minDate={new Date()}
                            placeholderText="Start Date"
                            onFocus={() => handleErrorOnFocus('startDate')}
                        />
                        <small className="text-danger">{errors.startDate}</small>
                    </div>
                    <div className="input bordered date custom-date-select">
                        <DatePicker
                            name="endDate"
                            minDate={formData.startDate}
                            selected={formData.endDate}
                            selectsEnd
                            startDate={formData.startDate}
                            endDate={formData.endDate}
                            onChange={(date) => handleInputChange('endDate', date)}
                            placeholderText="End Date"
                            onFocus={() => handleErrorOnFocus('endDate')}
                        />
                        <small className="text-danger">{errors.endDate}</small>
                    </div>
                </div>

                {/* Audition / Votes amount currently not allowed during update */}

                {/*<div className="tag">*/}
                {/*    <label htmlFor="auditionType">Audition Type</label>*/}
                {/*    <small className="text-danger pl-2">{errors.auditionType}</small>*/}
                {/*</div>*/}
                {/*<div className="">*/}
                {/*    <div className="radio_option mode">*/}
                {/*        <div className="custom-control custom-radio left">*/}
                {/*            <input*/}
                {/*                type="radio"*/}
                {/*                className="custom-control-input"*/}
                {/*                id="voting"*/}
                {/*                name="auditionType"*/}
                {/*                value="voting"*/}
                {/*                onChange={(e) =>*/}
                {/*                    handleInputChange('auditionType', e.target.value)*/}
                {/*                }*/}
                {/*                onFocus={() => handleErrorOnFocus('auditionType')}*/}
                {/*                checked={*/}
                {/*                    formData.auditionType === 'voting' ? true : false*/}
                {/*                }*/}
                {/*            />*/}
                {/*            <label className="custom-control-label" htmlFor="voting">*/}
                {/*                Voting{' '}*/}
                {/*                <small>Talents can get votes from other talents</small>*/}
                {/*            </label>*/}
                {/*        </div>*/}
                {/*        <div className="custom-control custom-radio right">*/}
                {/*            <input*/}
                {/*                type="radio"*/}
                {/*                className="custom-control-input"*/}
                {/*                id="non-voting"*/}
                {/*                name="auditionType"*/}
                {/*                value="non-voting"*/}
                {/*                onChange={(e) =>*/}
                {/*                    handleInputChange('auditionType', e.target.value)*/}
                {/*                }*/}
                {/*                onFocus={() => handleErrorOnFocus('auditionType')}*/}
                {/*                checked={*/}
                {/*                    formData.auditionType === 'non-voting' ? true : false*/}
                {/*                }*/}
                {/*            />*/}
                {/*            <label className="custom-control-label" htmlFor="non-voting">*/}
                {/*                Non Voting{' '}*/}
                {/*                <small>Audition does not require votes from others</small>*/}
                {/*            </label>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="row">
                    <div className="col-lg-6">
                        <div className="input bordered">
                            <input
                                type="text"
                                name="auditionAmount"
                                value={formData.auditionAmount}
                                onChange={(e) =>
                                    handleInputChange('auditionAmount', e.target.value)
                                }
                                onFocus={() => handleErrorOnFocus('auditionAmount')}
                                placeholder="Audition Amount"
                            />
                            <label>Audition amount</label>
                            {errors.auditionAmount ? (
                                <small className="text-danger">
                                    {errors.auditionAmount}
                                </small>
                            ) : (
                                <small className="form-info">
                                    Leave blank, if it is free
                                </small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="input bordered">
                            <input
                                type="text"
                                name="auditionAmount"
                                value={formData.voteAmount}
                                onChange={(e) =>
                                    handleInputChange('voteAmount', e.target.value)
                                }
                                onFocus={() => handleErrorOnFocus('voteAmount')}
                                placeholder="Vote Amount"
                            />
                            <label>Vote amount</label>
                            {errors.voteAmount ? (
                                <small className="text-danger">{errors.voteAmount}</small>
                            ) : (
                                <small className="form-info">
                                    Leave blank, if it is free
                                </small>
                            )}
                        </div>
                    </div>
                </div>*/}

                <Dropzone onDrop={handleDrop} accept="image/*, video/*">
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <figure>
                                <p className="aud_vid">
                                    <button type="button">
                                        <img src={FileAdd} alt="" />
                                        Add file
                                    </button>
                                    or drop Images here
                                </p>

                                <section className="animated bounceIn upload-box">
                                    <aside className="">
                                        <img src={CrossIcon} alt="" />
                                    </aside>
                                    <div>
                                        <img src={PlayIcon} alt="" />
                                    </div>
                                    <div className="file_type">
                                        <p className="upload-message"></p>
                                        <div className="progress">
                                            <span></span>
                                        </div>
                                    </div>
                                </section>
                            </figure>
                        </div>
                    )}
                </Dropzone>

                <small style={{color: '#7158e2'}}>
                    Current file: {formData.mediaUrl && formData.mediaUrl.slice(96)}
                </small>

                <div className="action">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading ? true : false}
                    >
                        {loading ? 'Updating Audition...' : 'Update & Save'}
                    </button>
                </div>
            </form>
        </section>
    );
};

const EditAudition = (props) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchSingleAudition();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchSingleAudition = async () => {
        setLoading(true);
        await props.fetchSingleAuditions(props.match.params.slug, props.history);
        setLoading(false);
    };

    const renderLoader = () => {
        return (
            <section className="waiting">
                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </section>
        );
    };

    const renderDisplayIfNotPostOwner = () => {
        return (
            <div className="jumbotron">
                <p className="">You can't edit an audition not created by you.</p>
                <Link className="btn btn-primary btn-lg" to="/talent-auditions">
                    Go Back to Auditions
                </Link>
            </div>
        );
    };

    useAlert(props.alert);

    return (
        <>
            <Helmet>
                <title>Scout | Edit Auditions</title>
            </Helmet>
            <main className="main feeds">
                <LeftSideBar />
                <section id="wrapper">
                    <Header />
                    <div className="container content_">
                        {loading ? (
                            renderLoader()
                        ) : props.audition.isAuditionOwner ? (
                            <section className="scouts-audition">
                                <EditForm
                                    audition={props.audition}
                                    history={props.history}
                                    updateAudition={props.updateAudition}
                                    getAllTalentCategories={props.getAllTalentCategories}
                                    talentCategories={props.talentCategories}
                                />
                            </section>
                        ) : (
                            renderDisplayIfNotPostOwner()
                        )}
                        <Footer />
                    </div>
                </section>
            </main>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        audition: state.audition.audition,
        talentCategories: state.talentCategories.talentCategories,
    };
};

export default withRouter(connect(mapStateToProps, actions)(EditAudition));
