export const tabOptions = [
    {
        id: 1,
        linkName: 'Update Profile',
        linkUrl: 'update-profile',
        linkSummary: 'Change bio, name and other KYC info',
    },
    {
        id: 2,
        linkName: 'Change Password',
        linkUrl: 'change-password',
        linkSummary: 'Change bio, name and other KYC info',
    },
    {
        id: 3,
        linkName: 'Privacy & Safety',
        linkUrl: 'privacy',
        linkSummary: 'Protect your post images and videos',
    },
    {
        id: 4,
        linkName: 'Notifcation & Alerts',
        linkUrl: 'account-notifications',
        linkSummary: 'Configure how you want to recieve notifications',
    },
    {
        id: 5,
        linkName: 'Support',
        linkUrl: 'support',
        linkSummary: 'Our contact informations and support channels',
    },
];
