import React from 'react';
import {Helmet} from 'react-helmet';
import LeftSideBar from '../../components/Feeds/LeftSideBar';
import Header from '../../components/Feeds/Header';
import Footer from '../../components/Feeds/Footer';
import FollowRequestList from '../../components/Feeds/Follows/FollowRequestList';

const FollowRequests = () => {
    return (
        <>
            <Helmet>
                <title>Talents | Follow Request</title>
            </Helmet>
            <main className="main feeds">
                <LeftSideBar />
                <section id="wrapper">
                    <Header />
                    <div className="container content_">
                        <FollowRequestList />
                        <Footer />
                    </div>
                </section>
            </main>
        </>
    );
};

export default FollowRequests;
