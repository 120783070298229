import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Link, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import $ from 'jquery';

import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';
import {validateAll} from 'indicative/validator';

import LeftSideBar from '../../../components/Feeds/LeftSideBar';
import Header from '../../../components/Feeds/Header';
import Footer from '../../../components/Feeds/Footer';
import Select from 'react-select';
import LessArrow from '../../../assets/img/svg/less-arrow.svg';
import FileAdd from '../../../assets/img/svg/file-add-icon.svg';
import CrossIcon from '../../../assets/img/svg/cross.svg';
import PlayIcon from '../../../assets/img/svg/play_icon.svg';

import Dropzone from 'react-dropzone';
import useAlert from '../../../components/CustomHooks/useAlert';
import {countries_states} from '../../../utils/countries_states';
import {capitalizeFirstLetter} from '../../../utils/capitalizeFirstLetter';

const ageRangeOptions = [
    {
        label: 'All',
        value: '0-100',
        startAge: '0',
        endAge: '100',
    },
    {
        label: '0 year - 14 years',
        value: '0-14',
        startAge: '0',
        endAge: '14',
    },
    {
        label: '15 years - 24 years',
        value: '15-24',
        startAge: '15',
        endAge: '24',
    },
    {
        label: '25 years - 35 years',
        value: '25-35',
        startAge: '25',
        endAge: '35',
    },
    {
        label: '36 years - 45 years',
        value: '36-45',
        startAge: '36',
        endAge: '45',
    },
    {
        label: '46 years - 55 years',
        value: '46-55',
        startAge: '46',
        endAge: '55',
    },
    {
        label: '55 years - above',
        value: '55-100',
        startAge: '55',
        endAge: '100',
    },
];

const initial = {
    auditionName: '',
    description: '',
    startDate: null,
    endDate: null,
    auditionType: '',
    mediaUrl: null,
    talentCategories: '',
    ageRange: ageRangeOptions[0],
    gender: 'all',
    countries: '',
    regions: '',
};
const AuditionForm = (props) => {
    const [formData, setFormData] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState('');
    const [fileSizeError, setFileSizeError] = useState(null);
    const [countriesList, setCountriesList] = useState([]);
    const [regionList, setRegionList] = useState([]);

    // fetch list of countries
    useEffect(() => {
        fetchCountries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchCountries = async () => {
        setCountriesList(countries_states);
        await props.getAllTalentCategories();
    };

    const handleInputChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCountryChange = (e) => {
        setFormData({
            ...formData,
            countries: e,
        });

        if (e) {
            const country_states = countriesList.find((i) => i.name === e.value);
            setRegionList(country_states ? country_states.states : []);
        } else {
            setRegionList([]);
        }
    };

    const handleErrorOnFocus = (name) => {
        setErrors({
            ...errors,
            [name]: '',
        });
    };

    const handleDrop = (accepted, rejected) => {
        if (accepted[0]) {
            // limit to 15mb => 15728640
            if (accepted[0].size <= 15728640) {
                handleInputChange('mediaUrl', accepted[0]);

                $('.aud_vid').html('Upload Successful!!!');
                $('.upload-message').html(accepted[0].name);
                $('figure section').addClass('show');
                $('figure section .file_type .progress span').css({
                    transition: '1.5s ease-in',
                    width: '100%',
                });
                $('.btn-primary').attr('disabled', false);
            } else {
                setFileSizeError('File size is too big. (Maximum filesize is 15MB');
            }
        } else {
            $('.aud_vid').html('Upload Failed!!! Try again');
        }
    };

    const handleAuditionSubmit = (e) => {
        e.preventDefault();

        const {
            auditionName,
            description,
            startDate,
            endDate,
            talentCategories,
            auditionType,
            ageRange,
            voteAmount,
            auditionAmount,
        } = formData;

        const data = {
            auditionName,
            description,
            startDate,
            endDate,
            auditionType,
            talentCategories,
            ageRange,
            voteAmount,
            auditionAmount,
        };
        const rules = {
            auditionName: 'required',
            description: 'required',
            startDate: 'required',
            endDate: 'required',
            auditionType: 'required',
            ageRange: 'required',
            voteAmount: 'number',
            auditionAmount: 'number',
        };

        const messages = {
            'auditionName.required': 'Audition name is required',
            'description.required': 'Description about the audition is required',
            'startDate.required': 'Start Date required',
            'endDate.required': 'End Date required',
            'auditionType.required': 'Pick an audition type',
            'ageRange.required':
                'Select the age range targeted for this audition or select All',
            'voteAmount.number': 'Enter a valid amount',
            'auditionAmount.number': 'Enter a valid amount',
        };

        validateAll(data, rules, messages)
            .then(() => {
                createNewAudition();
            })
            .catch((errors) => {
                const formattedErrors = {};
                errors.forEach((error) => (formattedErrors[error.field] = error.message));
                setErrors(formattedErrors);
            });

        const createNewAudition = async () => {
            const {
                auditionName,
                description,
                startDate,
                endDate,
                auditionType,
                mediaUrl,
                talentCategories,
                ageRange,
                countries,
                regions,
                gender,
                voteAmount,
                auditionAmount,
            } = formData;

            const payload = {
                auditionName,
                description,
                startDate: moment(startDate).format('YYYY-MM-DD') + 'T00:00',
                endDate: moment(endDate).format('YYYY-MM-DD') + 'T23:59',
                auditionType,
                mediaUrl,
                gender,
                startAge: ageRange.startAge,
                endAge: ageRange.endAge,
                countries: countries ? countries.value : null,
                regions: regions ? regions.value : null,
            };

            if (mediaUrl) {
                setLoading(true);
                const file = new FormData();
                file.append('file', payload.mediaUrl);
                file.set('title', payload.auditionName);
                file.set('description', payload.description);
                file.set('startDate', payload.startDate);
                file.set('endDate', payload.endDate);
                file.set('auditionType', payload.auditionType);
                file.set('gender', payload.gender);
                file.set('startAge', payload.startAge);
                file.set('endAge', payload.endAge);
                if (payload.countries) {
                    file.set('countries', payload.countries);
                }
                if (payload.regions) {
                    file.set('regions', payload.regions);
                }
                file.set('auditionCategory', auditionAmount ? 'pay' : 'free');
                if (auditionAmount) {
                    file.set('auditionAmount', auditionAmount);
                }
                file.set('voteCategory', voteAmount ? 'pay' : 'free');
                if (voteAmount) {
                    file.set('voteAmount', voteAmount);
                }
                if (talentCategories) {
                    file.set('talentCategories', [talentCategories.value]);
                }

                // Send request
                await props.createAudition(file, props.history);
                setLoading(false);
            } else {
                setErrors({
                    ...errors,
                    mediaUrl:
                        'Make sure you upload your image/video that matches your audition type',
                });
            }
        };
    };

    const renderSelectCategory = () => {
        const {talentCategories} = props;
        const options = talentCategories.map((c) => ({
            label: capitalizeFirstLetter(c.name),
            value: c.name,
        }));

        return (
            <div className="input bordered categories-select">
                <Select
                    name="talentCategories"
                    placeholder="Select Talent Category"
                    className="select"
                    onChange={(e) => handleInputChange('talentCategories', e)}
                    onFocus={() => handleErrorOnFocus('talentCategories')}
                    options={options}
                    isClearable
                    isLoading={props.talentCategories.length === 0 ? true : false}
                />
                <small className="text-danger">{errors.talentCategories}</small>
            </div>
        );
    };

    const renderAgeRange = () => {
        return (
            <div className="input bordered categories-select">
                <Select
                    name="ageRange"
                    placeholder="Select Age Range"
                    className="select"
                    onChange={(e) => handleInputChange('ageRange', e)}
                    onFocus={() => handleErrorOnFocus('ageRange')}
                    options={ageRangeOptions}
                    value={formData.ageRange}
                    isClearable
                />
                <small className="text-danger">{errors.ageRange}</small>
            </div>
        );
    };

    const renderCountries = () => {
        const options = countriesList.map((c) => ({
            label: c.name,
            value: c.name,
        }));

        return (
            <div className="input bordered categories-select">
                <Select
                    name="countries"
                    placeholder="Select Country"
                    className="select"
                    onChange={handleCountryChange}
                    options={options}
                    isClearable
                />
                <small className="form-info">
                    Note: You can omit country to make audition available worldwide
                </small>
            </div>
        );
    };

    const renderRegions = () => {
        const options = regionList.map((c) => ({
            label: c.name,
            value: c.name,
        }));

        return (
            <div className="input bordered categories-select">
                <Select
                    name="regions"
                    placeholder="Select State/City"
                    className="select"
                    onChange={(e) => handleInputChange('regions', e)}
                    options={options}
                    isClearable
                />
                <small className="form-info">
                    Note: You can omit state/city to make audition available in the
                    selected country
                </small>
            </div>
        );
    };

    const renderGender = () => {
        return (
            <>
                <div className="tag">
                    <label>Select Gender</label>
                </div>
                <div className="">
                    <div className="radio_option mode">
                        <div className="custom-control custom-radio left">
                            <input
                                type="radio"
                                className="custom-control-input"
                                id="all"
                                name="gender"
                                value="all"
                                onChange={(e) =>
                                    handleInputChange('gender', e.target.value)
                                }
                                checked={formData.gender === 'all' ? true : false}
                            />
                            <label className="custom-control-label" for="all">
                                Both <small>Audition is available for all genders</small>
                            </label>
                        </div>
                        <div className="custom-control custom-radio left">
                            <input
                                type="radio"
                                className="custom-control-input"
                                id="male"
                                name="gender"
                                value="male"
                                onChange={(e) =>
                                    handleInputChange('gender', e.target.value)
                                }
                                checked={formData.gender === 'male' ? true : false}
                            />
                            <label className="custom-control-label" for="male">
                                Male <small>Audition is available for males only</small>
                            </label>
                        </div>
                        <div className="custom-control custom-radio right">
                            <input
                                type="radio"
                                className="custom-control-input"
                                id="female"
                                name="gender"
                                value="female"
                                onChange={(e) =>
                                    handleInputChange('gender', e.target.value)
                                }
                                checked={formData.gender === 'female' ? true : false}
                            />
                            <label className="custom-control-label" for="female">
                                Female
                                <small>Audition is available for females only</small>
                            </label>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <section className="audition-form">
            <div className="head_">
                <Link to onClick={() => props.history.goBack()}>
                    <img src={LessArrow} alt="" /> Back
                </Link>
                <div>
                    <p>
                        Create New Audition{' '}
                        <small>Fill the information below to create your audition</small>
                    </p>
                </div>
            </div>
            <form onSubmit={handleAuditionSubmit}>
                <div className="input bordered">
                    <input
                        type="text"
                        name="auditionName"
                        value={formData.auditionName}
                        onChange={(e) =>
                            handleInputChange('auditionName', e.target.value)
                        }
                        onFocus={() => handleErrorOnFocus('auditionName')}
                        placeholder="Audition Name"
                    />
                    <label>Audition Name</label>
                    <small className="text-danger">{errors.auditionName}</small>
                </div>

                <div className="row">
                    <div className="col-lg-6">{renderSelectCategory()}</div>
                    <div className="col-lg-6">{renderAgeRange()}</div>
                    <div className="col-lg-6">{renderCountries()}</div>
                    <div className="col-lg-6">{renderRegions()}</div>
                </div>

                {renderGender()}

                <div className="input">
                    <textarea
                        id=""
                        cols="30"
                        rows="3"
                        name="description"
                        value={formData.description}
                        onChange={(e) => handleInputChange('description', e.target.value)}
                        onFocus={() => handleErrorOnFocus('description')}
                        placeholder="Short Description"
                    />
                    <small className="text-danger">{errors.description}</small>
                </div>

                <div className="d-flex justify-content-between">
                    <div className="input bordered date custom-date-select">
                        <DatePicker
                            name="startDate"
                            selected={formData.startDate}
                            selectsStart
                            startDate={formData.startDate}
                            endDate={formData.endDate}
                            onChange={(date) => handleInputChange('startDate', date)}
                            minDate={new Date()}
                            placeholderText="Start Date"
                            onFocus={() => handleErrorOnFocus('startDate')}
                        />
                        <small className="text-danger">{errors.startDate}</small>
                    </div>

                    <div className="input bordered date custom-date-select">
                        <DatePicker
                            name="endDate"
                            minDate={formData.startDate}
                            selected={formData.endDate}
                            selectsEnd
                            startDate={formData.startDate}
                            endDate={formData.endDate}
                            onChange={(date) => handleInputChange('endDate', date)}
                            placeholderText="End Date"
                            onFocus={() => handleErrorOnFocus('endDate')}
                        />
                        <small className="text-danger">{errors.endDate}</small>
                    </div>
                </div>

                <div className="tag">
                    <label>Audition Type</label>
                    <small className="text-danger pl-2">{errors.auditionType}</small>
                </div>
                <div className="">
                    <div className="radio_option mode">
                        <div className="custom-control custom-radio left">
                            <input
                                type="radio"
                                className="custom-control-input"
                                id="voting"
                                name="auditionType"
                                value="voting"
                                onChange={(e) =>
                                    handleInputChange('auditionType', e.target.value)
                                }
                                onFocus={() => handleErrorOnFocus('auditionType')}
                            />
                            <label className="custom-control-label" for="voting">
                                Voting{' '}
                                <small>Talents can get votes from other talents</small>
                            </label>
                        </div>
                        <div className="custom-control custom-radio right">
                            <input
                                type="radio"
                                className="custom-control-input"
                                id="non-voting"
                                name="auditionType"
                                value="non-voting"
                                onChange={(e) =>
                                    handleInputChange('auditionType', e.target.value)
                                }
                                onFocus={() => handleErrorOnFocus('auditionType')}
                            />
                            <label className="custom-control-label" for="non-voting">
                                Non Voting{' '}
                                <small>Audition does not require votes from others</small>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <div className="input bordered">
                            <input
                                type="text"
                                name="auditionAmount"
                                value={formData.auditionAmount}
                                onChange={(e) =>
                                    handleInputChange('auditionAmount', e.target.value)
                                }
                                onFocus={() => handleErrorOnFocus('auditionAmount')}
                                placeholder="Registration Amount"
                            />
                            <label>Registration amount</label>
                            {errors.auditionAmount ? (
                                <small className="text-danger">
                                    {errors.auditionAmount}
                                </small>
                            ) : (
                                <small className="form-info">
                                    Leave blank, if it is free
                                </small>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="input bordered">
                            <input
                                type="text"
                                name="auditionAmount"
                                value={formData.voteAmount}
                                onChange={(e) =>
                                    handleInputChange('voteAmount', e.target.value)
                                }
                                onFocus={() => handleErrorOnFocus('voteAmount')}
                                placeholder="Vote Amount"
                            />
                            <label>Vote amount</label>
                            {errors.voteAmount ? (
                                <small className="text-danger">{errors.voteAmount}</small>
                            ) : (
                                <small className="form-info">
                                    Leave blank, if it is free
                                </small>
                            )}
                        </div>
                    </div>
                </div>

                <Dropzone onDrop={handleDrop} accept="image/*, video/*">
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <figure>
                                <p className="aud_vid">
                                    <button type="button">
                                        <img src={FileAdd} alt="" />
                                        Add file
                                    </button>
                                    or drop Images here
                                </p>

                                <section className="animated bounceIn upload-box">
                                    <aside className="">
                                        <img src={CrossIcon} alt="" />
                                    </aside>
                                    <div>
                                        <img src={PlayIcon} alt="" />
                                    </div>
                                    <div className="file_type">
                                        <p className="upload-message"></p>
                                        <div className="progress">
                                            <span></span>
                                        </div>
                                    </div>
                                </section>
                            </figure>
                        </div>
                    )}
                </Dropzone>
                <small className="text-danger">{errors.mediaUrl || fileSizeError}</small>

                <div className="action">
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                        {loading ? 'Creating Audition...' : 'Create Audition'}
                    </button>
                </div>
            </form>
        </section>
    );
};

const CreateAudition = (props) => {
    useAlert(props.alert);

    return (
        <>
            <Helmet>
                <title>Scout | Create Auditions</title>
            </Helmet>
            <main className="main feeds">
                <LeftSideBar />
                <section id="wrapper">
                    <Header />
                    <div className="container content_">
                        <section className="scouts-audition">
                            <AuditionForm
                                createAudition={props.createAudition}
                                history={props.history}
                                getAllTalentCategories={props.getAllTalentCategories}
                                talentCategories={props.talentCategories}
                            />
                        </section>
                        <Footer />
                    </div>
                </section>
            </main>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        alert: state.alert,
        talentCategories: state.talentCategories.talentCategories,
    };
};

export default withRouter(connect(mapStateToProps, actions)(CreateAudition));
