import React from 'react';

const LoadingDetails = () => {
    return (
        <div className="col-lg-7">
            <aside>
                <section className="content-post loading-state_post">
                    <div className="panel">
                        <div className="head">
                            <div className="media-panel">
                                <figure>
                                    <div></div>
                                </figure>
                                <section>
                                    <div></div>
                                </section>
                            </div>
                        </div>
                        <section className="content">
                            <div className="media" style={{background: '#f2f2f2'}}></div>
                        </section>
                    </div>
                </section>
            </aside>
        </div>
    );
};

const LoadingComments = () => {
    return (
        <div className="col-lg-5">
            <section className="comments-post-wrap loading-state_comments">
                <div className="panel">
                    <div className="head">
                        <label htmlFor="">Comments</label>
                    </div>
                    <div className="d-comments">
                        <aside>
                            <div className="media-panel">
                                <figure>
                                    <div></div>
                                </figure>
                                <section>
                                    <div></div>
                                    <div></div>
                                </section>
                            </div>
                            <div className="media-panel">
                                <figure>
                                    <div></div>
                                </figure>
                                <section>
                                    <div></div>
                                    <div></div>
                                </section>
                            </div>
                            <div className="media-panel">
                                <figure>
                                    <div></div>
                                </figure>
                                <section>
                                    <div></div>
                                    <div></div>
                                </section>
                            </div>
                            <div className="media-panel">
                                <figure>
                                    <div></div>
                                </figure>
                                <section>
                                    <div></div>
                                    <div></div>
                                </section>
                            </div>
                        </aside>
                    </div>
                </div>
            </section>
        </div>
    );
};

const PostDetailsLoader = () => {
    return (
        <>
            <LoadingDetails />
            <LoadingComments />
        </>
    );
};
export default PostDetailsLoader;
