import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from '../../../redux/actions';

const PostComments = (props) => {
    const {commentLists, hideComment, history, location} = props;

    return (
        <>
            {hideComment ? (
                ''
            ) : (
                <section className="post">
                    {props.isCommentStart ? (
                        <>
                            <div className="comment-placeholder">
                                <figure></figure>
                            </div>
                            <div className="comment-placeholder">
                                <figure></figure>
                            </div>
                        </>
                    ) : (
                        ''
                    )}
                    {commentLists.length === 0
                        ? ''
                        : commentLists.slice(0, 3).map((commentList) => (
                              <p key={commentList.commentId}>
                                  <span
                                      onClick={() =>
                                          location.pathname === '/talents' ||
                                          location.pathname === '/scouts'
                                              ? ''
                                              : history.push(
                                                    `/profile/${commentList.commentorId}`
                                                )
                                      }
                                  >
                                      {commentList.commentorName}
                                  </span>{' '}
                                  {commentList.comment}
                              </p>
                          ))}
                </section>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {};
};

export default withRouter(connect(mapStateToProps, actions)(PostComments));
