import React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../components/layout/MainLayout/Header';
import Footer from '../components/layout/MainLayout/Footer';
import TalentFeedsWrapper from '../components/Talents/TalentFeedsWrapper';

import Shutter from '../assets/img/png/shutter 4.png';
import DotPattern from '../assets/img/svg/dot-pattern.svg';

const Intro = () => {
    return (
        <section className="intro">
            <div className="tier_one">
                <div className="container">
                    <div className="h3ro">
                        <h1>Your Next Big Discovery is waiting</h1>
                        <p>
                            Find talents you’ll fall in love with and have the whole world
                            in awe of. They’re waiting for you!
                        </p>
                    </div>
                    <div className="deco">
                        <img src={Shutter} alt="shutter" />
                        <img className="pattern" src={DotPattern} alt="pattern" />
                    </div>
                </div>
            </div>
        </section>
    );
};

const Scouts = () => {
    const defaultFilter = {areaOfInterest: 'scout'};
    return (
        <>
            <Helmet>
                <title>Scouts Details</title>
            </Helmet>
            <Header />
            <main className="plublic">
                <Intro />
                <TalentFeedsWrapper defaultFilter={defaultFilter} />
            </main>
            <Footer />
        </>
    );
};

export default Scouts;
