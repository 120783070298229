import React from 'react';

import PlusIcon from '../../../assets/img/svg/plus-icon-white.svg';
import SearchIcon from '../../../assets/img/svg/search-icon.svg';
import Profile3 from '../../../assets/img/svg/user.svg';
import {capitalizeFirstLetter} from '../../../utils/capitalizeFirstLetter';
import {TimeSettings} from '../../../utils/TimeSettings';
import $ from 'jquery';

const Loader = () => {
    return (
        <div className="col">
            <div className="chat-loader">
                <div className="media-panel d-flex">
                    <figure>
                        <div></div>
                    </figure>
                    <section>
                        <div></div>
                        <div></div>
                    </section>
                </div>
            </div>
        </div>
    );
};

// Main Wrapper
const ChatFriends = (props) => {
    const renderLoader = () => {
        return (
            <>
                <Loader />
                <Loader />
                <Loader />
            </>
        );
    };

    const toggleContactsOnSMDevice = (hideDropdown) => {
        if (hideDropdown) {
            $('.col-lg-5 .contacts_body').removeClass('show');
            $('.col-lg-5 .contacts_body').removeClass('animated fadeIn');
        } else {
            $('.col-lg-5 .contacts_body').toggleClass('show');
            $('.col-lg-5 .contacts_body').addClass('animated fadeIn');
        }
    };

    const renderConversations = () => {
        if (!props.conversations) {
            return <React.Fragment />;
        }

        if (props.conversations.length === 0) {
            return (
                <li className="">
                    <div className="d-flex">
                        <div className="user_info">
                            <div>
                                <p>No recent chat available</p>
                            </div>
                        </div>
                    </div>
                </li>
            );
        } else {
            // type searching a conversation list by user name
            const filteredConversations =
                props.conversationSearch === null
                    ? props.conversations
                    : props.conversations.filter(
                          (conversation) =>
                              conversation.userInfo &&
                              conversation.userInfo.fullName &&
                              conversation.userInfo.fullName
                                  .toLowerCase()
                                  .includes(
                                      props.conversationSearch &&
                                          props.conversationSearch.toLowerCase()
                                  )
                      );

            return filteredConversations.map((conversation) => (
                <li
                    key={conversation.message.conversationId}
                    className={`${
                        props.userInfo.id === conversation.userInfo.id ? 'active' : ''
                    }`}
                    onClick={() => {
                        toggleContactsOnSMDevice(true);
                        props.handleChatterClick(conversation.userInfo);
                    }}
                >
                    <div className="d-flex">
                        <div className="img_cont">
                            <img
                                src={conversation.userInfo.imageUrl || Profile3}
                                alt=""
                                style={{
                                    width: '3rem',
                                    height: '3rem',
                                    borderRadius: '50%',
                                }}
                            />
                        </div>
                        <div className="user_info">
                            <div>
                                <span>
                                    {capitalizeFirstLetter(
                                        conversation.userInfo.fullName
                                    )}
                                </span>
                            </div>
                            <div>
                                <p>{conversation.message.body}</p>
                                <small>
                                    {TimeSettings(conversation.message.createdAt)}
                                </small>
                            </div>
                        </div>
                    </div>
                </li>
            ));
        }
    };

    return (
        <div className="col-lg-5">
            <div className="card">
                <div className="cardheader">
                    <div className="d-flex justify-content-between">
                        <h5>Messages</h5>
                        <div className="sm-action">
                            <span
                                className="action-btn-icon"
                                onClick={() => toggleContactsOnSMDevice()}
                            >
                                <img src={PlusIcon} alt="" />
                            </span>
                        </div>
                    </div>

                    <form
                        autoComplete="off"
                        onSubmit={(e) => props.handleMessageSearch(e)}
                    >
                        <div className="input search">
                            <i className="tag">
                                <img src={SearchIcon} alt="" />
                            </i>
                            <input
                                type="text"
                                name="search_message"
                                onChange={(e) => props.handleMessageSearch(e)}
                                placeholder="Search for messages"
                            />
                        </div>
                    </form>
                </div>
                <div className="card-body contacts_body">
                    <ul className="contacts">
                        {props.loading ? renderLoader() : renderConversations()}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ChatFriends;
