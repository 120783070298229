import React, {useState, useRef, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import * as actions from '../../../redux/actions';

import CropPostImageModal from './CropPostImageModal';
import CreatePostModal from './CreatePostModal';

//--------------MAIN CREATE POST WRAPPER-------------------//
// Main Post on Feeds Page that renders the modal window
const CreatePostWrapper = (props) => {
    const imageRef = useRef(null);
    const previewCanvasRef = useRef(null);

    // store the image to crop on input of file
    const [toCropImage, setToCropImage] = useState('');

    // crop setting
    const [crop, setCrop] = useState({
        x: 50,
        y: 50,
        unit: '%',
        width: 50,
        height: 40,
        aspect: 16 / 10,
    });

    // store the cropped image url
    const [completedCrop, setCompletedCrop] = useState(null);

    // store the blob object to be sent to backend
    const [blobImage, setBlobImage] = useState({});

    // update with the crop image
    const handleToCropImage = (image) => {
        setToCropImage(image);
    };

    // handle on change of cropping
    const handleCropChange = (cropImage) => {
        setCrop(cropImage);
    };

    // handle on load of image to crop
    const handleLoad = (image) => {
        imageRef.current = image;

        setCrop({
            x: 0,
            y: 0,
            unit: '%',
            width: 100,
            height: 100,
            // aspect: 16 / 10
        });
        return false;
    };

    // We resize the canvas down when saving on retina devices otherwise the image
    // will be double or triple the preview size.

    // Setting a high pixel ratio avoids blurriness in the canvas crop preview.
    const pixelRatio = 4;

    function getResizedCanvas(canvas, newWidth, newHeight) {
        const tmpCanvas = document.createElement('canvas');
        tmpCanvas.width = newWidth;
        tmpCanvas.height = newHeight;

        const ctx = tmpCanvas.getContext('2d');
        ctx.drawImage(
            canvas,
            0,
            0,
            canvas.width,
            canvas.height,
            0,
            0,
            newWidth,
            newHeight
        );

        return tmpCanvas;
    }

    function handleCroppingSaveChanges(previewCanvas, crop) {
        if (!crop || !previewCanvas) {
            return;
        }

        const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);

        canvas.toBlob(
            (blob) => {
                setBlobImage(blob);
            },
            'image/png',
            1
        );
    }

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imageRef.current) {
            return;
        }

        const image = imageRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingEnabled = false;

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
    }, [completedCrop]);

    const handleCompletedCrop = (cropImage, percentCrop) => {
        setCompletedCrop(cropImage);
    };

    // clear cropped data on close of post modal
    const handleCropDataClear = () => {
        setCompletedCrop('');
        setBlobImage('');
        setToCropImage('');
        imageRef.current = null;
        previewCanvasRef.current = null;
    };

    return (
        <section className="form">
            {/* Children Component goes here */}
            {props.children}

            {/* Modal window */}
            <CreatePostModal
                handleToCropImage={handleToCropImage}
                completedCrop={completedCrop}
                blobImage={blobImage}
                createNewPost={props.createNewPost}
                talentCategories={props.talentCategories}
                history={props.history}
                previewCanvasRef={previewCanvasRef}
                handleCropDataClear={handleCropDataClear}
            />

            <CropPostImageModal
                toCropImage={toCropImage}
                crop={crop}
                completedCrop={completedCrop}
                handleCompletedCrop={handleCompletedCrop}
                handleCropChange={handleCropChange}
                handleLoad={handleLoad}
                previewCanvasRef={previewCanvasRef}
                handleCroppingSaveChanges={() =>
                    handleCroppingSaveChanges(previewCanvasRef.current, completedCrop)
                }
            />
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        talentCategories: state.feeds.talentCategories,
    };
};

export default withRouter(connect(mapStateToProps, actions)(CreatePostWrapper));
