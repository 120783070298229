import React, {useEffect} from 'react';
import LeftSidebar from './LeftSidebar';
import RightSidebar from './RightSidebar';
import TalentCenterBar from './TalentCenterBar';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from '../../redux/actions';
import useAlert from '../CustomHooks/useAlert';
import {sortAlphabets} from '../../utils/alphabetically';

const TalentFeedsWrapper = (props) => {
    const defaultFilter = props.defaultFilter || {};
    useEffect(() => {
        props.talentCategories[0] &&
            props.fetchFeeds(
                true,
                'discover',
                `${props.talentCategories[0].talentCategoryId}`,
                props.history,
                {...defaultFilter}
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.talentCategories]);

    useAlert(props.alert);

    return (
        <section className="highlights">
            <div className="container">
                <div className="row">
                    <LeftSidebar defaultFilter={defaultFilter} categories={sortAlphabets(props.talentCategories)} />

                    <TalentCenterBar categories={sortAlphabets(props.talentCategories)} />

                    <RightSidebar />
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        alert: state.alert,
        talentCategories: state.talentCategories.talentCategories,
    };
};

export default withRouter(connect(mapStateToProps, actions)(TalentFeedsWrapper));
