import React from 'react';
export const displayLoader = (
    title,
    description = 'Hold on to your seat belt, its about to be a journey',
    icon = ''
) => {
    return (
        <div className="loader">
            {icon ? (
                <div className="">
                    <img src={icon} alt="" />
                </div>
            ) : (
                <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            )}
            <p className="load">
                {title} <small>{description}</small>
            </p>
        </div>
    );
};
