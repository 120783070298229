/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import HorizontalIcon from '../../../assets/img/svg/horizontal-ellipsis.svg';
import FileIcon from '../../../assets/img/svg/file-icon_.svg';
import Profile3 from '../../../assets/img/svg/user.svg';
import FileIconWhite from '../../../assets/img/svg/file-icon-white.svg';
import ReadImg from '../../../assets/img/svg/double-check.svg';
import UnReadImg from '../../../assets/img/svg/double-check-unread.svg';
import {Link} from 'react-router-dom';
import {showDayBasedOnUTCDate} from '../../../utils/showDayBasedOnUTCDate';

const TypingMessage = ({typing}) => {
    return (
        <p
            className="mb-5 small"
            style={{color: '#7158e2', fontStyle: 'italic', textAlign: 'left'}}
        >
            {typing || ''}
        </p>
    );
};

const Chats = (props) => {
    const {messageLoading, previousMessages, userInfo, typing} = props;
    const [restructuredPrevMessages, setRestructuredPrevMessages] = useState([]);
    const scrollDown = () => {
        const chatBodyElement = document.getElementById('chats-body');
        if (chatBodyElement) {
            chatBodyElement.scrollTop =
                chatBodyElement.scrollHeight - chatBodyElement.clientHeight;
        }
    };

    useEffect(() => {
        let mount = true;
        if (mount) {
            // Restructure the messages to show chat based on date chatted
            const grouped = previousMessages.reduce((acc, item) => {
                const found = acc.find(
                    (i) =>
                        new Date(i.createdAt).getUTCDate() ===
                        new Date(item.createdAt).getUTCDate()
                );
                const messages = {
                    messageId: item.messageId,
                    body: item.body,
                    createdAt: item.createdAt,
                    sender: item.sender,
                    fileUrl: item.fileUrl || '',
                    read: item.read,
                    seen: item.seen,
                };

                if (found) {
                    found.content.push(messages);
                } else {
                    acc.push({
                        createdAt: item.createdAt,
                        content: [messages],
                    });
                }

                return acc;
            }, []);

            setRestructuredPrevMessages(grouped);
        }

        return () => {
            mount = false;
        };
    }, [previousMessages]);

    useEffect(() => {
        scrollDown();
    }, [restructuredPrevMessages]);

    return (
        <>
            {messageLoading ? (
                <p className="chat-message-loading">Loading...</p>
            ) : (
                <div className="card-body" id="chats-body">
                    {restructuredPrevMessages.map((restructuredPrevMessage) => (
                        <React.Fragment key={restructuredPrevMessage.createdAt}>
                            <div className="tracker">
                                <span>
                                    {showDayBasedOnUTCDate(
                                        restructuredPrevMessage.createdAt
                                    )}
                                </span>
                            </div>

                            {restructuredPrevMessage.content.map((message, index) => (
                                <React.Fragment key={`${message.id}${index}`}>
                                    {message.sender !== userInfo.id ? (
                                        <div className="incoming_msg d-flex justify-content-end">
                                            <div className="incoming_with_msg">
                                                <section>
                                                    <div className="action dropdown">
                                                        <a
                                                            className="dropdown-toggle"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                        >
                                                            <img
                                                                src={HorizontalIcon}
                                                                alt=""
                                                            />
                                                        </a>
                                                        <div
                                                            className="dropdown-menu dropdown-menu-right"
                                                            aria-labelledby="profile-menu"
                                                            x-placement="bottom-end"
                                                            style={{
                                                                position: 'absolute',
                                                                willChange: 'transform',
                                                                top: '0px',
                                                                left: '0px',
                                                                transform:
                                                                    'translate3d(4px, 19px, 0px)',
                                                            }}
                                                        >
                                                            <Link
                                                                to=""
                                                                className="dropdown-item"
                                                            >
                                                                <span>More Option 1</span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <p>
                                                        {message.body}
                                                        {message.fileUrl && (
                                                            <span
                                                                onClick={() =>
                                                                    window.open(
                                                                        message.fileUrl
                                                                    )
                                                                }
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                {message.fileUrl &&
                                                                    message.fileUrl.slice(
                                                                        96
                                                                    )}{' '}
                                                                <img
                                                                    src={FileIcon}
                                                                    alt=""
                                                                />
                                                            </span>
                                                        )}
                                                    </p>
                                                </section>
                                                <span className="time_date">
                                                    {new Date(
                                                        message.createdAt
                                                    ).toLocaleTimeString()}{' '}
                                                    {message.read ? (
                                                        <img
                                                            src={ReadImg}
                                                            width="15rem"
                                                            alt=""
                                                        />
                                                    ) : (
                                                        <img
                                                            src={UnReadImg}
                                                            width="15rem"
                                                            alt=""
                                                        />
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="outgoing_msg d-flex justify-content-start">
                                            <div className="outgoing_msg_img">
                                                {' '}
                                                <img
                                                    src={userInfo.mediaUrl || Profile3}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="sent_msg">
                                                <div className="sent_with_msg">
                                                    <section>
                                                        <p>
                                                            {message.body}
                                                            {message.fileUrl && (
                                                                <span
                                                                    onClick={() =>
                                                                        window.open(
                                                                            message.fileUrl
                                                                        )
                                                                    }
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    {message.fileUrl &&
                                                                        message.fileUrl.slice(
                                                                            96
                                                                        )}{' '}
                                                                    <img
                                                                        src={
                                                                            FileIconWhite
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </span>
                                                            )}
                                                        </p>
                                                        <div className="action dropdown">
                                                            <a
                                                                className="dropdown-toggle"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                            >
                                                                <img
                                                                    src={HorizontalIcon}
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div
                                                                className="dropdown-menu dropdown-menu-right"
                                                                aria-labelledby="profile-menu"
                                                                x-placement="bottom-end"
                                                                style={{
                                                                    position: 'absolute',
                                                                    willChange:
                                                                        'transform',
                                                                    top: '0px',
                                                                    left: '0px',
                                                                    transform:
                                                                        'translate3d(4px, 19px, 0px)',
                                                                }}
                                                            >
                                                                <Link
                                                                    to=""
                                                                    className="dropdown-item"
                                                                >
                                                                    <span>
                                                                        More Option 1
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <span className="time_date">
                                                        {new Date(
                                                            message.createdAt
                                                        ).toLocaleTimeString()}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    ))}
                    {/* Show when typing message */}
                    <TypingMessage typing={typing} />
                </div>
            )}
        </>
    );
};
export default Chats;
