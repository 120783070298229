/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import XIcon from '../../../assets/img/svg/x.svg';
import CheckMark from '../../../assets/img/svg/success-checkmark.svg';
import $ from 'jquery';
import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';
import {withRouter} from 'react-router-dom';

const UpdateBio = (props) => {
    const [bio, setBio] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setBio(props.bio);
    }, [props.bio]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);
        const payload = {
            bio,
        };
        await props.updateUserBio(payload);

        setLoading(false);

        // Just show the success message and hide after 4.5sec
        $('.tier_one').hide();
        $('.tier_two').show();
        setTimeout(() => {
            $('.tier_one').show();
            $('.tier_two').hide();
        }, 4500);
    };
    return (
        <div className="modal fade" id="editBio" role="dialog">
            <div className="modal-dialog modal-wrapper">
                <div className=" modal-content-wrap">
                    <div className="header-wrap">
                        <div className="close_">
                            <label data-dismiss="modal">
                                <img src={XIcon} alt="" />
                                <span>Close</span>
                            </label>
                        </div>
                        <div>
                            <p>Edit About</p>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="container wrapr">
                            <div className="tier_one">
                                <form
                                    className="profile-bio-form"
                                    onSubmit={handleSubmit}
                                >
                                    <div className="form-group">
                                        <label htmlFor="bio">Summary</label>
                                        <textarea
                                            className="form-control"
                                            name="bio"
                                            value={bio}
                                            onChange={(e) => setBio(e.target.value)}
                                            placeholder="Write a short bio about yourself..."
                                            rows="6"
                                            maxLength="500"
                                        ></textarea>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary">
                                            {loading ? 'Updating Bio...' : 'Save'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div className="tier_two">
                                <div className="success animated fadeIn">
                                    <div>
                                        <img src={CheckMark} alt="" />
                                    </div>
                                    <p>Updated Successfully</p>
                                    <a data-dismiss="modal">Close</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = () => {
    return {};
};

export default withRouter(connect(mapStateToProps, actions)(UpdateBio));
