import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../redux/actions';
import Input from '../../components/Form/Input';
import RegistrationDefault from '../../components/layout/RegistrationDefault';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleIcon from '../../assets/img/svg/google-icon.svg';
import FacebookIcon from '../../assets/img/svg/fb-icon.svg';
import {scrollTop} from '../../utils/scrollTop';
import {validateAll, extend} from 'indicative/validator';
import {getValue} from 'indicative-utils';
// import {useToasts} from 'react-toast-notifications';
// import {googleAuthResponseMessage} from '../../utils/googleAuthReponseMessage';
import {displayLoader} from '../../utils/displayLoader';
import LeftViewOnAuthPages from '../../components/Blocks/LeftViewOnAuthPages';
import useAlert from '../../components/CustomHooks/useAlert';

extend('validemail', {
    async: true,

    compile(args) {
        return args;
    },

    async validate(data, field, args, config) {
        const fieldValue = getValue(data, field);
        const re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(fieldValue);
    },
});

const Signin = (props) => {
    // const {addToast} = useToasts();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [checked, setChecked] = useState(true);
    const [errors, setErrors] = useState('');
    const [loadingOnSocial, setLoadingOnSocial] = useState(false);

    // Handleand google signin
    const responseSuccessGoogle = (response) => {
        scrollTop();
        setLoadingOnSocial(true);
        props.getGoogleSignupUser(response, props.history);
    };

    const responseFailureGoogle = ({error, details}) => {
        // if (error) addToast(googleAuthResponseMessage(error), {appearance: 'error'});
        setLoadingOnSocial(false);
    };

    // Handle facebook signin
    const responseFacebook = (response) => {
        scrollTop();
        setLoadingOnSocial(true);
        props.getFacebookSignupUser(response, props.history);
    };

    const responseFailureFacebook = () => {
        setLoadingOnSocial(false);
    };

    const handleErrorRemoveOnFocus = (e) => {
        setErrors({
            ...errors,
            [e.target.name]: '',
        });
    };

    //Handle submission
    const handleSubmit = (e) => {
        e.preventDefault();

        const rules = {
            email: 'required|email|validemail',
            password: 'required',
        };

        const data = {
            email,
            password,
        };

        const messages = {
            'email.email': 'Enter valid email address',
            'email.required': 'Enter email address',
            'email.validemail': 'Enter valid email address',
            'password.required': 'Password is required',
        };

        validateAll(data, rules, messages)
            .then(() => {
                scrollTop();
                signIn();
                setEmail('');
                setPassword('');
            })
            .catch((errors) => {
                const formattedErrors = {};
                errors.forEach((error) => (formattedErrors[error.field] = error.message));
                setErrors(formattedErrors);
            });

        const signIn = async () => {
            await props.normalLoginUser(email, password, props.history);
        };
    };

    const renderOnSocialSignIn = () => {
        return displayLoader('Checking Account');
    };

    const renderOnNormalSignIn = () => {
        return displayLoader('Checking Account');
    };

    useAlert(props.alert);

    return (
        <>
            <RegistrationDefault
                pageTitle={'Hi There! - Login'}
                linkName={'Create Account'}
                linkUrl={'/signup'}
                pageTag="Login Account"
            />
            <main className="account">
                <LeftViewOnAuthPages />
                <section className="right-pad">
                    {loadingOnSocial
                        ? renderOnSocialSignIn()
                        : props.loginStart
                        ? renderOnNormalSignIn()
                        : ''}
                    <div className="container">
                        <form onSubmit={handleSubmit}>
                            <section className="authenticate animated bounceIn">
                                <div className="title mb-5">
                                    <h3>Login into your account</h3>
                                </div>

                                <Input
                                    type="email"
                                    name="email"
                                    value={email}
                                    placeholder="Email Address"
                                    label="Email Address"
                                    onChange={(e) => setEmail(e.target.value)}
                                    errorMessage={errors.email}
                                    onFocus={handleErrorRemoveOnFocus}
                                />
                                <Input
                                    type="password"
                                    name="password"
                                    value={password}
                                    placeholder="Password"
                                    label="Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    errorMessage={errors.password}
                                    onFocus={handleErrorRemoveOnFocus}
                                />

                                <div className="base d-flex justify-content-between">
                                    <div className="input checker">
                                        <input
                                            type="checkbox"
                                            onChange={() => setChecked(!checked)}
                                            checked={checked}
                                            id=""
                                            name="remeberMe"
                                        />
                                        <label>Remember me</label>
                                    </div>
                                    <Link to="/forgetpassword">Forgot Password?</Link>
                                </div>
                                <div className="action">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={props.loginStart}
                                    >
                                        Access account
                                    </button>
                                    <p className="small m-0 p-0 d-lg-none mt-3">or signin with</p>
                                    <div className="alt">
                                        <small>or</small>
                                        <div className="d-flex">
                                            <GoogleLogin
                                                clientId={
                                                    process.env.REACT_APP_GOOGLE_CLIENT_ID
                                                }
                                                render={(renderProps) => (
                                                    <img
                                                        onClick={renderProps.onClick}
                                                        disabled={renderProps.disabled}
                                                        src={GoogleIcon}
                                                        alt="Login in with google"
                                                    />
                                                )}
                                                onSuccess={responseSuccessGoogle}
                                                onFailure={responseFailureGoogle}
                                                cookiePolicy={'single_host_origin'}
                                            />
                                            <FacebookLogin
                                                appId={
                                                    process.env
                                                        .REACT_APP_FACEBOOK_CLIENT_ID
                                                }
                                                fields="name,email,picture"
                                                callback={responseFacebook}
                                                onFailure={responseFailureFacebook}
                                                render={(renderProps) => (
                                                    <img
                                                        onClick={renderProps.onClick}
                                                        width="60%"
                                                        src={FacebookIcon}
                                                        alt="Login with facebook"
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </form>
                    </div>
                </section>
            </main>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        loginStart: state.auth.loginStart,
        alert: state.alert,
    };
};

export default connect(mapStateToProps, actions)(Signin);
