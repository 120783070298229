import React, {useState, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import RegistrationDefault from '../../components/layout/RegistrationDefault';
import {connect} from 'react-redux';
import * as actions from '../../redux/actions';
import {genderData} from './selectDatas';
import Select from 'react-select';
import {validateAll} from 'indicative/validator';
import {scrollTop} from '../../utils/scrollTop';
import store from 'store';
import {displayLoader} from '../../utils/displayLoader';
import LeftViewOnAuthPages from '../../components/Blocks/LeftViewOnAuthPages';
import useAlert from '../../components/CustomHooks/useAlert';
import {Link, withRouter} from 'react-router-dom';
import InputPhoneNumber from '../../components/Blocks/InputPhoneNumber';
import {capitalizeFirstLetter} from '../../utils/capitalizeFirstLetter';
import {countries_states} from "../../utils/countries_states";

const initial = {
    oauthId: '',
    fullName: '',
    email: '',
    profilePicture: '',
    phone: '',
    areaOfInterest: '',
    dob: '',
    gender: '',
    country: '',
};
const FacebookGoogleSignup = (props) => {
    const socialData = store.get('signUpwithSocialData');
    const signUpWith = store.get('signUpWith');
    const [data, setData] = useState(initial);
    const [category, setCategory] = useState('');
    const [errors, setErrors] = useState('');
    const [countriesList, setCountriesList] = useState([]);
    const [callingCode, setCallingCode] = useState('+000 ');

    useEffect(() => {
        scrollTop();
        // redirect if signwith not set
        if (!signUpWith) {
            props.history.push('/login');
        }

        if (socialData)
            setData({
                ...data,
                oauthId: socialData.oauthId,
                fullName: socialData.fullName,
                email: socialData.email,
                profilePicture: socialData.profilePicture,
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // fetch list of countries
    useEffect(() => {
        fetchCountries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchCountries = async () => {
        await props.getAllTalentCategories();

        const countries = countries_states.map((country) => ({
            label: country.name,
            value: country.name,
            callingCodes: country.phone_code.replace("+", ""),
        }));

        setCountriesList(countries);
    };

    const handleInputChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };
    const handleSelect = (name, value) => {
        if (name === 'country') {
            setCallingCode(value ? `+${value.callingCodes} ` : '');
        }

        setData({
            ...data,
            [name]: value,
            phone: name === 'country' ? '' : name === 'phone' ? value : '',
        });
    };
    const handleSelectedCategories = (e) => {
        setCategory(e);
    };

    const handleErrorRemoveOnFocus = (name) => {
        setErrors({
            ...errors,
            [name]: '',
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const form = {
            areaOfInterest: data.areaOfInterest,
            category,
        };

        const rules = {
            areaOfInterest: 'required',
            category: 'required',
        };

        const messages = {
            'areaOfInterest.required': 'Select your area of talent',
            'category.required': 'Pick a category that meet your talent',
        };

        validateAll(form, rules, messages)
            .then(() => {
                scrollTop();
                completeSignUp();
                setData(initial);
                setCategory('');
            })
            .catch((errors) => {
                const formattedErrors = {};
                errors.forEach((error) => (formattedErrors[error.field] = error.message));
                setErrors(formattedErrors);
            });

        const completeSignUp = () => {
            // let value = Array.from(category, (option) => option.value);
            const payload = {
                ...data,
                gender: data.gender.value,
                country: data.country.value,
                talentCategories: [category.value],
                phone: `${callingCode}${data.phone}`,
                agreement: true,
            };

            if (signUpWith === 'google') {
                props.completeSignupUserViaSocial('google', payload, props.history);
            }
            if (signUpWith === 'facebook') {
                props.completeSignupUserViaSocial('facebook', payload, props.history);
            }
        };
    };

    const renderSelectCategory = () => {
        const {talentCategories} = props;
        const options = talentCategories.map((c) => ({
            label: capitalizeFirstLetter(c.name),
            value: c.name,
        }));

        return (
            <div className="input bordered categories-select">
                <Select
                    isClearable
                    name="category"
                    placeholder="Select Categories"
                    className="select                            "
                    onChange={handleSelectedCategories}
                    options={options}
                    onFocus={() => handleErrorRemoveOnFocus('category')}
                />
                <small className="text-danger">{errors.category}</small>
            </div>
        );
    };

    useAlert(props.alert);

    return (
        <>
            <RegistrationDefault
                pageTitle={'Complete Signup via Google/Facebook'}
                linkName={'Login'}
                linkUrl={'/login'}
                linkStyle={'adj'}
                pageNum="02"
                pageTag="Personal Information"
            />
            <main className="account">
                <LeftViewOnAuthPages step={2} />
                <section className="right-pad">
                    {props.socialStart && displayLoader('Configuring Account')}
                    <div className="container">
                        <form onSubmit={handleSubmit}>
                            <section
                                className="step-2 animated fadeIn"
                                style={{display: 'block'}}
                            >
                                <div className="title">
                                    <h3>Almost Done !!</h3>
                                    <p>Please update your personal Information </p>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="input bordered date custom-date-select">
                                            <DatePicker
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={50}
                                                showMonthDropdown
                                                selected={data.dob}
                                                name="dob"
                                                onChange={(date) =>
                                                    handleSelect('dob', date)
                                                }
                                                maxDate={new Date()}
                                                placeholderText="Date of Birth"
                                                dateFormat="dd/MM/yyyy"
                                                autoComplete={false}
                                            />
                                        </div>
                                        <div className="input bordered categories-select">
                                            <Select
                                                isClearable
                                                name="gender"
                                                placeholder="Select Gender"
                                                className="select"
                                                onChange={(e) =>
                                                    handleSelect('gender', e)
                                                }
                                                options={genderData}
                                            />
                                        </div>
                                        <div className="input bordered categories-select">
                                            <Select
                                                isClearable
                                                name="country"
                                                placeholder="Select Country"
                                                className="select"
                                                onChange={(e) =>
                                                    handleSelect('country', e)
                                                }
                                                options={countriesList}
                                            />
                                        </div>

                                        <InputPhoneNumber
                                            callingCode={callingCode}
                                            phone={data.phone}
                                            handleInputChange={(e) =>
                                                handleInputChange(e)
                                            }
                                        />

                                        {renderSelectCategory()}

                                        <div className="tag">
                                            <label>Select User Type</label>
                                        </div>
                                        <small className="text-danger">
                                            {errors.areaOfInterest}
                                        </small>
                                        <div className="radio_option mode">
                                            <div className="custom-control custom-radio left">
                                                <input
                                                    type="radio"
                                                    className="custom-control-input"
                                                    id="talent"
                                                    name="areaOfInterest"
                                                    value="talent"
                                                    checked={
                                                        data.areaOfInterest === 'talent'
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={handleInputChange}
                                                    onClick={() =>
                                                        handleErrorRemoveOnFocus(
                                                            'areaOfInterest'
                                                        )
                                                    }
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="talent"
                                                >
                                                    Talents{' '}
                                                    <small>
                                                        I want to showcase my talents here
                                                    </small>
                                                </label>
                                            </div>
                                            <div className="custom-control custom-radio right">
                                                <input
                                                    type="radio"
                                                    className="custom-control-input"
                                                    id="scout"
                                                    name="areaOfInterest"
                                                    value="scout"
                                                    checked={
                                                        data.areaOfInterest === 'scout'
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={handleInputChange}
                                                    onClick={() =>
                                                        handleErrorRemoveOnFocus(
                                                            'areaOfInterest'
                                                        )
                                                    }
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="scout"
                                                >
                                                    Scouts{' '}
                                                    <small>
                                                        I want to see those who are
                                                        showcasing talents
                                                    </small>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="user-agreement px-2">
                                            <p className="text-center">
                                                By clicking Complete Registration, you
                                                agree to MySpotlyt's{' '}
                                                <Link to="user-agreement">
                                                    User Agreement
                                                </Link>
                                                ,{' '}
                                                <Link to="privacy-policy">
                                                    Privacy Policy
                                                </Link>
                                                , and{' '}
                                                <Link to="cookie-policy">
                                                    Cookie Policy
                                                </Link>
                                            </p>
                                        </div>

                                        <div className="action justify-content-center">
                                            <button
                                                type="submit"
                                                className="btn btn-block btn-primary"
                                                disabled={
                                                    props.socialStart ? true : false
                                                }
                                            >
                                                Complete Registration
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </form>
                    </div>
                </section>
            </main>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        socialStart: state.auth.socialStart,
        alert: state.alert,
        talentCategories: state.talentCategories.talentCategories,
    };
};

export default withRouter(connect(mapStateToProps, actions)(FacebookGoogleSignup));
