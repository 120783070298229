import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link, withRouter} from 'react-router-dom';
import Header from '../../components/layout/MainLayout/Header';
import Footer from '../../components/layout/MainLayout/Footer';

import IntroImg from '../../assets/img/png/audition-img-large.png';
import DotPattern from '../../assets/img/svg/dot-pattern.svg';
import BackArrow from '../../assets/img/svg/back-arrow.svg';

import UploadIcon from '../../assets/img/svg/upload-icon.svg';
import CrossIcon from '../../assets/img/svg/cross.svg';
import PlayIcon from '../../assets/img/svg/play_icon.svg';
// import SuccessMark from '../../assets/img/svg/success-checkmark.svg';
import Dropzone from 'react-dropzone';
// import FileAdd from '../../assets/img/svg/file-add-icon.svg';
import {stringFirstUpperCase} from '../../utils/stringFirstsUppercase';
import store from 'store';
import {validateAll} from 'indicative/validator';
import useAlert from '../../components/CustomHooks/useAlert';
import {scrollTop} from '../../utils/scrollTop';
import $ from 'jquery';
import {connect} from 'react-redux';
import * as actions from '../../redux/actions';

const Intro = () => {
    return (
        <section className="intro">
            <div
                className="tier_one sub"
                style={{
                    background: `#000000d9 url(${IntroImg}) no-repeat center`,
                }}
            />
        </section>
    );
};

const ApplicationForm = (props) => {
    const [description, setDescription] = useState('');
    const [mediaUrl, setMediaUrl] = useState(null);
    const [errors, setErrors] = useState('');
    const [loading, setLoading] = useState(false);
    const [fileSizeError, setFileSizeError] = useState(null);

    const handleDrop = (accepted) => {
        if (accepted[0]) {
            // limit to 50mb => 52428800
            if (accepted[0].size <= 52428800) {
                setMediaUrl(accepted[0]);

                // display an upload loader with filename
                $('.aud_vid').html('Upload Successful!!!');
                $('.upload-message').html(accepted[0].name);
                $('figure section').addClass('show');
                $('figure section .file_type .progress span').css({
                    transition: '1.5s ease-in',
                    width: '100%',
                });
                $('.btn-primary').attr('disabled', false);
                setFileSizeError(null);
            } else {
                setFileSizeError('File size is too big. (Maximum filesize is 50MB');
            }
        } else {
            $('.aud_vid').html('Upload Failed!!! Try again');
        }
    };

    const handleAuditionSubmit = (e) => {
        e.preventDefault();

        const data = {
            description,
        };
        const rules = {
            description: 'required',
        };

        const messages = {
            'description.required': 'Description of the video is required',
        };

        validateAll(data, rules, messages)
            .then(() => {
                submitAudition();
            })
            .catch((errors) => {
                const formattedErrors = {};
                errors.forEach((error) => (formattedErrors[error.field] = error.message));
                setErrors(formattedErrors);
            });

        const submitAudition = async () => {
            if (mediaUrl) {
                setLoading(true);
                const file = new FormData();
                file.append('file', mediaUrl);
                file.set('description', description);

                // Send request
                await props.applyAudition(props.match.params.slug, file, props.history);
                setLoading(false);
            } else {
                setErrors({
                    ...errors,
                });
            }
        };
    };

    useAlert(props.alert);

    return (
        <section className="application-form">
            <img className="pattern" src={DotPattern} alt="" />
            <div className="container">
                <div className="head__">
                    <Link
                        onClick={() =>
                            props.history.push(
                                `/auditions/details/${props.match.params.slug}`
                            )
                        }
                    >
                        <i>
                            <img src={BackArrow} alt="back" />
                        </i>
                        <label>Back</label>
                    </Link>
                </div>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="title">
                        <h3>{stringFirstUpperCase(props.applyAuditionTitle)}</h3>
                        <p>
                            Fill up your information below to enter in the audition stage
                            for this show, please note that all field are requeired to be
                            filled
                        </p>
                    </div>
                    <hr />
                    <section>
                        <div className="input">
                            <textarea
                                id=""
                                cols="30"
                                rows="3"
                                name="description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                onFocus={(e) => setErrors({...errors, description: ''})}
                                placeholder="Describe this video"
                            />
                            <small className="text-danger">{errors.description}</small>
                        </div>

                        <Dropzone onDrop={handleDrop} accept="video/mp4,image/*">
                            {({getRootProps, getInputProps}) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div className="tag">
                                        <label>Entrance Video</label>
                                    </div>
                                    <figure>
                                        <p>
                                            You can upload video or images only
                                            <small>
                                                Click on the button or drag & drop file
                                                here
                                            </small>
                                        </p>
                                        <button type="button" className="aud_vid">
                                            <p>
                                                <img src={UploadIcon} alt="" />
                                                <small>Upload Video</small>
                                            </p>
                                        </button>

                                        <section className="animated bounceIn upload-box">
                                            <aside className="">
                                                <img src={CrossIcon} alt="" />
                                            </aside>
                                            <div>
                                                <img src={PlayIcon} alt="" />
                                            </div>
                                            <div className="file_type">
                                                <p className="upload-message" />
                                                <div className="progress">
                                                    <span />
                                                </div>
                                            </div>
                                        </section>
                                    </figure>
                                </div>
                            )}
                        </Dropzone>
                        <small className="text-danger">
                            {errors.mediaUrl || fileSizeError}
                        </small>

                        <div className="action">
                            <button
                                type="submit"
                                className="btn btn-primary apply"
                                disabled={loading}
                                onClick={handleAuditionSubmit}
                            >
                                {loading ? 'Processing...' : 'Apply'}
                            </button>
                        </div>
                    </section>
                </form>
            </div>
        </section>
    );
};

const UnsignedInAuditionApply = (props) => {
    const applyAuditionTitle = store.get('applyAuditionTitle');
    useEffect(() => {
        scrollTop();

        // check if accessToken is stores, if not redirect to login
        if (store.get('kmt-user') && store.get('kmt-user').accessToken) {
            return;
        } else {
            store.set('performOperationFrom', props.history.location.pathname);
            props.history.push('/login');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Helmet>
                <title>Auditions Application</title>
            </Helmet>
            <Header />
            <main className="plublic">
                <Intro />
                <ApplicationForm
                    applyAuditionTitle={applyAuditionTitle}
                    applyAudition={props.applyAudition}
                    alert={props.alert}
                    history={props.history}
                    match={props.match}
                />
            </main>
            <Footer />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        alert: state.alert,
    };
};

export default withRouter(connect(mapStateToProps, actions)(UnsignedInAuditionApply));
