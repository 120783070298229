import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';
import LeftSideBar from '../../../components/Feeds/LeftSideBar';
import Header from '../../../components/Feeds/Header';
import MainLayoutHeader from '../../../components/layout/MainLayout/Header';
import Footer from '../../../components/Feeds/Footer';
import store from 'store';

import Img1 from '../../../assets/img/png/audition-img-4.png';
import {stringFirstUpperCase} from '../../../utils/stringFirstsUppercase';
import {capitalizeFirstLetter} from '../../../utils/capitalizeFirstLetter';
import useAlert from '../../../components/CustomHooks/useAlert';
import {scrollTop} from '../../../utils/scrollTop';
import UnsignedInAuditionHiglights from '../../unsignedInAudition/UnsignedInAuditionHiglights';
import {auditionEligibility, unsignedAuditionEligibility} from '../../../utils/global';
import useGeoLocation from '../../../utils/useGeoLocation';
import moment from 'moment';

const AuditionDetailsBanner = (props) => {
    const {mediaUrl, title, description} = props;
    return (
        <section id="audition-header">
            <div
                className="tier_one sub alt"
                style={{
                    background: `#000000d9 url(${mediaUrl}) no-repeat center`,
                }}
            >
                <div className="container">
                    <div className="h3ro alt alt_">
                        <h1>{stringFirstUpperCase(title)}</h1>
                        <p>
                            {description && description.length >= 100
                                ? capitalizeFirstLetter(description.slice(0, 100)) + '...'
                                : capitalizeFirstLetter(description)}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

const AuditionDescription = (props) => {
    const user = store.get('kmt-user') || null;
    const {country, region} = useGeoLocation();
    // const [loading, setLoading] = useState(false);

    const {
        id,
        title,
        description,
        startDate,
        endDate,
        isAuditionOwner,
        audition,
        auditionId,
    } = props;
    const isExpired = new Date(audition.endDate).getTime() >= new Date().getTime();

    const renderUnsignedApplyButton = () => {
        const isEligible = unsignedAuditionEligibility(country, region, audition);
        if (isEligible) {
            return isExpired ? (
                <Link
                    to={`/talent-auditions/apply-audition/${auditionId}`}
                    className="btn btn-primary"
                    onClick={() => store.set('applyAuditionTitle', title)}
                >
                    Apply for audition
                </Link>
            ) : (
                <button className="btn btn-secondary cursor-default">
                    Audition Expired
                </button>
            );
        } else {
            return (
                <p className="mt-3">
                    Oops!! This audition is not available for your account because this
                    audition is set for a specific user based location, talent category,
                    gender or age range.
                </p>
            );
        }
    };
    const renderApplyButtonBasedOnConditions = () => {
        if (!user) {
            return renderUnsignedApplyButton();
        }

        if (auditionEligibility(user, country, region, audition)) {
            return audition.hasApplied ? (
                <button className="btn btn-success cursor-default">Applied</button>
            ) : new Date(audition.endDate).getTime() < new Date().getTime() ? (
                <button className="btn btn-secondary cursor-default">
                    Audition Expired
                </button>
            ) : (
                <Link
                    to={`/talent-auditions/apply-audition/${id}`}
                    className="btn btn-primary"
                    onClick={() => store.set('applyAuditionTitle', title)}
                >
                    Apply For Audition
                </Link>
            );
        } else {
            return (
                <p className="mt-3">
                    Oops!! This audition is not available for your account because this
                    audition is set for a specific user based location, talent category,
                    gender or age range.
                </p>
            );
        }
    };

    return (
        <div className="col-lg-8">
            <h3>Description</h3>
            <p>{capitalizeFirstLetter(description)}</p>
            <section>
                <h4>Start Date - End Date</h4>
                <h5 className="text-muted">
                    {moment(startDate).utc().format('ddd ll')}
                    &nbsp;-&nbsp;
                    {moment(endDate).utc().format('ddd ll')}
                </h5>
                <div>
                    {user && user.areaOfInterest === 'talent' && (
                        renderApplyButtonBasedOnConditions()
                    )}

                    {/* If you are a post owner(i.e scout user), edit audition else view applicant to your audition */}
                    {isAuditionOwner ? (
                        <>
                            <Link
                                to={`/talent-auditions/edit-audition/${auditionId}`}
                                className="btn btn-secondary"
                            >
                                Edit Audition
                            </Link>
                            <Link
                                to={`/talent-auditions/applicants/${auditionId}`}
                                className="btn btn-primary"
                            >
                                View Applicants
                            </Link>
                        </>
                    ) : (
                        ''
                    )}
                </div>
            </section>
        </div>
    );
};

const AuditionDescriptionVideos = ({mediaUrl}) => {
    return (
        <div className="col-lg-4">
            {mediaUrl && mediaUrl.includes('.mp4') ? (
                <section>
                    <h4>Additional Video</h4>
                    <aside className="d-flex">
                        <video
                            controls
                            poster={Img1}
                            width="300"
                            height="200"
                            controlsList="nodownload"
                        >
                            <source src={mediaUrl} type="video/mp4" />
                            <p>
                                Your browser doesn't support HTML5 video. Here is a{' '}
                                <a
                                    href={mediaUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    link to the video
                                </a>{' '}
                                instead.
                            </p>
                        </video>
                    </aside>
                </section>
            ) : (
                ''
            )}
        </div>
    );
};

const AuditionDescriptionWrapper = (props) => {
    const {
        id,
        title,
        description,
        startDate,
        endDate,
        isAuditionOwner,
        auditionId,
        areaOfInterest,
        mediaUrl,
        audition,
    } = props;
    return (
        <section className="description">
            <div className="container">
                <div className="row">
                    <AuditionDescription
                        id={id}
                        title={title}
                        description={description}
                        startDate={startDate}
                        endDate={endDate}
                        isAuditionOwner={isAuditionOwner}
                        auditionId={auditionId}
                        areaOfInterest={areaOfInterest}
                        audition={audition}
                    />
                    <AuditionDescriptionVideos mediaUrl={mediaUrl} />
                </div>
            </div>
        </section>
    );
};

const AuditionDetails = (props) => {
    const userData = store.get('kmt-user') || null;
    const {audition} = props;
    const auditionId = props.match.params.slug;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        scrollTop();
        fetchSingleAudition();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchSingleAudition = async () => {
        setLoading(true);
        await props.fetchSingleAuditionByUrl(auditionId);
        setLoading(false);
    };

    const renderLoader = () => {
        return (
            <section className="waiting">
                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </section>
        );
    };

    useAlert(props.alert);

    return (
        <>
            <Helmet>
                <title>Talent Audition</title>
            </Helmet>
            <main className={`${userData ? 'main feeds' : 'main feeds public'}`}>
                {userData && <LeftSideBar />}
                <section id="wrapper">
                    {userData ? <Header /> : <MainLayoutHeader />}
                    <div className="container content_">
                        {loading ? (
                            renderLoader()
                        ) : (
                            <section className="scouts-audition">
                                <AuditionDetailsBanner
                                    mediaUrl={audition.mediaUrl}
                                    title={audition.title}
                                    description={audition.description}
                                    audition={audition}
                                />
                                <AuditionDescriptionWrapper
                                    id={audition.auditionId}
                                    title={audition.title}
                                    description={audition.description}
                                    startDate={audition.startDate}
                                    endDate={audition.endDate}
                                    isAuditionOwner={audition.isAuditionOwner}
                                    auditionId={audition.auditionId}
                                    areaOfInterest={
                                        userData ? userData.areaOfInterest : ''
                                    }
                                    mediaUrl={audition.mediaUrl}
                                    audition={audition}
                                />
                            </section>
                        )}

                        <div className="plublic">
                            <UnsignedInAuditionHiglights audition={audition} />
                        </div>

                        <Footer />
                    </div>
                </section>
            </main>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        audition: state.audition.audition,
        alert: state.alert,
        appliedAuditions: state.audition.appliedAuditions,
    };
};

export default withRouter(connect(mapStateToProps, actions)(AuditionDetails));
