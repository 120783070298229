import React from 'react';
import SettingsOptions from './SettingsOptions';
import SettingsContents from './SettingsContents';
import {withRouter} from 'react-router-dom';

const SettingsWrapper = (props) => {
    return (
        <section className="settings animated fadeIn  delay-1m">
            <div className="row">
                <div className="col-lg-4 col-sm-12 col-md-4">
                    <SettingsOptions slug={props.match.params.slug} />
                </div>
                <div className="col-lg-8 col-sm-12 col-md-8">
                    <SettingsContents />
                </div>
            </div>
        </section>
    );
};

export default withRouter(SettingsWrapper);
