import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import TagManager from 'react-gtm-module';

import {Provider} from 'react-redux';
import configureStore from './store';
import {ToastProvider} from 'react-toast-notifications';
import MainRoutes from './routes';
// Styling
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import './assets/components/animate/animate.css';
import './assets/components/owl-carousel/assets/owl.carousel.min.css';
import './assets/components/owl-carousel/assets/owl.theme.default.min.css';
import './assets/css/style.min.css';
import './assets/css/main.css';
import CookiesConsent from './components/Blocks/CookiesConsent';

const tagManagerArgs = {
    gtmId: 'GTM-TDFCHVK',
};

TagManager.initialize(tagManagerArgs);

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <ToastProvider autoDismiss autoDismissTimeout={3000}>
            <React.StrictMode>
                <MainRoutes />
                <CookiesConsent />
            </React.StrictMode>
        </ToastProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
