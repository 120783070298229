import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from '../../../redux/actions';

const NotificationSettings = (props) => {
    const [loading, setLoading] = useState(false);

    const [alertSettings, setAlertSettings] = useState({
        allowPushNotification: false,
        showEmailNotification: false,
        subscribeToNewsletter: false,
    });

    // componentDidMount
    useEffect(() => {
        fetchSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //fetch notification & alert settings
    const fetchSettings = async () => {
        setLoading(true);
        await props.fetchNotificationAndAlertSettings(props.history);
        setLoading(false);
    };

    // componentDidUpdate
    useEffect(() => {
        if (props.settings) setAlertSettings(props.settings);
    }, [props.settings]);

    // Handle switch changes
    const handleCheckChange = async (name, value) => {
        setAlertSettings({
            ...alertSettings,
            [name]: value,
        });

        switch (name) {
            case 'allowPushNotification':
                if (alertSettings.allowPushNotification) {
                    props.removePushNotification();
                } else {
                    props.triggerPushNotification();
                }
                break;

            case 'showEmailNotification':
                if (alertSettings.showEmailNotification) {
                    props.emailNotifcationActivation(false, true, props.history);
                } else {
                    props.emailNotifcationActivation(true, false, props.history);
                }
                break;

            case 'subscribeToNewsletter':
                if (alertSettings.subscribeToNewsletter) {
                    props.unsubscribeToNewsLetter();
                } else {
                    props.subscribeToNewsLetter();
                }
                break;

            default:
                return name;
        }
    };

    const renderLoader = () => {
        return (
            <div className="loading">
                <div className="lds-ripple">
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    };

    return (
        <>
            {loading ? (
                renderLoader()
            ) : (
                <div className="setting-sect active" id="setting-notifications">
                    <h5>
                        Notification{' '}
                        <small>A short description to help understand title...</small>
                    </h5>
                    <form className="options">
                        <div className="item">
                            <div>
                                <strong>Allow Push Notification</strong>
                                <p>Get push notifications to find out what’s going on </p>
                            </div>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={alertSettings.allowPushNotification}
                                    name="allowPushNotification"
                                    onChange={() =>
                                        handleCheckChange(
                                            'allowPushNotification',
                                            !alertSettings.allowPushNotification
                                        )
                                    }
                                />
                                <div className="slider round"></div>
                            </label>
                        </div>
                        <hr />
                        <div className="item">
                            <div>
                                <strong>Allow Email Notifcations</strong>
                                <p>
                                    Get emails to find out what’s going on when you’re not
                                    on Knowmytalent
                                </p>
                            </div>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={alertSettings.showEmailNotification}
                                    name="showEmailNotification"
                                    onChange={() =>
                                        handleCheckChange(
                                            'showEmailNotification',
                                            !alertSettings.showEmailNotification
                                        )
                                    }
                                />
                                <div className="slider round"></div>
                            </label>
                        </div>
                        <hr />
                        <div className="item">
                            <div>
                                <strong>Subscribe to Newsletter</strong>
                                <p>Get insightful News around our talents</p>
                            </div>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={alertSettings.subscribeToNewsletter}
                                    name="subscribeToNewsletter"
                                    onChange={() =>
                                        handleCheckChange(
                                            'subscribeToNewsletter',
                                            !alertSettings.subscribeToNewsletter
                                        )
                                    }
                                />
                                <div className="slider round"></div>
                            </label>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        settings: state.settings.settings,
    };
};
export default withRouter(connect(mapStateToProps, actions)(NotificationSettings));
