import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';
import {withRouter} from 'react-router-dom';

import PostDetailsLeftBar from './PostDetailsLeftBar';
import PostDetailsRightBar from './PostDetailsRightBar';
import PostDetailsLoader from './PostDetailsLoader';
import SharePostWithAddedTextModal from '../PostBits/SharePostWithAddedTextModal';
import ProfilePosts from '../Profile/ProfilePosts';
import {scrollTop} from '../../../utils/scrollTop';
import EditPostModal from '../PostBits/EditPostModal';
import DeletePostModal from '../PostBits/DeletePostModal';

const PostDetailsMain = (props) => {
    const postId = props.match.params.slug;

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        scrollTop();

        getSinglePost();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.singlePost.creatorId) getUsersPost();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.singlePost.creatorId]);

    const getSinglePost = async () => {
        setLoading(true);
        await props.fetchSinglePost(postId, props.history);
        setLoading(false);
    };

    const getUsersPost = async () => {
        setLoading(true);
        // fetch user posts
        await props.fetchUserPosts(
            {pageNumber: 1, pageSize: 4, userId: props.singlePost.creatorId},
            props.history
        );
        setLoading(false);
    };

    return (
        <>
            <div className="row">
                {loading ? (
                    <PostDetailsLoader />
                ) : (
                    <>
                        <PostDetailsLeftBar />
                        <PostDetailsRightBar />
                        <SharePostWithAddedTextModal
                            keptShareData={props.keptShareData}
                            singlePost={props.singlePost}
                            sharePost={props.sharePost}
                        />
                    </>
                )}
            </div>
            {/* Display user's post */}
            {loading ? (
                <section class="waiting">
                    <div class="lds-ripple">
                        <div></div>
                        <div></div>
                    </div>
                </section>
            ) : (
                <ProfilePosts
                    user={props.user}
                    userName={props.singlePost.creatorName}
                    isPostDetailsPage={true}
                />
            )}

            {/* Show edit modal */}
            <EditPostModal
                selectedFeed={{
                    ...props.singlePost,
                    // restructure talentCategory to form a list of categories without being in an object
                    talentCategories: props.singlePost?.talentCategories?.map(
                        (a) => a.name
                    ),
                }}
            />

            {/* Show delete modal */}
            <DeletePostModal selectedFeed={props.singlePost} />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        singlePost: state.feeds.singlePost,
        keptShareData: state.feeds.keptShareData,
        user: state.user.user,
    };
};

export default withRouter(connect(mapStateToProps, actions)(PostDetailsMain));
