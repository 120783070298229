import React from 'react';
import {
    AuthProgressIndicatorStep1,
    AuthProgressIndicatorStep2,
} from './AuthProgressIndicator';

const LeftViewOnAuthPages = ({quote, author, step}) => {
    return (
        <section className="left-pad">
            <div className="overlay"></div>
            <div className="container">
                <section>
                    <i className="icon">
                        <svg
                            width="98"
                            height="70"
                            opacity="0.5"
                            viewBox="0 0 98 70"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M35 0L21 28H42V70H0V28L14 0H35ZM91 0L77 28H98V70H56V28L70 0H91Z"
                                fill="white"
                            ></path>
                        </svg>
                    </i>
                    <p>
                        {quote ||
                            `Believe in yourself. You are braver than you think, 
                            more talented than you know, and capable of more than you imagine.`}

                        <small>
                            - {author || 'Roy T. Bennett, The Light in the Heart'}
                        </small>
                    </p>
                    {step === 1 ? (
                        <AuthProgressIndicatorStep1 />
                    ) : step === 2 ? (
                        <AuthProgressIndicatorStep2 />
                    ) : (
                        ''
                    )}
                </section>
            </div>
        </section>
    );
};

export default LeftViewOnAuthPages;
