import React, {useEffect, useState} from 'react';
// import Post from '../Feeds/Post';

import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import * as actions from '../../redux/actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import Post from '../Feeds/Post';
import {TimeSettings} from '../../utils/TimeSettings';
import FilterTalentModal from './FilterTalentModal';
import {DateSuperscript} from '../../utils/DateSuperscript';
import {stringFirstUpperCase} from '../../utils/stringFirstsUppercase';
import {capitalizeFirstLetter} from '../../utils/capitalizeFirstLetter';
import Audition from "../Feeds/Audition";

const ShowLoaderWhenFetchingPost = () => {
    return (
        <section className="content-post">
            <div className="panel">
                <div className="head">
                    <div className="media-panel">
                        <figure>
                            <div></div>
                        </figure>
                        <section>
                            <div></div>
                        </section>
                    </div>
                </div>
                <section className="content">
                    <div className="media fetching" style={{background: '#f2f2f2'}}></div>
                    <div className="media-panel">
                        <section className="control"></section>
                        <div className="comment-placeholder">
                            <figure>
                                <div></div>
                                <div></div>
                            </figure>
                            <div></div>
                            <div></div>
                            <div></div>
                            <hr />
                            <form>
                                <section className="input"></section>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
};

const TalentCenterBar = (props) => {
    const [feeds, setFeeds] = useState([]);

    useEffect(() => {
        setFeeds(props.feeds.slice(0, 10));
    }, [props.feeds]);

    const renderDisplayIfRecordIsEmpty = () => {
        return (
            <div className="jumbotron">
                <p className="">
                    Welcome, there are so many awesome talents to be discovered here
                </p>
            </div>
        );
    };

    const fetchMoreData = () => {
        setFeeds(props.feeds.slice(0, feeds.length + 10));
    };

    return (
        <>
            <div className="col-lg-6">
                <button
                    className="btn btn-secondary px-6 mb-4 d-sm-block d-lg-none"
                    data-toggle="modal"
                    data-target="#filterModal"
                >
                    Filter{' '}
                </button>
                {props.feedLoading ? (
                    <>
                        <ShowLoaderWhenFetchingPost />
                        <ShowLoaderWhenFetchingPost />
                    </>
                ) : feeds.length === 0 ? (
                    renderDisplayIfRecordIsEmpty()
                ) : (
                    <section className="content-post">
                        <InfiniteScroll
                            dataLength={feeds.length}
                            next={fetchMoreData}
                            hasMore={true}
                            loader={
                                <div className="loader text-center">
                                    <p>Loading more ...</p>
                                </div>
                            }
                            endMessage={
                                <div className="text-center">
                                    <p>Yay! You have seen it all</p>
                                </div>
                            }
                        >
                            {feeds.map((feed, index) =>
                                feed.auditionType ? (
                                    <div> &nbsp;</div>
                                ) : (
                                    <Post
                                        key={feed.postId}
                                        postId={feed.postId}
                                        profilePicture={feed.creatorImage}
                                        statusTag={feed.creatorAreaOfInterest}
                                        userId={feed.creatorId}
                                        fullName={feed.creatorName}
                                        timePosted={TimeSettings(feed.createdAt)}
                                        postImg={feed.mediaUrl}
                                        body={feed.body}
                                        isLiked={feed.isLiked}
                                        totalLikes={feed.likersCount}
                                        isCommentStart={feed.isCommentStart || null}
                                        totalComments={feed.commentorsCount}
                                        commentLists={feed.comments}
                                        hideComment={feed.hideComment}
                                        talentCategories={feed.talentCategories}
                                        isFollowing={feed.isFollowing}
                                        shareStatus={feed.shareStatus || null}
                                        sharedPost={feed.sharedPost}
                                        isPostOwner={feed.isPostOwner}
                                    />
                                )
                            )}
                        </InfiniteScroll>
                    </section>
                )}
            </div>
            <FilterTalentModal
                categories={props.categories}
                fetchFeeds={props.fetchFeeds}
                match={props.match}
                feedLoading={props.feedLoading}
                talentCategories={props.talentCategories}
                getAllTalentCategories={props.getAllTalentCategories}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        feedLoading: state.feeds.feedLoading,
        feeds: state.feeds.feeds,
        keptShareData: state.feeds.keptShareData,
        talentCategories: state.talentCategories.talentCategories,
    };
};

export default withRouter(connect(mapStateToProps, actions)(TalentCenterBar));
