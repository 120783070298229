import HomeIcon from '../../assets/img/svg/home-icon.svg';
import SearchIcon from '../../assets/img/svg/search-icon.svg';
import AuditionIcon from '../../assets/img/svg/audition-icon.svg';
import MessageIcon from '../../assets/img/svg/msg-icon.svg';
import NotificationIcon from '../../assets/img/svg/notification-icon.svg';
import ProfileIcon from '../../assets/img/svg/profile-icon.svg';
import SettingsIcon from '../../assets/img/svg/settings-icon.svg';
import PowerIcon from '../../assets/img/svg/power-icon.svg';

import Banner from '../../assets/img/png/banner.png';
import DonaldBanner from '../../assets/img/png/mac-donald.png';
import PipesBanner from '../../assets/img/png/pipes-banner.png';

// Left sidebar
export const leftSideBar = {
    links: [
        {
            url: '/feeds',
            imgSrc: `${HomeIcon}`,
            alt: 'Home Icon',
            tag: 'Home Feeds',
        },
        {
            url: '/explore-talents',
            imgSrc: `${SearchIcon}`,
            alt: 'Explore Icon',
            tag: 'Explore Talents',
        },
        {
            url: '/talent-auditions',
            imgSrc: `${AuditionIcon}`,
            alt: 'Audition Icon',
            tag: 'Auditions',
        },
        {
            url: '/messages',
            imgSrc: `${MessageIcon}`,
            alt: 'Message Icon',
            tag: 'Direct Message',
        },
        {
            url: '/notifications',
            imgSrc: `${NotificationIcon}`,
            alt: 'Notification Icon',
            tag: 'Notifications',
        },
        {
            url: '/profile',
            imgSrc: `${ProfileIcon}`,
            alt: 'Profile Icon',
            tag: 'My Profile',
        },
        {
            url: '/settings',
            imgSrc: `${SettingsIcon}`,
            alt: 'Settings Icon',
            tag: 'Settings',
        },
        {
            url: '/signout',
            imgSrc: `${PowerIcon}`,
            alt: 'Power Icon',
            tag: 'Logout',
        },
    ],
};

// Right side bar
export const rightSideBar = {
    categories: [
        {
            label: 'Soccer',
        },
        {
            label: 'Artist',
        },
        {
            label: 'Designers',
        },
        {
            label: 'Swimming',
        },
        {
            label: 'Dancing',
        },
        {
            label: 'Music',
        },
        {
            label: 'Inspiration Speaking',
        },
    ],
    ads: [
        {
            banner: `${Banner}`,
            alt: 'Banner 1',
        },
        {
            banner: `${DonaldBanner}`,
            alt: 'Donnald',
        },
        {
            banner: `${PipesBanner}`,
            alt: 'Pipes',
        },
    ],
};
