import React from 'react';

const Input = ({
    label,
    type,
    placeholder,
    name,
    value,
    onChange,
    onFocus,
    errorMessage,
    ...props
}) => {
    return (
        <div className="input bordered">
            <input
                type={type || 'text'}
                placeholder={placeholder || ''}
                name={name || ''}
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                {...props}
            />
            <label>{label || 'Label'}</label>
            <small className="text-danger">{errorMessage}</small>
        </div>
    );
};

export default Input;
