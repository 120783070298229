import React, {useState, useEffect} from 'react';
import {Link, withRouter, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import $ from 'jquery';

import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';

import LeftSideBar from '../../../components/Feeds/LeftSideBar';
import Header from '../../../components/Feeds/Header';
import Footer from '../../../components/Feeds/Footer';
import LessArrow from '../../../assets/img/svg/less-arrow.svg';
import FileAdd from '../../../assets/img/svg/file-add-icon.svg';
import CrossIcon from '../../../assets/img/svg/cross.svg';
import PlayIcon from '../../../assets/img/svg/play_icon.svg';
import Dropzone from 'react-dropzone';
import {stringFirstUpperCase} from '../../../utils/stringFirstsUppercase';
import store from 'store';
import {validateAll} from 'indicative/validator';
import useAlert from '../../../components/CustomHooks/useAlert';
import {scrollTop} from '../../../utils/scrollTop';
import useGeoLocation from '../../../utils/useGeoLocation';
import axios from '../../../axios/axiosInstance';
import {paymentOptions} from "../../../utils/global";
import Select from "react-select";

const ApplicationForm = (props) => {
    const [description, setDescription] = useState('');
    const [mediaUrl, setMediaUrl] = useState(null);
    const [errors, setErrors] = useState('');
    const [loading, setLoading] = useState(false);
    const [fileSizeError, setFileSizeError] = useState(null);
    const {country, region} = useGeoLocation();

    const submitAudition = async () => {
        if (mediaUrl) {
            setLoading(true);
            const file = new FormData();
            file.append('file', mediaUrl);
            file.set('description', description);
            file.set('country', country);
            file.set('region', region);

            // Send request
            await props.applyAudition(props.match.params.slug, file, props.history);
            setLoading(false);
        } else {
            setErrors({
                ...errors,
                mediaUrl: 'Make sure you upload your entrance video.',
            });
        }
    };

    const handleDrop = (accepted) => {
        if (accepted[0]) {
            // limit to 50mb => 52428800
            if (accepted[0].size <= 52428800) {
                setMediaUrl(accepted[0]);

                // display an upload loader with filename
                $('.aud_vid').html('Upload Successful!!!');
                $('.upload-message').html(accepted[0].name);
                $('figure section').addClass('show');
                $('figure section .file_type .progress span').css({
                    transition: '1.5s ease-in',
                    width: '100%',
                });
                $('.btn-primary').attr('disabled', false);
                setFileSizeError(null);
            } else {
                setFileSizeError('File size is too big. (Maximum filesize is 50MB');
            }
        } else {
            $('.aud_vid').html('Upload Failed!!! Try again');
        }
    };

    const handleAuditionSubmit = (e) => {
        e.preventDefault();

        const data = {
            description,
        };
        const rules = {
            description: 'required',
        };

        const messages = {
            'description.required': 'Description of the video is required',
        };

        validateAll(data, rules, messages)
            .then(async () => {
                 await submitAudition();
            })
            .catch((errors) => {
                const formattedErrors = {};
                errors.forEach((error) => (formattedErrors[error.field] = error.message));
                setErrors(formattedErrors);
            });
    };

    useAlert(props.alert);
    return (
        <section className="audition-form" style={{margin: '0 5em'}}>
            <div className="head_">
                <Link to onClick={() => props.history.goBack()}>
                    <img src={LessArrow} alt="" /> Back
                </Link>
            </div>
            <form onSubmit={handleAuditionSubmit}>
                <div className="title text-center">
                    <h3>{stringFirstUpperCase(props.audition.title)}</h3>
                    <p>
                        Fill up your information below to enter in the audition stage for
                        this show, please note that all field are required
                    </p>
                </div>
                <hr />
                <div className="input">
                    <textarea
                        id=""
                        cols="30"
                        rows="3"
                        name="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        onFocus={(e) => setErrors({...errors, description: ''})}
                        placeholder="Describe this video"
                    />
                    <small className="text-danger">{errors.description}</small>
                </div>

                <Dropzone onDrop={handleDrop} accept="video/mp4,image/*">
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="tag">
                                <label>Entrance Video</label>
                            </div>
                            <figure>
                                <p className="aud_vid">
                                    <p>You can upload video or images only</p>
                                    <button type="button">
                                        <img src={FileAdd} alt="" />
                                        Add
                                    </button>
                                    or drop file here
                                </p>

                                <section className="animated bounceIn upload-box">
                                    <aside className="">
                                        <img src={CrossIcon} alt="" />
                                    </aside>
                                    <div>
                                        <img src={PlayIcon} alt="" />
                                    </div>
                                    <div className="file_type">
                                        <p className="upload-message"></p>
                                        <div className="progress">
                                            <span></span>
                                        </div>
                                    </div>
                                </section>
                            </figure>
                        </div>
                    )}
                </Dropzone>
                <small className="text-danger">{errors.mediaUrl || fileSizeError}</small>

                <div className="action">
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                        {loading ? 'Processing Application...' : 'Apply'}
                    </button>
                </div>
            </form>
        </section>
    );
};
const ApplicationPaymentForm = (props) => {
    const [error, setError] = useState('');
    const {country, region} = useGeoLocation();
    const [payBy, setPayBy] = useState(paymentOptions[0]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        await axios
            .post(`/auditions/${props.audition.auditionId}/${payBy.value}/entries`, {
                country, region
            }, {})
            .then((response) => {
                window.location.href = response.data.url;
            })
            .catch((error) => {
                if (error.response) {
                    setError(error.response.data.message)
                    if (error.response.status === 403) {
                        props.history.push('/login');
                    }
                }
            });
    };

    useAlert(props.alert);

    return (
        <section className="audition-form" style={{margin: '0 5em'}}>
            <div className="head_">
                <Link to onClick={() => props.history.goBack()}>
                    <img src={LessArrow} alt="" /> Back
                </Link>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="title text-center">
                    <h3>{stringFirstUpperCase(props.audition.title)}</h3>
                    <p>{props.audition.description}</p>
                </div>
                <hr />

                <h5 className="pt-4 pb-4">This audition is a paid one</h5>

                <div className="input bordered categories-select">
                    <Select
                        name="payBy"
                        placeholder={'Pay by'}
                        className="select"
                        onChange={(option) => setPayBy(option)}
                        value={payBy}
                        options={paymentOptions}
                    />
                </div>

                <small className="text-danger">{error}</small>


                <div className="action">
                    <button type="submit" className="btn btn-primary">
                        Proceed
                    </button>
                </div>
            </form>
        </section>
    );
};

const ApplyAudition = (props) => {
    const user = store.get('kmt-user');
    const audition = props.audition ? props.audition.audition : {};

    const {slug} = useParams();

    useEffect(() => {
        scrollTop();
        props.fetchSingleAuditions(slug, props.history);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug]);

    return (
        <>
            <Helmet>
                <title>Talents | Auditions Application</title>
            </Helmet>
            <main className="main feeds">
                <LeftSideBar />
                <section id="wrapper">
                    <Header />
                    <div className="container content_">
                        <section className="scouts-audition">
                            {audition.auditionCategory &&
                            audition.auditionCategory === 'pay' &&
                            !audition.haspaid ? (
                                <ApplicationPaymentForm
                                    user={user}
                                    audition={audition}
                                    alert={props.alert}
                                    history={props.history}
                                />
                            ) : (
                                <ApplicationForm
                                    user={user}
                                    audition={audition}
                                    applyAudition={props.applyAudition}
                                    alert={props.alert}
                                    history={props.history}
                                    match={props.match}
                                />
                            )}
                        </section>
                        <Footer />
                    </div>
                </section>
            </main>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        alert: state.alert,
        audition: state.audition,
        appliedAuditions: state.audition.appliedAuditions
    };
};

export default withRouter(connect(mapStateToProps, actions)(ApplyAudition));
