import React from 'react';
import store from 'store';

import Profile1 from '../assets/img/png/photo-avatar.png';

export const renderUserProfile = () => {
    const userData = store.get('kmt-user');
    return (
        <div className="item d-flex align-items-center">
            <div
                className=" mr-2"
                style={{
                    height: '3rem',
                    width: '3rem',
                    borderRadius: '50px',
                    backgroundPosition: 'center center',
                    backgroundImage: `url(${userData.imageUrl || Profile1})`,

                    backgroundSize: 'cover',
                }}
            ></div>
            <div className=" pt-3">
                <p className="create-post-details">
                    {userData.fullName}
                    <br />
                    <small
                        className="text-white pt-1"
                        style={{
                            background: `${
                                userData.areaOfInterest === 'talent'
                                    ? '#7158e2'
                                    : '#007bff'
                            }`,
                        }}
                    >
                        {userData.areaOfInterest === 'talent' ? 'Talent' : 'Scout'}
                    </small>
                </p>
            </div>
        </div>
    );
};
