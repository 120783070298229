import React, {useEffect} from 'react';
import ProfileSettings from './ProfileSettings';
import PasswordSettings from './PasswordSettings';
import PrivacySettings from './PrivacySettings';
import NotificationSettings from './NotificationSettings';
import SupportSettings from './SupportSettings';
import {withRouter} from 'react-router-dom';
import {scrollTop} from '../../../utils/scrollTop';

const SettingsContents = (props) => {
    useEffect(() => {
        scrollTop();
    }, [props.match.params.slug]);

    const renderSettingsDetailsBasedOnCurrentSlug = (slug) => {
        switch (slug) {
            case 'update-profile':
                return <ProfileSettings />;
            case 'change-password':
                return <PasswordSettings />;
            case 'privacy':
                return <PrivacySettings />;
            case 'account-notifications':
                return <NotificationSettings />;
            case 'support':
                return <SupportSettings />;

            default:
                return <ProfileSettings />;
        }
    };

    return (
        <div className="setting-content">
            {renderSettingsDetailsBasedOnCurrentSlug(props.match.params.slug)}
        </div>
    );
};

export default withRouter(SettingsContents);
