/*
* Created by moscoworld
* */
const BDCReverseGeocode=function(localityLanguage,endpoint,server) {
    this.endpoint=endpoint ? endpoint : 'reverse-geocode-client';
    this.server=server ? server : 'api.bigdatacloud.net';
    this.localityLanguage=localityLanguage ? localityLanguage : 'en';
};

BDCReverseGeocode.prototype={
    setApi:function(api) {
        this.api=api;
        return this;
    },
    getApi:function() {
        return this.api;
    },
    getClientLocation:function(latLng,cb) {
        if (typeof latLng ==='function' && !cb) {
            cb=latLng;
            latLng=null;
        } else if (latLng ==='function') {
            latLng=latLng();
        }
        if (!cb) return false;

        this.callApi(this.processLatLng(latLng),function(result) {
            cb(result);
        },function(err) {
            console.error(err);
            cb(false);
        });
    },
    processLatLng:function(latLng) {
        const result={};
        if (!latLng || latLng === -1) return {};
        if (latLng.coords) {
            latLng=latLng.coords;
        }
        if (!typeof latLng.latitude) {
            if (latLng.lat) {
                latLng.latitude=latLng.lat;
            }
        }
        if (!typeof latLng.longitude) {
            if (latLng.long) {
                latLng.longitude=latLng.long;
            }
            if (latLng.lng) {
                latLng.longitude=latLng.lng;
            }
        }
        if (typeof latLng.latitude!= 'undefined') {
            result.latitude=parseFloat(parseFloat(latLng.latitude).toFixed(5));
        }
        if (typeof latLng.longitude!= 'undefined') {
            result.longitude=parseFloat(parseFloat(latLng.longitude).toFixed(5));
        }
        return result;
    },
    callApi:function(payload,cb) {
        const xhr = new XMLHttpRequest()
        xhr.open(
            'GET',
            'https://'+this.server+'/data/'+this.endpoint+'?'+this.prepareData(payload),
            true
        );
        xhr.onreadystatechange = function() {
            if (this.readyState === XMLHttpRequest.DONE) {
                if (this.status === 200) {
                    try {
                        cb(JSON.parse(this.responseText))
                    } catch (e) {
                        cb(false)
                    }
                } else {
                    try {
                        // var result=JSON.parse(this.responseText);
                        // console.error(result,this.status);
                        cb(false);
                    } catch (e) {
                        // console.error(this.responseText,this.status);
                        cb(false);
                    }
                }
            }
        }
        xhr.send();
    },
    prepareData:function(payload) {
        let data=[];
        let hasLocalityLanguage = false;
        if (payload) {
            for (let i in payload) {
                switch(i) {
                    case 'localityLanguage':
                        hasLocalityLanguage=true;
                        break;
                }
                data.push(encodeURIComponent(i)+'='+encodeURIComponent(payload[i]));
            }
        }
        if (!hasLocalityLanguage) data.push('localityLanguage='+this.localityLanguage);
        data=data.join('&');
        return data;
    }
}

// _w.BDCReverseGeocode=BDCReverseGeocode;
export default BDCReverseGeocode;
