import React, {useState, useEffect} from 'react';
import {validateAll} from 'indicative/validator';
import useAlert from '../../CustomHooks/useAlert';
import {renderUserProfile} from '../../../utils/renderUserProfile';
import XIcon from '../../../assets/img/svg/x.svg';
// import PlusIcon from '../../../assets/img/svg/plus-icon.svg';
import PhotoIcon from '../../../assets/img/svg/picture-icon.svg';
import VideoIcon from '../../../assets/img/svg/video-icon.svg';
import store from 'store';
// import axios from 'axios';
import {useToasts} from 'react-toast-notifications';
import $ from 'jquery';
import useGeoLocation from '../../../utils/useGeoLocation';

// Create Post Modal Window Form
const CreatePostModal = (props) => {
    const {addToast} = useToasts();
    const userData = store.get('kmt-user');

    const [loading, setLoading] = useState(false);
    const [post, setPost] = useState('');
    const [media, setMedia] = useState(null);
    const [talentCategories] = useState(userData.talentCategories || []);
    const [hideComment, setHideComment] = useState(false);
    const [errors, setErrors] = useState('');
    const userCurrentGeoLocation = useGeoLocation();

    // Alert error if post is empty
    useEffect(() => {
        if (errors) addToast(errors.post, {appearance: 'error'});
    }, [addToast, errors]);

    // Handle upload
    function uploadEitherImageOrVideo(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                if (
                    input.files[0].type === 'image/jpeg' ||
                    input.files[0].type === 'image/jpg' ||
                    input.files[0].type === 'image/png'
                ) {
                    $('.media-add').html(
                        '<img width="100%" alt="post" src="' + e.target.result + '"/>'
                    );

                    setMedia(input.files[0]);
                    // Cropping deactivated
                    // props.handleToCropImage(reader.result);
                    // $('#cropImage').modal('show');
                } else if (
                    input.files[0].type === 'video/mp4' ||
                    input.files[0].type === 'video/webm' ||
                    input.files[0].type === 'video/ogg'
                ) {
                    $('.media-add').html(
                        '<video id="myVideo" width="100%" controls><source src="' +
                            e.target.result +
                            '"/></video>'
                    );

                    setMedia(input.files[0]);
                }
            };
            reader.readAsDataURL(input.files[0]);
        }
    }
    const handleUpload = () => {
        $('#uploadImage_file, #uploadVideo_file').click();
        $('#uploadImage_file, #uploadVideo_file').change(function (e) {
            e.preventDefault();
            uploadEitherImageOrVideo(this);
        });
    };

    const handlePostSubmit = (e) => {
        e.preventDefault();

        const data = {
            // post,
        };
        const rules = {
            // post: 'required',
        };

        const messages = {
            // 'post.required': "You can't submit an empty post. Try add a few words!",
        };

        validateAll(data, rules, messages)
            .then(() => {
                createPost();
            })
            .catch((errors) => {
                const formattedErrors = {};
                errors.forEach((error) => (formattedErrors[error.field] = error.message));
                setErrors(formattedErrors);
            });

        const createPost = async () => {
            if (props.blobImage?.size > 0 || media?.name.length > 0 || post?.length > 0) {
                setLoading(true);
                // Media Uploaded
                let file = new FormData();
                // // Update the formData object
                file.append('file', props.blobImage?.size > 0 ? props.blobImage : media);
                file.set('body', post);
                file.set('talentCategories', [talentCategories[0].name]);
                file.set('hideComment', hideComment);
                file.set('country', userCurrentGeoLocation.country);
                file.set('region', userCurrentGeoLocation.region);

                // Send Request
                await props.createNewPost(file, props.history);
                setLoading(false);
                setPost('');
                setMedia(null);
                setHideComment(false);
                $('.media-add').removeAttr('style');
                $('.media-add').html('');
                $('#createPost').modal('hide');
            } else {
                setErrors({
                    post:
                        "You can't submit an empty post. Try add a few words or upload image or video!",
                });
            }
        };
    };

    const handleCloseModal = () => {
        setLoading(false);
        setPost('');
        setMedia(null);
        setHideComment(false);
        setErrors('');
        props.handleCropDataClear();
    };

    useAlert(props.alert);

    return (
        <div
            className="modal fade"
            data-backdrop="static"
            data-keyboard="false"
            id="createPost"
        >
            <div
                className="modal-dialog modal-wrapper"
                style={{maxWidth: '573px', marginTop: '4%'}}
            >
                <div className=" modal-content-wrap">
                    <div className="header-wrap">
                        <div className="close_">
                            <label data-dismiss="modal" onClick={handleCloseModal}>
                                <img src={XIcon} alt="" />
                                <span>Close</span>
                            </label>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="container wrapr">
                            <form
                                className="create-post-form"
                                onSubmit={handlePostSubmit}
                            >
                                <div>
                                    <p>Create A Post</p>
                                </div>
                                <hr />

                                {/* user profile */}
                                {renderUserProfile()}

                                <section className="list-wrap">
                                    <textarea
                                        className="form-control create-post-textarea"
                                        placeholder="What do you want to talk about?"
                                        name="post"
                                        value={post}
                                        onChange={(e) => setPost(e.target.value)}
                                        onFocus={() => setErrors('')}
                                    />
                                    <div className="media-add">
                                        {props.completedCrop && (
                                            <div>
                                                <canvas
                                                    className="cropped-image-canvas"
                                                    ref={props.previewCanvasRef}
                                                    style={{
                                                        width:
                                                            props.completedCrop?.width ??
                                                            0,
                                                        height:
                                                            props.completedCrop?.height ??
                                                            0,
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </section>
                                <section className="pt-3">
                                    <div className="container">
                                        <div className="row d-flex justify-content-between align-items-center">
                                            <div className="col-md-8 create-post-icons">
                                                <div className="action d-none dropup show post-icon">
                                                    {/* <Link
                                                        className="dropdown-toggle"
                                                        to="#"
                                                        role="button"
                                                        id="dropdownMenuLink"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <img src={PlusIcon} alt="" />
                                                    </Link> */}

                                                    {/* <div
                                                        className="dropdown-menu"
                                                        aria-labelledby="dropdownMenuLink"
                                                    >
                                                        <h6 className="dropdown-header">
                                                            Select Categories
                                                        </h6>
                                                        <div className="dropdown-item">
                                                            <input
                                                                className="mr-2"
                                                                type="checkbox"
                                                                id="talentCategories"
                                                                name="talentCategories[]"
                                                                value={
                                                                    talentCategories[0]
                                                                        .name
                                                                }
                                                                checked
                                                                disabled
                                                            />
                                                            <label
                                                                htmlFor={
                                                                    talentCategories[0]
                                                                        .name
                                                                }
                                                            >
                                                                {talentCategories[0].name}
                                                            </label>
                                                        </div>
                                                        {props.talentCategories.map(
                                                            (category, index) => (
                                                                <div
                                                                    className="dropdown-item"
                                                                    key={category.id}
                                                                >
                                                                    <input
                                                                        className="mr-2"
                                                                        type="checkbox"
                                                                        id="talentCategories"
                                                                        name="talentCategories[]"
                                                                        disabled
                                                                        value={
                                                                            category.name
                                                                        }
                                                                    />
                                                                    <label
                                                                        htmlFor={
                                                                            category.name
                                                                        }
                                                                    >
                                                                        {category.name}
                                                                    </label>
                                                                </div>
                                                            )
                                                        )}
                                                    </div> */}
                                                </div>
                                                <div className="post-icon">
                                                    <img
                                                        className="uploadImage"
                                                        src={PhotoIcon}
                                                        alt=""
                                                        onClick={handleUpload}
                                                    />
                                                    <input
                                                        type="file"
                                                        id="uploadPhoto_file"
                                                        accept="image/*"
                                                        hidden
                                                    />
                                                </div>
                                                <div className="post-icon">
                                                    <img
                                                        className="uploadVideo ="
                                                        src={VideoIcon}
                                                        alt=""
                                                        onClick={handleUpload}
                                                    />
                                                    <input
                                                        type="file"
                                                        id="uploadVideo_file"
                                                        hidden
                                                    />
                                                </div>

                                                <div className="post-icon d-flex">
                                                    <input
                                                        className="mt-2 p-2"
                                                        type="checkbox"
                                                        id="hideComment"
                                                        name="hideComment"
                                                        onChange={() => {
                                                            setHideComment(!hideComment);
                                                        }}
                                                    />
                                                    <label
                                                        htmlFor="hideComment"
                                                        className="small"
                                                    >
                                                        Disable Comment
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="action">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-block text-center"
                                                        disabled={loading}
                                                    >
                                                        {loading ? 'Sending...' : 'Post'}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreatePostModal;
