import {useEffect} from 'react';
import {useToasts} from 'react-toast-notifications';

const useAlert = (alert = {}) => {
    const {addToast} = useToasts();
    useEffect(() => {
        if (alert.message) {
            addToast(alert.message, {appearance: `${alert.type}`, autoDismiss: true, autoDismissTimeout: 10000});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alert.message]);
};
export default useAlert;
