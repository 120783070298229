import React, {useEffect} from 'react';
import {Link, useHistory, withRouter} from 'react-router-dom';
import {leftSideBar} from './data';
import {connect} from 'react-redux';
import * as actions from '../../redux/actions';
import ProfilePicture from '../../assets/img/svg/user.svg';
import store from 'store';
import {statCounter} from '../../utils/statCounter';

const StatusUser = () => {
    const userData = store.get('kmt-user');
    return (
        <>
            <div
                style={{
                    background: `url(${userData.imageUrl || ProfilePicture})`,
                }}
            >
                <span
                    className={`status_tag ${userData.areaOfInterest}`}
                >
                    {userData.areaOfInterest[0].toUpperCase()}
                </span>
            </div>
            <div>
                <p>
                    {userData.fullName} <small>{userData.email}</small>
                </p>
            </div>
        </>
    );
};

const StatusBars = (props) => {
    const history = useHistory();
    return (
        <div className="stats">
            <label onClick={() => history.push('/profile')}>
                {props.postsCount || 0}
                <small>Post</small>
            </label>
            <label onClick={() => history.push('/profile/0/followers')}>
                {statCounter(props.followersCount)}
                <small>Followers</small>
            </label>
            <label onClick={() => history.push('/profile/0/following')}>
                {statCounter(props.followingsCount)}
                <small>Following</small>
            </label>
        </div>
    );
};
const StatusHolder = ({records}) => {
    const stats = records || {};
    return (
        <section className="sidebar-header">
            <StatusUser />
            <StatusBars
                postsCount={stats.postsCount || 0}
                followersCount={stats.followersCount || 0}
                followingsCount={stats.followingsCount || 0}
            />
        </section>
    );
};

const LeftSideBarLinks = () => {
    const location = window.location.pathname;
    return (
        <section className="sidebar-content">
            <ul>
                {leftSideBar.links.map((link, i) => {
                    return (
                        <li
                            key={i}
                            className={`${
                                location.includes(link.url) === true ? 'active' : ''
                            }`}
                        >
                            <Link to={link.url}>
                                <i className="icon">
                                    <img src={link.imgSrc} alt={link.alt} />
                                </i>
                                <span>{link.tag}</span>
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </section>
    );
};

// const LeftSideBarFooter = () => {
//     return (
//         <section className="base">
//             <div className="container">
//                 <Link to="/faq">Help</Link>
//                 <Link to="/terms">Terms</Link>
//             </div>
//         </section>
//     );
// };

// Main
const LeftSideBar = (props) => {
    const match = props.match.path;
    const {userStats, user} = props.user;
    useEffect(() => {
        props.dispatchUserStats();
        if (!user.userId) {
            props.fetchUserProfile(0, props.history);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match]);

    return (
        <nav id="sidebar">
            <div className="container">
                <StatusHolder records={userStats} />
                <LeftSideBarLinks />
            </div>
        </nav>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default withRouter(connect(mapStateToProps, actions)(LeftSideBar));
