import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import LeftSideBar from '../../components/Feeds/LeftSideBar';
import Header from '../../components/Feeds/Header';
import Footer from '../../components/Feeds/Footer';
import ChatFriends from '../../components/Feeds/Messages/ChatFriends';
import ChatMessages from '../../components/Feeds/Messages/ChatMessages';
import io from 'socket.io-client';
import store from 'store';
import {connect} from 'react-redux';
import * as actions from '../../redux/actions';
import {withRouter} from 'react-router-dom';
import useAlert from '../../components/CustomHooks/useAlert';
import $ from 'jquery';
import ReportUser from '../../components/Feeds/Messages/ReportUser';
import DeleteChat from '../../components/Feeds/Messages/DeleteChat';
import {scrollTop} from '../../utils/scrollTop';

const Messages = (props) => {
    const [loading, setLoading] = useState(false);
    const [messageLoading, setMessageLoading] = useState(false);

    const [customSocket, setCustomSocket] = useState(null);

    const [conversations, setConversations] = useState([]);
    const [userInfo, setUserInfo] = useState({});
    const [previousMessages, setPreviousMessages] = useState([]);
    const [refreshTimeout, setRefreshTimeout] = useState(undefined);

    const [text, setText] = useState('');
    const [typing, setTyping] = useState('');
    const [, setStatus] = useState('');
    const [uploadedFile, setUploadedFile] = useState(null);

    const [conversationSearch, setConversationSearch] = useState('');

    // get users conversations when componentDidMount
    useEffect(() => {
        scrollTop();
        // if the conversation is updated with the kept user data, update state
        if (props.conversations) setConversations(props.conversations);

        // get conversations
        getConversations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // get conversations
    const getConversations = async () => {
        setLoading(true);
        await props.fetchAllConversations(props.history);
        setLoading(false);
    };

    // setState when componentDidUpdate with conversations
    useEffect(() => {
        setConversations(props.conversations);

        // once conversations get updated, fetch first chat history
        props.conversations[0] &&
            getFirstTopChatHistoryOnRender(props.conversations[0].userInfo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.conversations]);

    // Handle fetching of top first chat history
    const getFirstTopChatHistoryOnRender = async (userInfo) => {
        // update userInfo
        setUserInfo(userInfo);
        setMessageLoading(true);
        await props.fetchPreviousConversations(userInfo.id, props.history);
        setMessageLoading(false);
    };

    // setState when componentDidUpdate with previousMessages
    useEffect(() => {
        if (props.previousMessages) setPreviousMessages(props.previousMessages);
    }, [props.previousMessages]);

    // onclick on the user to chat with
    const handleChatterClick = async (userInfo) => {
        // update userInfo
        setUserInfo(userInfo);
        setMessageLoading(true);
        await props.fetchPreviousConversations(userInfo.id, props.history);
        setMessageLoading(false);
    };

    // This hook handles componentDidUpdate via props, conversation and userInfo
    //Step 1: Initiate socket connection when conversations && user id exist
    //Step 2: check if connection was successful
    // Step 3: setState of socket to customSocket to be used outside the hook
    // Step 4: Emit to server message
    // Step 5: Listen to the typing event and update typing state
    // Step 6: Listen to connection status
    useEffect(() => {
        if (conversations && userInfo && userInfo.id) {
            const socket = io(process.env.REACT_APP_SOCKET_URL, {
                query: {
                    chatToken: store.get('kmt-user') && store.get('kmt-user').accessToken,
                    chatID2: userInfo.id,
                },
            });

            // check for connection
            if (socket !== undefined) {
                if(customSocket){
                    customSocket.disconnect();
                }
                setCustomSocket(socket);

                // Emit to server messages
                socket.emit('previous', {});

                // Event listener on typing
                socket.on('typing', (info) => {
                    setTyping(info.isTyping ? 'Typing message....' : '');
                });

                //Get Status from server
                socket.on('status', (data) => {
                    //get message status
                    setStatus(typeof data === 'object' ? data.message : data);

                    //If status is clear, clear text
                    if (data.clear) {
                        setText('');
                        setTyping('');
                    }
                });

                socket.on('read', (data) => {
                    setPreviousMessages([]);
                    socket.emit('previous', {});
                });
            }
        }
    }, [conversations, userInfo]);

    // Display message after submitting typed text in chat
    useEffect(() => {
        customSocket &&
            customSocket.on('msg', (data) => {
                if (data && data.length) {
                    let mergeData = [...previousMessages, data[0]];

                    const filteredArr = mergeData.reduce((acc, current) => {
                        const x = acc.find(
                            (item) => item.messageId === current.messageId
                        );
                        if (!x) {
                            return acc.concat([current]);
                        } else {
                            return acc;
                        }
                    }, []);

                    setPreviousMessages(filteredArr);
                }
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousMessages]);

    // Emit when typing
    const timeoutFunction = () => {
        customSocket.emit('typing', {isTyping: false});
    };

    // Handle on press using the send button
    const handleSendMessage = (e) => {
        e.preventDefault();
        const tokenCopy = store.get('kmt-user')?.accessToken;
        const userIdCopy = userInfo.id;

        if (uploadedFile) {
            const reader = new FileReader();

            reader.onload = () => {
                const dataUrl = reader.result;
                // Emit to server msg
                customSocket.emit('msg', {
                    token: tokenCopy,
                    user1: userIdCopy,
                    msg: text,
                    fileData: dataUrl,
                });
            };

            reader.readAsDataURL(uploadedFile);
            setText('');
            setUploadedFile('');
        } else {
            // Emit to server msg
            customSocket.emit('msg', {
                token: tokenCopy,
                user1: userIdCopy,
                msg: text,
            });
            setText('');
            setUploadedFile('');
        }

        setText('');
        setUploadedFile('');
        // Hide the upload dropup actions
        $('.card-footer .more-options').removeClass('show');
    };

    // handle key press up and set a timeout when not pressing
    const handleKeyUp = (e) => {
        clearTimeout(refreshTimeout);

        if (e.keyCode === 13) {
            customSocket.emit('typing', {isTyping: false});
            handleSendMessage(e);
        } else {
            customSocket.emit('typing', {isTyping: true});
            setRefreshTimeout(setTimeout(timeoutFunction, 1500));
        }
    };

    // Handle on typing text change
    const handleInputChange = (e) => {
        setText(e.target.value);
    };

    // handle file upload in chat
    const handleMediaUploader = (name) => {
        switch (name) {
            case 'video':
                triggerUploader('video_uploader');
                break;
            case 'image':
                triggerUploader('image_uploader');
                break;
            default:
                triggerUploader('doc_uploader');
        }
    };
    // Trigger uploader file window
    const triggerUploader = (id) => {
        $(`#${id}`).click();
        $(`#${id}`).change(function (e) {
            e.preventDefault();
            if (this.files && this.files[0]) {
                setUploadedFile(this.files[0]);
            }
        });
    };

    // Searching converstaions
    const handleMessageSearch = (e) => {
        e.preventDefault();
        setConversationSearch(e.target.value);
    };

    //delete chat from socket
    const handleDeleteChatSocket = () => {
        customSocket.emit('previous', {});
    };

    // Alert message if error occur on fetch
    useAlert(props.alert);

    return (
        <>
            <Helmet>
                <title>Talents Feeds</title>
            </Helmet>
            <main className="main feeds">
                <LeftSideBar />
                <section id="wrapper">
                    <Header />
                    <div className="container content_">
                        <section className="messaging">
                            <div className="row">
                                <ChatFriends
                                    conversations={conversations}
                                    userInfo={userInfo}
                                    loading={loading}
                                    handleChatterClick={handleChatterClick}
                                    handleMessageSearch={handleMessageSearch}
                                    conversationSearch={conversationSearch}
                                />
                                <ChatMessages
                                    conversations={conversations}
                                    loading={loading}
                                    userInfo={userInfo}
                                    messageLoading={messageLoading}
                                    previousMessages={previousMessages}
                                    // Input Field props
                                    typing={typing}
                                    text={text}
                                    handleInputChange={handleInputChange}
                                    handleKeyUp={handleKeyUp}
                                    handleSendMessage={handleSendMessage}
                                    handleMediaUploader={handleMediaUploader}
                                    uploadedFile={uploadedFile}
                                />
                            </div>
                        </section>
                        {/* Report user*/}
                        <ReportUser userId={userInfo.id} />

                        {/* clear chat history*/}
                        <DeleteChat
                            userId={userInfo.id}
                            handleDeleteChatSocket={handleDeleteChatSocket}
                        />

                        <Footer />
                    </div>
                </section>
            </main>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        conversations: state.message.conversations,
        previousMessages: state.message.previousMessages,
        alert: state.alert,
        keptUserData: state.message.keptUserData,
    };
};
export default withRouter(connect(mapStateToProps, actions)(Messages));
