import React from 'react';
// import RegistrationDefault from '../../components/layout/RegistrationDefault';
import {useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../redux/actions';
import Succ from '../../assets/img/svg/success-checkmark.svg';
import {displayLoader} from '../../utils/displayLoader';
import LeftViewOnAuthPages from '../../components/Blocks/LeftViewOnAuthPages';
import useAlert from '../../components/CustomHooks/useAlert';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ConfirmEmail = (props) => {
    let query = useQuery();

    // Handle reset password
    const handleClick = async (e) => {
        e.preventDefault();
        const data = {
            token: query.get('key'),
        };
        await props.activateNormalUser(data, props.history);
    };

    useAlert(props.alert);

    return (
        <>
            {/* <RegistrationDefault
                pageTitle={'Account Activation!'}
                linkName={'Login'}
                linkUrl={'/login'}
                linkStyle={'adj'}
                pageTag="Confirm Email"
            /> */}
            <main className="account">
                <LeftViewOnAuthPages step={1} />
                <section className="right-pad">
                    {props.activateStart && displayLoader('Activating Account')}
                    <div className="container">
                        <form>
                            <section className="animated bounceIn">
                                <div className="container">
                                    <section className="title">
                                        <h3>Complete Email Verification.</h3>
                                    </section>
                                    <section className="mt-5">
                                        <div className="col-lg-12 text-center">
                                            <div className="d-block">
                                                <img src={Succ} width="100rem" alt="" />
                                            </div>
                                            <div className="d-block mt-4">
                                                <button
                                                    onClick={handleClick}
                                                    className="btn btn-primary px-5 btn-lg font-weight-bold"
                                                    disabled={
                                                        props.activateStart ? true : false
                                                    }
                                                >
                                                    Proceed to Activate
                                                </button>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </section>
                        </form>
                    </div>
                </section>
            </main>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        activateStart: state.auth.activateStart,
        alert: state.alert,
    };
};

export default connect(mapStateToProps, actions)(ConfirmEmail);
