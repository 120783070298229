import Actions from '../actions/actionTypes';
import store from 'store';

const INITIAL_STATE = {
    loading: false,

    feedLoading: false,
    feeds: [],
    recentMediaFeeds: [],
    talentCategories: [],

    singlePost: {
        comments: [],
    },

    error: null,
    follow: null,
    isLike: false,
    isCommentStart: false,
    comment: null,

    shareStatus: false,
    keptShareData: {},
};

const FeedReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Actions.LOADING:
            return {
                ...state,
                loading: true,
            };

        case Actions.FETCH_FEEDS_START:
            return {
                ...state,
                feedLoading: action.payload,
            };

        case Actions.FETCH_FEEDS:
            let newFeeds = action.payload.feeds.feeds.filter(
                (feed) => feed.postId !== undefined
            );
            let talentCatFeeds = action.payload.feeds.extraFeeds.talentCatFeeds.filter(
                (feed) => feed.postId !== undefined
            );
            let otherFeeds = action.payload.feeds.extraFeeds.otherFeeds.filter(
                (feed) => feed.postId !== undefined
            );
            let auditions = action.payload.feeds.extraFeeds.auditions;

            const allFeeds = [...newFeeds, ...talentCatFeeds, ...otherFeeds, ...auditions].sort(
                function (a, b) {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                }
            );

            return {
                ...state,
                feedLoading: false,
                feeds: allFeeds,
                talentCategories: action.payload.feeds.talentCategories,
            };

        case Actions.FETCH_SINGLE_POST:
            return {
                ...state,
                singlePost: action.payload,
            };

        case Actions.FOLLOW_REQUEST:
            return {
                ...state,
                requestId: action.payload.isRequestSent,
                singlePost: {
                    ...state.singlePost,
                    requestId: action.payload.isRequestSent,
                },
                feeds: state.feeds.map((feed) => {
                    if (feed.creatorId === action.payload.userId) {
                        return {
                            ...feed,
                            requestId: action.payload.isRequestSent,
                        };
                    }
                    return feed;
                }),
            };

        case Actions.CANCEL_FOLLOW_REQUEST:
            return {
                ...state,
                requestId: action.payload.isRequestSent,
                singlePost: {
                    ...state.singlePost,
                    requestId: action.payload.isRequestSent,
                },
                feeds: state.feeds.map((feed) => {
                    if (feed.creatorId === action.payload.userId) {
                        return {
                            ...feed,
                            requestId: action.payload.isRequestSent,
                        };
                    }
                    return feed;
                }),
            };

        case Actions.FOLLOW_STATUS:
            return {
                ...state,
                follow: action.payload.isFollowOrUnfollow,
                singlePost: {
                    ...state.singlePost,
                    isFollowing: action.payload.isFollowOrUnfollow,
                },
                feeds: state.feeds.map((feed) => {
                    if (feed.creatorId === action.payload.userId) {
                        return {
                            ...feed,
                            isFollowing: action.payload.isFollowOrUnfollow,
                        };
                    }
                    return feed;
                }),
            };

        case Actions.LIKE_POST_STATUS:
            const isLike = action.payload.isLike;
            return {
                ...state,
                isLike: isLike,
                singlePost: {
                    ...state.singlePost,
                    isLiked: isLike,
                    likersCount: isLike
                        ? state.singlePost.likersCount + 1
                        : state.singlePost.likersCount - 1,
                },
                feeds: state.feeds.map((feed) => {
                    if (feed.postId === action.payload.postId) {
                        return {
                            ...feed,
                            isLiked: isLike,
                            likersCount: isLike
                                ? feed.likersCount + 1
                                : feed.likersCount - 1,
                        };
                    }
                    return feed;
                }),
            };

        case Actions.UNLIKE_POST_STATUS:
            const isUnLike = action.payload.isUnLike;
            return {
                ...state,
                isLike: isUnLike,
                singlePost: {
                    ...state.singlePost,
                    isLiked: isUnLike,
                    likersCount: isUnLike
                        ? state.singlePost.likersCount
                        : state.singlePost.likersCount - 1,
                },
                feeds: state.feeds.map((feed) => {
                    if (feed.postId === action.payload.postId) {
                        return {
                            ...feed,
                            isLiked: isUnLike,
                            likersCount: isUnLike
                                ? feed.likersCount
                                : feed.likersCount - 1,
                        };
                    }
                    return feed;
                }),
            };

        case Actions.COMMENT_START:
            return {
                ...state,
                isCommentStart: true,
                singlePost: {
                    ...state.singlePost,
                    isCommentStart: true,
                },
                feeds: state.feeds.map((feed) => {
                    if (feed.postId === action.payload) {
                        return {
                            ...feed,
                            isCommentStart: true,
                        };
                    }
                    return feed;
                }),
            };

        case Actions.COMMENT_SUCCESSFUL:
            const userData = store.get('kmt-user');
            return {
                ...state,
                loading: false,
                isCommentStart: false,
                comment: action.payload.comment,
                feeds: state.feeds.map((feed) => {
                    if (feed.postId === action.payload.comment.postId) {
                        return {
                            ...feed,
                            isCommentStart: false,
                            commentorsCount: feed.commentorsCount + 1,
                            comments: [
                                {
                                    comment: action.payload.comment.commentBody,
                                    commentId: Math.floor(
                                        Math.random() * Math.floor(1000)
                                    ),
                                    commentorName: userData && userData.fullName,
                                },
                                ...feed.comments,
                            ],
                        };
                    }
                    return feed;
                }),
                singlePost: {
                    ...state.singlePost,
                    isCommentStart: false,
                    commentorsCount: state.singlePost.commentorsCount + 1,
                    comments: [
                        {
                            comment: action.payload.comment.commentBody,
                            commentId: Math.floor(Math.random() * Math.floor(1000)),
                            commentorName: userData && userData.fullName,
                            commentorImage: userData && userData.imageUrl,
                        },
                        ...state.singlePost.comments,
                    ],
                },
            };

        case Actions.COMMENT_FAILURE:
            return {
                ...state,
                loading: false,
                isCommentStart: false,
                comment: {},
                singlePost: {
                    ...state.singlePost,
                    isCommentStart: false,
                    commentorsCount: state.singlePost.commentorsCount,
                    comments: [...state.singlePost.comments],
                },
                feeds: state.feeds.map((feed) => {
                    if (feed.postId === action.payload.comment.postId) {
                        return {
                            ...feed,
                            isCommentStart: false,
                            commentorsCount: feed.commentorsCount,
                            comments: [...feed.comments],
                        };
                    }
                    return feed;
                }),
            };

        case Actions.SHARE_STATUS:
            return {
                ...state,
                shareStatus: action.payload.isShared,
                singlePost: {
                    ...state.singlePost,
                    shareStatus: action.payload.isShared,
                },
                feeds: state.feeds.map((feed) => {
                    if (feed.postId === action.payload.sharePostId) {
                        return {
                            ...feed,
                            shareStatus: action.payload.isShared,
                        };
                    }
                    return feed;
                }),
            };

        case Actions.UPDATE_POST:
            return {
                ...state,
                singlePost: {
                    ...action.payload.post,
                },
                feeds: state.feeds.map((feed) => {
                    if (feed.postId === action.payload.post.postId) {
                        return {
                            ...feed,
                            body: action.payload.post.body,
                        };
                    }
                    return feed;
                }),
            };

        case Actions.DELETE_POST:
            return {
                ...state,
                feeds: state.feeds.filter(
                    (feed) => feed.postId !== action.payload.post.postId
                ),
            };

        case Actions.KEEP_DATA_TO_SHARE:
            return {
                ...state,
                keptShareData: action.payload,
            };

        case Actions.FETCH_RECENT_MEDIA_FEEDS:
            return {
                ...state,
                recentMediaFeeds: action.payload.feeds,
            };
        default:
            return state;
    }
};

export default FeedReducer;
