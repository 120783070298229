import Actions from './actionTypes';

export const alertActions = {
    success,
    error,
    info,
    warning,
    clear,
};

function success(message) {
    return {type: Actions.ALERT_SUCCESS, message};
}

function error(message) {
    return {type: Actions.ALERT_ERROR, message};
}

function info(message) {
    return {type: Actions.ALERT_INFO, message};
}

function warning(message) {
    return {type: Actions.ALERT_WARNING, message};
}

function clear() {
    return {type: Actions.ALERT_CLEAR};
}
